import React from "react";
import {
  ConfigurationProvider,
  useConfigurationLoader,
} from "../hooks/useConfiguration";
import { ConfigurationStatus } from "../hooks/useConfiguration/types";
import { App } from "./App";
import { ErrorPage } from "./ErrorPage";
import { LoadingIndicator } from "./shared/LoadingIndicator";
import { CookieConsent } from "./shared/Cookies/CookieConsent";

export function Root() {
  const { configurationState } = useConfigurationLoader();

  if (configurationState.status === ConfigurationStatus.Fetching) {
    return <LoadingIndicator position="fullscreen" />;
  }

  if (configurationState.status === ConfigurationStatus.Error) {
    return <ErrorPage error={configurationState.error} />;
  }

  return (
    <ConfigurationProvider value={configurationState.configuration}>
      <CookieConsent />
      <App />
    </ConfigurationProvider>
  );
}
