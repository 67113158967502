import {
  Button,
  Flex,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ServerError } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Icons } from "../shared/Icons";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Card } from "../shared/Card";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { EmptyListAlert } from "../shared/EmptyListAlert";
import {
  productPackageApi,
  ProductPackageDetails,
  ProductPackageStyle,
} from "../../api/productPackageApi";
import { AddProductPackageStyleModal } from "./AddProductPackageStyleModal";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../../routes";

export function ProductPackagesStyleList({
  productPackageId,
  productPackageDetails,
  customerId,
  onUpdate,
}: {
  productPackageId: string;
  productPackageDetails: ProductPackageDetails;
  customerId: string;
  onUpdate: (id: string) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();

  const [addStyleModalVisible, setAddStyleModalVisible] =
    useState<boolean>(false);

  return (
    <Card
      titleContent={t("productPackage.styles")}
      extraContent={
        <Button size="sm" onClick={() => setAddStyleModalVisible(true)}>
          {t("general.add")}
        </Button>
      }
    >
      {addStyleModalVisible && (
        <AddProductPackageStyleModal
          productPackageId={productPackageId}
          customerId={customerId}
          onClose={() => setAddStyleModalVisible(false)}
          onSuccess={() => {
            setAddStyleModalVisible(false);
            onUpdate(productPackageDetails.id);
            toast({
              title: t("general.created"),
              status: "success",
            });
          }}
        />
      )}
      <StyleTable
        productPackageId={productPackageId}
        styles={productPackageDetails.productPackageStyles}
        customerId={customerId}
        onRemoveSuccess={() => {
          onUpdate(productPackageDetails.id);
        }}
      />
    </Card>
  );
}

function StyleTable({
  productPackageId,
  styles,
  customerId,
  onRemoveSuccess,
}: {
  productPackageId: string;
  styles: ProductPackageStyle[] | null;
  customerId: string;
  onRemoveSuccess: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [error, setError] = useState<ServerError>();
  const [styleToRemove, setStyleToRemove] =
    useState<ProductPackageStyle | null>(null);
  const [isRemoving, removeStyleRequest] = useApiRequestCallback(
    productPackageApi.removeProductPackageStyle,
  );

  function removeStyle(styleId: string) {
    setStyleToRemove(null);
    setError(undefined);
    removeStyleRequest({
      onSuccess: () => {
        toast({
          title: t("general.deleted"),
          status: "success",
        });
        onRemoveSuccess();
      },
      onError: setError,
    }).send(productPackageId, styleId);
  }

  return (
    <>
      {isRemoving && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      <Table>
        <Thead>
          <Tr>
            <Th width="94%">{t("general.name")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {styles?.map((p) => (
            <Tr key={p.id}>
              <Td>{p.name}</Td>
              <Td>
                <Flex gridGap={4}>
                  <Button
                    size="sm"
                    as={RouterLink}
                    to={routes.style(customerId, p.id)}
                  >
                    {t("general.edit")}
                  </Button>
                  <IconButton
                    variant="outline"
                    aria-label={t("general.delete")}
                    icon={<Icons.Trash />}
                    size="sm"
                    onClick={() => setStyleToRemove(p)}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {styles?.length === 0 && <EmptyListAlert />}
      {styleToRemove && (
        <ConfirmationModal
          message={t("general.deleteConfirmation", {
            name: styleToRemove.name,
          })}
          confirmButtonText={t("general.delete")}
          confirmButtonColor="red"
          onConfirm={() => removeStyle(styleToRemove.id)}
          onClose={() => setStyleToRemove(null)}
        />
      )}
    </>
  );
}
