import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
  Button,
  ModalCloseButton,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  stylesApi,
  StyleProductVariant,
  StyleProductGroup,
} from "../../api/stylesApi";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { ServerError } from "../../types";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { StyleProductGroupAsyncSelect } from "./StyleProductGroupAsyncSelect";
import { StyleProductVariantAsyncSelect } from "./StyleProductVariantAsyncSelect";

export enum addEditModalStateType {
  Hidden = "Hidden",
  Edit = "Edit",
  Add = "Add",
}

export function AddEditStyleProductVariantModal({
  addEditModalState,
  productVariant,
  styleId,
  customerId,
  hasAnyProductPackages,
  onClose,
  onSuccess,
}: {
  addEditModalState: addEditModalStateType;
  productVariant: StyleProductVariant | null;
  styleId: string;
  customerId: string;
  hasAnyProductPackages: boolean;
  onClose: () => void;
  onSuccess: (styleProductVariant: StyleProductVariant) => void;
}) {
  const [error, setError] = useState<ServerError>();
  const { handleSubmit, control, setValue } = useForm<AddStyleProductVariant>({
    defaultValues: {
      styleProductGroup: productVariant?.styleProductGroup,
      styleProductVariant: productVariant,
    },
  });

  const [showChangesWarning, setShowChangesWarning] = useState<boolean>(false);

  const [createLoading, createRequest] = useApiRequestCallback(
    stylesApi.createStyleProductVariant,
  );

  const [updateLoading, updateRequest] = useApiRequestCallback(
    stylesApi.updateStyleProductVariant,
  );

  const [productGroupId, setProductGroupId] = useState<string | undefined>(
    productVariant?.styleProductGroup?.id,
  );
  const [productVariantId, setProductVariantId] = useState<string | undefined>(
    productVariant?.id,
  );

  const { t } = useTranslation();

  function onSubmit(isConfirmed: boolean) {
    if (hasAnyProductPackages && !isConfirmed) {
      setShowChangesWarning(true);
      return;
    }

    if (!productVariantId) {
      return;
    }

    if (addEditModalState === addEditModalStateType.Add) {
      createRequest({ onSuccess, onError: setError }).send(
        styleId,
        productVariantId,
      );
    }

    if (addEditModalState === addEditModalStateType.Edit) {
      updateRequest({ onSuccess, onError: setError }).send(
        styleId,
        productVariantId,
      );
    }
  }
  return (
    <>
      <Modal isOpen={true} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          {error && <ErrorDetails error={error} />}
          {(createLoading || updateLoading) && <LoadingIndicator />}
          <ModalHeader>
            {addEditModalState === addEditModalStateType.Add ? (
              <>{t("general.add")} </>
            ) : (
              <>{t("general.edit")} </>
            )}
            {t("style.productVariant").toLowerCase()}
          </ModalHeader>
          <form
            onSubmit={handleSubmit(() => onSubmit(false))}
            autoComplete="off"
          >
            <ModalBody>
              <Box m="5">
                <FormControl id="styleProductGroup">
                  <FormLabel>{t("productGroup.productGroup")}</FormLabel>
                  <Controller
                    name="styleProductGroup"
                    render={({ field, fieldState }) => (
                      <StyleProductGroupAsyncSelect
                        customerId={customerId}
                        styleId={styleId}
                        isDisabled={
                          !!productVariantId ||
                          addEditModalState === addEditModalStateType.Edit
                        }
                        isClearable={false}
                        {...field}
                        {...fieldState}
                        onChange={(productGroup) => {
                          field.onChange(productGroup);
                          setValue("styleProductGroup", productGroup);
                          return setProductGroupId(productGroup?.id);
                        }}
                      />
                    )}
                    control={control}
                  />
                </FormControl>
              </Box>
              {productGroupId && (
                <Box m="5">
                  <FormControl id="styleProductVariant" isRequired={true}>
                    <FormLabel>{t("style.productVariant")}</FormLabel>
                    <Controller
                      name="styleProductVariant"
                      render={({ field, fieldState }) => (
                        <StyleProductVariantAsyncSelect
                          customerId={customerId}
                          productGroupId={productGroupId}
                          isDisabled={false}
                          isClearable={true}
                          {...field}
                          {...fieldState}
                          onChange={(productVariant) => {
                            field.onChange(productVariant);
                            setValue("styleProductVariant", productVariant);
                            return setProductVariantId(productVariant?.id);
                          }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Box>
              )}
            </ModalBody>
            <ModalFooter>
              <Button type="submit" disabled={!productVariantId}>
                {addEditModalState === addEditModalStateType.Add ? (
                  <>{t("general.add")} </>
                ) : (
                  <>{t("general.save")} </>
                )}{" "}
              </Button>
            </ModalFooter>
          </form>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
      {showChangesWarning && (
        <ConfirmationModal
          message={t("productPackage.warningDeleteStylesFromProductPackages")}
          confirmButtonText={t("general.continue")}
          confirmButtonColor="red"
          onConfirm={() => {
            onSubmit(true);
            return setShowChangesWarning(false);
          }}
          onClose={() => setShowChangesWarning(false)}
        />
      )}
    </>
  );
}

interface AddStyleProductVariant {
  styleProductVariant: StyleProductVariant | null;
  styleProductGroup: StyleProductGroup | null;
}
