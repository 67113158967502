import { ProjectPackageList } from "./ProjectPackageList";
import { KitchenScenesCard } from "./KitchenScenesCard";
import { Stack } from "@chakra-ui/react";
import { CustomerDetails } from "../../api/customersApi";
import { ProjectDetails } from "../../api/projectsApi";

export function KitchenDesignerView({
  customer,
  project,
  onChange,
}: {
  customer: CustomerDetails;
  project: ProjectDetails;
  onChange: () => void;
}) {
  return (
    <Stack spacing={4} shouldWrapChildren={true}>
      <KitchenScenesCard projectId={project.id} onChange={onChange} />
      <ProjectPackageList
        project={project}
        customer={customer}
        onChange={onChange}
      />
    </Stack>
  );
}
