import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Input,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useState } from "react";
import { FieldError, useForm, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomOrderFieldType } from "../../../api/customOrderFieldsApi";
import {
  CreateOrderRequest,
  CustomOrderFieldItem,
  externalApi,
  OrderProductPackage,
  SceneType,
} from "../../../api/externalApi";
import { useApiRequestCallback } from "../../../hooks/useApi/useApiRequestCallback";
import { ServerError } from "../../../types";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { TextHighlight } from "../../shared/TextHighlight";
import TermsModal from "./TermsModal";
import { SelectedProduct } from "./types";

interface SummaryOrderFormData {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  address: string;
  apartmentNumber: string;
  postalCode: string;
  city: string;
  termsAccepted: boolean;
  termsInProject: boolean;
  fields?: Record<string, string>;
}

export function SummaryOrderForm({
  totalPrice,
  projectId,
  linkToTerms,
  selectedProductPackage,
  selectedProductByProductGroupId,
  selectedKitchenScene,
  customOrderFields,
  onConfirmation,
  uncompletedSteps,
}: {
  totalPrice: number | null;
  projectId: string;
  selectedProductPackage: OrderProductPackage;
  selectedProductByProductGroupId: Record<string, SelectedProduct>;
  selectedKitchenScene: SceneType;
  customOrderFields: CustomOrderFieldItem[];
  uncompletedSteps?: string[];
  linkToTerms: string | null;
  onConfirmation: () => void;
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<ServerError | null>(null);
  const [isCreating, createOrderRequest] = useApiRequestCallback(
    externalApi.createOrder,
  );
  const [openTerms, setOpenTerms] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<SummaryOrderFormData>();

  const termsAccepted = watch("termsAccepted");
  const termsInProject =
    watch("termsInProject") || linkToTerms == null || linkToTerms.length === 0;

  function handleOrderFormSubmit(data: SummaryOrderFormData) {
    const variants: Record<string, string> = {};

    for (const productGroupId in selectedProductByProductGroupId) {
      variants[productGroupId] =
        selectedProductByProductGroupId[productGroupId].variant.id;
    }

    const fields: Record<string, string> = {};
    if (data.fields) {
      Object.keys(data.fields).forEach(
        (f) => (fields[f] = String(data.fields?.[f])),
      );
    }
    const request: CreateOrderRequest = {
      formFields: fields,
      productPackageId: selectedProductPackage.id,
      totalPrice,
      projectId,
      variantPerProductGroup: variants,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      telephone: data.telephone,
      address: data.address,
      apartmentNumber: data.apartmentNumber,
      postalCode: data.postalCode,
      city: data.city,
      sceneType: selectedKitchenScene,
    };

    createOrderRequest({
      onSuccess: () => {
        setError(null);
        onConfirmation();
      },
      onError: setError,
    }).send(request);
  }

  const hasAnyUncompletedSteps =
    !!uncompletedSteps && uncompletedSteps?.length > 0;

  return (
    <>
      {isCreating && <LoadingIndicator position="fullscreen" />}
      {error && <ErrorDetails error={error} />}
      <form onSubmit={handleSubmit(handleOrderFormSubmit)}>
        <Stack direction="column" spacing={8} shouldWrapChildren={true}>
          {hasAnyUncompletedSteps && (
            <Alert status="error" flexDirection="column" alignItems="start">
              <AlertTitle>
                {t("summary.errorTitleNotCompletedSteps")}
              </AlertTitle>
              <AlertDescription>
                <UnorderedList my={2}>
                  {uncompletedSteps.map((stepName) => (
                    <ListItem key={stepName}>{stepName}</ListItem>
                  ))}
                </UnorderedList>
              </AlertDescription>
            </Alert>
          )}

          <FieldGrid header={t("external.contactInformation")}>
            <FormControl
              isRequired={true}
              isInvalid={!!errors.firstName}
              isDisabled={hasAnyUncompletedSteps}
            >
              <FormLabel>{t("order.firstName")}</FormLabel>
              <Input
                {...register("firstName", {
                  required: {
                    value: true,
                    message: t("error.required"),
                  },
                })}
              />
              <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isRequired={true}
              isInvalid={!!errors.lastName}
              isDisabled={hasAnyUncompletedSteps}
            >
              <FormLabel>{t("order.lastName")}</FormLabel>
              <Input
                {...register("lastName", {
                  required: {
                    value: true,
                    message: t("error.required"),
                  },
                })}
              />
              <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isRequired={true}
              isInvalid={!!errors.email}
              isDisabled={hasAnyUncompletedSteps}
            >
              <FormLabel>{t("order.email")}</FormLabel>
              <Input
                type="email"
                {...register("email", {
                  required: {
                    value: true,
                    message: t("error.required"),
                  },
                })}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isRequired={true}
              isInvalid={!!errors.telephone}
              isDisabled={hasAnyUncompletedSteps}
            >
              <FormLabel>{t("order.telephone")}</FormLabel>
              <Input
                {...register("telephone", {
                  required: {
                    value: true,
                    message: t("error.required"),
                  },
                })}
                disabled={hasAnyUncompletedSteps}
              />
              <FormErrorMessage>{errors.telephone?.message}</FormErrorMessage>
            </FormControl>
          </FieldGrid>

          <FieldGrid header={t("external.locationInformation")}>
            <FormControl
              isRequired={true}
              isInvalid={!!errors.address}
              isDisabled={hasAnyUncompletedSteps}
            >
              <FormLabel>{t("order.address")}</FormLabel>
              <Input
                {...register("address", {
                  required: {
                    value: true,
                    message: t("error.required"),
                  },
                })}
              />
              <FormErrorMessage>{errors.address?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isRequired={true}
              isInvalid={!!errors.apartmentNumber}
              isDisabled={hasAnyUncompletedSteps}
            >
              <FormLabel>{t("order.apartmentNumber")}</FormLabel>
              <Input
                {...register("apartmentNumber", {
                  required: {
                    value: true,
                    message: t("error.required"),
                  },
                })}
              />
              <FormErrorMessage>
                {errors.apartmentNumber?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isRequired={true}
              isInvalid={!!errors.postalCode}
              isDisabled={hasAnyUncompletedSteps}
            >
              <FormLabel>{t("order.postalCode")}</FormLabel>
              <Input
                {...register("postalCode", {
                  required: {
                    value: true,
                    message: t("error.required"),
                  },
                })}
              />
              <FormErrorMessage>{errors.postalCode?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isRequired={true}
              isInvalid={!!errors.city}
              isDisabled={hasAnyUncompletedSteps}
            >
              <FormLabel>{t("order.city")}</FormLabel>
              <Input
                {...register("city", {
                  required: {
                    value: true,
                    message: t("error.required"),
                  },
                })}
              />
              <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
            </FormControl>
          </FieldGrid>

          {customOrderFields.length && (
            <FieldGrid header={t("general.other")}>
              {customOrderFields.map((f) => (
                <CustomField
                  key={f.id}
                  field={f}
                  register={register}
                  error={errors.fields?.[f.id]}
                  isDisabled={hasAnyUncompletedSteps}
                />
              ))}
            </FieldGrid>
          )}

          <FieldGrid>
            <Checkbox
              {...register("termsAccepted")}
              mr="4"
              isDisabled={hasAnyUncompletedSteps}
              flexGrow={1}
            >
              <Text as="span" fontSize="sm" mr="1">
                {t("summary.terms&conditions")}
                <Text
                  as="span"
                  _hover={{ cursor: "pointer" }}
                  fontSize="sm"
                  ml="1"
                  mr="10"
                  color="primary.600"
                  borderBottom="1px"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpenTerms(true);
                  }}
                >
                  {t("summary.theTerms")}
                </Text>
              </Text>
            </Checkbox>
            {linkToTerms && linkToTerms.length > 0 && (
              <Checkbox
                {...register("termsInProject")}
                mr="4"
                isDisabled={hasAnyUncompletedSteps}
                flexGrow={1}
              >
                <Text as="span" fontSize="sm" mr="1">
                  {t("summary.iApproveTermsProject")}
                  <Text
                    as="a"
                    href={linkToTerms ?? ""}
                    target="_blank"
                    fontSize="sm"
                    ml="1"
                    mr="1"
                    color="primary.600"
                    borderBottom="1px"
                  >
                    {t("summary.terms")}
                  </Text>
                  {t("summary.regardingOrderSelections")}
                </Text>
              </Checkbox>
            )}
            <Button
              isDisabled={
                !termsAccepted ||
                !termsInProject ||
                hasAnyUncompletedSteps ||
                isCreating
              }
              type="submit"
              w={48}
            >
              {t("general.send")}
            </Button>
          </FieldGrid>
        </Stack>
      </form>

      <TermsModal onClose={() => setOpenTerms(false)} isOpen={openTerms} />
    </>
  );
}

function CustomField({
  register,
  field,
  error,
  isDisabled,
}: {
  register: UseFormRegister<SummaryOrderFormData>;
  field: CustomOrderFieldItem;
  error?: FieldError;
  isDisabled?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <FormControl
      isRequired={field.isRequired}
      isInvalid={!!error}
      isDisabled={isDisabled}
    >
      <FormLabel>{field.name}</FormLabel>
      {field.fieldType === CustomOrderFieldType.Text && (
        <Input
          {...register(`fields.${field.id}`, {
            required: {
              value: field.isRequired,
              message: t("error.required"),
            },
          })}
        />
      )}
      {field.fieldType === CustomOrderFieldType.Checkbox && (
        <Checkbox
          {...register(`fields.${field.id}`, {
            required: {
              value: field.isRequired,
              message: t("error.required"),
            },
          })}
        />
      )}
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}

function FieldGrid({
  header,
  children,
}: {
  header?: string;
  children: React.ReactNode;
}) {
  return (
    <>
      {header && (
        <Heading size="md" mb={3}>
          <TextHighlight>{header}</TextHighlight>
        </Heading>
      )}
      <Grid
        gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr));"
        gridColumnGap={8}
        gridRowGap={4}
      >
        {children}
      </Grid>
    </>
  );
}
