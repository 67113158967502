import { BadgeProps } from "@chakra-ui/layout";
import { Badge } from "@chakra-ui/react";

type Status = "active" | "inactive" | "new";

const stylingProps: Record<Status, BadgeProps> = {
  active: {
    colorScheme: "green",
  },
  inactive: {
    colorScheme: "red",
  },
  new: {
    colorScheme: "yellow",
  },
};

export interface StatusBadgeProps {
  status: Status;
  text: string;
}

export function StatusBadge({ status, text }: StatusBadgeProps) {
  return (
    <Badge
      w="100%"
      textAlign="center"
      minWidth="11em"
      paddingY="2"
      rounded="md"
      {...stylingProps[status]}
    >
      {text}
    </Badge>
  );
}
