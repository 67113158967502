export enum ServerErrorCode {
  "Unknown",
}

export interface UnknownServerError {
  errorCode: ServerErrorCode.Unknown;
  status: number;
}

export interface ServerErrorDetails {
  errorCode: string;
  traceId?: string;
  details: Record<string, string>;
}

export type ServerError = UnknownServerError | ServerErrorDetails;

export enum ErrorCode {
  ProjectStatusIsInactive = "ProjectStatusIsInactive",
}

export interface Session {
  id: string;
  email: string;
  name: string;
  permissions: Permission[];
}

export interface Configuration {
  hostEnvironment: HostEnvironment;
  raygunApiKey: string;
}

export interface PagingOptions {
  page: number;
  pageSize: number;
}

export interface SortingOptions<T> {
  sortingKey: keyof T;
  sortingDesc: boolean;
}

export interface PagedItems<T> {
  items: T[];
  totalItems: number;
}

export enum LandingPageLayout {
  None = "None",
  Layout1 = "Layout1",
  Layout2 = "Layout2",
  Layout3 = "Layout3",
}

export enum HostEnvironment {
  Development = "Development",
  Staging = "Staging",
  Production = "Production",
}

export type Color = "primary" | "secondary" | "red" | "green" | "yellow";

// See: https://github.com/microsoft/TypeScript/issues/15300
export type WithIndexSignature<T> = {
  [K in keyof T]: WithIndexSignature<T[K]>;
};

export enum Permission {
  ManageSettings = "ManageSettings",
  ManageProducts = "ManageProducts",
}
