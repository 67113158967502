import { SceneType } from "../../../api/externalApi";
import hjonekokkenImg from "../../../assets/hjornekokken.jpeg";
import lillekokkenImg from "../../../assets/lillekokken.jpeg";
import parallelkokkenetImg from "../../../assets/parallelkokkenet.jpeg";

export function getScenePreviewImage(sceneType: SceneType | null) {
  switch (sceneType) {
    case SceneType.Hjornekokken:
      return hjonekokkenImg;
    case SceneType.Lillekokken:
      return lillekokkenImg;
    case SceneType.Parallelkokkenet:
      return parallelkokkenetImg;
    case null:
      return SceneType.Hjornekokken;
  }
}
