import {
  Box,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  Tfoot,
  Image,
  Stack,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Angle,
  CustomOrderFieldItem,
  ProductGroup,
  OrderProductPackage,
  SceneType,
  OrderProduct,
  ExternalProjectDetails,
} from "../../../api/externalApi";
import { Card } from "../../shared/Card";
import { SelectedProduct } from "./types";
import placeholderImage from "../../../assets/external_placeholder.jpg";
import { Preview } from "./Preview";
import { TextHighlight } from "../../shared/TextHighlight";
import { SummaryOrderForm } from "./SummaryOrderForm";
import { nullSafeAddUpList, nullSafeSubtract } from "../../../helpers";
import { ProductDetailsModal } from "./ProductDetailsModal";

export function Summary({
  project,
  selectedProductPackage,
  selectedProductByProductGroupId,
  customOrderFields,
  totalPrice,
  totalVariantsPrice,
  totalOtherVariantsPrice,
  totalOtherIncludedPrice,
  selectedKitchenScene,
  uncompletedSteps,
  onConfirmation,
}: {
  project: ExternalProjectDetails;
  selectedProductPackage: OrderProductPackage;
  selectedProductByProductGroupId: Record<string, SelectedProduct>;
  customOrderFields: CustomOrderFieldItem[];
  totalPrice: number | null;
  totalVariantsPrice: number | null;
  totalOtherVariantsPrice: number | null;
  totalOtherIncludedPrice: number | null;
  selectedKitchenScene: SceneType;
  uncompletedSteps?: string[];
  onConfirmation: () => void;
}) {
  const { t } = useTranslation();

  const { productList, otherProductList, includedProductsList } =
    useProductLists(
      selectedProductByProductGroupId,
      selectedProductPackage.requiredProductGroups,
    );

  return (
    <>
      <Flex flexDirection="row" pt={28} pb={10}>
        <Box
          display={{ base: "none", lg: "flex" }}
          overflow="hidden"
          p={4}
          pt={0}
        >
          <Box
            width="300px"
            height="250px"
            display="flex"
            position="fixed"
            top="200px"
            rounded="xl"
            overflow="hidden"
          >
            <Preview
              selectedProductByProductGroupId={selectedProductByProductGroupId}
              sceneType={selectedKitchenScene}
              angle={Angle.Overview}
              isImageNavigationVisible={false}
              otherOptionsImage={
                selectedProductPackage.renderedProductGroups.length === 0
                  ? selectedProductPackage.otherOptionsImage
                  : null
              }
            />
          </Box>
        </Box>
        <Box
          ml={{ base: "none", lg: "300px" }}
          flexGrow={1}
          px={2}
          w="100px"
          maxW="4xl"
        >
          <Card p={{ base: 0, lg: 8 }}>
            <Stack spacing={8} direction="column" shouldWrapChildren={true}>
              {productList.length > 0 ? (
                <ProductSummaryTable
                  tableHeader={
                    <Heading fontSize="2xl">
                      <TextHighlight>
                        {t("summary.packageTitle")} -{" "}
                        {selectedProductPackage.name}
                      </TextHighlight>
                    </Heading>
                  }
                  productList={productList}
                  productTotalList={[
                    {
                      name: t("external.packageCost"),
                      price: selectedProductPackage.price,
                    },
                    {
                      name: t("external.subtotal"),
                      price: nullSafeAddUpList([
                        totalVariantsPrice,
                        selectedProductPackage.price,
                      ]),
                    },
                  ]}
                />
              ) : (
                <>
                  {selectedProductPackage.renderedProductGroups.length > 0 && (
                    <Heading mb="20px" fontSize="2xl">
                      <TextHighlight>
                        {t("summary.notMadeAnyChoice")}...
                      </TextHighlight>
                    </Heading>
                  )}
                </>
              )}
              {otherProductList.length > 0 && (
                <ProductSummaryTable
                  tableHeader={
                    <Heading fontSize="xl">
                      <TextHighlight>
                        <>
                          {selectedProductPackage.renderedProductGroups.length >
                          0
                            ? t("external.otherCategories")
                            : selectedProductPackage.name}
                        </>
                      </TextHighlight>
                    </Heading>
                  }
                  productList={otherProductList}
                  productTotalList={[
                    {
                      name: t("external.subtotal"),
                      price: totalOtherVariantsPrice,
                    },
                  ]}
                />
              )}
              {includedProductsList.length > 0 && (
                <ProductSummaryTable
                  tableHeader={
                    <Heading fontSize="xl">
                      <TextHighlight>
                        {t("external.otherIncluded")}
                      </TextHighlight>
                    </Heading>
                  }
                  productList={includedProductsList}
                  productTotalList={[
                    {
                      name: t("external.subtotal"),
                      price: totalOtherIncludedPrice,
                    },
                  ]}
                />
              )}
              <PriceSummaryTable
                productPackagePrice={selectedProductPackage.price}
                totalPrice={totalPrice}
              />
            </Stack>
          </Card>
          <Card mt="50px" p={{ base: 0, lg: 8 }}>
            <SummaryOrderForm
              totalPrice={totalPrice}
              projectId={project.id}
              linkToTerms={project.linkToTerms}
              selectedProductPackage={selectedProductPackage}
              selectedProductByProductGroupId={selectedProductByProductGroupId}
              selectedKitchenScene={selectedKitchenScene}
              customOrderFields={customOrderFields}
              onConfirmation={onConfirmation}
              uncompletedSteps={uncompletedSteps}
            />
          </Card>
        </Box>
      </Flex>
    </>
  );
}

function ProductSummaryTable({
  tableHeader,
  productList,
  productTotalList,
}: {
  tableHeader: React.ReactNode;
  productList: ProductListItem[];
  productTotalList: ProductTotalListItem[];
}) {
  const { t } = useTranslation();
  const [modalProduct, setModalProduct] = useState<OrderProduct>();
  const [modalProductGroup, setModalProductGroup] = useState<ProductGroup>();
  const isSmallScreen = useBreakpointValue({ base: true, lg: false });

  return (
    <>
      {modalProduct && modalProductGroup && (
        <ProductDetailsModal
          productGroup={modalProductGroup}
          product={modalProduct}
          selectedProduct={undefined}
          onClose={() => {
            setModalProduct(undefined);
            setModalProductGroup(undefined);
          }}
          onSelectProduct={() => {}}
          isReadOnly={true}
        />
      )}
      {tableHeader}
      <Table variant="simple" mt={4} mb={6}>
        <Thead>
          <Tr>
            {!isSmallScreen && <Th />}
            <Th>{t("product.product")}</Th>
            <Th>{t("productGroup.productGroup")}</Th>
            {!isSmallScreen && <Th>{t("productVariant.productVariant")}</Th>}
            {!isSmallScreen && <Th />}
            <Th isNumeric={true}>{t("general.price")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {productList.map((productItem) => (
            <Tr key={productItem.variantId}>
              {!isSmallScreen && (
                <Td>
                  <Image
                    objectFit="cover"
                    minWidth="50px"
                    src={productItem.image}
                    w={14}
                    h={14}
                    onClick={() => {
                      setModalProduct(productItem.product);
                      setModalProductGroup(productItem.productGroup);
                    }}
                    cursor="pointer"
                  />
                </Td>
              )}
              <Td>
                <Flex maxW="30vw" direction="column" px={2}>
                  <Heading size="sm">{productItem.product.name}</Heading>
                  <Box>{productItem.variantName}</Box>
                  {productItem?.product?.description &&
                    productItem.product.description.trim() !== "" && (
                      <Box mt={1}>
                        <Button
                          onClick={() => {
                            setModalProduct(productItem.product);
                            setModalProductGroup(productItem.productGroup);
                          }}
                          variant="outline"
                          size="xs"
                        >
                          {t("external.moreInfo")}
                        </Button>
                      </Box>
                    )}
                </Flex>
              </Td>
              <Td maxW="30vw">{productItem.productGroup.name}</Td>
              {!isSmallScreen && (
                <Td>
                  {!productItem.product.hasVariants ||
                  (productItem.product.hasVariants &&
                    productItem.variantName !== "")
                    ? productItem.variantName
                    : productItem.product.variants.at(0)?.name}
                </Td>
              )}
              {!isSmallScreen && <Td />}
              <Td maxW="20vw" isNumeric={true}>
                {productItem.price || productItem.price === 0 ? (
                  <Text>
                    {t("external.krPerMonth", {
                      price: productItem.price,
                    })}
                  </Text>
                ) : (
                  <Text>{t("external.included")}</Text>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          {productTotalList.map((total, i) => (
            <PriceSummaryRow
              key={i}
              colSpan={isSmallScreen ? 2 : 5}
              label={total.name}
              price={total.price}
            />
          ))}
        </Tfoot>
      </Table>
    </>
  );
}

function PriceSummaryTable({
  productPackagePrice,
  totalPrice,
}: {
  productPackagePrice: number | null;
  totalPrice: number | null;
}) {
  const { t } = useTranslation();

  if (productPackagePrice === null && totalPrice === null) return <></>;

  return (
    <>
      <Heading fontSize="xl" mb={4}>
        <TextHighlight>{t("external.priceSummary")}</TextHighlight>
      </Heading>
      <Table>
        <Tbody>
          <PriceSummaryRow
            label={t("external.packageCost")}
            price={productPackagePrice}
          />
          <PriceSummaryRow
            label={t("external.options")}
            price={nullSafeSubtract(totalPrice, productPackagePrice)}
          />
          <PriceSummaryRow
            label={<Text fontWeight="bold">{t("external.total")}</Text>}
            price={totalPrice}
          />
        </Tbody>
      </Table>
    </>
  );
}

function PriceSummaryRow({
  colSpan,
  label,
  price,
}: {
  colSpan?: number;
  label: ReactNode;
  price: number | null;
}) {
  const { t } = useTranslation();

  if (price === null) return <></>;

  return (
    <Tr
      height="60px"
      borderStyle="solid"
      borderTopWidth={2}
      borderBottomWidth={2}
      borderColor="secondary.300"
      backgroundColor="secondary.100"
    >
      <Td colSpan={colSpan}>{label}</Td>
      <Td isNumeric={true}>
        <strong>
          {t("external.krPerMonth", {
            price,
          })}
        </strong>
      </Td>
    </Tr>
  );
}

function useProductLists(
  selectedProductByProductGroupId: Record<string, SelectedProduct>,
  includedCategories: ProductGroup[],
) {
  const productList = useMemo(
    () =>
      Object.values(selectedProductByProductGroupId)
        .filter((s) => s.productGroup.isRendered === true)
        .map(mapProductListItem),
    [selectedProductByProductGroupId],
  );

  const otherProductList = useMemo(
    () =>
      Object.values(selectedProductByProductGroupId)
        .filter((s) => s.productGroup.isRendered === false)
        .map(mapProductListItem),
    [selectedProductByProductGroupId],
  );

  const includedProductsList = useMemo<ProductListItem[]>(
    () => [
      ...includedCategories.flatMap((c) =>
        c.products.flatMap((p) =>
          p.variants.map((v) => ({
            product: p,
            productGroup: c,
            variantId: v.id,
            variantName: v.name,
            image:
              v?.images[0]?.thumbnailUrl ??
              p?.images[0]?.thumbnailUrl ??
              placeholderImage,
            price: v.price,
          })),
        ),
      ),
    ],
    [includedCategories],
  );

  return { productList, otherProductList, includedProductsList };
}

interface ProductListItem {
  variantId: string;
  variantName: string;
  image: string;
  price: number | null;
  productGroup: ProductGroup;
  product: OrderProduct;
}

interface ProductTotalListItem {
  name: string;
  price: number | null;
}

function mapProductListItem(selectedProduct: SelectedProduct): ProductListItem {
  return {
    productGroup: selectedProduct.productGroup,
    product: selectedProduct.product,
    variantId: selectedProduct.variant.id,
    variantName:
      selectedProduct.product.variants.length === 1
        ? ""
        : selectedProduct.variant.name,
    image:
      selectedProduct?.variant?.images[0]?.thumbnailUrl ??
      selectedProduct?.product?.images[0]?.thumbnailUrl ??
      placeholderImage,
    price: selectedProduct.variant.price,
  };
}
