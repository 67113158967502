import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Input,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  SimpleGrid,
  Textarea,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  LicenseType,
  ApartmentIntervalType,
  CustomerDetails,
  customersApi,
  FrequencyType,
} from "../../api/customersApi";
import { urlSafeRegExp } from "../../constants";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { ServerError } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Card } from "../shared/Card";
import { Page } from "../shared/Page";
import { routes } from "../../routes";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useToastNotification } from "../../hooks/useToastNotification";

export function CustomerDetailsView({
  customer,
  onUpdate,
}: {
  customer: CustomerDetails;
  onUpdate: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Page breadcrumbs={useBreadcrumbs(customer.id, customer.name)}>
      <SimpleGrid spacing={6}>
        <Card titleContent={t("customer.customerDetails")}>
          <SimpleGrid columns={2} spacing={4}>
            <FormControl>
              <FormLabel>{t("general.name")}</FormLabel>
              <Input value={customer.name} readOnly={true} variant="filled" />
            </FormControl>
            <FormControl>
              <FormLabel>{t("customer.customerNumber")}</FormLabel>
              <Input
                value={customer.customerNumber}
                readOnly={true}
                variant="filled"
              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={2} spacing={4} mt={2}>
            <FormControl>
              <FormLabel>{t("customer.organizationNumber")}</FormLabel>
              <Input
                value={customer.organizationNumber}
                readOnly={true}
                variant="filled"
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("customer.reference")}</FormLabel>
              <Input
                value={customer.customerReference}
                readOnly={true}
                variant="filled"
              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={2} spacing={4} mt={2}>
            <FormControl>
              <FormLabel>{t("region.region")}</FormLabel>
              <Input value={customer.region} readOnly={true} variant="filled" />
            </FormControl>
            <FormControl>
              <FormLabel>{t("customer.seller")}</FormLabel>
              <Input value={customer.seller} readOnly={true} variant="filled" />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={2} spacing={4} mt={2}>
            <FormControl>
              <FormLabel>{t("general.phoneNumber")}</FormLabel>
              <Input
                value={customer.phoneNumber}
                readOnly={true}
                variant="filled"
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("general.email")}</FormLabel>
              <Input value={customer.email} readOnly={true} variant="filled" />
            </FormControl>
          </SimpleGrid>

          <Flex justifyContent="space-between" alignItems="center" p={4} pl={0}>
            <Heading size="sm">{t("customer.visitingAddress")}</Heading>
          </Flex>
          <SimpleGrid columns={2} spacing={4}>
            <FormControl>
              <FormLabel>{t("customer.address")}</FormLabel>
              <Input
                value={customer.visitingAddress}
                readOnly={true}
                variant="filled"
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("customer.postalcode")}</FormLabel>
              <Input
                value={customer.visitingPostalCode}
                readOnly={true}
                variant="filled"
              />
            </FormControl>
          </SimpleGrid>

          <Flex justifyContent="space-between" alignItems="center" p={4} pl={0}>
            <Heading size="sm">{t("customer.invoiceAddress")}</Heading>
          </Flex>
          <SimpleGrid columns={2} spacing={4}>
            <FormControl>
              <FormLabel>{t("customer.address")}</FormLabel>
              <Input
                value={customer.invoiceAddress}
                readOnly={true}
                variant="filled"
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("customer.postalcode")}</FormLabel>
              <Input
                value={customer.invoicePostalCode}
                readOnly={true}
                variant="filled"
              />
            </FormControl>
          </SimpleGrid>
        </Card>
        <CustomerDetailsForm details={customer} onUpdate={onUpdate} />
      </SimpleGrid>
    </Page>
  );
}

interface CustomerFormData {
  urlSlug: string;
  licenseType: LicenseType;
  licenseCost: number;
  otherLicenseInformation: string;
  licenseStartDate: string;
  billingFrequency: FrequencyType;
  apartmentInterval: ApartmentIntervalType;
}

function CustomerDetailsForm({
  details,
  onUpdate,
}: {
  details: CustomerDetails;
  onUpdate: (customer: CustomerDetails) => void;
}) {
  const { t } = useTranslation();
  const [id] = useState(details.id);
  const [loading, updateCustomerRequest] = useApiRequestCallback(
    customersApi.updateCustomerDetails,
  );
  const [error, setError] = useState<ServerError | null>();
  const toast = useToastNotification();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm<CustomerFormData>({ defaultValues: details });

  function handleUpdateSuccess(updatedCustomer: CustomerDetails) {
    reset(updatedCustomer);
    setError(null);
    onUpdate(updatedCustomer);
    toast({
      title: t("general.saved"),
      status: "success",
    });
  }

  async function onSubmit(data: CustomerFormData) {
    updateCustomerRequest({
      onSuccess: handleUpdateSuccess,
      onError: setError,
    }).send(id, {
      urlSlug: data.urlSlug,
      licenseCost: data.licenseCost,
      otherLicenseInformation: data.otherLicenseInformation,
      licenseStartDate: data.licenseStartDate,
      billingFrequency: data.billingFrequency,
      licenseType: data.licenseType,
      apartmentInterval: data.apartmentInterval,
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {error && <ErrorDetails error={error} />}
      {loading && <LoadingIndicator />}

      <Card titleContent={t("customer.other")} borderBottomRadius={0}>
        <SimpleGrid spacing={4}>
          <FormControl
            id="urlSlug"
            isRequired={true}
            isInvalid={!!errors.urlSlug}
          >
            <FormLabel>{t("customer.slug")}</FormLabel>
            <Input
              {...register("urlSlug", {
                required: { value: true, message: t("error.required") },
                pattern: {
                  value: urlSafeRegExp,
                  message: t("error.slugPattern"),
                },
              })}
            />
            <FormErrorMessage>{errors.urlSlug?.message}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
      </Card>
      <Divider></Divider>
      <Card mt={0} borderTopRadius={0} titleContent={t("customer.licenses")}>
        <Grid
          gridGap={4}
          gridTemplateAreas={`'licenseType licenseType' 'licenseCost licenseStartDate' 'billingFrequency apartmentInterval' 'otherLicenseInformation otherLicenseInformation' 'save save'`}
        >
          <FormControl gridArea="licenseType" id="licenseType">
            <Controller
              name="licenseType"
              render={({ field }) => (
                <Flex alignItems="center" height="38px">
                  <RadioGroup
                    onChange={(status) => {
                      field.onChange(status as LicenseType);
                    }}
                    value={field.value}
                  >
                    <HStack>
                      <Radio value={LicenseType.NoLicense}>
                        {t("customer.noLicense")}
                      </Radio>
                      <Radio value={LicenseType.License}>
                        {t("customer.license")}
                      </Radio>
                      <Radio value={LicenseType.Demo}>
                        {t("customer.demo")}
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </Flex>
              )}
              control={control}
            />
          </FormControl>
          <FormControl gridArea="licenseCost" id="licenseCost">
            <FormLabel>{t("customer.licenseCost")}</FormLabel>
            <Controller
              name="licenseCost"
              render={({ field }) => (
                <NumberInput
                  {...field}
                  min={0}
                  value={field.value ?? ""}
                  onChange={(_, value) =>
                    field.onChange(isNaN(value) ? null : value)
                  }
                >
                  <NumberInputField />
                </NumberInput>
              )}
              control={control}
            />
          </FormControl>
          <FormControl gridArea="licenseStartDate" id="licenseStartDate">
            <FormLabel>{t("customer.licenseStartDate")}</FormLabel>
            <Controller
              name="licenseStartDate"
              control={control}
              render={({ field }) => (
                <Input
                  type="date"
                  defaultValue={details.licenseStartDate?.slice(0, 10)}
                  onChange={(date) => {
                    field.onChange(new Date(date.target.value), "yyyy-MM-dd");
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl gridArea="billingFrequency" id="billingFrequency">
            <FormLabel>{t("customer.billingFrequency")}</FormLabel>
            <Controller
              name="billingFrequency"
              render={({ field }) => (
                <Flex alignItems="center" height="38px">
                  <RadioGroup
                    onChange={(status) => {
                      field.onChange(status as FrequencyType);
                    }}
                    value={field.value}
                  >
                    <HStack>
                      <Radio value={FrequencyType.Monthly}>
                        {t("frequencyType.monthly")}
                      </Radio>
                      <Radio value={FrequencyType.Quarterly}>
                        {t("frequencyType.quarterly")}
                      </Radio>
                      <Radio value={FrequencyType.Annually}>
                        {t("frequencyType.annualy")}
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </Flex>
              )}
              control={control}
            />
          </FormControl>
          <FormControl gridArea="apartmentInterval" id="apartmentInterval">
            <FormLabel>{t("customer.apartmentInterval")}</FormLabel>
            <Controller
              name="apartmentInterval"
              render={({ field }) => (
                <Flex alignItems="center" height="38px">
                  <RadioGroup
                    onChange={(status) => {
                      field.onChange(status as ApartmentIntervalType);
                    }}
                    value={field.value}
                  >
                    <HStack>
                      <Radio value={ApartmentIntervalType.ZeroToTwoThousand}>
                        {t("apartmentIntervalType.zeroToTwoThousand")}
                      </Radio>
                      <Radio
                        value={ApartmentIntervalType.TwoThousandToEightThousand}
                      >
                        {t("apartmentIntervalType.twoThousandToEightThousand")}
                      </Radio>
                      <Radio value={ApartmentIntervalType.EightThousandAndMore}>
                        {t("apartmentIntervalType.eightThousandAndMore")}
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </Flex>
              )}
              control={control}
            />
          </FormControl>
          <FormControl
            gridArea="otherLicenseInformation"
            id="otherLicenseInformation"
          >
            <FormLabel>{t("customer.other")}</FormLabel>
            <Textarea
              id="otherLicenseInformation"
              {...register("otherLicenseInformation")}
            />
          </FormControl>
          <Flex gridArea="save" justifyContent="flex-end">
            <Button type="submit">{t("general.save")}</Button>
          </Flex>
        </Grid>
      </Card>
    </form>
  );
}

function useBreadcrumbs(customerId: string, customerName: string) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("customer.customers"),
        to: routes.customers,
      },
      {
        label: customerName,
        to: routes.customer(customerId),
      },
      {
        label: t("customer.customerDetails"),
      },
    ],
    [customerId, customerName, t],
  );
}
