import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ProjectListItem,
  projectsApi,
  ProjectStatus,
} from "../../api/projectsApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { ErrorDetails } from "../shared/ErrorDetails";
import { useTranslation } from "react-i18next";
import { Page } from "../shared/Page";
import { Card } from "../shared/Card";
import { Box, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { CustomerDetails } from "../../api/customersApi";
import CreateProjectModal from "./CreateProjectModal";
import { PagingOptions, SortingOptions } from "../../types";
import { Column, DataTable } from "../shared/DataTable/DataTable";
import { SearchFilterInput } from "../shared/SearchFilterInput";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { routes } from "../../routes";
import { useSavedSorting } from "../../hooks/useSavedSorting";
import { useGlobalPageSize } from "../../hooks/usePageSize";
import { StatusBadge } from "../shared/StatusBadges/StatusBadge";
import { Badge } from "@chakra-ui/react";

interface ListOptions extends PagingOptions, SortingOptions<ProjectListItem> {
  searchTerm: string;
}

export function ProjectList({
  customer,
  onUpdate,
}: {
  customer: CustomerDetails;
  onUpdate: () => void;
}) {
  const { t } = useTranslation();
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [projects, loading, error, fetch] = useApiRequest(
    projectsApi.listProjects,
  );
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const { savedSorting, setSavedSorting } = useSavedSorting<ProjectListItem>(
    "ProjectListItem",
    { sortingDesc: false, sortingKey: "name" },
  );
  const [listOptions, setListOptions] = useState<ListOptions>({
    ...savedSorting,
    page: 1,
    pageSize: globalPageSize,
    searchTerm: "",
  });

  const fetchProjects = useCallback(() => {
    fetch({ customerId: customer.id, ...listOptions });
  }, [fetch, customer.id, listOptions]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const columns = useMemo<Column<ProjectListItem>[]>(
    () => [
      {
        key: "name",
        header: t("general.name"),
        sortable: true,
        cell: ({ row }) => `${customer.name} - ${row.name}`,
      },
      {
        key: "packageNames",
        width: 250,
        header: t("project.packages"),
        cell: ({ row }) => (
          <>
            {row.packageNames.map((n) => {
              return (
                <Badge
                  key={n}
                  textAlign="center"
                  rounded="md"
                  marginLeft="0.5"
                  marginTop="0.5"
                  marginRight="0.5"
                >
                  {customer.name} - {n}
                </Badge>
              );
            })}
          </>
        ),
      },
      {
        key: "orderSum",
        header: t("project.orderSum"),
        cell: ({ row }) => row.orderSum,
      },
      {
        key: "startDate",
        header: t("project.startDate"),
        cell: ({ row }) => row.startDate?.slice(0, 10),
      },
      {
        key: "endDate",
        header: t("project.endDate"),
        cell: ({ row }) => row.endDate?.slice(0, 10),
      },
      {
        key: "status",
        width: 200,
        header: t("general.status"),
        cell: ({ row }) => <ProjectStatusBadge status={row.status} />,
      },
      {
        key: "actions",
        width: 150,
        cell: ({ row }) => (
          <Button as={Link} to={row.id} size="sm">
            {t("general.edit")}
          </Button>
        ),
      },
    ],
    [customer.name, t],
  );

  return (
    <Page breadcrumbs={useBreadcrumbs(customer.id, customer.name)}>
      {error && <ErrorDetails error={error} />}
      <Card
        titleContent={t("general.projects")}
        extraContent={
          <Button size="sm" onClick={() => setAddModalVisible(true)}>
            {t("general.add")}
          </Button>
        }
      >
        <Box mb={4}>
          <SearchFilterInput
            value={listOptions.searchTerm}
            onChange={(value) =>
              setListOptions((options) => ({
                ...options,
                searchTerm: value,
                page: 1,
              }))
            }
          />
        </Box>
        <DataTable
          isLoading={loading}
          columns={columns}
          data={projects?.items}
          sorting={listOptions}
          paging={listOptions}
          totalItems={projects?.totalItems ?? 0}
          onSortingChange={(sorting) => {
            setListOptions((o) => ({ ...o, ...sorting }));
            setSavedSorting(sorting);
          }}
          onPagingChange={(paging) => {
            setListOptions((o) => ({ ...o, ...paging }));
            setGlobalPageSize(paging.pageSize);
          }}
        />
      </Card>
      {addModalVisible && (
        <CreateProjectModal
          customerId={customer.id}
          onClose={() => setAddModalVisible(false)}
          onSuccess={() => {
            fetchProjects();
            setAddModalVisible(false);
            onUpdate();
          }}
        />
      )}
    </Page>
  );
}

function useBreadcrumbs(customerId: string, customerName: string) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("customer.customers"),
        to: routes.customers,
      },
      {
        label: customerName,
        to: routes.customer(customerId),
      },
      {
        label: t("general.projects"),
      },
    ],
    [customerName, customerId, t],
  );
}

function ProjectStatusBadge({ status }: { status: ProjectStatus }) {
  const { t } = useTranslation();

  switch (status) {
    case ProjectStatus.Active:
      return <StatusBadge status="active" text={t("general.active")} />;
    case ProjectStatus.Inactive:
      return <StatusBadge status="inactive" text={t("general.inactive")} />;
  }
}
