import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { ProductDetails, productsApi } from "../../api/productsApi";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import { ProductGroupAsyncSelect } from "../shared/AsyncSelect/ProductGroupAsyncSelect";
import { ErrorDetails } from "../shared/ErrorDetails";
import { ServerError } from "../../types";
import { useToastNotification } from "../../hooks/useToastNotification";

export function CreateProductModal({
  onSuccess,
  onClose,
}: {
  onSuccess: (product: ProductDetails) => void;
  onClose: () => void;
}) {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalFormContent onSuccess={onSuccess} />
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}

interface CreateProductFormData {
  name: string;
  productGroupId: string | null;
}

function ModalFormContent({
  onSuccess,
}: {
  onSuccess: (product: ProductDetails) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [error, setError] = useState<ServerError>();

  const [createProductLoading, createProductRequest] = useApiRequestCallback(
    productsApi.createProduct,
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<CreateProductFormData>();

  async function onSubmit(data: CreateProductFormData) {
    createProductRequest({
      onSuccess: (product) => {
        onSuccess(product);
        toast({ title: t("general.saved"), status: "success" });
      },
      onError: setError,
    }).send({
      name: data.name,
      productGroupId: data.productGroupId!,
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <ModalHeader>
        {t("general.add")} {t("product.product").toLowerCase()}
      </ModalHeader>
      <ModalBody>
        {createProductLoading && <LoadingIndicator />}
        {error && <ErrorDetails error={error} />}
        <Stack spacing={4} shouldWrapChildren={true}>
          <FormControl id="name" isRequired={true} isInvalid={!!errors.name}>
            <FormLabel>{t("general.name")}</FormLabel>
            <Input
              {...register("name", {
                required: { value: true, message: t("error.required") },
              })}
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
          <FormControl
            id="productGroup"
            isRequired={true}
            isInvalid={!!errors.productGroupId}
          >
            <FormLabel>{t("productGroup.productGroup")}</FormLabel>
            <Controller
              name="productGroupId"
              render={({ field, fieldState }) => (
                <ProductGroupAsyncSelect {...field} {...fieldState} />
              )}
              control={control}
              rules={{
                required: { value: true, message: t("error.required") },
              }}
            />
            <FormErrorMessage>
              {errors.productGroupId?.message}
            </FormErrorMessage>
          </FormControl>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button type="submit">{t("general.save")}</Button>
      </ModalFooter>
    </form>
  );
}
