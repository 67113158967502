import { CSSObject } from "@chakra-ui/react";

export const richTextStyles: CSSObject = {
  fontVariantLigatures: "none",
  lineHeight: "1.3",

  h1: {
    lineHeight: 1.2,
    fontWeight: 500,
    marginBottom: "1rem",
    fontSize: "2.5rem",
    minHeight: "2.5rem",
  },
  h2: {
    lineHeight: 1.2,
    fontWeight: 500,
    marginBottom: ".5rem",
    fontSize: "1.5rem",
    minHeight: "1.5rem",
  },
  p: {
    minHeight: "1rem",
  },
  a: {
    cursor: "pointer",
    textDecoration: "none",
    outline: "none",
    color: "#319795",
    _hover: {
      textDecoration: "underline",
    },
    _focus: {
      boxShadow: "outline",
    },
  },
};
