import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Image,
  Text,
  Radio,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Angle,
  ProductGroup,
  OrderProduct,
  SceneType,
} from "../../../api/externalApi";
import { Icons } from "../../shared/Icons";
import { Preview } from "./Preview";
import { ProductDetailsModal } from "./ProductDetailsModal";
import { ProductSelection, SelectedProduct } from "./types";
import { Sidebar } from "./Sidebar";
import placeholderImage from "../../../assets/external_placeholder.jpg";
import { TextHighlight } from "../../shared/TextHighlight";
import { ProductVariants } from "./ProductVariants";
import { useCompactSideMenu } from "../../../hooks/useCompactSideMenu";

interface ModalProductDetails {
  product: OrderProduct;
  productGroup: ProductGroup;
}

export function OtherOptionsProductsSelector({
  selectedProductByProductGroupId,
  selectedKitchenScene,
  hideNextStep,
  selectProduct,
  goToNextStep,
  categories,
  otherOptionsImage,
}: {
  selectedProductByProductGroupId: Record<string, SelectedProduct>;
  selectedKitchenScene: SceneType;
  hideNextStep: boolean;
  selectProduct: (product: ProductSelection) => void;
  goToNextStep: () => void;
  categories: ProductGroup[];
  otherOptionsImage: string | null;
}) {
  const [modalProductDetails, setModalProductDetails] =
    useState<ModalProductDetails | null>(null);

  return (
    <Box
      flex="1"
      position="relative"
      minHeight={0}
      flexBasis={0}
      overflow="hidden"
      display="flex"
      mt={{ base: "64px", xl: 0 }}
    >
      <Preview
        selectedProductByProductGroupId={selectedProductByProductGroupId}
        sceneType={selectedKitchenScene}
        angle={Angle.Overview}
        isImageNavigationVisible={true}
        otherOptionsImage={otherOptionsImage}
      />
      <Sidebar>
        <ProductGroupList
          productGroups={categories}
          selectedProductByProductGroupId={selectedProductByProductGroupId}
          onSelectProduct={selectProduct}
          onNextStep={goToNextStep}
          hideNextStep={hideNextStep}
          onShowProductDetailsDialog={setModalProductDetails}
        />
      </Sidebar>
      {modalProductDetails && (
        <ProductDetailsModal
          product={modalProductDetails.product}
          productGroup={modalProductDetails.productGroup}
          selectedProduct={
            selectedProductByProductGroupId[modalProductDetails.productGroup.id]
          }
          onSelectProduct={selectProduct}
          onClose={() => setModalProductDetails(null)}
        />
      )}
    </Box>
  );
}

function ProductGroupList({
  productGroups,
  selectedProductByProductGroupId,
  hideNextStep,
  onSelectProduct,
  onNextStep,
  onShowProductDetailsDialog,
}: {
  productGroups: ProductGroup[];
  selectedProductByProductGroupId: Record<string, SelectedProduct | undefined>;
  hideNextStep: boolean;
  onSelectProduct: (product: ProductSelection) => void;
  onNextStep: () => void;
  onShowProductDetailsDialog: (details: ModalProductDetails) => void;
}) {
  const [activeProductGroupId, setActiveProductGroupId] = useState<
    string | null
  >(null);
  const { t } = useTranslation();

  const selectProduct = useCallback(
    (selection: ProductSelection) => {
      setActiveProductGroupId(selection.productGroup.id);
      onSelectProduct(selection);
    },
    [onSelectProduct],
  );

  const activeSelectedProduct = useMemo(() => {
    if (!activeProductGroupId) {
      return null;
    }

    return selectedProductByProductGroupId[activeProductGroupId] ?? null;
  }, [activeProductGroupId, selectedProductByProductGroupId]);

  const [isProductVariantsVisible, setProductVariantsIsVisible] =
    useState<boolean>(true);

  const isUsingCompactSideMenu = useCompactSideMenu();

  return (
    <>
      {activeSelectedProduct &&
        activeSelectedProduct.product.variants.length > 1 &&
        !isUsingCompactSideMenu && (
          <ProductVariants
            productGroup={activeSelectedProduct.productGroup}
            selectedVariant={activeSelectedProduct.variant}
            selectedProduct={activeSelectedProduct.product}
            onSelectProduct={onSelectProduct}
            isVisible={isProductVariantsVisible}
            onClose={() => setProductVariantsIsVisible(false)}
          ></ProductVariants>
        )}

      <Box
        display="flex"
        height="100%"
        flexDir="column"
        w="xs"
        bg="white"
        zIndex="100"
      >
        <Box flex="1" overflowY="auto" p={4}>
          <Stack spacing={8} shouldWrapChildren={true}>
            {productGroups.map((productGroup) => (
              <Box key={productGroup.id}>
                <ProductItems
                  selectedProductByProductGroupId={
                    selectedProductByProductGroupId
                  }
                  productGroup={productGroup}
                  products={productGroup.products}
                  onSelectProduct={(p) => {
                    setProductVariantsIsVisible(true);
                    return selectProduct(p);
                  }}
                  onShowProductDetailsDialog={onShowProductDetailsDialog}
                />
                <Box
                  height="1px"
                  width="100%"
                  backgroundColor="black"
                  borderRadius="20px"
                  mt="10px"
                ></Box>
              </Box>
            ))}
          </Stack>
        </Box>
        <Box p={{ base: 1, xl: 4 }} pl="4">
          {activeSelectedProduct &&
            activeSelectedProduct.product.variants.length > 1 &&
            isUsingCompactSideMenu && (
              <ProductVariants
                productGroup={activeSelectedProduct.productGroup}
                selectedVariant={activeSelectedProduct.variant}
                selectedProduct={activeSelectedProduct.product}
                onSelectProduct={onSelectProduct}
                isVisible={true}
                onClose={() => setProductVariantsIsVisible(false)}
              ></ProductVariants>
            )}
          {!hideNextStep && (
            <Button isFullWidth={true} onClick={onNextStep}>
              <Flex width="100%" alignItems="center">
                <Box flex="1">{t("general.next")}</Box>
                <Icons.ArrowRight fontSize="xx-large" />
              </Flex>
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}

function ProductItems({
  productGroup,
  products,
  selectedProductByProductGroupId,
  onSelectProduct,
  onShowProductDetailsDialog,
}: {
  productGroup: ProductGroup;
  products: OrderProduct[];
  selectedProductByProductGroupId: Record<string, SelectedProduct | undefined>;
  onSelectProduct: (product: ProductSelection) => void;
  onShowProductDetailsDialog: (details: ModalProductDetails) => void;
}) {
  const { product: selectedProduct } =
    selectedProductByProductGroupId[productGroup.id] ?? {};
  const { t } = useTranslation();

  return (
    <Stack shouldWrapChildren={true} spacing={4}>
      <Heading size="md">
        <TextHighlight>{productGroup.name}</TextHighlight>
      </Heading>
      {products.map((product) => (
        <Flex
          key={product.id}
          cursor="pointer"
          onClick={() => {
            onSelectProduct({
              productGroup: productGroup,
              product: product,
            });
          }}
        >
          <Image
            src={
              product.images.length > 0
                ? product.images[0].thumbnailUrl
                : placeholderImage
            }
            w={{ base: "60px", xl: "90px" }}
            maxH="70px"
            h="100%"
          />
          <Flex w="180px" direction="column" px={2} overflow="hidden">
            <Heading size="sm">{product.name}</Heading>
            <Text
              color="gray.600"
              fontSize="sm"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {product.description}
            </Text>
            {product.description && product.description.trim() !== "" && (
              <Box mt={1}>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onShowProductDetailsDialog({
                      product,
                      productGroup: productGroup,
                    });
                  }}
                  variant="outline"
                  size="xs"
                >
                  {t("external.moreInfo")}
                </Button>
              </Box>
            )}
          </Flex>
          <Box ml="auto" textAlign="right">
            <Radio
              id={`product-${product.id}`}
              name={`product-${product.id}`}
              cursor="pointer"
              verticalAlign="middle"
              isChecked={product.id === selectedProduct?.id}
              onChange={() => {
                onSelectProduct({
                  productGroup: productGroup,
                  product,
                });
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <ProductPrice product={product} />
          </Box>
        </Flex>
      ))}
    </Stack>
  );
}

const ProductPrice = React.memo(function ProductPrice({
  product,
}: {
  product: OrderProduct;
}) {
  const { t } = useTranslation();
  if (product.variants.length === 1 && product.variants[0].price !== null) {
    return <PricePerMonth price={product.variants[0].price} />;
  }

  if (product.variants.length > 1) {
    const prices = product.variants.map((it) => it.price);

    const allSamePrice = prices.every((p) => p === prices[0]);
    const allVariantsAreFree = allSamePrice && prices[0] === null;

    if (allVariantsAreFree) {
      return null;
    }
    if (allSamePrice && prices[0] !== null) {
      return <PricePerMonth price={prices[0]} />;
    }

    return (
      <Text fontSize="sm" fontStyle="italic" color="gray.600">
        {t("external.pricePerVariant")}
      </Text>
    );
  }
  return null;
});

function PricePerMonth({ price }: { price: number }) {
  const { t } = useTranslation();
  return (
    <Text mt="2" fontWeight="bold" fontSize="sm" maxWidth="50px">
      {t("general.krPerMonth", { price })}
    </Text>
  );
}
