import signInBg from "../assets/signin-bg.jpg";
import { useSessionStore } from "../hooks/useSessionStore";
import { ErrorDetails } from "./shared/ErrorDetails";
import { SessionStatus } from "../hooks/useSessionStore/types";
import { LoadingIndicator } from "./shared/LoadingIndicator";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Image,
  Button,
  Link,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../routes";
import { useBobutikenLogoUrl } from "../hooks/useBobutikenLogoUrl";

export function SignIn() {
  const { sessionState } = useSessionStore();

  if (sessionState.status === SessionStatus.Fetching) {
    return <LoadingIndicator position="fullscreen" />;
  }

  return <LoginForm />;
}

function LoginForm() {
  const { t } = useTranslation();
  const bobutikenLogoUrl = useBobutikenLogoUrl();
  const { signIn, sessionState } = useSessionStore();

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<LoginFormData>();

  function submit(data: LoginFormData) {
    signIn(data.email, data.password, data.rememberMe);
  }

  return (
    <Flex height="100vh" bg="white">
      <Box width={{ base: "100%", md: "lg" }}>
        <Stack justifyContent="center" spacing={8} px={20} h="100%">
          <Image w="72" src={bobutikenLogoUrl} alt="logo" />
          <form onSubmit={handleSubmit(submit)}>
            <Stack spacing={4}>
              {sessionState.status === SessionStatus.Error && (
                <ErrorDetails error={sessionState.error} />
              )}
              <FormControl id="email">
                <FormLabel>{t("user.email")}</FormLabel>
                <Input
                  id="email"
                  type="email"
                  autoComplete="email"
                  disabled={isSubmitting}
                  {...register("email")}
                />
              </FormControl>

              <FormControl id="password">
                <FormLabel>{t("password.password")}</FormLabel>
                <Input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  disabled={isSubmitting}
                  {...register("password")}
                />
              </FormControl>

              <Flex justifyContent="space-between">
                <FormControl id="rememberMe">
                  <Checkbox
                    fontSize="sm"
                    id="rememberMe"
                    disabled={isSubmitting}
                    {...register("rememberMe")}
                  >
                    {t("signInView.rememberMe")}
                  </Checkbox>
                </FormControl>
                <Link
                  to={routes.forgotPassword}
                  as={RouterLink}
                  whiteSpace="nowrap"
                >
                  {t("password.forgotPassword")}
                </Link>
              </Flex>

              <Button
                disabled={isSubmitting}
                type="submit"
                data-test-id="signInBtn"
              >
                {t("general.signIn")}
              </Button>
            </Stack>
          </form>
        </Stack>
      </Box>
      <Box
        display={{ base: "none", md: "block" }}
        position="relative"
        flex="1"
        minW={0}
      >
        <Image
          position="absolute"
          height="100%"
          width="100%"
          inset={0}
          objectFit="cover"
          src={signInBg}
        />
      </Box>
    </Flex>
  );
}
interface LoginFormData {
  email: string;
  password: string;
  rememberMe: boolean;
}
