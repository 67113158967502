import { SimpleGrid, Stack } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Page } from "../shared/Page";
import { routes } from "../../routes";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useRequiredParams } from "../../hooks/useRequiredParams";
import { CustomerDetails } from "../../api/customersApi";
import { stylesApi, StyleDetails } from "../../api/stylesApi";
import { StyleImageForm } from "./StyleImageForm";
import { StyleDetailsForm } from "./StyleDetailsForm";
import { StyleProductVariantList } from "./StyleProductVariantList";

export function StyleDetailsView({ customer }: { customer: CustomerDetails }) {
  const { styleId } = useRequiredParams("styleId");
  const [styleDetails, isLoading, error, fetch, reset] = useApiRequest(
    stylesApi.getStyleDetails,
  );

  useEffect(() => {
    fetch(styleId);
  }, [fetch, styleId]);

  return (
    <Page
      breadcrumbs={useBreadcrumbs(
        customer.id,
        customer.name,
        styleDetails?.name,
      )}
    >
      {isLoading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      {styleDetails && (
        <SimpleGrid spacing={6}>
          <StyleForm
            styleDetails={styleDetails}
            customerId={customer.id}
            onUpdate={reset}
          />
        </SimpleGrid>
      )}
    </Page>
  );
}

function StyleForm({
  styleDetails,
  customerId,
  onUpdate,
}: {
  styleDetails: StyleDetails;
  customerId: string;
  onUpdate: (styleDetails: StyleDetails) => void;
}) {
  return (
    <Stack spacing={4} shouldWrapChildren={true}>
      <StyleDetailsForm
        styleDetails={styleDetails}
        onUpdate={onUpdate}
      ></StyleDetailsForm>
      <StyleImageForm style={styleDetails} onUpdate={onUpdate} />
      <StyleProductVariantList
        hasAnyProductPackages={styleDetails.hasAnyProductPackages}
        styleId={styleDetails.id}
        customerId={customerId}
      ></StyleProductVariantList>
    </Stack>
  );
}

function useBreadcrumbs(
  customerId: string,
  customerName: string,
  styleName?: string,
) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("customer.customers"),
        to: routes.customers,
      },
      {
        label: customerName,
        to: routes.customer(customerId),
      },
      {
        label: t("style.styles"),
        to: routes.styles(customerId),
      },
      { label: styleName },
    ],
    [customerName, styleName, customerId, t],
  );
}
