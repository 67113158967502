import {
  Flex,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useApiRequest } from "../../../hooks/useApi/useApiRequest";
import { useEffect } from "react";
import { externalApi, ExternalProjectDetails } from "../../../api/externalApi";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { baseApiUrl } from "../../../constants";
import { Icons } from "../../shared/Icons";
import { useTranslation } from "react-i18next";
import { Card } from "../../shared/Card";
import { Header } from "../Header";

export function OrderSheet({
  project,
  customerSlug,
  projectSlug,
}: {
  project: ExternalProjectDetails;
  customerSlug: string;
  projectSlug: string;
}) {
  const { t } = useTranslation();

  const [packages, packagesLoading, packagesError, packagesFetch] =
    useApiRequest(externalApi.getProjectPackages);

  useEffect(() => {
    packagesFetch(project.id);
  }, [packagesFetch, project.id]);

  return (
    <>
      <Header
        customerSlug={customerSlug}
        projectSlug={projectSlug}
        logoImage={project.logoImage}
      />
      {packagesLoading && <LoadingIndicator />}
      {packagesError && <ErrorDetails error={packagesError} />}
      <Flex m="5" justify="center" mt="20vh">
        <Card titleContent={t("orderSheet.downloadOrderSheet")} width="100vh">
          <Table>
            <Thead>
              <Tr>
                <Th width="90%">{t("orderSheet.orderSheet")}</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {packages?.map((p) => (
                <Tr key={p.id}>
                  <Td>{p.name}</Td>
                  <Td>
                    <IconButton
                      as="a"
                      href={`${baseApiUrl}/v1/external/projects/${project.id}/product-package/${p.id}/pdf`}
                      target="_blank"
                      size="sm"
                      variant="outline"
                      aria-label="download"
                      icon={<Icons.Download />}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
      </Flex>
    </>
  );
}
