import { useConfiguration } from "./useConfiguration";
import { HostEnvironment } from "../types";
import logoDefault from "../assets/modexa-logo.png";
import logoDevelopment from "../assets/modexa-logo-development.png";
import logoStaging from "../assets/modexa-logo-staging.png";

export function useBobutikenLogoUrl() {
  const { hostEnvironment } = useConfiguration();

  switch (hostEnvironment) {
    case HostEnvironment.Development:
      return logoDevelopment;
    case HostEnvironment.Staging:
      return logoStaging;
    default:
      return logoDefault;
  }
}
