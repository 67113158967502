import { Thead, Tr, Tbody, Td, Table, HStack } from "@chakra-ui/react";
import { ColumnType, TableComponents } from "rc-table/lib/interface";
import { ReactNode, useCallback, useMemo } from "react";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { DataTableEmptyText } from "./DataTableEmptyText";
import { DataTableHeader, DataTableHeaderProps } from "./DataTableHeader";
import { TableSortingProvider } from "./hooks";
import RcTable from "rc-table";
import { Pagination } from "../Pagination/Pagination";
import { PagingOptions, SortingOptions } from "../../../types";
import { PageSizePicker } from "../PageSizePicker/PageSizePicker";

export interface Column<T> {
  key: string;
  header?: ReactNode;
  cell?: (data: CellData<T>) => ReactNode;
  align?: "left" | "right";
  width?: number;
  sortable?: boolean;
  show?: boolean;
}

export interface CellData<T> {
  row: T;
  value: any;
}

export interface TableProps<T> {
  data?: T[];
  totalItems: number;
  columns: Column<T>[];
  sorting: SortingOptions<T>;
  paging: PagingOptions;
  isLoading: boolean;
  onSortingChange: (newSorting: SortingOptions<T>) => void;
  onPagingChange: (newPaging: PagingOptions) => void;
}

export function DataTable<T>({
  columns,
  data,
  sorting,
  paging,
  isLoading,
  onSortingChange,
  onPagingChange,
  totalItems,
}: TableProps<T>) {
  const handleSortedChange = useCallback(
    (headerKey: keyof T) => {
      onSortingChange({
        sortingKey: headerKey,
        sortingDesc:
          sorting.sortingKey === headerKey ? !sorting.sortingDesc : false,
      });
      onPagingChange({ pageSize: paging.pageSize, page: 1 });
    },
    [
      onSortingChange,
      sorting.sortingKey,
      sorting.sortingDesc,
      onPagingChange,
      paging,
    ],
  );

  const rcTableColumns = useMemo(
    () => mapColumnsToRcColumns(columns),
    [columns],
  );

  return (
    <TableSortingProvider
      value={{ sorting, onToggleSorting: handleSortedChange }}
    >
      <RcTable
        rowKey="id"
        data={data}
        columns={rcTableColumns}
        components={rcTableComponents}
        emptyText={<DataTableEmptyText isLoading={isLoading} />}
      />
      {isLoading && data && data.length > 0 && <LoadingIndicator />}
      <HStack justifyContent="flex-end" mt={4}>
        <PageSizePicker
          pageSizes={[15, 25, 50, 100]}
          onPageSizeChange={(pageSize) =>
            onPagingChange({ ...paging, pageSize })
          }
          pageSize={paging.pageSize}
        />
        <Pagination
          totalItems={totalItems}
          pageSize={paging.pageSize}
          currentPage={paging.page}
          onPageChange={(page) => onPagingChange({ ...paging, page })}
        />
      </HStack>
    </TableSortingProvider>
  );
}

function mapColumnsToRcColumns<T>(columns: Column<T>[]): ColumnType<T>[] {
  return columns.map((c) => ({
    dataIndex: c.key,
    key: c.key,
    title: c.header,
    align: c.align,
    width: c.width,
    render: c.cell
      ? (value, record) => c.cell!({ value, row: record })
      : undefined,
    onHeaderCell: (): DataTableHeaderProps => ({
      columnKey: c.key,
      align: c.align,
      sortable: c.sortable,
    }),
  }));
}

const rcTableComponents: TableComponents<any> = {
  table: Table,
  header: { wrapper: Thead, row: Tr, cell: DataTableHeader },
  body: {
    wrapper: Tbody,
    row: Tr,
    cell: Td,
  },
};
