import { UnorderedList, ListItem, Badge } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CalculatedStatus, ProjectStatus } from "../../api/projectsApi";

export const ActivationStatusErrorMessages = ({
  calculatedStatus,
}: {
  calculatedStatus: CalculatedStatus;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Badge
        textAlign="center"
        minWidth="11em"
        paddingY="5"
        rounded="md"
        colorScheme={
          calculatedStatus.status === ProjectStatus.Active ? "green" : "red"
        }
      >
        {calculatedStatus.status === ProjectStatus.Active
          ? t("general.active")
          : t("general.inactive")}
        {calculatedStatus.activationErrors && (
          <UnorderedList paddingTop={2}>
            {calculatedStatus.activationErrors.map((e) => (
              <ListItem
                key={e.errorCode.toString()}
                textAlign="left"
                fontSize="xx-small"
                marginLeft={2}
              >
                {t([
                  `serverError.${e.errorCode}` as any,
                  "serverError.UNKNOWN",
                ])}
              </ListItem>
            ))}
          </UnorderedList>
        )}
      </Badge>
    </>
  );
};
