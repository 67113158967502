import { Select } from "@chakra-ui/react";

interface PageSizePickerProps {
  pageSizes: number[];
  pageSize: number;
  onPageSizeChange: (page: number) => void;
}

export function PageSizePicker({
  pageSizes,
  pageSize,
  onPageSizeChange,
}: PageSizePickerProps) {
  return (
    <Select
      size="sm"
      width="max-content"
      onChange={(e) => onPageSizeChange(parseInt(e.target.value))}
      value={pageSize}
    >
      {pageSizes.map((size) => (
        <option value={size} key={size}>
          {size}
        </option>
      ))}
    </Select>
  );
}
