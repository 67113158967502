import { Preview } from "./Preview";
import { SelectedProduct, ProductSelection } from "./types";
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Image,
  Text,
  Radio,
} from "@chakra-ui/react";
import { Icons } from "../../shared/Icons";
import { useTranslation } from "react-i18next";
import {
  Angle,
  ProductGroup,
  OrderProduct,
  OrderProductVariant,
  SceneType,
} from "../../../api/externalApi";
import { useState } from "react";
import { ProductDetailsModal } from "./ProductDetailsModal";
import React from "react";
import placeholderImage from "../../../assets/external_placeholder.jpg";
import { AngleSelector } from "./AngleSelector";
import { Sidebar } from "./Sidebar";
import { TextHighlight } from "../../shared/TextHighlight";
import { ProductVariants } from "./ProductVariants";
import { useCompactSideMenu } from "../../../hooks/useCompactSideMenu";

export function ProductSelector({
  productGroup,
  selectedProductByProductGroupId,
  selectedKitchenScene,
  hideNextStep,
  selectProduct,
  goToNextStep,
}: {
  productGroup: ProductGroup;
  selectedProductByProductGroupId: Record<string, SelectedProduct>;
  selectedKitchenScene: SceneType;
  hideNextStep: boolean;
  selectProduct: (product: ProductSelection) => void;
  goToNextStep: () => void;
}) {
  const { variant: selectedVariant, product: selectedProduct } =
    selectedProductByProductGroupId[productGroup.id] ?? {};
  const [modalProduct, setModalProduct] = useState<OrderProduct>();
  const [selectedAngle, setSelectedAngle] = useState<Angle>(Angle.Overview);

  return (
    <Box
      flex="1"
      position="relative"
      minHeight={0}
      flexBasis={0}
      overflow="hidden"
      display="flex"
      mt={{ base: "64px", xl: 0 }}
    >
      <Preview
        selectedProductByProductGroupId={selectedProductByProductGroupId}
        sceneType={selectedKitchenScene}
        angle={selectedAngle}
        isImageNavigationVisible={true}
      />
      <Sidebar>
        <ProductList
          productGroup={productGroup}
          products={productGroup.products}
          selectedVariant={selectedVariant}
          selectedProduct={selectedProduct}
          hideNextStep={hideNextStep}
          onSelectProduct={selectProduct}
          onNextStep={goToNextStep}
          onShowProductDetailsDialog={setModalProduct}
        />
      </Sidebar>
      {modalProduct && (
        <ProductDetailsModal
          productGroup={productGroup}
          product={modalProduct}
          selectedProduct={selectedProductByProductGroupId[productGroup.id]}
          onClose={() => setModalProduct(undefined)}
          onSelectProduct={selectProduct}
        />
      )}
      <AngleSelector
        selectedKitchenScene={selectedKitchenScene}
        selectedAngle={selectedAngle}
        onSetAngle={(a: Angle) => setSelectedAngle(a)}
      ></AngleSelector>
    </Box>
  );
}

function ProductList({
  productGroup,
  products,
  selectedVariant,
  selectedProduct,
  hideNextStep,
  onSelectProduct,
  onNextStep,
  onShowProductDetailsDialog,
}: {
  productGroup: ProductGroup;
  products: OrderProduct[];
  selectedVariant?: OrderProductVariant;
  selectedProduct?: OrderProduct;
  hideNextStep: boolean;
  onSelectProduct: (product: ProductSelection) => void;
  onNextStep: () => void;
  onShowProductDetailsDialog: (product: OrderProduct) => void;
}) {
  const { t } = useTranslation();
  const [isProductVariantsVisible, setProductVariantsIsVisible] =
    useState<boolean>(true);

  const isUsingCompactSideMenu = useCompactSideMenu();

  return (
    <>
      {selectedProduct?.hasVariants && !isUsingCompactSideMenu && (
        <Box overflowY="auto">
          <ProductVariants
            productGroup={productGroup}
            selectedVariant={selectedVariant}
            selectedProduct={selectedProduct}
            onSelectProduct={onSelectProduct}
            isVisible={isProductVariantsVisible}
            onClose={() => setProductVariantsIsVisible(false)}
          ></ProductVariants>
        </Box>
      )}
      <Box
        display="flex"
        height="100%"
        flexDir="column"
        w="xs"
        bg="white"
        zIndex="100"
      >
        <Box flex="1" overflowY="auto" p={4}>
          <Stack spacing={8} shouldWrapChildren={true}>
            <Heading size="md">
              <TextHighlight>
                {t("external.typeOf", {
                  name: productGroup.name.toLowerCase(),
                })}
              </TextHighlight>
            </Heading>
            <ProductItems
              productGroup={productGroup}
              products={products}
              selectedProduct={selectedProduct}
              onSelectProduct={(p) => {
                setProductVariantsIsVisible(true);
                return onSelectProduct(p);
              }}
              onShowProductDetailsDialog={onShowProductDetailsDialog}
            />
          </Stack>
        </Box>
        <Box p={{ base: 1, xl: 4 }} pl="4">
          {selectedProduct?.hasVariants && isUsingCompactSideMenu && (
            <ProductVariants
              productGroup={productGroup}
              selectedVariant={selectedVariant}
              selectedProduct={selectedProduct}
              onSelectProduct={onSelectProduct}
              isVisible={true}
              onClose={() => setProductVariantsIsVisible(false)}
            ></ProductVariants>
          )}
          {!hideNextStep && (
            <Button isFullWidth={true} onClick={onNextStep}>
              <Flex width="100%" alignItems="center">
                <Box flex="1">{t("general.next")}</Box>
                <Icons.ArrowRight fontSize="xx-large" />
              </Flex>
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}

function ProductItems({
  productGroup,
  products,
  selectedProduct,
  onSelectProduct,
  onShowProductDetailsDialog,
}: {
  productGroup: ProductGroup;
  products: OrderProduct[];
  selectedProduct?: OrderProduct;
  onSelectProduct: (product: ProductSelection) => void;
  onShowProductDetailsDialog: (product: OrderProduct) => void;
}) {
  const { t } = useTranslation();

  return (
    <Stack shouldWrapChildren={true} spacing={4}>
      {products.map((p) => (
        <Flex
          key={p.id}
          cursor="pointer"
          onClick={() => {
            onSelectProduct({
              productGroup,
              product: p,
            });
          }}
        >
          <Image
            src={
              p.images.length > 0 ? p.images[0].thumbnailUrl : placeholderImage
            }
            objectFit="contain"
            w={{ base: "60px", xl: "90px" }}
            maxH="70px"
            h="100%"
          />
          <Flex w="190px" direction="column" px={2} overflow="hidden">
            <Heading size="sm">{p.name}</Heading>
            <Text
              color="gray.600"
              fontSize="sm"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {p.description}
            </Text>
            {p.description && p.description.trim() !== "" && (
              <Box mt={1}>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onShowProductDetailsDialog(p);
                  }}
                  variant="outline"
                  size="xs"
                >
                  {t("external.moreInfo")}
                </Button>
              </Box>
            )}
          </Flex>
          <Box ml="auto" textAlign="right">
            <Radio
              id={`product-${p.id}`}
              name={`product-${p.id}`}
              cursor="pointer"
              isChecked={p.id === selectedProduct?.id}
              onChange={() => {
                onSelectProduct({
                  productGroup,
                  product: p,
                });
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <ProductPrice product={p} />
          </Box>
        </Flex>
      ))}
    </Stack>
  );
}

const ProductPrice = React.memo(function ProductPrice({
  product,
}: {
  product: OrderProduct;
}) {
  const { t } = useTranslation();
  if (product.variants.length === 1 && product.variants[0].price !== null) {
    return <PricePerMonth price={product.variants[0].price} />;
  }

  if (product.variants.length > 1) {
    const prices = product.variants.map((it) => it.price);

    const allSamePrice = prices.every((p) => p === prices[0]);
    const allVariantsAreFree = allSamePrice && prices[0] === null;

    if (allVariantsAreFree) {
      return null;
    }
    if (allSamePrice && prices[0] !== null) {
      return <PricePerMonth price={prices[0]} />;
    }

    return (
      <Text fontSize="sm" fontStyle="italic" color="gray.600">
        {t("external.pricePerVariant")}
      </Text>
    );
  }
  return null;
});

function PricePerMonth({ price }: { price: number }) {
  const { t } = useTranslation();
  return (
    <Text mt="2" fontWeight="bold" fontSize="sm" maxWidth="50px">
      {t("general.krPerMonth", { price })}
    </Text>
  );
}
