import {
  Button,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  ProjectDetails,
  ProjectPackageListItem,
  projectsApi,
} from "../../api/projectsApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ServerError } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Icons } from "../shared/Icons";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Card } from "../shared/Card";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { EmptyListAlert } from "../shared/EmptyListAlert";
import { AddProjectPackageModal } from "./AddProjectPackageModal";
import { routes } from "../../routes";
import { CustomerDetails } from "../../api/customersApi";

export function ProjectPackageList({
  customer,
  project,
  onChange,
}: {
  customer: CustomerDetails;
  project: ProjectDetails;
  onChange: () => void;
}) {
  const { t } = useTranslation();

  const [addPackageModalVisible, setAddPackageModalVisible] =
    useState<boolean>(false);

  const [packages, packagesLoading, packagesError, packagesFetch] =
    useApiRequest(projectsApi.getProjectPackages);

  useEffect(() => {
    packagesFetch(project.id);
  }, [packagesFetch, project.id]);

  return (
    <Card
      titleContent={t("productPackage.productPackages")}
      subtitleContent={t("productPackage.orderByExplanation")}
      extraContent={
        <Button size="sm" onClick={() => setAddPackageModalVisible(true)}>
          {t("general.add")}
        </Button>
      }
    >
      {packagesLoading && <LoadingIndicator />}
      {packagesError && <ErrorDetails error={packagesError} />}
      {addPackageModalVisible && (
        <AddProjectPackageModal
          addedPackages={packages ?? []}
          customer={customer}
          projectId={project.id}
          onClose={() => setAddPackageModalVisible(false)}
          onSuccess={() => {
            packagesFetch(project.id);
            onChange();
          }}
        />
      )}
      <PackageTable
        customer={customer}
        projectId={project.id}
        packages={packages}
        onRemoveSuccess={() => {
          packagesFetch(project.id);
          onChange();
        }}
      />
    </Card>
  );
}

function PackageTable({
  customer,
  projectId,
  packages,
  onRemoveSuccess,
}: {
  customer: CustomerDetails;
  projectId: string;
  packages: ProjectPackageListItem[] | null;
  onRemoveSuccess: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [error, setError] = useState<ServerError>();
  const [packageToRemove, setPackageToRemove] =
    useState<ProjectPackageListItem | null>(null);
  const [isRemoving, removePackageRequest] = useApiRequestCallback(
    projectsApi.removeProjectPackage,
  );

  function removePackage(packageId: string) {
    setPackageToRemove(null);
    setError(undefined);
    removePackageRequest({
      onSuccess: () => {
        toast({
          title: t("general.deleted"),
          status: "success",
        });
        onRemoveSuccess();
      },
      onError: setError,
    }).send(projectId, packageId);
  }

  return (
    <>
      {isRemoving && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      <Table>
        <Thead>
          <Tr>
            <Th width="90%">{t("general.name")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {packages?.map((p) => (
            <Tr key={p.id}>
              <Td>
                {customer.name} - {p.name}
              </Td>
              <Td>
                <HStack>
                  <IconButton
                    variant="outline"
                    aria-label={t("general.delete")}
                    icon={<Icons.Trash />}
                    size="sm"
                    onClick={() => setPackageToRemove(p)}
                  />
                  <Button
                    as={RouterLink}
                    to={routes.productPackage(customer.id, p.id)}
                    size="sm"
                  >
                    {t("general.edit")}
                  </Button>
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {packages?.length === 0 && <EmptyListAlert />}
      {packageToRemove && (
        <ConfirmationModal
          message={t("general.deleteConfirmation", {
            name: packageToRemove.name,
          })}
          confirmButtonText={t("general.delete")}
          confirmButtonColor="red"
          onConfirm={() => removePackage(packageToRemove.id)}
          onClose={() => setPackageToRemove(null)}
        />
      )}
    </>
  );
}
