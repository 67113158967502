import { Box, IconButton } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { baseApiUrl } from "../../../constants";
import {
  useImageLoader,
  ImageLoaderStatus,
} from "../../../hooks/useImageLoader";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { SelectedProduct } from "./types";
import { SceneType, Angle } from "../../../api/externalApi";
import { Icons } from "../../shared/Icons";
import { TextHighlight } from "../../shared/TextHighlight";
import { useTranslation } from "react-i18next";
import {
  ScreenOrientation,
  useScreenOrientation,
} from "../../../hooks/useScreenOrientation";
import { useDebouncedCallback } from "use-debounce";

export enum Align {
  Left = "left",
  Center = "center",
  Right = "right",
}

export function Preview({
  selectedProductByProductGroupId,
  sceneType,
  angle,
  isImageNavigationVisible,
  otherOptionsImage,
}: {
  selectedProductByProductGroupId: Record<string, SelectedProduct>;
  sceneType: SceneType;
  angle: Angle;
  isImageNavigationVisible: boolean;
  otherOptionsImage?: string | null;
}) {
  const variantIds = useMemo(
    () =>
      Object.values(selectedProductByProductGroupId).map((p) => p.variant.id),
    [selectedProductByProductGroupId],
  );

  const [previewUrl, setPreviewUrl] = useState("");

  const onSetPreviewUrlDebounced = useDebouncedCallback(setPreviewUrl, 10);

  useMemo(
    () =>
      onSetPreviewUrlDebounced(composePreviewUrl(variantIds, sceneType, angle)),
    [variantIds, sceneType, angle, onSetPreviewUrlDebounced],
  );
  const imgLoader = useImageLoader(previewUrl);
  const [imageBackgroundAlign, setImageBackgroundAlign] = useState(
    Align.Center,
  );

  const { t } = useTranslation();
  const isScreenPortrait =
    useScreenOrientation() === ScreenOrientation.Portrait;

  return (
    <Box
      flex="1"
      bg="gray.900"
      transition="background 300ms ease-in-out"
      style={{
        backgroundSize: "cover",
        backgroundPosition: `bottom ${imageBackgroundAlign}`,
        backgroundRepeat: "no-repeat",
        backgroundImage: otherOptionsImage
          ? `url(${otherOptionsImage})`
          : imgLoader.imgSrc != null
          ? `url(${imgLoader.imgSrc})`
          : "none",
      }}
    >
      {imgLoader.status === ImageLoaderStatus.Loading && (
        <LoadingIndicator position="center" />
      )}
      {imgLoader.status === ImageLoaderStatus.Error && (
        <ErrorDetails error={imgLoader.error} />
      )}
      {isScreenPortrait && isImageNavigationVisible && (
        <Box
          position="absolute"
          bottom="10px"
          left="5"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box mb="1" textAlign="center" position="relative" top="10px">
            <Box
              display="inline-block"
              fontSize="s"
              textAlign="center"
              fontWeight="bold"
              pl="3"
              pr="3"
            >
              <TextHighlight>{t("external.imageNavigation")}</TextHighlight>
            </Box>
          </Box>

          <Box display="flex" flexDirection="row" gap="2">
            <IconButton
              m="2"
              variant="ghost"
              aria-label="expand"
              color="blackAlpha.800"
              bg="whiteAlpha.700"
              borderRadius="full"
              icon={<Icons.ArrowLeft boxSize="6" />}
              onClick={() => setImageBackgroundAlign(Align.Left)}
            />
            <IconButton
              m="2"
              position="relative"
              top="10px"
              variant="ghost"
              aria-label="expand"
              color="blackAlpha.800"
              icon={<Icons.Focus3 boxSize="6" />}
              bg="whiteAlpha.700"
              borderRadius="full"
              onClick={() => setImageBackgroundAlign(Align.Center)}
            />
            <IconButton
              m="2"
              variant="ghost"
              aria-label="expand"
              color="blackAlpha.800"
              icon={<Icons.ArrowRight boxSize="6" />}
              bg="whiteAlpha.700"
              borderRadius="full"
              onClick={() => setImageBackgroundAlign(Align.Right)}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

function composePreviewUrl(
  variantIds: string[],
  sceneType: SceneType,
  angle: Angle,
) {
  const baseUrl = `${baseApiUrl}/v1/external/preview`;
  const params = new URLSearchParams();

  for (const productId of variantIds) {
    params.append("variantId", productId);
  }

  params.append("sceneType", sceneType);

  if (!!angle) {
    params.append("angle", angle);
  }

  return `${baseUrl}?${params.toString()}`;
}
