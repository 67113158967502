import { OrderProductPackage, SceneType } from "../../../api/externalApi";
import { Container, Heading } from "@chakra-ui/layout";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Icons } from "../../shared/Icons";
import { getScenePreviewImage } from "../Helpers/KitchenSceneHelper";
import { TextHighlight } from "../../shared/TextHighlight";

export function KitchenSceneSelector({
  scenes,
  selectedKitchenScene,
  selectedProductPackage,
  selectKitchenScene,
  goBack,
}: {
  scenes: SceneType[];
  selectedKitchenScene: SceneType | null;
  selectedProductPackage: OrderProductPackage | null;
  selectKitchenScene: (scene: SceneType) => void;
  goBack: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Box
      position="relative"
      top={selectedKitchenScene && selectedProductPackage ? "80px" : "0"}
    >
      <Container maxWidth="7xl">
        <Flex flexDirection="column" alignItems="center" py={10}>
          <Heading fontSize="5xl" mb={4}>
            <TextHighlight>{t("scenes.selectKitchenType")}</TextHighlight>
          </Heading>
          <Text textAlign="center" fontSize="sm" fontWeight="bold">
            {t("scenes.kitchenTypeDescription")}
          </Text>
          <Text textAlign="center" fontWeight="light" fontSize="sm" m="3">
            {t("scenes.kitchenTypeSubDescription")}
          </Text>
          <Box w="180px" mt="6" mb="2" h="1" background="primary.500" />
          <Flex
            w={{ base: "580px", lg: "800px" }}
            maxW="80vw"
            textAlign="left"
            m="4"
          >
            <Button
              variant="link"
              fontSize="xs"
              display="flex"
              alignItems="center"
              onClick={() => goBack()}
            >
              <Icons.ArrowLeft boxSize={5} />
              {t("general.back")}
            </Button>
          </Flex>
          <Flex overflowY="auto" maxWidth="90vw">
            {scenes.map((scene) => (
              <KitchenScene
                key={scene}
                isSelected={selectedKitchenScene === scene}
                scene={scene}
                selectKitchenScene={(s) => {
                  selectKitchenScene(s);
                }}
              />
            ))}
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}

function KitchenScene({
  isSelected,
  scene,
  selectKitchenScene,
}: {
  isSelected: boolean;
  scene: SceneType;
  selectKitchenScene: (scene: SceneType) => void;
}) {
  const { t } = useTranslation();

  function handleClick() {
    selectKitchenScene(scene);
  }

  return (
    <Box
      borderWidth={isSelected ? 3 : 0}
      borderColor={isSelected ? "primary.500" : "primary.100"}
      m="4"
    >
      <Box
        height={{ base: "150px", lg: "300px" }}
        w={{ base: "200px", lg: "270px" }}
        cursor={isSelected ? "default" : "pointer"}
        key={scene}
        display="flex"
        flexDirection="column"
        alignItems="center"
        borderStartStyle="solid"
        background={`url(${getScenePreviewImage(scene)})`}
        backgroundSize="cover"
        backgroundPosition="center"
        py="6"
      ></Box>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p="4"
        gridGap={2}
        background="blackAlpha.100"
      >
        <Heading fontSize="xl" color="blackAlpha.800">
          {t(`scenes.${scene}`)}
        </Heading>
        <Flex w="100%" justifyContent="center">
          <Button
            w="60%"
            leftIcon={isSelected ? <Icons.Check /> : undefined}
            marginTop="auto"
            opacity={isSelected ? 0.4 : 1}
            onClick={handleClick}
          >
            {isSelected ? t("general.selected") : t("general.select")}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
