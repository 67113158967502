import { useCallback, useState } from "react";

export const localStorageKey = "global-page-size";
export const initialGlobalPageSize = 15;

function getStorageValue() {
  try {
    const value = localStorage.getItem(localStorageKey);
    return value !== null ? (JSON.parse(value) as number) : null;
  } catch {
    return null;
  }
}

export function useGlobalPageSize() {
  const [value, setValue] = useState(() => {
    return getStorageValue() ?? initialGlobalPageSize;
  });

  const updateGlobalPageSize = useCallback((newValue: number) => {
    setValue(newValue);
    try {
      localStorage.setItem(localStorageKey, JSON.stringify(newValue));
    } catch {}
  }, []);

  return { globalPageSize: value, setGlobalPageSize: updateGlobalPageSize };
}
