import { Box } from "@chakra-ui/react";
import DOMPurify from "dompurify";
import { sanitize } from "dompurify";
import { useMemo } from "react";
import { richTextStyles } from "./styles";

DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  // set all elements owning target to target=_blank
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
    node.setAttribute("rel", "noopener noreferrer");
  }
});

export function RichTextContent({ content }: { content: string }) {
  const sanitizedHtml = useMemo(() => sanitize(content), [content]);

  return (
    <Box
      sx={richTextStyles}
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />
  );
}
