import {
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  Stack,
  SimpleGrid,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { StyleDetails } from "../../../api/stylesApi";
import placeholderImg from "../../../assets/external_placeholder.jpg";
import { CustomModal } from "../../shared/CustomModal";
import { ImageCarousel } from "../../shared/ImageCarousel";

export function StyleDetailsModal({
  styleDetails,
  onClose,
}: {
  styleDetails: StyleDetails;
  onClose: () => void;
}) {
  return (
    <CustomModal isOpen={true} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody overflow="auto" pr="8" pl="8" mt={10} mb={10}>
          <SimpleGrid spacing="8" columns={{ base: 1, sm: 2 }}>
            <Box>
              <StyleImages styleDetails={styleDetails} />
            </Box>
            <Stack spacing={4}>
              <Heading>{styleDetails.name}</Heading>
              <Text whiteSpace="pre-wrap">{styleDetails.description}</Text>
            </Stack>
          </SimpleGrid>
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </CustomModal>
  );
}

function StyleImages({ styleDetails }: { styleDetails: StyleDetails }) {
  const images = useMemo(() => {
    const styleImageUrls = !!styleDetails.image
      ? [styleDetails.image]
      : [placeholderImg];

    const images = [...styleImageUrls];
    return images.length ? images : [placeholderImg];
  }, [styleDetails]);

  return <ImageCarousel images={images} imageMaxHeight="500px" />;
}
