import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ProjectDetails,
  projectsApi,
  ProjectOrderConfirmationPageDetails,
} from "../../api/projectsApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { Card } from "../shared/Card";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
} from "@chakra-ui/react";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { Controller, useForm } from "react-hook-form";
import { ServerError } from "../../types";
import { useTranslation } from "react-i18next";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { RichTextEditor } from "../shared/RichText/RichTextEditor";
import { ImageList } from "../shared/ImageList";

export const ProjectOrderConfirmationPageView = ({
  project,
  onUpdate,
}: {
  project: ProjectDetails;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();

  const [
    orderConfirmationPage,
    orderConfirmationPageLoading,
    orderConfirmationPageError,
    orderConfirmationPageFetch,
    orderConfirmationPageReset,
  ] = useApiRequest(projectsApi.getProjectOrderConfirmationPage);

  useEffect(() => {
    orderConfirmationPageFetch(project.id);
  }, [orderConfirmationPageFetch, project.id]);

  return (
    <>
      {orderConfirmationPageLoading && <LoadingIndicator />}
      {orderConfirmationPageError && (
        <ErrorDetails error={orderConfirmationPageError} />
      )}
      {orderConfirmationPage && (
        <Stack spacing={4} shouldWrapChildren={true}>
          <Card>
            <OrderConfirmationPageForm
              orderConfirmationPage={orderConfirmationPage}
              projectId={project.id}
              onUpdate={(details: ProjectOrderConfirmationPageDetails) => {
                orderConfirmationPageReset(details);
                onUpdate();
              }}
            />
          </Card>
          <Card titleContent={t("general.image")}>
            <OrderConfirmationPageImageForm
              orderConfirmationPage={orderConfirmationPage}
              projectId={project.id}
              onUpdate={orderConfirmationPageReset}
            />
          </Card>
        </Stack>
      )}
    </>
  );
};

interface FormData {
  content: string;
}

function OrderConfirmationPageForm({
  projectId,
  orderConfirmationPage,
  onUpdate,
}: {
  projectId: string;
  orderConfirmationPage: ProjectOrderConfirmationPageDetails;
  onUpdate: (product: ProjectOrderConfirmationPageDetails) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [error, setError] = useState<ServerError>();

  const [updatePageLoading, updatePageRequest] = useApiRequestCallback(
    projectsApi.updateProjectOrderConfirmationPage,
  );

  const {
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    control,
  } = useForm<FormData>({ defaultValues: orderConfirmationPage });

  function handleUpdateSuccess(
    updatedPage: ProjectOrderConfirmationPageDetails,
  ) {
    setError(undefined);
    reset(updatedPage);
    onUpdate(updatedPage);
    toast({ title: t("general.saved"), status: "success" });
  }

  async function onSubmit({ content }: FormData) {
    updatePageRequest({
      onSuccess: handleUpdateSuccess,
      onError: setError,
    }).send(projectId, {
      content,
    });
  }

  return (
    <>
      {updatePageLoading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Stack spacing={4} shouldWrapChildren={true}>
          <FormControl id="content" isInvalid={!!errors.content}>
            <FormLabel>{t("general.content")}</FormLabel>
            <Controller
              name="content"
              render={({ field }) => (
                <RichTextEditor
                  value={field.value}
                  onChange={field.onChange}
                  showFontFamilyOptions={true}
                />
              )}
              control={control}
            />
            <FormErrorMessage>{errors.content?.message}</FormErrorMessage>
          </FormControl>
          <Flex justifyContent="flex-end">
            <Button type="submit" disabled={!isDirty}>
              {t("general.save")}
            </Button>
          </Flex>
        </Stack>
      </form>
    </>
  );
}

function OrderConfirmationPageImageForm({
  projectId,
  orderConfirmationPage,
  onUpdate,
}: {
  projectId: string;
  orderConfirmationPage: ProjectOrderConfirmationPageDetails;
  onUpdate: (
    orderConfirmationPage: ProjectOrderConfirmationPageDetails,
  ) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();

  const [uploadError, setUploadError] = useState<ServerError | null>(null);
  const [uploadLoading, uploadRequest] = useApiRequestCallback(
    projectsApi.uploadOrderConfirmationPageImage,
  );

  const [deleteError, setDeleteError] = useState<ServerError | null>(null);
  const [deleteLoading, deleteRequest] = useApiRequestCallback(
    projectsApi.deleteOrderConfirmationPageImage,
  );

  const uploadImage = useCallback(
    (image: File) => {
      uploadRequest({
        onSuccess: (response) => {
          setUploadError(null);
          toast({
            title: t("general.added"),
            status: "success",
          });
          onUpdate({ ...orderConfirmationPage, image: response.image });
        },
        onError: setUploadError,
      }).send(projectId, image);
    },
    [uploadRequest, projectId, toast, t, onUpdate, orderConfirmationPage],
  );

  function deleteImage() {
    deleteRequest({
      onSuccess: () => {
        setDeleteError(null);
        toast({
          title: t("general.deleted"),
          status: "success",
        });
        onUpdate({ ...orderConfirmationPage, image: null });
      },
      onError: setDeleteError,
    }).send(projectId);
  }

  const imageList = useMemo(
    () =>
      orderConfirmationPage.image
        ? [{ id: "image-1", url: orderConfirmationPage.image }]
        : [],
    [orderConfirmationPage],
  );

  return (
    <ImageList
      maxImages={1}
      images={imageList}
      errors={[deleteError, uploadError]}
      isLoading={deleteLoading || uploadLoading}
      onUpload={uploadImage}
      onRemove={deleteImage}
    />
  );
}
