import { IconButton, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { baseApiUrl } from "../../constants";
import { routes } from "../../routes";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { Card } from "../shared/Card";
import { Icons } from "../shared/Icons";
import { Page } from "../shared/Page";

export function ReportsListPage() {
  const { t } = useTranslation();

  let reports = [
    {
      id: 1,
      title: t("customer.customers"),
      name: "customers",
    },
    {
      id: 2,
      title: t("general.projects"),
      name: "projects",
    },
    {
      id: 3,
      title: t("external.productVariantsInCustomerAssortment"),
      name: "productVariants",
    },
  ];

  return (
    <Page breadcrumbs={useBreadcrumbs()}>
      <Card titleContent={t("admin.reports")}>
        <Table>
          <Thead>
            <Tr>
              <Th width="90%">{t("general.name")}</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {reports?.map((p) => (
              <Tr key={p.id}>
                <Td>{p.title}</Td>
                <Td>
                  <IconButton
                    as="a"
                    target="_blank"
                    size="sm"
                    variant="outline"
                    aria-label="download"
                    href={`${baseApiUrl}/v1/reports/${p.name}`}
                    icon={<Icons.Download />}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    </Page>
  );
}

function useBreadcrumbs() {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("general.settings"),
        to: routes.settings,
      },
      {
        label: t("admin.reports"),
        to: routes.users,
      },
    ],
    [t],
  );
}
