import { Preview } from "./Preview";
import { SelectedProduct, StyleSelection } from "./types";
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Image,
  Text,
  Radio,
} from "@chakra-ui/react";
import { Icons } from "../../shared/Icons";
import { useTranslation } from "react-i18next";
import { Angle, SceneType } from "../../../api/externalApi";
import { useState } from "react";
import placeholderImage from "../../../assets/external_placeholder.jpg";
import { AngleSelector } from "./AngleSelector";
import { Sidebar } from "./Sidebar";
import { TextHighlight } from "../../shared/TextHighlight";
import { StyleDetails } from "../../../api/stylesApi";
import { StyleDetailsModal } from "./StyleDetailsModal";
import { StyleInfoModal } from "./StyleInfoModal";

export function StylesSelector({
  selectedProductByProductGroupId,
  selectedKitchenScene,
  styles,
  selectedStyle,
  isStyleInfoAtInitVisible,
  onSelectStyle,
  goToNextStep,
  setIsStyleInfoAtInitVisible,
}: {
  selectedProductByProductGroupId: Record<string, SelectedProduct>;
  selectedKitchenScene: SceneType;
  styles: StyleDetails[];
  selectedStyle: StyleDetails | null;
  isStyleInfoAtInitVisible: boolean;
  onSelectStyle: (style: StyleSelection) => void;
  goToNextStep: () => void;
  setIsStyleInfoAtInitVisible: (isStyleInfoVisible: boolean) => void;
}) {
  const [modalStyle, setModalStyle] = useState<StyleDetails>();
  const [isStyleInfoModalVisible, setIsStyleInfoModalVisible] =
    useState<boolean>(isStyleInfoAtInitVisible);
  const [selectedAngle, setSelectedAngle] = useState<Angle>(Angle.Overview);

  return (
    <Box
      flex="1"
      position="relative"
      minHeight={0}
      flexBasis={0}
      overflow="hidden"
      display="flex"
      mt={{ base: "64px", xl: 0 }}
    >
      <Preview
        selectedProductByProductGroupId={selectedProductByProductGroupId}
        sceneType={selectedKitchenScene}
        angle={selectedAngle}
        isImageNavigationVisible={true}
      />
      <Sidebar>
        <StyleList
          styles={styles}
          selectedStyle={selectedStyle}
          onSelectStyle={onSelectStyle}
          onNextStep={goToNextStep}
          onShowStyleDetailsDialog={setModalStyle}
          setIsStyleInfoModalVisible={setIsStyleInfoModalVisible}
        />
      </Sidebar>
      {modalStyle && (
        <StyleDetailsModal
          styleDetails={modalStyle}
          onClose={() => setModalStyle(undefined)}
        />
      )}
      {isStyleInfoModalVisible && (
        <StyleInfoModal
          onClose={() => setIsStyleInfoModalVisible(false)}
          onConfirm={() => {
            setIsStyleInfoAtInitVisible(false);
            return setIsStyleInfoModalVisible(false);
          }}
        />
      )}
      <AngleSelector
        selectedKitchenScene={selectedKitchenScene}
        selectedAngle={selectedAngle}
        onSetAngle={(a: Angle) => setSelectedAngle(a)}
      ></AngleSelector>
    </Box>
  );
}

function StyleInfo({
  onShowStyleInfoDialog,
}: {
  onShowStyleInfoDialog: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Box bg="white" overflowX="clip">
      <Box mb="10">
        <Heading size="md">
          <TextHighlight>{t("style.styles")}</TextHighlight>
        </Heading>
        <Text whiteSpace="pre-wrap">
          {t("style.stylesInfoShortIntro")}
          <strong>{t("style.stylesInfoShortOutro")}</strong>
        </Text>
        <Box mt="4">
          <Button onClick={onShowStyleInfoDialog} variant="outline" size="xs">
            {t("external.moreInfo")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

function StyleList({
  styles,
  selectedStyle,
  onSelectStyle,
  onNextStep,
  onShowStyleDetailsDialog,
  setIsStyleInfoModalVisible,
}: {
  styles: StyleDetails[];
  selectedStyle: StyleDetails | null;
  onSelectStyle: (style: StyleSelection) => void;
  onNextStep: () => void;
  onShowStyleDetailsDialog: (style: StyleDetails) => void;
  setIsStyleInfoModalVisible: (isStyleInfoVisible: boolean) => void;
}) {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      height="100%"
      flexDir="column"
      w="xs"
      bg="white"
      zIndex="100"
    >
      <Box flex="1" overflowY="auto" p={4}>
        <StyleInfo
          onShowStyleInfoDialog={() => setIsStyleInfoModalVisible(true)}
        ></StyleInfo>
        <Stack spacing={8} shouldWrapChildren={true}>
          <StyleItems
            styles={styles}
            selectedStyle={selectedStyle}
            onSelectStyle={onSelectStyle}
            onShowStyleDetailsDialog={onShowStyleDetailsDialog}
          />
        </Stack>
      </Box>
      <Box p={{ base: 1, xl: 4 }} pl="4">
        <Button isFullWidth={true} onClick={onNextStep}>
          <Flex width="100%" alignItems="center">
            <Box flex="1">{t("general.next")}</Box>
            <Icons.ArrowRight fontSize="xx-large" />
          </Flex>
        </Button>
      </Box>
    </Box>
  );
}

function StyleItems({
  styles,
  selectedStyle,
  onSelectStyle,
  onShowStyleDetailsDialog,
}: {
  selectedStyle: StyleDetails | null;
  styles: StyleDetails[];
  onSelectStyle: (style: StyleSelection) => void;
  onShowStyleDetailsDialog: (style: StyleDetails) => void;
}) {
  const { t } = useTranslation();

  return (
    <Stack shouldWrapChildren={true} spacing={4}>
      {styles?.map((s) => (
        <Flex
          key={s.id}
          cursor="pointer"
          onClick={() => {
            onSelectStyle({
              styleDetails: s,
            });
          }}
        >
          <Image
            src={s.image != null ? s.image : placeholderImage}
            objectFit="contain"
            w={{ base: "60px", xl: "90px" }}
            onClick={(e) => {
              e.stopPropagation();
              onShowStyleDetailsDialog(s);
            }}
          />
          <Flex direction="column" px={2} overflow="hidden">
            <Heading size="sm">{s.name}</Heading>
            <Text
              color="gray.600"
              fontSize="sm"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >
              {s.description}
            </Text>
            {s.description && s.description.trim() !== "" && (
              <Box mt={1}>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onShowStyleDetailsDialog(s);
                  }}
                  variant="outline"
                  size="xs"
                >
                  {t("external.moreInfo")}
                </Button>
              </Box>
            )}
          </Flex>
          <Box ml="auto" textAlign="right">
            <Radio
              id={`style-${s.id}`}
              name={`style-${s.id}`}
              cursor="pointer"
              isChecked={s.id === selectedStyle?.id}
              onChange={() => {
                onSelectStyle({
                  styleDetails: s,
                });
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Box>
        </Flex>
      ))}
    </Stack>
  );
}
