import { BoxProps } from "@chakra-ui/layout";
import { Box, Flex, Heading } from "@chakra-ui/react";
import React from "react";

export interface CardProps extends BoxProps {
  titleContent?: React.ReactNode;
  subtitleContent?: React.ReactNode;
  extraContent?: React.ReactNode;
}

export function Card({
  titleContent,
  subtitleContent,
  extraContent,
  children,
  ...props
}: CardProps) {
  return (
    <Box bg="white" rounded="md" shadow="md" position="relative" {...props}>
      <Box borderBottom="1px" borderBottomColor="gray.100">
        {(titleContent || extraContent) && (
          <Flex justifyContent="space-between" alignItems="center" p={4}>
            {titleContent && <Heading size="sm">{titleContent}</Heading>}
            {extraContent && <Box ml="auto">{extraContent}</Box>}
          </Flex>
        )}
        {subtitleContent && (
          <Box pl={4} pb="22.4px" lineHeight="16px" mt="-6px">
            {subtitleContent}
          </Box>
        )}
      </Box>
      <Box p={4}>{children}</Box>
    </Box>
  );
}
