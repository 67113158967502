import { ApiRequest } from "../hooks/useApi/types";
import { PagedItems, PagingOptions, SortingOptions } from "../types";

export const productsApi = {
  listProducts:
    (request: ListProductRequest): ApiRequest<PagedItems<ProductListItem>> =>
    (httpClient) =>
      httpClient.get("v1/products", { params: request }),

  getProductDetails:
    (id: string): ApiRequest<ProductDetails> =>
    (httpClient) =>
      httpClient.get(`v1/products/${id}`),

  createProduct:
    (request: CreateProductRequest): ApiRequest<ProductDetails> =>
    (httpClient) =>
      httpClient.post("v1/products", { data: request }),

  updateProduct:
    (id: string, request: UpdateProductRequest): ApiRequest<ProductDetails> =>
    (httpClient) =>
      httpClient.put(`v1/products/${id}`, { data: request }),

  listProductImages:
    (productId: string): ApiRequest<ProductImageItem[]> =>
    (httpClient) =>
      httpClient.get(`v1/products/${productId}/images`),

  uploadProductImage:
    (productId: string, file: File): ApiRequest<ProductImageItem> =>
    (httpClient) => {
      const data = new FormData();
      data.append("file", file);
      return httpClient.post(`v1/products/${productId}/images`, {
        data,
      });
    },

  deleteProductImage:
    (productId: string, imageId: string): ApiRequest =>
    (httpClient) =>
      httpClient.delete(`v1/products/${productId}/images/${imageId}`),
};

interface ListProductRequest
  extends SortingOptions<ProductListItem>,
    PagingOptions {
  searchTerm?: string;
}

export interface ProductListItem {
  id: string;
  name: string;
  imageThumbnailUrl: string | null;
  productGroupName: string;
  numVariants: number;
}

export interface CreateProductRequest {
  name: string;
  productGroupId: string;
}

export interface UpdateProductRequest {
  name: string;
  description: string;
  productGroupId: string;
}

export interface ProductDetails {
  id: string;
  name: string;
  description: string;
  productGroupId: string;
  isUsedInCustomerAssortment: boolean;
}

export interface ProductImageItem {
  id: string;
  url: string;
  thumbnailUrl: string;
}
