import { useMemo } from "react";
import { useParams } from "react-router";

/**
 * Returns the specified parameters from the current URL.
 * Throws error if the parameters are absent.
 */
function useRequiredParams(): {};
function useRequiredParams<T extends string>(
  ...paramKeys: T[]
): Record<T, string>;
function useRequiredParams(...requiredKeys: string[]) {
  const params = useParams();

  const requiredParams = useMemo(
    () =>
      requiredKeys.reduce<Record<string, string>>((acc, requiredKey) => {
        const value = params[requiredKey];
        if (!value) {
          throw new Error(`Required parameter ${requiredKey} not found`);
        }

        acc[requiredKey] = value;

        return acc;
      }, {}),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params, `${requiredKeys}`],
  );

  return requiredParams;
}

export { useRequiredParams };
