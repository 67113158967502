import { ApiRequest } from "../hooks/useApi/types";
import {
  PagedItems,
  PagingOptions,
  Permission,
  SortingOptions,
} from "../types";

export const rolesApi = {
  listRoles:
    (params: ListRolesRequest): ApiRequest<PagedItems<RoleListItem>> =>
    (httpClient) =>
      httpClient.get("v1/roles", { params }),

  createRole:
    (data: CreateRoleRequest): ApiRequest =>
    (httpClient) =>
      httpClient.post("v1/roles", { data }),

  deleteRole:
    (id: string): ApiRequest =>
    (httpClient) =>
      httpClient.delete(`v1/roles/${id}`),

  getRolesDetails:
    (id: string): ApiRequest<RoleDetails> =>
    (httpClient) =>
      httpClient.get(`v1/roles/${id}`),

  updateRole:
    (id: string, data: UpdateRoleRequest): ApiRequest<RoleDetails> =>
    (httpClient) =>
      httpClient.put(`v1/roles/${id}`, { data }),
};

interface ListRolesRequest
  extends SortingOptions<RoleListItem>,
    PagingOptions {}

export interface RoleListItem {
  id: string;
  name: string;
}

export interface CreateRoleRequest {
  name: string;
}

export interface RoleDetails {
  id: string;
  name: string;
  permissions: Permission[];
}

export interface UpdateRoleRequest {
  name: string;
  permissions: Permission[];
}
