import { useCallback, useEffect, useMemo, useState } from "react";
import { ProductListItem, productsApi } from "../../api/productsApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { ErrorDetails } from "../shared/ErrorDetails";
import { useTranslation } from "react-i18next";
import { Page } from "../shared/Page";
import { Card } from "../shared/Card";
import { Box, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CreateProductModal } from "./CreateProductModal";
import { PagingOptions, Permission, SortingOptions } from "../../types";
import { Column, DataTable } from "../shared/DataTable/DataTable";
import { Link as RouterLink } from "react-router-dom";
import { SearchFilterInput } from "../shared/SearchFilterInput";
import placeholderImg from "../../assets/placeholder.jpg";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { routes } from "../../routes";
import { useGlobalPageSize } from "../../hooks/usePageSize";
import { useSavedSorting } from "../../hooks/useSavedSorting";
import { usePermission } from "../../hooks/usePermission";
import { Image } from "../shared/Image";

interface ListOptions extends PagingOptions, SortingOptions<ProductListItem> {
  searchTerm: string;
}

export function ProductList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [createProductModalVisible, setCreateProductModalVisible] =
    useState<boolean>(false);
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const hasPermission = usePermission(Permission.ManageProducts);
  const { savedSorting, setSavedSorting } = useSavedSorting<ProductListItem>(
    "ProductList",
    { sortingDesc: false, sortingKey: "productGroupName" },
  );
  const [listOptions, setListOptions] = useState<ListOptions>({
    ...savedSorting,
    page: 1,
    pageSize: globalPageSize,
    searchTerm: "",
  });

  const [products, loading, error, fetch] = useApiRequest(
    productsApi.listProducts,
  );

  const fetchProducts = useCallback(() => {
    fetch({ ...listOptions });
  }, [fetch, listOptions]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const columns = useMemo<Column<ProductListItem>[]>(
    () => [
      {
        key: "image",
        sortable: false,
        cell: ({ row }) => (
          <Image
            src={row.imageThumbnailUrl ?? placeholderImg}
            alt={row.name}
            boxSize={12}
            objectFit="contain"
          />
        ),
      },
      {
        key: "name",
        header: t("general.name"),
        sortable: true,
        cell: ({ row }) => row.name,
      },
      {
        key: "productGroupName",
        header: t("productGroup.productGroup"),
        sortable: true,
        cell: ({ row }) => row.productGroupName,
      },
      {
        key: "variants",
        header: t("product.variants"),
        sortable: false,
        cell: ({ row }) => row.numVariants,
      },
      {
        key: "actions",
        width: 150,
        cell: ({ row }) => (
          <Button size="sm" as={RouterLink} to={routes.product(row.id)}>
            {t("general.edit")}
          </Button>
        ),
      },
    ],
    [t],
  );

  function goToProduct(productId: string) {
    navigate(productId);
  }

  return (
    <Page breadcrumbs={useBreadcrumbs()}>
      {loading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      {products && (
        <Card
          titleContent={t("product.products")}
          extraContent={
            <Button
              isDisabled={!hasPermission}
              size="sm"
              onClick={() => setCreateProductModalVisible(true)}
            >
              {t("general.add")}
            </Button>
          }
        >
          <Box mb={4}>
            <SearchFilterInput
              value={listOptions.searchTerm}
              onChange={(value) =>
                setListOptions((options) => ({
                  ...options,
                  searchTerm: value,
                  page: 1,
                }))
              }
            />
          </Box>
          <DataTable
            isLoading={loading}
            columns={columns}
            data={products?.items}
            sorting={listOptions}
            paging={listOptions}
            totalItems={products?.totalItems ?? 0}
            onSortingChange={(sorting) => {
              setListOptions((o) => ({ ...o, ...sorting }));
              setSavedSorting(sorting);
            }}
            onPagingChange={(paging) => {
              setListOptions((o) => ({ ...o, ...paging }));
              setGlobalPageSize(paging.pageSize);
            }}
          />
        </Card>
      )}

      {createProductModalVisible && (
        <CreateProductModal
          onClose={() => setCreateProductModalVisible(false)}
          onSuccess={(product) => goToProduct(product.id)}
        />
      )}
    </Page>
  );
}

function useBreadcrumbs() {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("assortment.assortment"),
        to: routes.assortment,
      },
      {
        label: t("product.products"),
      },
    ],
    [t],
  );
}
