import { Box, BoxProps, Container, Flex } from "@chakra-ui/react";
import { Breadcrumb, Breadcrumbs } from "../shared/Breadcrumbs";
import React from "react";

export interface PageProps extends BoxProps {
  breadcrumbs: Breadcrumb[];
}

export function Page({ breadcrumbs, children, ...props }: PageProps) {
  return (
    <Box {...props}>
      <Flex
        h={12}
        bg="secondary.100"
        shadow="sm"
        justifyContent="space-between"
        alignItems="center"
        px={6}
      >
        <Breadcrumbs crumbs={breadcrumbs} />
      </Flex>
      <Container maxW="7xl" p={4}>
        {children}
      </Container>
    </Box>
  );
}
