import React from "react";
import { useEffect } from "react";
import {
  ProductGroupListItem,
  productGroupsApi,
} from "../../../api/productGroupsApi";
import { useApiRequest } from "../../../hooks/useApi/useApiRequest";
import { SharedSelectProps } from "../Select/types";
import { AsyncSelect } from "./AsyncSelect";

interface ProductGroupAsyncSelectProps extends SharedSelectProps {
  value: string | null;
  onChange?: (value: string | null) => void;
  onSelect?: (productGroup: ProductGroupListItem | null) => void;
}

export const ProductGroupAsyncSelect = React.forwardRef(
  (
    {
      value,
      onChange,
      onSelect,
      ...sharedSelectProps
    }: ProductGroupAsyncSelectProps,
    ref,
  ) => {
    const [
      productGroups,
      productGroupsLoading,
      productGroupsError,
      fetchProductGroups,
    ] = useApiRequest(productGroupsApi.listProductGroups);

    const [
      productGroup,
      productGroupLoading,
      productGroupError,
      fetchProductGroup,
      setProductGroup,
    ] = useApiRequest<[string], ProductGroupListItem>(
      productGroupsApi.getProductGroup,
    );

    useEffect(() => {
      if (value === productGroup?.id) {
        return;
      }

      if (!value) {
        setProductGroup(null);
      } else {
        fetchProductGroup(value);
      }
    }, [fetchProductGroup, productGroup, value, setProductGroup]);

    function handleChange(item: ProductGroupListItem | null) {
      onChange?.(item?.id ?? null);
      onSelect?.(item);
      setProductGroup(item);
    }

    return (
      <AsyncSelect
        innerRef={ref}
        value={productGroup}
        valueLoading={productGroupLoading}
        valueError={productGroupError}
        options={productGroups}
        optionsLoading={productGroupsLoading}
        optionsError={productGroupsError}
        getOptionValue={(i) => i.id}
        getOptionLabel={(i) => i.name}
        onChange={handleChange}
        onSearchOptions={(params) =>
          fetchProductGroups({
            sortingDesc: false,
            sortingKey: "name",
            ...params,
          })
        }
        {...sharedSelectProps}
      />
    );
  },
);
