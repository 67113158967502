import React, { useState } from "react";
import { useEffect } from "react";
import { CustomerListItem, customersApi } from "../../../api/customersApi";
import { useApiRequest } from "../../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../../hooks/useApi/useApiRequestCallback";
import { ServerError } from "../../../types";
import { SharedSelectProps } from "../Select/types";
import { AsyncSelect } from "./AsyncSelect";

interface CustomerAsyncSelectProps extends SharedSelectProps {
  value: string | null;
  onChange?: (value: string | null) => void;
  onSelect?: (customer: CustomerListItem | null) => void;
}

export const CustomerAsyncSelect = React.forwardRef(
  (
    {
      value,
      onChange,
      onSelect,
      ...sharedSelectProps
    }: CustomerAsyncSelectProps,
    ref,
  ) => {
    const [customers, customersLoading, customersLoadingError, fetchCustomers] =
      useApiRequest(customersApi.listCustomers);

    const [customer, setCustomer] = useState<CustomerListItem | null>(null);
    const [error, setError] = useState<ServerError | null>(null);
    const [isUpdating, getCustomerDetails] = useApiRequestCallback(
      customersApi.getCustomerDetails,
    );

    useEffect(() => {
      if (value === customer?.id) {
        return;
      }

      if (!value) {
        setCustomer(null);
      } else {
        getCustomerDetails({
          onSuccess: (customer) => {
            setCustomer(customer);
          },
          onError: setError,
        }).send(value);
      }
    }, [customer?.id, getCustomerDetails, value]);

    function handleChange(item: CustomerListItem | null) {
      onChange?.(item?.id ?? null);
      onSelect?.(item);
      setCustomer(item);
    }

    return (
      <AsyncSelect
        innerRef={ref}
        value={customer}
        valueLoading={isUpdating}
        valueError={error}
        options={customers}
        optionsLoading={customersLoading}
        optionsError={customersLoadingError}
        getOptionValue={(i) => i.id}
        getOptionLabel={(i) => i.customerNumber + " - " + i.name}
        onChange={handleChange}
        onSearchOptions={(params) =>
          fetchCustomers({
            sortingDesc: false,
            sortingKey: "name",
            ignoreBobutikenFlags: true,
            ...params,
          })
        }
        {...sharedSelectProps}
      />
    );
  },
);
