import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Stack,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ProjectDetails,
  ProjectDetailsImageType,
  projectsApi,
  ProjectState,
  ProjectStatus,
} from "../../api/projectsApi";
import { urlSafeRegExp } from "../../constants";
import { slugify } from "../../helpers";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { ServerError } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Card } from "../shared/Card";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ActivationStatusErrorMessages } from "./ActivationStatusErrorMessages";
import { ProjectLogoImageForm } from "./ProjectLogoImageForm";
import { LicenseType } from "../../api/customersApi";

interface FormData {
  name: string;
  startDate: Date;
  endDate: Date;
  urlSlug: string;
  status: ProjectStatus;
  state: ProjectState;
  email: string | null;
  onlyVisualization: boolean;
  hideLogo: boolean;
}

export function ProjectDetailsView({
  project,
  onUpdate,
}: {
  project: ProjectDetails;
  onUpdate: (details: ProjectDetails) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [id] = useState(project.id);
  const [error, setError] = useState<ServerError | null>(null);
  const [isUpdating, request] = useApiRequestCallback(
    projectsApi.updateProjectDetails,
  );

  function updateDetails(data: FormData) {
    request({
      onSuccess: (updatedProject) => {
        setError(null);
        reset({
          ...updatedProject,
          startDate: updatedProject.startDate
            ? new Date(updatedProject.startDate)
            : undefined,
          endDate: updatedProject.endDate
            ? new Date(updatedProject.endDate)
            : undefined,
        });
        onUpdate(updatedProject);
        toast({
          title: t("general.saved"),
          status: "success",
        });
      },
      onError: setError,
    }).send(id, {
      name: data.name,
      startDate: data.startDate,
      endDate: data.endDate,
      urlSlug: data.urlSlug,
      state: data.state,
      email: data.email,
      onlyVisualization: data.onlyVisualization,
      hideLogo: data.hideLogo,
    });
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    reset,
    control,
  } = useForm<FormData>({
    defaultValues: {
      ...project,
      startDate: project.startDate ? new Date(project.startDate) : undefined,
      endDate: project.endDate ? new Date(project.endDate) : undefined,
      onlyVisualization: project.onlyVisualization,
    },
  });

  return (
    <Stack spacing={4} shouldWrapChildren={true}>
      <Card>
        <form onSubmit={handleSubmit(updateDetails)} autoComplete="off">
          {isUpdating && <LoadingIndicator />}
          {error && <ErrorDetails error={error} />}
          <SimpleGrid pt={2} columns={2} spacing={4}>
            <FormControl id="name" isRequired={true} isInvalid={!!errors.name}>
              <FormLabel>{t("general.name")}</FormLabel>
              <Controller
                name="name"
                render={({ field }) => (
                  <Input
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue("urlSlug", slugify(e.target.value), {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                  />
                )}
                control={control}
                rules={{
                  required: { value: true, message: t("error.required") },
                }}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id="urlSlug"
              isRequired={true}
              isInvalid={!!errors.urlSlug}
            >
              <FormLabel>{t("project.slug")}</FormLabel>
              <Input
                {...register("urlSlug", {
                  required: { value: true, message: t("error.required") },
                  pattern: {
                    value: urlSafeRegExp,
                    message: t("error.slugPattern"),
                  },
                })}
              />
              <FormErrorMessage>{errors.urlSlug?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id="startDate">
              <FormLabel>{t("project.startDate")}</FormLabel>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <Input
                    type="date"
                    defaultValue={project.startDate?.slice(0, 10)}
                    onChange={(date) => {
                      field.onChange(new Date(date.target.value), "yyyy-MM-dd");
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl id="endDate">
              <FormLabel>{t("project.endDate")}</FormLabel>
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <Input
                    type="date"
                    defaultValue={project.endDate?.slice(0, 10)}
                    onChange={(date) => {
                      field.onChange(new Date(date.target.value), "yyyy-MM-dd");
                    }}
                  />
                )}
              />
            </FormControl>
            <Box display="flex" flexDirection="column" gap="10px">
              <FormControl id="state">
                <FormLabel>{t("general.project")}</FormLabel>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <ProjectStateCheckbox
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </FormControl>
              <FormControl id="onlyVisualization">
                <Controller
                  name="onlyVisualization"
                  control={control}
                  render={({ field }) => (
                    <Checkbox isChecked={field.value} onChange={field.onChange}>
                      {t("project.onlyVisualization")},
                    </Checkbox>
                  )}
                />
              </FormControl>
              <FormControl id="hideLogo">
                <Controller
                  name="hideLogo"
                  control={control}
                  render={({ field }) => (
                    <Checkbox isChecked={field.value} onChange={field.onChange}>
                      {t("project.hideLogo")}
                    </Checkbox>
                  )}
                />
              </FormControl>
              <ActivationStatusErrorMessages
                calculatedStatus={project.calculatedStatus}
              />
            </Box>
            <FormControl
              id="email"
              isRequired={
                project.customerLicenseType === LicenseType.NoLicense
                  ? false
                  : true
              }
              isInvalid={!!errors.email}
            >
              <FormLabel>{t("project.emailForOrders")}</FormLabel>
              <Input {...register("email")} />
            </FormControl>
          </SimpleGrid>
          <Flex pt={4} justifyContent="flex-end">
            <Button type="submit" disabled={!isDirty}>
              {t("general.save")}
            </Button>
          </Flex>
        </form>
      </Card>
      <SimpleGrid columns={2} spacing={10}>
        <Card titleContent={t("project.logo")}>
          <ProjectLogoImageForm
            image={project.logoImage}
            projectId={project.id}
            projectDetailsImageType={ProjectDetailsImageType.Logo}
            onUpdate={(image) =>
              onUpdate({
                ...project,
                logoImage: image,
              })
            }
          />
        </Card>
      </SimpleGrid>
    </Stack>
  );
}

function ProjectStateCheckbox({
  value,
  onChange,
}: {
  value: ProjectState;
  onChange: (value: ProjectState) => void;
}) {
  const { t } = useTranslation();

  return (
    <Stack>
      <Checkbox
        isChecked={value === ProjectState.Closed}
        onChange={() =>
          onChange(
            value === ProjectState.Open
              ? ProjectState.Closed
              : ProjectState.Open,
          )
        }
      >
        {t("project.CloseProject")}
      </Checkbox>
    </Stack>
  );
}
