import { createContext, ReactNode, useContext, useState } from "react";
import { baseApiUrl } from "../../constants";
import { createHttpClient, HttpClient } from "./httpClient";

const HttpClientContext = createContext<HttpClient>(createHttpClient());

export function useHttpClient() {
  return useContext(HttpClientContext);
}

export function HttpClientProvider({ children }: { children: ReactNode }) {
  const [httpClient] = useState<HttpClient>(() => createHttpClient(baseApiUrl));

  return (
    <HttpClientContext.Provider value={httpClient}>
      {children}
    </HttpClientContext.Provider>
  );
}
