import { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";

export enum ScreenOrientation {
  Portrait = "Portrait",
  Landscape = "Landscape",
}

export function useScreenOrientation(delay = 500) {
  const [orientation, setOrientation] = useState(getOrientation());

  const debouncedHandleResize = useDebouncedCallback(() => {
    setOrientation(getOrientation());
  }, delay);

  useEffect(() => {
    window.addEventListener("orientationchange", debouncedHandleResize);
    return () => {
      window.removeEventListener("orientationchange", debouncedHandleResize);
    };
  }, [debouncedHandleResize]);

  return orientation;
}

function getOrientation(): ScreenOrientation {
  const windowOrientation = window.screen?.orientation?.type;
  if (
    windowOrientation != null &&
    (windowOrientation === "portrait-primary" ||
      windowOrientation === "portrait-secondary")
  ) {
    return ScreenOrientation.Portrait;
  }

  if (
    windowOrientation != null &&
    (windowOrientation === "landscape-primary" ||
      windowOrientation === "landscape-secondary")
  ) {
    return ScreenOrientation.Landscape;
  }

  if (window.matchMedia("(orientation: portrait)").matches) {
    return ScreenOrientation.Portrait;
  }

  return ScreenOrientation.Landscape;
}
