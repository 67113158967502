import { useCallback, useEffect, useState } from "react";
import { Configuration } from "../../types";
import { createContextValue } from "../contextState";
import { useHttpClient } from "../useHttpClient";
import { ConfigurationState, ConfigurationStatus } from "./types";

export function useConfigurationLoader() {
  const httpClient = useHttpClient();
  const [configurationState, setConfigurationState] =
    useState<ConfigurationState>({
      status: ConfigurationStatus.Fetching,
    });

  const getConfiguration = useCallback(async () => {
    setConfigurationState({ status: ConfigurationStatus.Fetching });

    const response = await httpClient.get<Configuration>(
      "v1/configurations/client",
    );

    if (!response.hasError) {
      setConfigurationState({
        status: ConfigurationStatus.Loaded,
        configuration: response.data,
      });
    } else {
      setConfigurationState({
        status: ConfigurationStatus.Error,
        error: response.error,
      });
    }
  }, [httpClient]);

  useEffect(() => {
    getConfiguration();
  }, [getConfiguration]);

  return { configurationState };
}

export const [ConfigurationProvider, useConfiguration] =
  createContextValue<Configuration>();
