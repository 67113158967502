import { Box, Grid, Flex, Button, Image, AspectRatio } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ExternalLandingPageDetails } from "../../../api/externalApi";
import { RichTextContent } from "../../shared/RichText/RichTextContent";
import { Link } from "react-router-dom";
import { LandingPageLayout } from "../../../types";
import { Center } from "@chakra-ui/layout";

export function LandingPageLayoutDesign({
  landingPage,
  buttonImage,
}: {
  landingPage: ExternalLandingPageDetails;
  buttonImage?: string;
}) {
  const { t } = useTranslation();

  return (
    <Flex justifyContent="center">
      <Grid
        textAlign="center"
        width="100vw"
        gridGap="20px"
        gridTemplateAreas={{
          base: `'bannerText bannerText' 'actionButton actionButton' 'banner banner' 'image1 image1' 'content1 content1' 'image2 image2' 'content2 content2'`,
          md:
            landingPage.layout === LandingPageLayout.Layout1
              ? `'bannerText bannerText' 'actionButton actionButton' 'banner banner' 'image1 content1' 'content2 image2'`
              : landingPage.layout === LandingPageLayout.Layout2
              ? `'bannerText bannerText' 'actionButton actionButton' 'banner banner' 'image1 content1' 'image2 content2'`
              : `'bannerText bannerText' 'actionButton actionButton' 'banner banner' 'image1 image2' 'content1 content2'`,
        }}
        gridTemplateColumns="1fr 1fr"
        maxWidth="4xl"
        ml="30px"
        mr="30px"
        mt="50px"
      >
        {landingPage.bannerContent.length > 0 && (
          <Box gridArea="bannerText" textAlign="center">
            <RichTextContent content={landingPage.bannerContent} />
          </Box>
        )}
        <Flex gridArea="actionButton" justifyContent="center">
          <Button
            as={Link}
            to="order"
            variant={buttonImage ? "unstyled" : "solid"}
            size="lg"
          >
            {buttonImage ? (
              <Image height="100%" src={buttonImage} />
            ) : (
              t("external.startOrder")
            )}
          </Button>
        </Flex>
        {landingPage.bannerImage && (
          <Center gridArea="banner">
            <AspectRatio ratio={9 / 1} minWidth="100%">
              <Image
                src={landingPage.bannerImage}
                style={{ objectFit: "contain" }}
              />
            </AspectRatio>
          </Center>
        )}
        {landingPage.image1 && (
          <Center gridArea="image1">
            <AspectRatio ratio={5 / 4} minWidth="100%">
              <Image
                src={landingPage.image1}
                style={{ objectFit: "contain" }}
              />
            </AspectRatio>
          </Center>
        )}
        <Box gridArea="content1" textAlign="left">
          <RichTextContent content={landingPage.content1} />
        </Box>
        {landingPage.image2 && (
          <Center gridArea="image2">
            <AspectRatio ratio={5 / 4} minWidth="100%">
              <Image
                src={landingPage.image2}
                style={{ objectFit: "contain" }}
              />
            </AspectRatio>
          </Center>
        )}
        <Box gridArea="content2" textAlign="left">
          <RichTextContent content={landingPage.content2} />
        </Box>
      </Grid>
    </Flex>
  );
}
