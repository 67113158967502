import { LoadingIndicator } from "../shared/LoadingIndicator";
import { ErrorDetails } from "../shared/ErrorDetails";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Column, DataTable } from "../shared/DataTable/DataTable";
import { PagingOptions, SortingOptions } from "../../types";
import { routes } from "../../routes";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useGlobalPageSize } from "../../hooks/usePageSize";
import { useSavedSorting } from "../../hooks/useSavedSorting";
import { useTranslation } from "react-i18next";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { regionApi, RegionListItem } from "../../api/regionApi";
import { Button } from "@chakra-ui/button";
import { Page } from "../shared/Page";
import { Card } from "../shared/Card";
import { RegionModal } from "./RegionModal";

interface ListOptions extends PagingOptions, SortingOptions<RegionListItem> {}
type ModalState = { open: false } | { open: true; regionId: string | null };

export function RegionListPage() {
  const { t } = useTranslation();
  const [modalOpenState, setModalOpenState] = useState<ModalState>({
    open: false,
  });
  const { savedSorting, setSavedSorting } = useSavedSorting<RegionListItem>(
    "RegionListPage",
    { sortingDesc: false, sortingKey: "pyramidId" },
  );
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const [listOptions, setListOptions] = useState<ListOptions>({
    page: 1,
    pageSize: globalPageSize,
    ...savedSorting,
  });

  const [regions, isLoading, regionsError, fetch] = useApiRequest(
    regionApi.listRegions,
  );

  const fetchRegions = useCallback(() => {
    fetch({ ...listOptions });
  }, [fetch, listOptions]);

  useEffect(() => {
    fetchRegions();
  }, [fetchRegions]);

  const columns = useMemo<Column<RegionListItem>[]>(
    () => [
      {
        key: "pyramidId",
        header: t("region.pyramidId"),
        sortable: true,
      },
      {
        key: "name",
        header: t("general.name"),
        sortable: true,
      },
      {
        key: "actions",
        width: 150,
        cell: ({ row }) => (
          <Button
            size="sm"
            onClick={() => {
              setModalOpenState({
                open: true,
                regionId: row.id,
              });
            }}
          >
            {t("general.edit")}
          </Button>
        ),
      },
    ],
    [t],
  );

  function handleRegionSuccess() {
    setModalOpenState({ open: false });
    fetchRegions();
  }

  return (
    <Page breadcrumbs={useBreadcrumbs()}>
      <Card
        titleContent={t("region.regions")}
        extraContent={
          <Button
            size="sm"
            float="right"
            onClick={() => setModalOpenState({ open: true, regionId: null })}
          >
            {t("general.add")}
          </Button>
        }
      >
        {isLoading && <LoadingIndicator />}
        {regionsError && <ErrorDetails error={regionsError} />}

        {modalOpenState.open && (
          <RegionModal
            onSuccess={() => handleRegionSuccess()}
            onClose={() => setModalOpenState({ open: false })}
            regionId={modalOpenState.regionId}
          />
        )}
        <DataTable
          isLoading={isLoading}
          columns={columns}
          data={regions?.items}
          sorting={listOptions}
          paging={listOptions}
          totalItems={regions?.totalItems ?? 0}
          onSortingChange={(sorting) => {
            setListOptions((o) => ({ ...o, ...sorting }));
            setSavedSorting(sorting);
          }}
          onPagingChange={(paging) => {
            setListOptions((o) => ({ ...o, ...paging }));
            setGlobalPageSize(paging.pageSize);
          }}
        />
      </Card>
    </Page>
  );
}

function useBreadcrumbs() {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("general.settings"),
        to: routes.settings,
      },
      {
        label: t("region.regions"),
      },
    ],
    [t],
  );
}
