const theme = {
  fonts: {
    body: "Dosis",
    heading: "Dosis",
  },
  colors: {
    primary: {
      50: "#F9FBF9",
      100: "#f4e8e8",
      200: "#c78c8a",
      300: "#a54744",
      400: "#9a302c",
      500: "#8f1915",
      600: "#811713",
      700: "#721411",
      800: "#64120f",
      900: "#560f0d",
    },
    secondary: {
      50: "#F9F8F9",
      100: "#EFECF0",
      200: "#DCD5DE",
      300: "#C9BFCC",
      400: "#B6A8BA",
      500: "#A391A8",
      600: "#8B7591",
      700: "#705D76",
      800: "#554759",
      900: "#3A303D",
    },
  },
  components: {
    Button: {
      baseStyle: {
        transition: "none",
        borderRadius: "0px",
        fontWeight: "light",
      },
    },
    Heading: {
      fontWeight: "light",
    },
    Checkbox: {
      baseStyle: { control: { borderColor: "gray.400" } },
    },
    Table: {
      baseStyle: {
        table: {
          tbody: {
            tr: {
              _hover: {
                background: "blackAlpha.50",
              },
            },
          },
        },
      },
      variants: {
        noRowHighlight: {
          table: {
            borderCollapse: "separate",
            borderSpacing: "0 0.5rem",
            tbody: {
              tr: {
                _hover: {
                  background: "blackAlpha.0",
                },
              },
            },
          },
        },
      },
      sizes: {
        md: {
          th: {
            px: "3",
            py: "2",
            lineHeight: "4",
            fontSize: "xs",
          },
          td: {
            px: "4",
            py: "3",
            lineHeight: "5",
          },
          caption: {
            px: "4",
            py: "3",
            fontSize: "sm",
          },
        },
      },
    },
  },
};

export default theme;
