import { Box, Button, Link } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useCookie } from "./useCookie";

export function CookieConsent() {
  const { t } = useTranslation();
  const [cookie, updateCookie] = useCookie("has_cookie_consent", "false");

  if (cookie === "true") {
    return null;
  }

  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      zIndex="100"
      p={4}
      display="flex"
      alignItems="center"
      justifyContent="center"
      fontSize={14}
      bg="black"
      color="white"
    >
      {t("cookieConsent.description")}
      <Link
        href={t("cookieConsent.link")}
        isExternal={true}
        marginLeft={2}
        marginRight={5}
        textDecoration="underline"
      >
        {t("cookieConsent.learnMore")}
      </Link>
      <Button onClick={() => updateCookie("true", 365)} size="sm">
        {t("cookieConsent.iUnderstand")}
      </Button>
    </Box>
  );
}
