import { useCallback, useEffect, useRef, useState } from "react";
import { ServerError } from "../../types";
import { useHttpClient } from "../useHttpClient";
import { HttpClientResponse } from "../useHttpClient/types";
import { ApiRequest } from "./types";

export const useApiRequestCallback = <A extends any[], R>(
  apiRequestFactory: (...args: A) => ApiRequest<R>,
) => {
  const httpClient = useHttpClient();
  const isMountedRef = useRef(true);
  const latestRequestSymbol = useRef<symbol>();
  const [loading, setLoading] = useState(false);

  const sendRequest = useCallback(
    async (
      args: any,
      onSuccess?: (result: R) => void,
      onError?: (error: ServerError) => void,
      onResponse?: (response: HttpClientResponse<R>) => void,
    ) => {
      const currentRequestSymbol = Symbol();
      latestRequestSymbol.current = currentRequestSymbol;

      setLoading(true);

      const apiRequest = apiRequestFactory(...args);
      const response = await apiRequest(httpClient);

      if (!isMountedRef.current) {
        return;
      }

      if (onResponse) {
        onResponse(response);
      }

      if (response.hasError) {
        if (onError) {
          onError(response.error);
        }
      } else if (onSuccess) {
        onSuccess(response.data);
      }

      if (
        currentRequestSymbol === latestRequestSymbol.current &&
        isMountedRef.current
      ) {
        setLoading(false);
      }
    },
    [apiRequestFactory, httpClient],
  );

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const createRequest = useCallback(
    ({
      onSuccess,
      onError,
      onResponse,
    }: {
      onSuccess?: (result: R) => void;
      onError?: (error: ServerError) => void;
      onResponse?: (response: HttpClientResponse<R>) => void;
    }) => ({
      send: (...args: A) => {
        sendRequest(args, onSuccess, onError, onResponse);
      },
    }),
    [sendRequest],
  );

  return [loading, createRequest] as const;
};
