import { useState, useCallback, useEffect } from "react";
import { StepPerformStatus, workPlansApi } from "../../../api/workPlansApi";
import { useApiRequest } from "../../../hooks/useApi/useApiRequest";
import { useGlobalPageSize } from "../../../hooks/usePageSize";
import { useSavedSorting } from "../../../hooks/useSavedSorting";
import { PagingOptions, SortingOptions } from "../../../types";
import { useMemo } from "react";

interface ListOptions extends PagingOptions, SortingOptions<ListItem> {
  searchTerm: string;
}

interface ListItem {
  id: string;
  workStepId?: string;
  customWorkPlanStepId?: string;
  name: string;
  productGroupName: string;
  performStatus: StepPerformStatus;
  note: string;
}

export function useWorkSteps(workPlanId: string) {
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();

  const { savedSorting, setSavedSorting } = useSavedSorting<ListItem>(
    "EditWorkPlanView",
    { sortingDesc: false, sortingKey: "productGroupName" },
  );

  const [listOptions, setListOptions] = useState<ListOptions>({
    ...savedSorting,
    page: 1,
    pageSize: globalPageSize,
    searchTerm: "",
  });

  const [workPlanSteps, isLoading, error, fetchItems] = useApiRequest(
    workPlansApi.getWorkPlanSteps,
  );

  const fetchWorkPlanSteps = useCallback(
    () => fetchItems(workPlanId, listOptions),
    [fetchItems, workPlanId, listOptions],
  );

  useEffect(() => {
    fetchWorkPlanSteps();
  }, [fetchWorkPlanSteps]);

  return useMemo(
    () => ({
      workPlanSteps,
      isLoading,
      error,
      fetchWorkPlanSteps,
      savedSorting,
      setSavedSorting,
      globalPageSize,
      setGlobalPageSize,
      listOptions,
      setListOptions,
    }),
    [
      error,
      fetchWorkPlanSteps,
      globalPageSize,
      isLoading,
      listOptions,
      savedSorting,
      setGlobalPageSize,
      setSavedSorting,
      workPlanSteps,
    ],
  );
}

export type WorkSteps = ReturnType<typeof useWorkSteps>;
