import { useConfiguration } from "./useConfiguration";
import { HostEnvironment } from "../types";

export function useBobutikenBaseUrl() {
  const { hostEnvironment } = useConfiguration();

  switch (hostEnvironment) {
    case HostEnvironment.Development:
      return "localhost:3000";
    case HostEnvironment.Staging:
      return "https://staging-bobutiken.modexa.se";
    case HostEnvironment.Production:
      return "https://bobutiken.modexa.se";
  }
}
