import React, { useState } from "react";
import { useEffect } from "react";
import { CustomerDetails } from "../../../api/customersApi";
import {
  productPackageApi,
  ProductPackageDetails,
  ProductPackageListItem,
} from "../../../api/productPackageApi";
import { useApiRequest } from "../../../hooks/useApi/useApiRequest";
import { PagedItems } from "../../../types";
import { SharedSelectProps } from "../Select/types";
import { AsyncSelect } from "./AsyncSelect";

interface PackageAsyncSelectProps extends SharedSelectProps {
  customer: CustomerDetails;
  value: string | null;
  onChange?: (value: string | null) => void;
  onSelect?: (packageItem: ProductPackageListItem | null) => void;
}

export const PackageAsyncSelect = React.forwardRef(
  (
    {
      customer,
      value,
      onChange,
      onSelect,
      ...sharedSelectProps
    }: PackageAsyncSelectProps,
    ref,
  ) => {
    const [packages, packagesLoading, packagesLoadingError, fetchPackages] =
      useApiRequest(productPackageApi.listProductPackages);
    const [customerPackages, setCustomerPackages] =
      useState<PagedItems<ProductPackageListItem> | null>(null);
    const [selectedPackage, setSelectedPackage] =
      useState<ProductPackageDetails | null>(null);

    const [
      packageDetails,
      packageDetailsLoading,
      packageDetailsLoadingError,
      fetchPackageDetails,
      setPackageDetails,
    ] = useApiRequest(productPackageApi.getProductPackageDetails);

    useEffect(() => {
      if (value === packageDetails?.id) {
        return;
      }

      if (!value) {
        setPackageDetails(null);
      } else {
        fetchPackageDetails(value);
      }
    }, [fetchPackageDetails, packageDetails, value, setPackageDetails]);

    useEffect(() => {
      setSelectedPackage(
        packageDetails
          ? {
              ...packageDetails,
              name: `${customer.name} - ${packageDetails.name}`,
            }
          : null,
      );
    }, [customer.name, packageDetails, setPackageDetails]);

    function handleChange(item: ProductPackageListItem | null) {
      onChange?.(item?.id ?? null);
      onSelect?.(item);
      setPackageDetails(
        item
          ? {
              ...item,
              image: null,
              name: item.name.replace(`${customer.name} - `, ""),
              productPackageStyles: [],
              otherOptionsImage: null,
            }
          : null,
      );
    }

    useEffect(() => {
      setCustomerPackages(
        packages
          ? {
              items: packages.items.map((i) => ({
                ...i,
                name: `${customer.name} - ${i.name}`,
              })),
              totalItems: packages.totalItems,
            }
          : null,
      );
    }, [customer.name, packages, setCustomerPackages]);

    return (
      <AsyncSelect
        innerRef={ref}
        value={selectedPackage}
        valueLoading={packageDetailsLoading}
        valueError={packageDetailsLoadingError}
        options={customerPackages}
        optionsLoading={packagesLoading}
        optionsError={packagesLoadingError}
        getOptionValue={(i) => i.id}
        getOptionLabel={(i) => i.name}
        onChange={handleChange}
        onSearchOptions={(params) =>
          fetchPackages({
            customerId: customer.id,
            sortingDesc: false,
            sortingKey: "name",
            ...params,
          })
        }
        {...sharedSelectProps}
      />
    );
  },
);
