import { Button } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { CustomerDetails } from "../../api/customersApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { PagingOptions, SortingOptions } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Card } from "../shared/Card";
import { DataTable, Column } from "../shared/DataTable/DataTable";
import { Page } from "../shared/Page";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { routes } from "../../routes";
import { useGlobalPageSize } from "../../hooks/usePageSize";
import { useSavedSorting } from "../../hooks/useSavedSorting";
import { CreateStyleModal } from "./CreateStyleModal";
import { stylesApi, StyleDetails, StyleListItem } from "../../api/stylesApi";

export function StyleList({
  customer,
  onUpdate,
}: {
  customer: CustomerDetails;
  onUpdate: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const { savedSorting, setSavedSorting } = useSavedSorting<StyleListItem>(
    "StyleList",
    {
      sortingDesc: false,
      sortingKey: "name",
    },
  );
  const [paging, setPaging] = useState<PagingOptions>({
    page: 1,
    pageSize: globalPageSize,
  });
  const [sorting, setSorting] = useState<SortingOptions<StyleListItem>>({
    ...savedSorting,
  });
  const [packages, isLoading, error, fetch] = useApiRequest(
    stylesApi.listStyles,
  );

  useEffect(() => {
    fetch({
      ...paging,
      ...sorting,
      customerId: customer.id,
    });
  }, [customer.id, fetch, paging, sorting]);

  function onStyleCreated(style: StyleDetails) {
    navigate(routes.style(customer.id, style.id));
    onUpdate();
  }

  const columns: Column<StyleListItem>[] = [
    {
      key: "name",
      sortable: true,
      header: t("general.name"),
      cell: ({ row }) => (
        <>
          {customer.name} - {row.name}
        </>
      ),
    },
    {
      key: "actions",
      align: "right",
      cell: ({ row }) => (
        <Button
          as={RouterLink}
          to={routes.style(customer.id, row.id)}
          size="sm"
        >
          {t("general.edit")}
        </Button>
      ),
    },
  ];

  return (
    <Page breadcrumbs={useBreadcrumbs(customer.id, customer.name)}>
      {createModalVisible && (
        <CreateStyleModal
          customerId={customer.id}
          onClose={() => setCreateModalVisible(false)}
          onSuccess={onStyleCreated}
        />
      )}
      <Card
        titleContent={t("style.styles")}
        extraContent={
          <Button
            onClick={() => {
              setCreateModalVisible(true);
            }}
            size="sm"
          >
            {t("general.add")}
          </Button>
        }
      >
        {error && <ErrorDetails error={error} />}
        <DataTable
          columns={columns}
          isLoading={isLoading}
          onSortingChange={(sorting) => {
            setSorting(sorting);
            setSavedSorting(sorting);
          }}
          onPagingChange={(paging) => {
            setPaging((o) => ({ ...o, ...paging }));
            setGlobalPageSize(paging.pageSize);
          }}
          paging={paging}
          sorting={sorting}
          totalItems={packages?.totalItems ?? 0}
          data={packages?.items}
        />
      </Card>
    </Page>
  );
}

function useBreadcrumbs(customerId: string, customerName: string) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("customer.customers"),
        to: routes.customers,
      },
      {
        label: customerName,
        to: routes.customer(customerId),
      },
      {
        label: t("style.styles"),
      },
    ],
    [customerName, customerId, t],
  );
}
