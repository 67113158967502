import { getEnumValues } from "../helpers";
import { ApiRequest } from "../hooks/useApi/types";
import { PagedItems, PagingOptions, SortingOptions } from "../types";

export const productGroupsApi = {
  listProductGroups:
    (
      request: ListProductGroupRequest,
    ): ApiRequest<PagedItems<ProductGroupListItem>> =>
    (httpClient) =>
      httpClient.get("v1/product-groups", { params: request }),

  getProductGroup:
    (id: string): ApiRequest<ProductGroupDetails> =>
    (httpClient) =>
      httpClient.get(`v1/product-groups/${id}`),

  createProductGroup:
    (request: CreateProductGroupRequest): ApiRequest<ProductGroupDetails> =>
    (httpClient) =>
      httpClient.post("v1/product-groups", { data: request }),

  updateProductGroup:
    (
      id: string,
      request: UpdateProductGroupRequest,
    ): ApiRequest<ProductGroupDetails> =>
    (httpClient) =>
      httpClient.put(`v1/product-groups/${id}`, { data: request }),

  deleteProductGroup:
    (id: string, request: DeleteProductGroupRequest): ApiRequest =>
    (httpClient) =>
      httpClient.delete(`/v1/product-groups/${id}`, { params: request }),

  listRenderLayers: (): ApiRequest<RenderLayer[]> => (httpClient) =>
    httpClient.get(`v1/product-groups/render-layers`),
};

interface ListProductGroupRequest
  extends SortingOptions<ProductGroupListItem>,
    PagingOptions {
  searchTerm?: string;
}

export interface ProductGroupListItem {
  id: string;
  name: string;
  renderLayerId?: string;
  sortOrder: number;
}

export interface ProductGroupDetails {
  id: string;
  name: string;
  renderLayer: RenderLayer | null;
  sortOrder: number;
  printLayout: ProductGroupPrintLayout;
}

export interface CreateProductGroupRequest {
  name: string;
  renderLayer: RenderLayer | null;
  sortOrder: number;
}

export interface UpdateProductGroupRequest {
  name: string;
  renderLayer: RenderLayer | null;
  sortOrder: number;
  printLayout: ProductGroupPrintLayout;
}

export interface DeleteProductGroupRequest {
  force: boolean;
}

export interface RenderLayer {
  id: string;
  name: string;
}

export enum ProductGroupPrintLayout {
  Standard = "Standard",
  Compact = "Compact",
}

export const productGroupPrintLayouts = getEnumValues(ProductGroupPrintLayout);
