import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Icons } from "../shared/Icons";

export interface Breadcrumb {
  label: string | undefined;
  to?: string;
}

export function Breadcrumbs({ crumbs }: { crumbs: Breadcrumb[] }) {
  return (
    <ChakraBreadcrumb
      spacing={3}
      separator={<Icons.ArrowRight color="gray.500" />}
    >
      {crumbs.map((crumb, i) => {
        const isLast = i === crumbs.length - 1;
        return (
          <BreadcrumbItem key={i} isLastChild={true} isCurrentPage={isLast}>
            <BreadcrumbLink
              as={RouterLink}
              fontSize="sm"
              to={crumb.to ?? ""}
              fontWeight={isLast ? "bold" : "normal"}
            >
              {crumb.label ?? "-"}
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </ChakraBreadcrumb>
  );
}
