import { ApiRequest } from "../hooks/useApi/types";

export const customOrderFieldsApi = {
  createCustomOrderField:
    (request: CreateCustomOrderFieldRequest): ApiRequest =>
    (httpClient) =>
      httpClient.post("v1/custom-order-fields", { data: request }),

  listCustomOrderFields:
    (projectId: string): ApiRequest<CustomOrderFieldListItem[]> =>
    (httpClient) =>
      httpClient.get("v1/custom-order-fields", { params: { projectId } }),

  updateCustomOrderField:
    (id: string, request: UpdateCustomOrderFieldRequest): ApiRequest =>
    (httpClient) =>
      httpClient.put(`v1/custom-order-fields/${id}`, { data: request }),

  updateCustomOrderFieldSortOrder:
    (request: UpdateCustomOrderFieldSortOrderRequest): ApiRequest =>
    (httpClient) =>
      httpClient.put(`v1/custom-order-fields/sort-order`, {
        data: request,
      }),

  deleteCustomOrderField:
    (id: string): ApiRequest =>
    (httpClient) =>
      httpClient.delete(`v1/custom-order-fields/${id}`),
};

export interface UpdateCustomOrderFieldRequest {
  name: string;
  isRequired: boolean;
  fieldType: CustomOrderFieldType;
}

export interface CreateCustomOrderFieldRequest {
  projectId: string;
  name: string;
  isRequired: boolean;
  fieldType: CustomOrderFieldType;
}

export interface CustomOrderFieldListItem {
  id: string;
  name: string;
  isRequired: boolean;
  sortOrder: number;
  fieldType: CustomOrderFieldType;
}

export interface UpdateCustomOrderFieldSortOrderRequest {
  id: string;
  direction: Direction;
}

export enum Direction {
  Up = "Up",
  Down = "Down",
}

export enum CustomOrderFieldType {
  Text = "Text",
  Checkbox = "Checkbox",
}
