import { Box, Flex } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router";
import { routes } from "../../routes";
import { Sidebar, SidebarNavItem } from "../Sidebar";
import { RegionListPage } from "./RegionListPage";
import { RoleListPage } from "./RoleListPage";
import { UserDetailsView } from "./UserDetailsView";
import { UserListPage } from "./UserListPage";
import { RoleDetailsView } from "./RoleDetailsView";
import { ReportsListPage } from "./ReportsListPage";

export function Settings() {
  const { t } = useTranslation();
  return (
    <Flex flexWrap="nowrap" flexDirection="row" flex="1">
      <Sidebar heading={t("general.settings")}>
        <SidebarNavItem to={routes.users} label={t("user.users")} />
        <SidebarNavItem to={routes.roles} label={t("role.rights")} />
        <SidebarNavItem to={routes.regions} label={t("region.regions")} />
        <SidebarNavItem to={routes.reports} label={t("admin.reports")} />
      </Sidebar>
      <Box flexGrow={1}>
        <Routes>
          <Route path="users/:userId" element={<UserDetailsView />} />
          <Route path="roles/:roleId" element={<RoleDetailsView />} />
          <Route path="users" element={<UserListPage />} />
          <Route path="regions" element={<RegionListPage />} />
          <Route path="roles" element={<RoleListPage />} />
          <Route path="reports" element={<ReportsListPage />} />
          <Route
            path="*"
            element={<Navigate to={routes.users} replace={true} />}
          />
        </Routes>
      </Box>
    </Flex>
  );
}
