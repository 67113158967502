import { Topbar } from "./Topbar";
import { Navigate, Route, Routes } from "react-router";
import { Customers } from "./customers/Customers";
import { useSessionStore } from "../hooks/useSessionStore";
import { SessionStatus } from "../hooks/useSessionStore/types";
import { SignIn } from "./SignIn";
import { Assortment } from "./assortment";
import { Box } from "@chakra-ui/react";
import { Settings } from "./settings";
import { WorkStepsList } from "./workSteps/WorkStepsList";
import { routes } from "../routes";
import ProtectedRoute from "./ProtectedRoute";
import { Permission } from "../types";

export function Internal() {
  const { sessionState } = useSessionStore();

  if (sessionState.status === SessionStatus.Active) {
    return <InternalContent />;
  }

  return <SignIn />;
}

function InternalContent() {
  return (
    <Box
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      bg="primary.50"
    >
      <Topbar />
      <Routes>
        <Route path="/customers/*" element={<Customers />} />
        <Route path="/assortment/*" element={<Assortment />} />
        <Route path="/work-steps/*" element={<WorkStepsList />} />
        <Route
          element={<ProtectedRoute permission={Permission.ManageSettings} />}
        >
          <Route path="/settings/*" element={<Settings />} />
        </Route>
        <Route
          path="*"
          element={<Navigate to={routes.customers} replace={true} />}
        />
      </Routes>
    </Box>
  );
}
