import React from "react";
import { useTranslation } from "react-i18next";
import {
  productPackageApi,
  ProductPackageStyle,
} from "../../api/productPackageApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Select } from "../shared/Select/Select";
import { SharedSelectProps } from "../shared/Select/types";

interface ProductPackageStyleAsyncSelectProps extends SharedSelectProps {
  value: ProductPackageStyle | null;
  customerId: string;
  productPackageId: string;
  onChange: (productPackagesStyle: ProductPackageStyle | null) => void;
}

export const ProductPackageStyleAsyncSelect = React.forwardRef(
  (
    {
      value,
      customerId,
      productPackageId,
      onChange,
      isDisabled,
      ...sharedSelectProps
    }: ProductPackageStyleAsyncSelectProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const [
      productPackagesStyles,
      loading,
      error,
      fetchProductPackageStyleOptions,
    ] = useApiRequest(productPackageApi.listProductPackageStyleOptions, {
      clearDataOnLoad: true,
    });

    return (
      <Select
        isDisabled={isDisabled}
        isMulti={false}
        innerRef={ref}
        value={value}
        options={productPackagesStyles ?? []}
        isLoading={loading}
        getOptionValue={(i) => i.id}
        getOptionLabel={(i) => i.name}
        noOptionsMessage={() =>
          error ? <ErrorDetails error={error} /> : t("general.noItemsFound")
        }
        onChange={(selectedItem) => onChange(selectedItem)}
        onMenuOpen={() => {
          fetchProductPackageStyleOptions(productPackageId, {
            customerId: customerId,
          });
        }}
        {...sharedSelectProps}
      />
    );
  },
);
