import { useSessionStore } from "./useSessionStore";
import { SessionStatus } from "./useSessionStore/types";

export function useActiveSession() {
  const { sessionState, signOut } = useSessionStore();

  if (sessionState.status !== SessionStatus.Active) {
    throw new Error("No active session");
  }

  return {
    session: sessionState.session,
    signOut,
  };
}
