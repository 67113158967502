import { Button } from "@chakra-ui/button";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Stack } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { rolesApi } from "../../api/rolesApi";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ServerError } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";

export function CreateRoleModal({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const [isLoading, request] = useApiRequestCallback(rolesApi.createRole);
  const [error, setError] = useState<ServerError>();
  const toast = useToastNotification();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<CreateRoleFormData>();

  const onSubmit = useCallback(
    (data: CreateRoleFormData) => {
      request({
        onSuccess: () => {
          onSuccess();
          toast({ title: t("general.saved"), status: "success" });
        },
        onError: setError,
      }).send({ name: data.name });
    },
    [onSuccess, request, t, toast],
  );

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t("general.add")} {t("role.rights").toLowerCase()}
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <ModalBody>
            {isLoading && <LoadingIndicator />}
            <Stack>
              {error && <ErrorDetails error={error} />}
              <FormControl
                id="name"
                isRequired={true}
                isInvalid={!!errors.name}
              >
                <FormLabel>{t("general.name")}</FormLabel>
                <Input
                  {...register("name", {
                    required: { value: true, message: t("error.required") },
                  })}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" isLoading={isSubmitting}>
              {t("general.add")}
            </Button>
          </ModalFooter>
        </form>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}

interface CreateRoleFormData {
  name: string;
}
