import {
  Box,
  Button,
  Checkbox,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState, useMemo, useCallback, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  CustomerProductListItem,
  CustomerProductOptionVariantListItem,
  customerProductsApi,
} from "../../api/customerProductsApi";
import { CustomerDetails } from "../../api/customersApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { PagingOptions, ServerError, SortingOptions } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Icons } from "../shared/Icons";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Card } from "../shared/Card";
import { Page } from "../shared/Page";
import { Pagination } from "../shared/Pagination/Pagination";
import { SearchFilterInput } from "../shared/SearchFilterInput";
import { useNavigate } from "react-router-dom";
import placeholderImg from "../../assets/placeholder.jpg";
import { EmptyListAlert } from "../shared/EmptyListAlert";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { routes } from "../../routes";
import { useGlobalPageSize } from "../../hooks/usePageSize";
import { PageSizePicker } from "../shared/PageSizePicker/PageSizePicker";
import { Image } from "../shared/Image";
import { ConfirmationModal } from "../shared/ConfirmationModal";

interface Changes {
  variantsToAdd: string[];
  variantsToRemove: string[];
}

interface ListOptions
  extends PagingOptions,
    SortingOptions<CustomerProductListItem> {
  searchTerm: string;
}

export function EditCustomerProductsView({
  customer,
  onUpdate,
}: {
  customer: CustomerDetails;
  onUpdate: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [expandedProductIds, setExpandedProductIds] = useState<string[]>([]);
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const [listOptions, setListOptions] = useState<ListOptions>({
    sortingKey: "name",
    sortingDesc: false,
    page: 1,
    pageSize: globalPageSize,
    searchTerm: "",
  });

  const [createError, setCreateError] = useState<ServerError>();
  const [changes, setChanges] = useState<Changes>({
    variantsToAdd: [],
    variantsToRemove: [],
  });

  const [showChangesWarning, setShowChangesWarning] = useState<boolean>(false);

  const [products, isLoading, error, fetch] = useApiRequest(
    customerProductsApi.listCustomerProductOptions,
  );

  const [isUpdating, updateAssortmentRequest] = useApiRequestCallback(
    customerProductsApi.updateCustomerAssortment,
  );

  const isVariantSelected = useCallback(
    (variant: { id: string; inAssortment: boolean }) => {
      return variant.inAssortment
        ? changes.variantsToRemove.indexOf(variant.id) < 0
        : changes.variantsToAdd.indexOf(variant.id) >= 0;
    },
    [changes.variantsToAdd, changes.variantsToRemove],
  );

  useEffect(() => {
    fetch({ customerId: customer.id, ...listOptions });
  }, [fetch, customer.id, listOptions]);

  function getProductSelectedState(
    variants: ProductVariantEditItem[],
  ): ProductSelectState {
    if (variants.every((v) => v.isSelected)) {
      return "selected";
    }
    if (variants.some((v) => v.isSelected)) {
      return "partial";
    }
    return "notSelected";
  }

  const allProducts = useMemo((): ProductEditItem[] | undefined => {
    return products?.items.map((p) => {
      const variants = p.variants.map((v) => ({
        ...v,
        isSelected: isVariantSelected(v),
        inAssortment: v.inAssortment,
      }));
      return {
        ...p,
        selectState: getProductSelectedState(variants),
        isExpanded: expandedProductIds.indexOf(p.id) >= 0,
        variants,
      };
    });
  }, [products, expandedProductIds, isVariantSelected]);

  function onProductCheckboxChange(product: ProductEditItem) {
    if (product.selectState === "selected") {
      setChanges(product.variants.reduce(removeFromAssortment, changes));
    } else {
      setChanges(
        product.variants
          .filter((v) => !v.isSelected)
          .reduce(addToAssortment, changes),
      );
    }
  }

  function onProductExpandClicked(productId: string) {
    const i = expandedProductIds.indexOf(productId);
    if (i >= 0) {
      const newExpandedProductIds = [...expandedProductIds];
      newExpandedProductIds.splice(i, 1);
      setExpandedProductIds(newExpandedProductIds);
    } else {
      setExpandedProductIds([...expandedProductIds, productId]);
    }
  }

  const addToAssortment = useCallback(
    (changes: Changes, variant: ProductVariantEditItem): Changes => {
      return {
        variantsToAdd: variant.inAssortment
          ? [...changes.variantsToAdd]
          : [...changes.variantsToAdd, variant.id],

        variantsToRemove: [
          ...changes.variantsToRemove.filter((x) => x !== variant.id),
        ],
      };
    },
    [],
  );

  const removeFromAssortment = useCallback(
    (
      changes: Changes,
      variant: CustomerProductOptionVariantListItem,
    ): Changes => {
      return {
        variantsToAdd: [
          ...changes.variantsToAdd.filter((x) => x !== variant.id),
        ],
        variantsToRemove: variant.inAssortment
          ? [...changes.variantsToRemove, variant.id]
          : [...changes.variantsToRemove],
      };
    },
    [],
  );

  function handleSave() {
    updateAssortmentRequest({
      onError: (err) => {
        setCreateError(err);
      },
      onSuccess: () => {
        onUpdate();
        navigate(routes.customerProducts(customer.id));
      },
    }).send({
      customerId: customer.id,
      ...changes,
    });
  }

  function onVariantCheckboxChange(variant: ProductVariantEditItem) {
    if (!variant.isSelected) {
      setChanges(addToAssortment(changes, variant));
    } else {
      setChanges(removeFromAssortment(changes, variant));
    }
  }

  return (
    <>
      <Page breadcrumbs={useBreadcrumbs(customer.id, customer.name)}>
        <Card
          titleContent={t("product.products")}
          extraContent={
            <>
              <Button
                disabled={
                  changes.variantsToAdd.length === 0 &&
                  changes.variantsToRemove.length === 0
                }
                onClick={() => {
                  if (changes.variantsToRemove.length > 0)
                    return setShowChangesWarning(true);
                  handleSave();
                }}
              >
                {t("general.save")}
              </Button>
            </>
          }
        >
          {createError && <ErrorDetails error={createError} />}
          {error && <ErrorDetails error={error} />}
          {(isUpdating || isLoading) && <LoadingIndicator />}
          <Box mb={4}>
            <SearchFilterInput
              value={listOptions.searchTerm}
              onChange={(value) =>
                setListOptions((options) => ({
                  ...options,
                  searchTerm: value,
                  page: 1,
                }))
              }
            />
          </Box>
          <Table>
            <Thead>
              <Tr>
                <Th width="65px" />
                <Th />
                <Th />
                <Th>{t("general.name")}</Th>
                <Th>{t("productGroup.productGroup")}</Th>
                <Th>{t("product.variants")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {allProducts?.map((product) => (
                <Fragment key={product.id}>
                  <Tr>
                    <Td>
                      {product.variants.length > 0 && (
                        <IconButton
                          variant="ghost"
                          aria-label="expand"
                          color="blackAlpha.800"
                          icon={
                            product.isExpanded ? (
                              <Icons.ArrowDown boxSize="6" />
                            ) : (
                              <Icons.ArrowRight boxSize="6" />
                            )
                          }
                          size="sm"
                          onClick={() => onProductExpandClicked(product.id)}
                        />
                      )}
                    </Td>
                    <Td>
                      <Checkbox
                        size="lg"
                        isIndeterminate={product.selectState === "partial"}
                        isChecked={product.selectState === "selected"}
                        onChange={() => onProductCheckboxChange(product)}
                      />
                    </Td>
                    <Td>
                      <Image
                        src={product.imageThumbnailUrl ?? placeholderImg}
                        boxSize={12}
                        objectFit="contain"
                      />
                    </Td>
                    <Td>{product.name}</Td>
                    <Td>{product.productGroupName}</Td>
                    <Td>{product.variants.length}</Td>
                  </Tr>
                  {product.isExpanded &&
                    product.variants.map((v) => (
                      <Tr key={v.id} backgroundColor="gray.100">
                        <Td />
                        <Td>
                          <Checkbox
                            size="lg"
                            isChecked={v.isSelected}
                            onChange={() => onVariantCheckboxChange(v)}
                          />
                        </Td>
                        <Td>
                          <Image
                            src={
                              v.imageThumbnailUrl ??
                              product.imageThumbnailUrl ??
                              placeholderImg
                            }
                            boxSize={12}
                            objectFit="contain"
                          />
                        </Td>
                        <Td colSpan={4}>{v.name}</Td>
                      </Tr>
                    ))}
                </Fragment>
              ))}
            </Tbody>
          </Table>
          {products?.items.length === 0 && <EmptyListAlert />}
          {products && (
            <HStack justifyContent="flex-end" mt={4}>
              <PageSizePicker
                pageSizes={[15, 25, 50, 100]}
                onPageSizeChange={(pageSize) => {
                  setListOptions({ ...listOptions, pageSize });
                  setGlobalPageSize(pageSize);
                }}
                pageSize={listOptions.pageSize}
              />
              <Pagination
                totalItems={products?.totalItems}
                pageSize={listOptions.pageSize}
                currentPage={listOptions.page}
                onPageChange={(p) =>
                  setListOptions({ ...listOptions, page: p })
                }
              />
            </HStack>
          )}
        </Card>
      </Page>
      {showChangesWarning && (
        <ConfirmationModal
          message={t("customer.warningDeleteCustomerAssortment", {
            noOfProducts: changes.variantsToRemove.length,
          })}
          confirmButtonText={t("general.continue")}
          confirmButtonColor="red"
          onConfirm={handleSave}
          onClose={() => setShowChangesWarning(false)}
        />
      )}
    </>
  );
}

function useBreadcrumbs(customerId: string, customerName: string) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("customer.customers"),
        to: routes.customers,
      },
      {
        label: customerName,
        to: routes.customer(customerId),
      },
      {
        label: t("assortment.assortment"),
      },
    ],
    [customerName, customerId, t],
  );
}

type ProductSelectState = "selected" | "partial" | "notSelected";
interface ProductEditItem {
  id: string;
  name: string;
  productGroupName: string;
  imageThumbnailUrl: string | null;
  selectState: ProductSelectState;
  isExpanded: boolean;
  variants: ProductVariantEditItem[];
}
interface ProductVariantEditItem {
  id: string;
  name: string;
  imageThumbnailUrl: string | null;
  inAssortment: boolean;
  isSelected: boolean;
}
