import {
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Text,
  Box,
  UnorderedList,
  ListItem,
  Heading,
  Divider,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { CustomModal } from "../../shared/CustomModal";

export default function TermsModal({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { t } = useTranslation();

  return (
    <CustomModal scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t("summary.term")}</ModalHeader>
        <ModalBody paddingX={10}>
          <Heading size="xl" mb={4}>
            Integritetspolicy Modexa
          </Heading>
          <Text>
            Modexa arbetar för att säkerställa att din integritet skyddas vid
            användning av våra tjänster. Vi har därför en policy som fastställer
            hur dina personuppgifter ska behandlas och skyddas. Denna
            integritetspolicy gäller Modexas kunder, samt hyresgäster hos våra
            kunder.
          </Text>
          <Divider marginY={8} />
          <Text mb="10">
            Är du anställd på Modexa finner du information kring hur vi hanterar
            dina personuppgifter på vårt intranät i dokumentet
            ”Integritetspolicy Modexa AB anställda”.
          </Text>

          <TermsSection label="Vem ansvarar för dina personuppgifter?">
            Modexa AB (”Modexa”), är personuppgiftsansvarig för de
            personuppgifter som behandlas inom bolaget, samt av våra biträden.
          </TermsSection>

          <TermsSection label="Var lagras dina personuppgifter?">
            De uppgifter som vi behandlar om dig lagras inom Europeiska Unionen.
            All behandling av dina personuppgifter sker i enlighet med gällande
            lagstiftning.
          </TermsSection>

          <TermsSection label="Vad för slags personuppgifter samlar vi in?">
            Vi samlar in personuppgifter som behövs för att utföra vårt uppdrag
            hos våra kunder. De personuppgifter som vi behandlar är framförallt
            kontaktinformation såsom namn, telefonnummer och adress. När du
            kontaktar Modexa eller någon av våra anställda via telefon eller
            e-post, samlar vi automatiskt in personuppgifter som en naturlig del
            av genomförandet av elektronisk kommunikation. När du besöker vår
            webbplats samlar analys- och loggningstjänster de vanliga uppgifter
            som din webbläsare ger oss, till exempel IP-adresser, val av
            webbläsare etc.
          </TermsSection>

          <TermsSection label="Hur använder vi dina personuppgifter?">
            <UnorderedList mb="10">
              <ListItem>För att bearbeta våra kunders beställningar.</ListItem>
              <ListItem>
                För att skicka dig aviseringar om leveransstatus.
              </ListItem>
              <ListItem>
                För att kontakta dig vid leveranser av dina varor och för att
                säkerställa vårt garantiåtagande.
              </ListItem>
              <ListItem>För att besvara dina frågor.</ListItem>
              <ListItem>
                För att skicka dig enkäter som ger dig möjlighet att påverka vår
                service och kvalitet.
              </ListItem>
            </UnorderedList>
            <Text>
              Vi sparar dina uppgifter så länge som det krävs för att uppfylla
              ovanstående ändamål eller så länge som vi enligt lag är skyldiga
              att göra detta. Därefter raderas dina personuppgifter.
            </Text>
          </TermsSection>

          <TermsSection label="Vilka är dina rättigheter?">
            <Text mb="10">
              Du har rätt att när som helst (en gång per år utan kostnad) begära
              information om de personuppgifter vi har om dig. Om dina uppgifter
              är felaktiga, ofullständiga eller irrelevanta, kan du begära att
              få dem rättade eller raderade. Vi kan inte radera dina uppgifter i
              de fall det föreligger ett lagstadgat krav på lagring, som
              exempelvis bokföringsregler, eller när det finns andra legitima
              skäl till varför uppgifterna måste sparas. Begäran om att åberopa
              dessa rättigheter skall vara skriftlig och skickas till oss på
              adressen nedan:
            </Text>
            <Text mb="10">
              Modexa AB
              <br />
              att: Dataskyddsombud <br />
              Box 958 <br />
              501 10 <br />
              Borås
            </Text>
            <Text mb="10">
              För att kunna hitta dina personuppgifter, samt kunna skicka dem
              till dig behöver vi namn, personnr, adress, telefonnummer och
              e-postadress. Ange även vilken e-postadress du vill ha
              informationen skickad till, samt vilket lösenord du vill att den
              komprimerade filen har. För att säkerställa att det enbart är Du
              som får tillgång till dessa uppgifter är det viktigt att du utöver
              din namnteckning också skickar med en kopia på en godkänd
              identifikationshandling i din förfrågan.
            </Text>
            <Text>
              Efter utskicket av detta registerutdrag kommer vi spara denna
              förfrågan i 12 månader.
            </Text>
          </TermsSection>

          <TermsSection label="Vem har tillgång till dina personuppgifter?">
            <Text mb="8">
              Dina uppgifter behandlas enbart av berörda avdelningar inom
              bolaget. Uppgifter som vidarebefordras till tredje part används
              endast för att tillhandahålla ovannämnda tjänster till dig, t.ex.
              speditörer i samband med leverans, hantverkare i samband med
              montering.
            </Text>
            <Text>
              Vi vidarebefordrar, säljer eller byter aldrig dina personuppgifter
              för marknadsföringsändamål till tredje part utanför Modexa.
            </Text>
          </TermsSection>

          <TermsSection label="Hur skyddar vi dina personuppgifter?">
            Vi har vidtagit tekniska och organisatoriska åtgärder för att skydda
            dina uppgifter från förlust, manipulation och obehörig åtkomst. Vi
            anpassar fortlöpande våra säkerhetsåtgärder i enlighet med
            framstegen och utvecklingen på det tekniska området.
          </TermsSection>

          <TermsSection label="Personuppgiftsansvarig">
            <Text mb="8">
              Modexa AB
              <br />
              Box 958
              <br />
              501 10 Borås
              <br />
              Sverige
            </Text>
            <Text>
              Telefon: +46 33 23 22 00
              <br />
              Fax: +46 33 27 00 35
              <br />
              E-post: support@modexa.se
              <br />
            </Text>
          </TermsSection>
        </ModalBody>
      </ModalContent>
    </CustomModal>
  );
}

function TermsSection({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) {
  return (
    <Box mb={8}>
      <Heading size="sm" mb={5}>
        {label}
      </Heading>
      <Box ml={8}>{children}</Box>
    </Box>
  );
}
