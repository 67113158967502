import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { authApi } from "../api/authApi";
import { useApiRequestCallback } from "../hooks/useApi/useApiRequestCallback";
import { ServerError } from "../types";
import { ErrorDetails } from "./shared/ErrorDetails";
import { LoadingIndicator } from "./shared/LoadingIndicator";
import { Icons } from "./shared/Icons";
import { Link as RouterLink } from "react-router-dom";
import { useBobutikenLogoUrl } from "../hooks/useBobutikenLogoUrl";
import { Image } from "./shared/Image";

interface FormData {
  email: string;
}

export function ForgotPassword() {
  const { t } = useTranslation();
  const bobutikenLogoUrl = useBobutikenLogoUrl();
  const [sent, setSent] = useState(false);
  const [sendResetPasswordTokenError, setSendResetPasswordTokenError] =
    useState<ServerError>();

  const [isLoading, request] = useApiRequestCallback(
    authApi.sendResetPasswordToken,
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>();

  function submit(data: FormData) {
    request({
      onSuccess: () => {
        setSent(true);
        setSendResetPasswordTokenError(undefined);
      },
      onError: setSendResetPasswordTokenError,
    }).send({
      userEmail: data.email,
    });
  }

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      bg="primary.50"
      direction="column"
    >
      <Image w="72" src={bobutikenLogoUrl} alt="logo" />
      <Box maxW="md" my={10}>
        <Link to="/" as={RouterLink} display="flex" alignItems="center">
          <Icons.ArrowLeft boxSize={5} /> {t("general.signIn")}
        </Link>
        <Box w="full" bg="white" rounded="xl" boxShadow="lg" p={6} my={4}>
          {isLoading && <LoadingIndicator />}
          <Stack spacing={3}>
            <Heading fontSize="2xl">{t("password.forgotPassword")}</Heading>
            {!sent && (
              <Text color="gray.700">
                {t("password.forgotPasswordInstructions")}
              </Text>
            )}
            {sent && (
              <Alert status="success" variant="subtle">
                <AlertIcon />
                <AlertDescription>
                  {t("password.forgotPasswordConfirmation")}
                </AlertDescription>
              </Alert>
            )}
          </Stack>
          <form onSubmit={handleSubmit(submit)}>
            <Stack spacing={4} mt={4}>
              {sendResetPasswordTokenError && (
                <ErrorDetails error={sendResetPasswordTokenError} />
              )}
              <FormControl
                id="email"
                isInvalid={!!errors.email}
                isRequired={true}
              >
                <FormLabel>{t("user.email")}</FormLabel>
                <Input
                  id="email"
                  type="email"
                  autoComplete="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: t("error.required"),
                    },
                  })}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
              <Button type="submit" isDisabled={isLoading || sent}>
                {t("general.send")}
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </Flex>
  );
}
