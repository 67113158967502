import { HStack, IconButton } from "@chakra-ui/react";
import { useState, useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { OrderListItem, ordersApi } from "../../api/ordersApi";
import { baseApiUrl } from "../../constants";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { useGlobalPageSize } from "../../hooks/usePageSize";
import { useSavedSorting } from "../../hooks/useSavedSorting";
import { PagingOptions, ServerError, SortingOptions } from "../../types";
import { Card } from "../shared/Card";
import { Column, DataTable } from "../shared/DataTable/DataTable";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Icons } from "../shared/Icons";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";

interface ListOptions extends PagingOptions, SortingOptions<OrderListItem> {}

export function OrdersList({
  customerId,
  customerName,
}: {
  customerId: string;
  customerName: string;
}) {
  const { t } = useTranslation();
  const [OrderToDelete, setOrderToDelete] = useState<OrderListItem>();
  const [deleteError, setDeleteError] = useState<ServerError>();
  const toast = useToastNotification();
  useState<boolean>(false);
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const [orderList, loadingOrderList, orderListError, fetch] = useApiRequest(
    ordersApi.getOrdersList,
  );
  const [isDeleting, deleteRequest] = useApiRequestCallback(
    ordersApi.deleteOrder,
  );
  const { savedSorting, setSavedSorting } = useSavedSorting<OrderListItem>(
    "OrderListPage",
    { sortingDesc: false, sortingKey: "projectName" },
  );
  const [listOptions, setListOptions] = useState<ListOptions>({
    page: 1,
    pageSize: globalPageSize,
    ...savedSorting,
  });

  const fetchOrderList = useCallback(() => {
    fetch({ customerId: customerId, ...listOptions });
  }, [fetch, listOptions, customerId]);

  useEffect(() => {
    fetchOrderList();
  }, [fetchOrderList]);

  function deleteOrder(toDelete: OrderListItem) {
    deleteRequest({
      onSuccess: () => {
        setOrderToDelete(undefined);
        setDeleteError(undefined);
        fetchOrderList();
        toast({
          title: t("general.deleted"),
          status: "success",
        });
      },
      onError: (e) => {
        setOrderToDelete(undefined);
        setDeleteError(e);
      },
    }).send(toDelete.id);
  }

  const columns = useMemo<Column<OrderListItem>[]>(
    () => [
      {
        key: "projectName",
        header: t("general.project"),
        sortable: true,
        cell: ({ row }) => `${customerName} - ${row.projectName}`,
      },
      {
        key: "orderDate",
        header: t("order.date"),
        cell: ({ row }) => row.orderDate?.slice(0, 10),
        sortable: true,
      },
      {
        key: "address",
        header: t("order.address"),
        sortable: true,
        cell: ({ row }) =>
          `${row.tenantAddress}, ${row.tenantPostalCode}, ${row.tenantCity}`,
      },
      {
        key: "apartmentNumber",
        header: t("order.apartmentNumber"),
        sortable: true,
        cell: ({ row }) => row.tenantApartmentNumber,
      },

      {
        key: "actions",
        width: 50,
        cell: ({ row }) => (
          <HStack>
            <IconButton
              size="sm"
              variant="outline"
              aria-label="remove"
              icon={<Icons.Trash />}
              isDisabled={isDeleting}
              onClick={() => setOrderToDelete(row)}
            />

            <IconButton
              as="a"
              href={`${baseApiUrl}/v1/orders/${row.id}/pdf`}
              target="_blank"
              size="sm"
              variant="outline"
              aria-label="download"
              icon={<Icons.Download />}
            />
          </HStack>
        ),
      },
    ],
    [t, customerName, isDeleting],
  );

  return (
    <Card titleContent={t("order.orders")}>
      {OrderToDelete && (
        <ConfirmationModal
          confirmButtonText={t("general.delete")}
          message={t("general.deleteConfirmation", {
            name: OrderToDelete.projectName,
          })}
          onClose={() => setOrderToDelete(undefined)}
          onConfirm={() => deleteOrder(OrderToDelete)}
          confirmButtonColor="red"
        />
      )}
      {deleteError && <ErrorDetails error={deleteError} />}
      {orderListError && <ErrorDetails error={orderListError} />}
      <DataTable
        isLoading={loadingOrderList}
        columns={columns}
        data={orderList?.items}
        sorting={listOptions}
        paging={listOptions}
        totalItems={orderList?.totalItems ?? 0}
        onSortingChange={(sorting) => {
          setListOptions((o) => ({ ...o, ...sorting }));
          setSavedSorting(sorting);
        }}
        onPagingChange={(paging) => {
          setListOptions((o) => ({ ...o, ...paging }));
          setGlobalPageSize(paging.pageSize);
        }}
      />
    </Card>
  );
}
