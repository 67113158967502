import React from "react";
import { useTranslation } from "react-i18next";
import { stylesApi, StyleProductVariant } from "../../api/stylesApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Select } from "../shared/Select/Select";
import { SharedSelectProps } from "../shared/Select/types";

interface StyleProductVariantAsyncSelectProps extends SharedSelectProps {
  value: StyleProductVariant | null;
  customerId: string;
  productGroupId: string;
  onChange: (productVariant: StyleProductVariant | null) => void;
}

export const StyleProductVariantAsyncSelect = React.forwardRef(
  (
    {
      value,
      customerId,
      productGroupId,
      onChange,
      isDisabled,
      ...sharedSelectProps
    }: StyleProductVariantAsyncSelectProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const [productVariants, loading, error, fetchStyleProductVariantOptions] =
      useApiRequest(stylesApi.listStyleProductVariantOptions, {
        clearDataOnLoad: true,
      });

    return (
      <Select
        isDisabled={isDisabled}
        isMulti={false}
        innerRef={ref}
        value={value}
        options={productVariants ?? []}
        isLoading={loading}
        getOptionValue={(i) => i.id}
        getOptionLabel={(i) => i.name}
        noOptionsMessage={() =>
          error ? <ErrorDetails error={error} /> : t("general.noItemsFound")
        }
        onChange={(selectedItem) => onChange(selectedItem)}
        onMenuOpen={() => {
          fetchStyleProductVariantOptions({
            customerId: customerId,
            productGroupId: productGroupId,
          });
        }}
        {...sharedSelectProps}
      />
    );
  },
);
