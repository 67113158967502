import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Flex,
  Button,
  SimpleGrid,
  Textarea,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  productVariantsApi,
  ProductVariantDetails,
  RenderMaterial,
} from "../../../api/productVariantsApi";
import { useApiRequest } from "../../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../../hooks/useToastNotification";
import { Permission, ServerError } from "../../../types";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { Card } from "../../shared/Card";
import { ImageList } from "../../shared/ImageList";
import React from "react";
import { SharedSelectProps } from "../../shared/Select/types";
import { Select } from "../../shared/Select/Select";
import { usePermission } from "../../../hooks/usePermission";
import { ProductDetails } from "../../../api/productsApi";

export function ProductVariantDetailsView({
  selectedVariantId,
  product,
  onUpdateSuccess,
  onIsDirtyChange,
}: {
  selectedVariantId: string;
  product: ProductDetails;
  onUpdateSuccess: () => void;
  onIsDirtyChange: (isDirty: boolean) => void;
}) {
  const { t } = useTranslation();
  const [productVariant, loading, error, fetch] = useApiRequest(
    productVariantsApi.getProductVariantDetails,
  );

  useEffect(() => {
    fetch(selectedVariantId);
  }, [fetch, selectedVariantId, product.productGroupId]);

  function handleVariantUpdateSuccess() {
    onUpdateSuccess();
    fetch(selectedVariantId);
  }

  return (
    <>
      {loading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      {productVariant && (
        <SimpleGrid spacing={6}>
          <Card titleContent={productVariant.name}>
            <ProductVariantForm
              productVariant={productVariant}
              productDescription={product.description}
              onUpdateSuccess={handleVariantUpdateSuccess}
              onIsDirtyChange={onIsDirtyChange}
            />
          </Card>
          <Card titleContent={t("productVariant.variantImages")}>
            <ProductVariantImages productVariant={productVariant} />
          </Card>
        </SimpleGrid>
      )}
    </>
  );
}

interface ProductVariantFormData {
  name: string;
  description: string;
  renderMaterial: RenderMaterial;
}

function ProductVariantForm({
  productVariant,
  productDescription,
  onUpdateSuccess,
  onIsDirtyChange,
}: {
  productVariant: ProductVariantDetails;
  productDescription: string;
  onUpdateSuccess: () => void;
  onIsDirtyChange: (isDirty: boolean) => void;
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<ServerError>();
  const toast = useToastNotification();
  const hasPermission = usePermission(Permission.ManageProducts);

  const [loading, updateProductGroupRequest] = useApiRequestCallback(
    productVariantsApi.updateProductVariant,
  );

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty },
    control,
  } = useForm<ProductVariantFormData>({ defaultValues: productVariant });

  useEffect(() => {
    reset(productVariant);
  }, [productVariant, reset]);

  function onSubmit(data: ProductVariantFormData) {
    updateProductGroupRequest({
      onSuccess: () => {
        toast({ title: t("general.saved"), status: "success" });
        onUpdateSuccess();
      },
      onError: setError,
    }).send(productVariant.id, {
      name: data.name,
      description: data.description,
      renderMaterial: data.renderMaterial,
    });
  }

  useEffect(() => {
    onIsDirtyChange(isDirty);
  }, [isDirty, onIsDirtyChange]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {loading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      <Stack spacing={4} shouldWrapChildren={true}>
        <FormControl
          id="name"
          isRequired={true}
          isInvalid={!!errors.name}
          isReadOnly={!hasPermission}
        >
          <FormLabel>{t("general.name")}</FormLabel>
          <Input
            {...register("name", {
              required: { value: true, message: t("error.required") },
            })}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>
        <FormControl
          id="description"
          isInvalid={!!errors.description}
          isReadOnly={!hasPermission}
        >
          <FormLabel>{t("general.description")}</FormLabel>
          <Textarea
            {...register("description")}
            placeholder={productDescription}
          />
          <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
        </FormControl>
        {productVariant.productGroupRenderLayerId && (
          <FormControl id="renderMaterial" isReadOnly={!hasPermission}>
            <FormLabel>{t("productVariant.renderMaterial")}</FormLabel>
            <Controller
              name="renderMaterial"
              render={({ field, fieldState }) => (
                <RenderMaterialAsyncSelect
                  isDisabled={!hasPermission}
                  renderLayerId={productVariant.productGroupRenderLayerId}
                  isClearable={hasPermission ? true : false}
                  {...field}
                  {...fieldState}
                />
              )}
              control={control}
            />
          </FormControl>
        )}
        <Flex justifyContent="flex-end">
          <Button type="submit" disabled={!isDirty}>
            {t("general.save")}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
}

function ProductVariantImages({
  productVariant,
}: {
  productVariant: ProductVariantDetails;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const hasPermission = usePermission(Permission.ManageProducts);

  const [uploadError, setUploadError] = useState<ServerError | null>(null);
  const [uploadLoading, uploadRequest] = useApiRequestCallback(
    productVariantsApi.uploadProductVariantImage,
  );

  const [deleteError, setDeleteError] = useState<ServerError | null>(null);
  const [deleteLoading, deleteRequest] = useApiRequestCallback(
    productVariantsApi.deleteProductVariantImage,
  );

  const [
    productImages,
    productImagesLoading,
    productImagesError,
    fetchProductVariantImages,
  ] = useApiRequest(productVariantsApi.listProductVariantImages);

  useEffect(() => {
    fetchProductVariantImages(productVariant.id);
  }, [fetchProductVariantImages, productVariant.id]);

  const uploadImage = useCallback(
    (image: File) => {
      uploadRequest({
        onSuccess: () => {
          toast({
            title: t("general.added"),
            status: "success",
          });
          setUploadError(null);
          fetchProductVariantImages(productVariant.id);
        },
        onError: setUploadError,
      }).send(productVariant.id, image);
    },
    [fetchProductVariantImages, productVariant.id, uploadRequest, toast, t],
  );

  function deleteImage(imageId: string) {
    deleteRequest({
      onSuccess: () => {
        toast({
          title: t("general.deleted"),
          status: "success",
        });
        setDeleteError(null);
        fetchProductVariantImages(productVariant.id);
      },
      onError: setDeleteError,
    }).send(productVariant.id, imageId);
  }

  return (
    <ImageList
      readOnly={!hasPermission}
      images={productImages}
      errors={[productImagesError, deleteError, uploadError]}
      isLoading={productImagesLoading || deleteLoading || uploadLoading}
      onUpload={uploadImage}
      onRemove={deleteImage}
    />
  );
}

interface RenderMaterialAsyncSelectProps extends SharedSelectProps {
  renderLayerId: string;
  value: RenderMaterial | null;
  onChange: (renderLayer: RenderMaterial | null) => void;
}

export const RenderMaterialAsyncSelect = React.forwardRef(
  (
    {
      renderLayerId,
      value,
      onChange,
      isDisabled,
      ...sharedSelectProps
    }: RenderMaterialAsyncSelectProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const [
      renderMaterials,
      renderMaterialsLoading,
      renderMaterialsError,
      fetchRenderMaterials,
    ] = useApiRequest(productVariantsApi.listRenderMaterials, {
      clearDataOnLoad: true,
    });

    return (
      <Select
        isDisabled={isDisabled}
        isMulti={false}
        innerRef={ref}
        value={value}
        options={renderMaterials ?? []}
        isLoading={renderMaterialsLoading}
        getOptionValue={(i) => i.id}
        getOptionLabel={(i) => i.name}
        noOptionsMessage={() =>
          renderMaterialsError ? (
            <ErrorDetails error={renderMaterialsError} />
          ) : (
            t("general.noItemsFound")
          )
        }
        onChange={(selectedItem) => onChange(selectedItem)}
        onMenuOpen={() => {
          fetchRenderMaterials(renderLayerId);
        }}
        {...sharedSelectProps}
      />
    );
  },
);
