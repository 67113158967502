import { Box, Th } from "@chakra-ui/react";
import { HTMLAttributes } from "react";
import { Icons } from "../../shared/Icons";
import { useTableSorting } from "./hooks";

export interface DataTableHeaderProps extends HTMLAttributes<HTMLElement> {
  columnKey: string;
  align?: "left" | "right";
  sortable?: boolean;
}

export function DataTableHeader({
  sortable,
  columnKey,
  children,
  align,
}: DataTableHeaderProps) {
  const { onToggleSorting, sorting } = useTableSorting();
  return (
    <Th
      userSelect="none"
      cursor={sortable ? "pointer" : "default"}
      onClick={() => {
        if (!sortable) {
          return;
        }
        onToggleSorting(columnKey);
      }}
    >
      <Box
        display="flex"
        textAlign={align ?? "left"}
        justifyContent={align === "right" ? "flex-end" : "flex-start"}
      >
        {children}
        {sortable && sorting && (
          <Box ml={0.5} display="flex" flexDir="column" justifyContent="center">
            <Icons.ArrowUpFill
              w={5}
              h={5}
              my="-7px"
              color={
                sorting.sortingKey === columnKey && !sorting.sortingDesc
                  ? "primary.500"
                  : "gray.300"
              }
            />
            <Icons.ArrowDownFill
              w={5}
              h={5}
              my="-7px"
              color={
                sorting.sortingKey === columnKey && sorting.sortingDesc
                  ? "primary.500"
                  : "gray.300"
              }
            />
          </Box>
        )}
      </Box>
    </Th>
  );
}
