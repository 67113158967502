import {
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  Button,
  Box,
  useRadioGroup,
  SimpleGrid,
  useRadio,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ProjectLandingPageDetails,
  projectsApi,
} from "../../../api/projectsApi";
import { useApiRequestCallback } from "../../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../../hooks/useToastNotification";
import { LandingPageLayout, ServerError } from "../../../types";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { RichTextEditor } from "../../shared/RichText/RichTextEditor";

interface FormData {
  layout: LandingPageLayout;
  bannerContent: string;
  content1: string;
  content2: string;
}

export function LandingPageForm({
  projectId,
  landingPage,
  onUpdate,
}: {
  projectId: string;
  landingPage: ProjectLandingPageDetails;
  onUpdate: (product: ProjectLandingPageDetails) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [error, setError] = useState<ServerError>();

  const [updateProductLoading, updateProductRequest] = useApiRequestCallback(
    projectsApi.updateProjectLandingPage,
  );

  const {
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    control,
  } = useForm<FormData>({ defaultValues: landingPage });

  function handleUpdateSuccess(updatedProduct: ProjectLandingPageDetails) {
    setError(undefined);
    reset(updatedProduct);
    onUpdate(updatedProduct);
    toast({ title: t("general.saved"), status: "success" });
  }

  async function onSubmit({
    bannerContent,
    content1,
    content2,
    layout,
  }: FormData) {
    updateProductRequest({
      onSuccess: handleUpdateSuccess,
      onError: setError,
    }).send(projectId, {
      layout,
      bannerContent,
      content1,
      content2,
    });
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "layout",
    defaultValue: landingPage.layout,
  });

  return (
    <>
      {updateProductLoading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Stack spacing={4} shouldWrapChildren={true}>
          <FormControl id="layout">
            <FormLabel>{t("general.layout")}</FormLabel>
            <Controller
              name="layout"
              render={({ field }) => (
                <Box {...getRootProps()} onChange={field.onChange}>
                  <Stack direction="row">
                    <LayoutRadioCard
                      value={LandingPageLayout.None.toString()}
                      {...getRadioProps({ value: LandingPageLayout.None })}
                    >
                      <PageLayoutThumbnail layout={LandingPageLayout.None} />
                    </LayoutRadioCard>
                    <LayoutRadioCard
                      value={LandingPageLayout.Layout1}
                      {...getRadioProps({ value: LandingPageLayout.Layout1 })}
                    >
                      <PageLayoutThumbnail layout={LandingPageLayout.Layout1} />
                    </LayoutRadioCard>
                    <LayoutRadioCard
                      value={LandingPageLayout.Layout2}
                      {...getRadioProps({ value: LandingPageLayout.Layout2 })}
                    >
                      <PageLayoutThumbnail layout={LandingPageLayout.Layout2} />
                    </LayoutRadioCard>
                    <LayoutRadioCard
                      value={LandingPageLayout.Layout3}
                      {...getRadioProps({ value: LandingPageLayout.Layout3 })}
                    >
                      <PageLayoutThumbnail layout={LandingPageLayout.Layout3} />
                    </LayoutRadioCard>
                  </Stack>
                </Box>
              )}
              control={control}
            />
          </FormControl>
          <FormControl id="bannerContent" isInvalid={!!errors.bannerContent}>
            <FormLabel>{t("project.bannerText")}</FormLabel>
            <Controller
              name="bannerContent"
              render={({ field }) => (
                <RichTextEditor
                  value={field.value}
                  onChange={field.onChange}
                  showFontFamilyOptions={true}
                />
              )}
              control={control}
            />
            <FormErrorMessage>{errors.bannerContent?.message}</FormErrorMessage>
          </FormControl>
          <FormControl id="content1" isInvalid={!!errors.content1}>
            <FormLabel>{`${t("general.text")} 1`}</FormLabel>
            <Controller
              name="content1"
              render={({ field }) => (
                <RichTextEditor
                  value={field.value}
                  onChange={field.onChange}
                  showFontFamilyOptions={true}
                />
              )}
              control={control}
            />
            <FormErrorMessage>{errors.content1?.message}</FormErrorMessage>
          </FormControl>
          <FormControl id="content2" isInvalid={!!errors.content2}>
            <FormLabel>{`${t("general.text")} 2`}</FormLabel>
            <Controller
              name="content2"
              render={({ field }) => (
                <RichTextEditor
                  value={field.value}
                  onChange={field.onChange}
                  showFontFamilyOptions={true}
                />
              )}
              control={control}
            />
            <FormErrorMessage>{errors.content2?.message}</FormErrorMessage>
          </FormControl>
          <Flex justifyContent="flex-end">
            <Button type="submit" disabled={!isDirty}>
              {t("general.save")}
            </Button>
          </Flex>
        </Stack>
      </form>
    </>
  );
}

export function LayoutRadioCard(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        _checked={{
          boxShadow: "md",
          borderColor: "primary.500",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
        width={60}
        height="100%"
      >
        {props.children}
      </Box>
    </Box>
  );
}

function PageLayoutThumbnail({ layout }: { layout: LandingPageLayout }) {
  const { t } = useTranslation();
  const commonStylingProps = {
    h: "48px",
    width: "100%",
    borderRadius: "md",
    border: "1px solid",
    borderColor: "primary.500",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <>
      {
        {
          [LandingPageLayout.None]: (
            <Flex h="100%" w="100%" justifyContent="center" alignItems="center">
              {t("project.hideLandingPage")}
            </Flex>
          ),
          [LandingPageLayout.Layout1]: (
            <SimpleGrid columns={2} spacing={2}>
              <Box
                color="white"
                backgroundColor="primary.500"
                {...commonStylingProps}
              >
                {`${t("general.image")} 1`}
              </Box>
              <Box backgroundColor="white" {...commonStylingProps}>
                {`${t("general.text")} 1`}
              </Box>
              <Box backgroundColor="white" {...commonStylingProps}>
                {`${t("general.text")} 2`}
              </Box>
              <Box
                color="white"
                backgroundColor="primary.500"
                {...commonStylingProps}
              >
                {`${t("general.image")} 2`}
              </Box>
            </SimpleGrid>
          ),
          [LandingPageLayout.Layout2]: (
            <SimpleGrid columns={2} spacing={2}>
              <Box
                color="white"
                backgroundColor="primary.500"
                {...commonStylingProps}
              >{`${t("general.image")} 1`}</Box>
              <Box backgroundColor="white" {...commonStylingProps}>
                {`${t("general.text")} 1`}
              </Box>
              <Box
                color="white"
                backgroundColor="primary.500"
                {...commonStylingProps}
              >
                {`${t("general.image")} 2`}
              </Box>
              <Box backgroundColor="white" {...commonStylingProps}>
                {`${t("general.text")} 2`}
              </Box>
            </SimpleGrid>
          ),
          [LandingPageLayout.Layout3]: (
            <SimpleGrid columns={2} spacing={2}>
              <Box
                color="white"
                backgroundColor="primary.500"
                {...commonStylingProps}
              >{`${t("general.image")} 1`}</Box>
              <Box
                color="white"
                backgroundColor="primary.500"
                {...commonStylingProps}
              >
                {`${t("general.image")} 2`}
              </Box>
              <Box backgroundColor="white" {...commonStylingProps}>
                {`${t("general.text")} 1`}
              </Box>
              <Box backgroundColor="white" {...commonStylingProps}>
                {`${t("general.text")} 2`}
              </Box>
            </SimpleGrid>
          ),
        }[layout]
      }
    </>
  );
}
