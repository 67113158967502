import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export function ConfirmationModal({
  message,
  confirmButtonText,
  confirmButtonColor = "primary",
  onClose,
  onConfirm,
}: {
  confirmButtonText: string;
  confirmButtonColor?: "red" | "primary";
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const ref = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  return (
    <AlertDialog isOpen={true} onClose={onClose} leastDestructiveRef={ref}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader />
          <AlertDialogBody>{message}</AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button variant="outline" ref={ref} onClick={onClose}>
                {t("general.cancel")}
              </Button>
              <Button colorScheme={confirmButtonColor} onClick={onConfirm}>
                {confirmButtonText}
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
