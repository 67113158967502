import { IconButton, HStack, Button } from "@chakra-ui/react";
import { useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  GeneralWorkPlanStepListItem,
  workPlansApi,
  WorkPlanStepChange,
  WorkPlanStepType,
} from "../../../api/workPlansApi";
import { useApiRequestCallback } from "../../../hooks/useApi/useApiRequestCallback";
import { useConfirmation } from "../../../hooks/useConfirmation";
import { useToastNotification } from "../../../hooks/useToastNotification";
import { ServerError } from "../../../types";
import { Card } from "../../shared/Card";
import { Column, DataTable } from "../../shared/DataTable/DataTable";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { Icons } from "../../shared/Icons";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { EditWorkStepNoteModal } from "../EditWorkStepNoteModal";
import { GeneralWorkSteps } from "./useGeneralWorkSteps";

interface ChangeSet {
  custom?: Record<string, WorkPlanStepChange>;
  global?: Record<string, WorkPlanStepChange>;
}
interface EditNoteParams {
  id: string;
  note: string;
  type: WorkPlanStepType;
}
export function GeneralWorkStepList({
  workPlanId,
  generalWorkSteps,
}: {
  workPlanId: string;
  generalWorkSteps: GeneralWorkSteps;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [isEditMode, setIsEditMode] = useState(false);
  const [editNoteData, setEditNoteData] = useState<EditNoteParams | null>(null);
  const [changes, setChanges] = useState<ChangeSet>({});
  const [isUpdating, updateRequest] = useApiRequestCallback(
    workPlansApi.updateGeneralWorkPlanSteps,
  );
  const [updateError, setUpdateError] = useState<ServerError>();
  const [confirm, ConfirmationModal] = useConfirmation();

  const {
    workSteps,
    isLoading,
    error,
    savedSorting,
    setSavedSorting,
    setGlobalPageSize,
    paging,
    setPaging,
    fetchGeneralWorkSteps,
  } = generalWorkSteps;

  const getNote = useCallback(
    (id: string, type: WorkPlanStepType, defaultValue: string) => {
      if (type === WorkPlanStepType.Global)
        return changes.global?.[id]?.note ?? defaultValue;

      return changes.custom?.[id]?.note ?? defaultValue;
    },
    [changes.custom, changes.global],
  );

  const items = useMemo(
    () =>
      workSteps?.items.map((it) => ({
        ...it,
        note: getNote(it.id, it.type, it.note),
      })),
    [getNote, workSteps?.items],
  );

  const columns = useMemo<Column<GeneralWorkPlanStepListItem>[]>(
    () => [
      {
        key: "name",
        header: t("general.name"),
        sortable: true,
      },
      {
        key: "note",
        sortable: false,
        header: t("workPlan.note"),
        width: 150,
        cell: ({ row }) => (
          <IconButton
            variant={row.note ? "solid" : "outline"}
            aria-label={t("general.edit")}
            icon={<Icons.Edit />}
            size="sm"
            onClick={() => {
              setEditNoteData({
                id: row.id,
                note: row.note,
                type: row.type,
              });
            }}
          />
        ),
      },
    ],
    [t],
  );
  function onSaveChanges() {
    updateRequest({
      onSuccess: () => {
        fetchGeneralWorkSteps().then(() => {
          setChanges({});
          setIsEditMode(false);
          toast({
            title: t("general.saved"),
            status: "success",
          });
        });
      },
      onError: setUpdateError,
    }).send(workPlanId, changes);
  }

  function onCancelEditMode() {
    const hasChanges =
      (changes.custom && Object.keys(changes.custom).length) ||
      (changes.global && Object.keys(changes.global).length);

    if (hasChanges) {
      confirm({
        message: t("general.unsavedChangesConfirmation"),
        confirmButtonText: t("general.continue"),
        confirmButtonColor: "red",
        onConfirmation: () => {
          setIsEditMode(false);
          setChanges({});
        },
      });
    } else {
      setIsEditMode(false);
      setChanges({});
    }
  }

  function onEditNoteSuccess(newNote: string) {
    if (!editNoteData) return;

    if (editNoteData.type === WorkPlanStepType.Custom) {
      setChanges({
        global: changes.global,
        custom: {
          ...changes.custom,
          [editNoteData.id]: { note: newNote },
        },
      });
    } else {
      setChanges({
        custom: changes.custom,
        global: {
          ...changes.global,
          [editNoteData.id]: { note: newNote },
        },
      });
    }
    setEditNoteData(null);
  }

  return (
    <Card
      titleContent={t("workStep.generalWorkSteps")}
      extraContent={
        isEditMode ? (
          <HStack>
            <Button size="sm" onClick={onCancelEditMode}>
              {t("general.cancel")}
            </Button>
            <Button size="sm" onClick={onSaveChanges}>
              {t("general.save")}
            </Button>
          </HStack>
        ) : (
          <Button size="sm" onClick={() => setIsEditMode(true)}>
            {t("general.edit")}
          </Button>
        )
      }
    >
      {error && <ErrorDetails error={error} />}
      {updateError && <ErrorDetails error={updateError} />}
      {isUpdating && <LoadingIndicator />}
      <DataTable
        columns={columns}
        totalItems={workSteps?.totalItems ?? 0}
        sorting={savedSorting}
        onSortingChange={setSavedSorting}
        isLoading={isLoading}
        paging={paging}
        onPagingChange={(p) => {
          setPaging(p);
          setGlobalPageSize(p.pageSize);
        }}
        data={items}
      />
      {editNoteData && (
        <EditWorkStepNoteModal
          currentNote={editNoteData.note}
          readonly={!isEditMode}
          onClose={() => setEditNoteData(null)}
          onSuccess={onEditNoteSuccess}
        />
      )}
      {ConfirmationModal}
    </Card>
  );
}
