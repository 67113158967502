import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
} from "@chakra-ui/react";

export function EditWorkStepNoteModal({
  currentNote,
  readonly,
  onSuccess,
  onClose,
}: {
  currentNote: string;
  readonly: boolean;
  onSuccess: (note: string) => void;
  onClose: () => void;
}) {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {readonly ? (
          <ReadOnlyContent currentNote={currentNote} />
        ) : (
          <ModalFormContent currentNote={currentNote} onSuccess={onSuccess} />
        )}
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}

function ReadOnlyContent({ currentNote }: { currentNote: string }) {
  const { t } = useTranslation();
  return (
    <>
      <ModalHeader>{t("workPlan.note")}</ModalHeader>
      <ModalBody>
        <Textarea value={currentNote} minHeight={150} readOnly={true} />
      </ModalBody>
    </>
  );
}

interface UpdateWorkStepNoteFormData {
  note: string;
}

function ModalFormContent({
  currentNote,
  onSuccess,
}: {
  currentNote: string;
  onSuccess: (note: string) => void;
}) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UpdateWorkStepNoteFormData>({
    defaultValues: { note: currentNote },
  });

  function onSubmit(data: UpdateWorkStepNoteFormData) {
    onSuccess(data.note);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <ModalHeader>{t("workPlan.editNote")}</ModalHeader>
      <ModalBody>
        <Stack spacing={4} shouldWrapChildren={true}>
          <FormControl id="note" isInvalid={!!errors.note}>
            <FormLabel>{t("workPlan.note")}</FormLabel>
            <Textarea {...register("note")} />
            <FormErrorMessage>{errors.note?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button type="submit">{t("general.save")}</Button>
      </ModalFooter>
    </form>
  );
}
