import { useCallback, useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";
import {
  externalApi,
  OrderConfiguration,
  ProductPackageSummary,
  SceneType,
} from "../../../api/externalApi";
import { nullSafeAddUpList } from "../../../helpers";
import { useApiRequestCallback } from "../../../hooks/useApi/useApiRequestCallback";
import { getInitialState, orderWizardReducer } from "./reducer";
import { SelectedOrderStep, ProductSelection, StyleSelection } from "./types";

export function useOrderState(configuration: OrderConfiguration) {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(
    orderWizardReducer,
    configuration,
    getInitialState,
  );

  const totalVariantsPrice = useMemo(() => {
    return Object.values(state.selectedProductByProductGroupId)
      .filter((p) => p.productGroup.isRendered && p.variant.price !== null)
      .map((p) => p.variant.price)
      .reduce((prev, curr) => prev! + curr!, null);
  }, [state.selectedProductByProductGroupId]);

  const totalOtherVariantsPrice = useMemo(() => {
    return Object.values(state.selectedProductByProductGroupId)
      .filter((p) => !p.productGroup.isRendered && p.variant.price !== null)
      .map((p) => p.variant.price)
      .reduce((prev, curr) => prev! + curr!, null);
  }, [state.selectedProductByProductGroupId]);

  const isLastRenderedProductGroupStep = useMemo(() => {
    if (
      !state?.selectedProductPackage ||
      !(
        state?.selectedOrderStep.type === "RenderedProductGroup" &&
        state?.selectedOrderStep?.productGroup
      )
    ) {
      return false;
    }
    const currentProductGroupId = state.selectedOrderStep.productGroup.id;
    const currentIndex =
      state.selectedProductPackage.renderedProductGroups.findIndex(
        (c) => c.id === currentProductGroupId,
      );
    const nextProductGroup =
      state.selectedProductPackage.renderedProductGroups[currentIndex + 1];

    return nextProductGroup === undefined;
  }, [state.selectedProductPackage, state.selectedOrderStep]);

  const totalIncludedVariantsPrice = useMemo(() => {
    if (!state.selectedProductPackage) {
      return null;
    }

    return state.selectedProductPackage.requiredProductGroups
      .flatMap((c) => c.products.flatMap((p) => p.variants.map((v) => v.price)))
      .filter((p) => p !== null)
      .reduce((prev, curr) => prev! + curr!, null);
  }, [state.selectedProductPackage]);

  const totalPrice = useMemo(() => {
    const packagePrice = state.selectedProductPackage?.price ?? null;

    return nullSafeAddUpList([
      packagePrice,
      totalVariantsPrice,
      totalOtherVariantsPrice,
      totalIncludedVariantsPrice,
    ]);
  }, [
    state.selectedProductPackage,
    totalIncludedVariantsPrice,
    totalOtherVariantsPrice,
    totalVariantsPrice,
  ]);

  const uncompletedSteps = useMemo(() => {
    const uncompletedCategories =
      state.selectedProductPackage?.renderedProductGroups
        .filter(
          (productGroup) =>
            state.selectedProductByProductGroupId[productGroup.id] ===
            undefined,
        )
        .map((c) => c.name) ?? [];

    const uncompletedOtherCategories =
      state.selectedProductPackage?.nonRenderedProductGroups
        .filter(
          (productGroup) =>
            state.selectedProductByProductGroupId[productGroup.id] ===
            undefined,
        )
        .map((c) => `${t("external.otherCategories")} - ${c.name}`) ?? [];

    return [...uncompletedCategories, ...uncompletedOtherCategories];
  }, [state.selectedProductPackage, state.selectedProductByProductGroupId, t]);

  const isAllOtherCategoriesComplete = useMemo(() => {
    return (
      (
        state.selectedProductPackage?.nonRenderedProductGroups
          .filter(
            (productGroup) =>
              state.selectedProductByProductGroupId[productGroup.id] ===
              undefined,
          )
          .map((c) => c.name) ?? []
      ).length === 0
    );
  }, [state.selectedProductPackage, state.selectedProductByProductGroupId]);

  const hasAnyOtherCategories = useMemo(() => {
    return (
      !!state.selectedProductPackage?.nonRenderedProductGroups &&
      state.selectedProductPackage?.nonRenderedProductGroups.length > 0
    );
  }, [state.selectedProductPackage]);

  const [_, getProductPackageDetails] = useApiRequestCallback(
    externalApi.getOrderProductPackage,
  );

  const selectProductPackage = useCallback(
    (productPackage: ProductPackageSummary) => {
      getProductPackageDetails({
        onSuccess: (productPackage) => {
          dispatch({ type: "selectedProductPackage", productPackage });
          for (let productGroup of [
            ...productPackage.renderedProductGroups,
            ...productPackage.nonRenderedProductGroups,
          ]) {
            if (
              productGroup.products.length === 1 &&
              (!productGroup.products[0].hasVariants ||
                productGroup.products[0].variants.length === 1)
            ) {
              selectProduct({
                productGroup,
                product: productGroup.products[0],
                variant: productGroup.products[0].hasVariants
                  ? productGroup.products[0].variants[0]
                  : undefined,
              });
            }
          }
          return;
        },
      }).send(productPackage.id);
    },

    [getProductPackageDetails],
  );

  function selectProduct(selection: ProductSelection) {
    dispatch({ type: "selectProduct", selection });
  }

  function selectStyle(selection: StyleSelection) {
    if (state.selectedStyle?.id === selection.styleDetails.id) {
      clearStyleProducts(selection);
      return;
    }
    selectStyleProducts(selection);
    dispatch({ type: "selectStyle", selection });
  }

  function clearStyleProducts(styleSelection: StyleSelection) {
    styleSelection.styleDetails.productGroups.forEach((p) => {
      dispatch({
        type: "unSelectProduct",
        unSelection: {
          productGroup: p,
          product: p.products[0],
          variant: p.products[0].variants[0],
        },
      });
    });
  }

  function selectStyleProducts(styleSelection: StyleSelection) {
    styleSelection.styleDetails.productGroups.forEach((p) => {
      dispatch({
        type: "selectProduct",
        selection: {
          productGroup: p,
          product: p.products[0],
          variant: p.products[0].variants[0],
        },
      });
    });
  }

  function selectOrderStep(step: SelectedOrderStep) {
    dispatch({
      type: "selectOrderStep",
      step,
    });
  }

  function selectKitchenScene(sceneType: SceneType) {
    dispatch({ type: "selectedKitchenScene", scene: sceneType });
  }

  function setIsStyleInfoAtInitVisible(isStyleInfoAtInitVisible: boolean) {
    dispatch({
      type: "setIsStyleInfoAtInitVisible",
      isStyleInfoAtInitVisible: isStyleInfoAtInitVisible,
    });
  }

  const goToNextStep = () => {
    if (!state.selectedKitchenScene) {
      selectOrderStep({
        type: "KitchenScene",
      });
      return;
    }

    if (!state.selectedProductPackage) {
      selectOrderStep({
        type: "ProductPackages",
      });
      return;
    }

    switch (state.selectedOrderStep.type) {
      case "ProductPackages": {
        selectOrderStep({
          type: "KitchenScene",
        });
        break;
      }
      case "KitchenScene": {
        selectOrderStep({
          type: "Styles",
        });
        break;
      }

      case "Styles": {
        if (
          state.selectedProductPackage.renderedProductGroups[0] !== undefined
        ) {
          selectOrderStep({
            type: "RenderedProductGroup",
            productGroup: state.selectedProductPackage.renderedProductGroups[0],
          });
          break;
        }
        if (state.selectedProductPackage.nonRenderedProductGroups.length > 0) {
          selectOrderStep({ type: "NonRenderedProductGroup" });
          break;
        }

        selectOrderStep({
          type: "Summary",
        });
        break;
      }

      case "RenderedProductGroup": {
        const currentProductGroupId = state.selectedOrderStep.productGroup.id;
        const currentIndex =
          state.selectedProductPackage.renderedProductGroups.findIndex(
            (c) => c.id === currentProductGroupId,
          );
        const nextProductGroup =
          state.selectedProductPackage.renderedProductGroups[currentIndex + 1];

        if (nextProductGroup !== undefined) {
          selectOrderStep({
            type: "RenderedProductGroup",
            productGroup: nextProductGroup,
          });
        } else if (
          state.selectedProductPackage.nonRenderedProductGroups.length > 0
        ) {
          selectOrderStep({ type: "NonRenderedProductGroup" });
        } else {
          selectOrderStep({
            type: "Summary",
          });
        }
        break;
      }
      case "NonRenderedProductGroup": {
        selectOrderStep({ type: "Summary" });
        break;
      }
      default: {
        break;
      }
    }
  };

  const goToPreviousStep = () => {
    switch (state.selectedOrderStep.type) {
      case "KitchenScene": {
        if (state.kitchenScenes.length > 1) {
          selectOrderStep({ type: "ProductPackages" });
          break;
        }
        window.history.back();
        break;
      }

      default: {
        window.history.back();
        break;
      }
    }
  };

  return {
    selectedOrderStep: state.selectedOrderStep,
    selectedKitchenScene: state.selectedKitchenScene,
    selectedProductPackage: state.selectedProductPackage,
    selectedProductByProductGroupId: state.selectedProductByProductGroupId,
    productPackages: state.productPackages,
    kitchenScenes: state.kitchenScenes,
    selectedStyle: state.selectedStyle,
    isStyleInfoAtInitVisible: state.isStyleInfoAtInitVisible,
    totalPrice,
    totalVariantsPrice,
    totalOtherVariantsPrice,
    totalIncludedVariantsPrice,
    isAllOtherCategoriesComplete,
    isLastRenderedProductGroupStep,
    uncompletedSteps,
    hasAnyOtherCategories,
    goToPreviousStep,
    goToNextStep,
    selectOrderStep,
    selectKitchenScene,
    selectProductPackage,
    selectProduct,
    selectStyle,
    setIsStyleInfoAtInitVisible,
  };
}
