import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Box,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectPackageListItem, projectsApi } from "../../api/projectsApi";
import {
  productPackageApi,
  ProductPackageListItem,
} from "../../api/productPackageApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { PagingOptions, ServerError, SortingOptions } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Icons } from "../shared/Icons";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Column, DataTable } from "../shared/DataTable/DataTable";
import { SearchFilterInput } from "../shared/SearchFilterInput";
import { useSavedSorting } from "../../hooks/useSavedSorting";
import { CustomerDetails } from "../../api/customersApi";

interface ListOptions
  extends PagingOptions,
    SortingOptions<ProductPackageListItem> {
  searchTerm: string;
}

export function AddProjectPackageModal({
  addedPackages,
  customer,
  projectId,
  onSuccess,
  onClose,
}: {
  customer: CustomerDetails;
  projectId: string;
  addedPackages: ProjectPackageListItem[];
  onClose: () => void;
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [addPackageError, setAddPackageError] = useState<ServerError>();

  const { savedSorting, setSavedSorting } =
    useSavedSorting<ProductPackageListItem>("AddProjectPackageModal", {
      sortingDesc: false,
      sortingKey: "name",
    });
  const [listOptions, setListOptions] = useState<ListOptions>({
    ...savedSorting,
    page: 1,
    pageSize: 8,
    searchTerm: "",
  });

  const [packages, packagesLoading, packagesError, fetch] = useApiRequest(
    productPackageApi.listProductPackages,
  );

  const [addPackageLoading, addPackageRequest] = useApiRequestCallback(
    projectsApi.addProjectPackage,
  );

  const fetchPackages = useCallback(
    (customerId: string) => {
      fetch({ customerId, ...listOptions });
    },
    [fetch, listOptions],
  );

  useEffect(() => {
    fetchPackages(customer.id);
  }, [customer.id, fetchPackages]);

  const addPackage = useCallback(
    (productPackageId: string) => {
      addPackageRequest({
        onSuccess: () => {
          setAddPackageError(undefined);
          onSuccess();
          toast({ title: t("general.added"), status: "success" });
        },
        onError: setAddPackageError,
      }).send(projectId, { productPackageId });
    },
    [addPackageRequest, onSuccess, projectId, t, toast],
  );

  const addedPackageIds = useMemo(
    () => new Set(addedPackages.map((p) => p.id)),
    [addedPackages],
  );

  const columns = useMemo<Column<ProductPackageListItem>[]>(
    () => [
      {
        key: "name",
        header: t("general.name"),
        sortable: true,
        cell: ({ row }) => {
          return (
            <>
              {customer.name} - {row.name}
            </>
          );
        },
      },
      {
        key: "actions",
        width: 150,
        cell: ({ row }) => {
          const isAdded = addedPackageIds.has(row.id);
          return isAdded ? (
            <Button size="sm" disabled={true} colorScheme="gray">
              <Icons.Check mr={1} />
              {t("general.added")}
            </Button>
          ) : (
            <Button onClick={() => addPackage(row.id)} size="sm">
              {t("general.add")}
            </Button>
          );
        },
      },
    ],
    [t, customer.name, addedPackageIds, addPackage],
  );

  return (
    <Modal size="2xl" isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t("general.add")} {t("productPackage.productPackage").toLowerCase()}
        </ModalHeader>
        <ModalBody>
          {packagesError && <ErrorDetails error={packagesError} />}
          {addPackageError && <ErrorDetails error={addPackageError} />}
          {addPackageLoading && <LoadingIndicator />}
          <Box mb={4}>
            <SearchFilterInput
              value={listOptions.searchTerm}
              onChange={(value) =>
                setListOptions((options) => ({
                  ...options,
                  searchTerm: value,
                  page: 1,
                }))
              }
            />
          </Box>
          <DataTable
            isLoading={packagesLoading}
            columns={columns}
            data={packages?.items}
            sorting={listOptions}
            paging={listOptions}
            totalItems={packages?.totalItems ?? 0}
            onSortingChange={(sorting) => {
              setListOptions((o) => ({ ...o, ...sorting }));
              setSavedSorting(sorting);
            }}
            onPagingChange={(paging) => {
              setListOptions((o) => ({ ...o, ...paging }));
            }}
          />
        </ModalBody>
        <ModalCloseButton />
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
}
