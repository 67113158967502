import { Button, Flex, HStack, Image } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { ReactNode } from "react";
import { useBobutikenLogoUrl } from "../../hooks/useBobutikenLogoUrl";
import { Icons } from "../shared/Icons";
import { useTranslation } from "react-i18next";

export function Header({
  customerSlug,
  projectSlug,
  extraContent,
  logoImage,
  hideLogo,
  showCleanAndStartOver = false,
}: {
  customerSlug: string;
  projectSlug: string;
  extraContent?: ReactNode;
  logoImage?: string;
  hideLogo?: boolean;
  showCleanAndStartOver?: boolean;
}) {
  const bobutikenLogoUrl = useBobutikenLogoUrl();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      flexShrink={0}
      h="8vh"
      maxH="20"
      px={8}
    >
      <HStack alignItems="center" spacing={{ base: 4, xl: 12 }}>
        {!hideLogo && (
          <Link to={routes.externalProject(customerSlug, projectSlug)}>
            <Image src={bobutikenLogoUrl} alt="MODEXA" maxW="20vw" maxH="4vh" />
          </Link>
        )}
        {showCleanAndStartOver && (
          <Button
            variant="link"
            as="a"
            href={routes.externalProject(customerSlug, projectSlug)}
            fontSize="xs"
            display="flex"
            alignItems="center"
          >
            <Icons.ArrowGoBack mr="2" boxSize={5} />
            {t("general.cleanAndStartOver")}
          </Button>
        )}
      </HStack>
      <Flex gridGap={{ base: 2, xl: 10 }} alignItems="center">
        {extraContent}
        {logoImage && (
          <Image
            cursor="pointer"
            onClick={() =>
              navigate(routes.externalProject(customerSlug, projectSlug))
            }
            src={logoImage}
            alt="logo"
            maxH="4vh"
            maxW="20vw"
            borderRadius="5px"
          />
        )}
      </Flex>
    </Flex>
  );
}
