import { Flex, Box, Button } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  productVariantsApi,
  ProductVariantDetails,
} from "../../../api/productVariantsApi";
import { useApiRequest } from "../../../hooks/useApi/useApiRequest";
import { useConfirmation } from "../../../hooks/useConfirmation";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { Icons } from "../../shared/Icons";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { Card } from "../../shared/Card";
import CreateProductVariantModal from "./CreateProductVariantModal";
import { ProductVariantDetailsView } from "./ProductVariantDetailsView";
import { ProductVariantList } from "./ProductVariantList";
import { ProductDetails } from "../../../api/productsApi";
import { usePermission } from "../../../hooks/usePermission";
import { Permission } from "../../../types";

export function ProductVariants({ product }: { product: ProductDetails }) {
  const { t } = useTranslation();
  const [isVariantDirty, setVariantDirty] = useState(false);
  const hasPermission = usePermission(Permission.ManageProducts);
  const [selectedVariantId, setSelectedVariantId] = useState<string | null>();
  const [
    createProductVariantModalVisible,
    setCreateProductVariantModalVisible,
  ] = useState<boolean>(false);

  const [productVariants, loading, error, fetchProductVariants] = useApiRequest(
    productVariantsApi.listProductVariants,
  );

  const [confirm, ConfirmationModal] = useConfirmation();

  useEffect(() => {
    fetchProductVariants(product.id);
  }, [fetchProductVariants, product.id]);

  useEffect(() => {
    if (!selectedVariantId && productVariants?.length) {
      setSelectedVariantId(productVariants[0].id);
    }
  }, [productVariants, selectedVariantId]);

  function handleCreateProductVariantSuccess(
    productVariant: ProductVariantDetails,
  ) {
    setCreateProductVariantModalVisible(false);
    fetchProductVariants(product.id);
    setSelectedVariantId(productVariant.id);
  }

  function handleSelectVariantId(variantId: string) {
    if (isVariantDirty) {
      confirm({
        message: t("general.unsavedChangesConfirmation"),
        confirmButtonText: t("general.continue"),
        confirmButtonColor: "red",
        onConfirmation: () => {
          setSelectedVariantId(variantId);
        },
      });
    } else {
      setSelectedVariantId(variantId);
    }
  }

  function handleCreateVariant() {
    if (isVariantDirty) {
      confirm({
        message: t("general.unsavedChangesConfirmation"),
        confirmButtonText: t("general.continue"),
        confirmButtonColor: "red",
        onConfirmation: () => {
          setCreateProductVariantModalVisible(true);
        },
      });
    } else {
      setCreateProductVariantModalVisible(true);
    }
  }

  if (error) {
    return <ErrorDetails error={error} />;
  }

  if (!selectedVariantId || !productVariants) {
    return null;
  }

  return (
    <>
      <Flex>
        <Card w="60" minH="xl">
          {loading && <LoadingIndicator />}
          <ProductVariantList
            selectedVariantId={selectedVariantId}
            productVariants={productVariants}
            onSelectVariantId={handleSelectVariantId}
          />
          <Box py={2} px={4}>
            <Button
              size="sm"
              variant="outline"
              isFullWidth={true}
              onClick={handleCreateVariant}
              isDisabled={!hasPermission}
            >
              <Icons.Add /> {t("general.add")}
            </Button>
          </Box>
        </Card>
        <Box flex="1" ml={3} position="relative">
          <ProductVariantDetailsView
            key={selectedVariantId}
            selectedVariantId={selectedVariantId}
            product={product}
            onUpdateSuccess={() => fetchProductVariants(product.id)}
            onIsDirtyChange={setVariantDirty}
          />
        </Box>
      </Flex>
      {createProductVariantModalVisible && (
        <CreateProductVariantModal
          productId={product.id}
          onClose={() => setCreateProductVariantModalVisible(false)}
          onSuccess={handleCreateProductVariantSuccess}
        />
      )}
      {ConfirmationModal}
    </>
  );
}
