import { useCallback, useEffect, useMemo, useState } from "react";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { ErrorDetails } from "../shared/ErrorDetails";
import { useTranslation } from "react-i18next";
import { Page } from "../shared/Page";
import { Card } from "../shared/Card";
import { Box, Button, HStack, IconButton } from "@chakra-ui/react";
import { WorkStepModal } from "./WorkStepModal";
import { PagingOptions, ServerError, SortingOptions } from "../../types";
import { Column, DataTable } from "../shared/DataTable/DataTable";
import { SearchFilterInput } from "../shared/SearchFilterInput";
import { WorkStepListItem, workStepsApi } from "../../api/workStepsApi";
import { Icons } from "../shared/Icons";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useGlobalPageSize } from "../../hooks/usePageSize";
import { useSavedSorting } from "../../hooks/useSavedSorting";

interface ListOptions extends PagingOptions, SortingOptions<WorkStepListItem> {
  searchTerm: string;
}

type WorkStepModalState =
  | { open: false }
  | { open: true; workStepId: string | null };

export function WorkStepsList() {
  const { t } = useTranslation();
  const [workStepModalState, setWorkStepModalState] =
    useState<WorkStepModalState>({
      open: false,
    });
  const [workStepToDelete, setWorkStepToDelete] = useState<WorkStepListItem>();
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const { savedSorting, setSavedSorting } = useSavedSorting<WorkStepListItem>(
    "WorkStepList",
    { sortingDesc: false, sortingKey: "name" },
  );
  const [listOptions, setListOptions] = useState<ListOptions>({
    ...savedSorting,
    page: 1,
    pageSize: globalPageSize,
    searchTerm: "",
  });

  const toast = useToastNotification();
  const [workSteps, loading, error, fetch] = useApiRequest(
    workStepsApi.listWorkSteps,
  );
  const [deleteError, setDeleteError] = useState<ServerError>();
  const [isDeleting, deleteRequest] = useApiRequestCallback(
    workStepsApi.deleteWorkStep,
  );

  const fetchWorkSteps = useCallback(() => {
    fetch({ ...listOptions });
  }, [fetch, listOptions]);

  useEffect(() => {
    fetchWorkSteps();
  }, [fetchWorkSteps]);

  const columns = useMemo<Column<WorkStepListItem>[]>(
    () => [
      {
        key: "name",
        header: t("general.name"),
        sortable: true,
        cell: ({ row }) => row.name,
      },
      {
        key: "group",
        header: t("productGroup.productGroup"),
        sortable: true,
        cell: ({ row }) => row.productGroupName,
      },
      {
        key: "actions",
        width: 150,
        cell: ({ row }) => (
          <HStack>
            <IconButton
              size="sm"
              variant="outline"
              aria-label="remove"
              icon={<Icons.Trash />}
              isDisabled={isDeleting}
              onClick={() => setWorkStepToDelete(row)}
            />
            <Button
              size="sm"
              onClick={() => {
                setWorkStepModalState({
                  open: true,
                  workStepId: row.id,
                });
              }}
            >
              {t("general.edit")}
            </Button>
          </HStack>
        ),
      },
    ],
    [t, isDeleting],
  );

  function deleteWorkStep(toDelete: WorkStepListItem) {
    deleteRequest({
      onSuccess: () => {
        setWorkStepToDelete(undefined);
        setDeleteError(undefined);
        fetchWorkSteps();
        toast({
          title: t("general.deleted"),
          status: "success",
        });
      },
      onError: (e) => {
        setWorkStepToDelete(undefined);
        setDeleteError(e);
      },
    }).send(toDelete.id);
  }

  return (
    <Page breadcrumbs={useBreadcrumbs()}>
      {loading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      {deleteError && <ErrorDetails error={deleteError} />}
      {workSteps && (
        <Card
          titleContent={t("workStep.workStep")}
          extraContent={
            <Button
              size="sm"
              onClick={() =>
                setWorkStepModalState({ open: true, workStepId: null })
              }
            >
              {t("general.add")}
            </Button>
          }
        >
          <Box mb={4}>
            <SearchFilterInput
              value={listOptions.searchTerm}
              onChange={(value) =>
                setListOptions((options) => ({
                  ...options,
                  searchTerm: value,
                  page: 1,
                }))
              }
            />
          </Box>
          {workStepToDelete && (
            <ConfirmationModal
              confirmButtonText={t("general.delete")}
              message={t("general.deleteConfirmation", {
                name: workStepToDelete.name,
              })}
              onClose={() => setWorkStepToDelete(undefined)}
              onConfirm={() => deleteWorkStep(workStepToDelete)}
              confirmButtonColor="red"
            />
          )}
          <DataTable
            isLoading={loading}
            columns={columns}
            data={workSteps?.items}
            sorting={listOptions}
            paging={listOptions}
            totalItems={workSteps?.totalItems ?? 0}
            onSortingChange={(sorting) => {
              setListOptions((o) => ({ ...o, ...sorting }));
              setSavedSorting(sorting);
            }}
            onPagingChange={(paging) => {
              setListOptions((o) => ({ ...o, ...paging }));
              setGlobalPageSize(paging.pageSize);
            }}
          />
        </Card>
      )}

      {workStepModalState.open && (
        <WorkStepModal
          workStepId={workStepModalState.workStepId}
          onClose={() => setWorkStepModalState({ open: false })}
          onSuccess={() => {
            fetchWorkSteps();
            setWorkStepModalState({ open: false });
          }}
        />
      )}
    </Page>
  );
}

function useBreadcrumbs() {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("workStep.workSteps"),
      },
    ],
    [t],
  );
}
