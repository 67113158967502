import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CustomerListItem,
  customersApi,
  LicenseType,
} from "../../api/customersApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { ErrorDetails } from "../shared/ErrorDetails";
import { useTranslation } from "react-i18next";
import { Page } from "../shared/Page";
import { Card } from "../shared/Card";
import { Box, Button } from "@chakra-ui/react";
import { PagingOptions, SortingOptions } from "../../types";
import { Column, DataTable } from "../shared/DataTable/DataTable";
import { Link as RouterLink } from "react-router-dom";
import { SearchFilterInput } from "../shared/SearchFilterInput";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { routes } from "../../routes";
import { useGlobalPageSize } from "../../hooks/usePageSize";
import { useSavedSorting } from "../../hooks/useSavedSorting";
import { Icons } from "../shared/Icons";
import { CustomerStatusBadge } from "./CustomerStatusBadge";

interface ListOptions extends PagingOptions, SortingOptions<CustomerListItem> {
  searchTerm: string;
  ignoreBobutikenFlags: boolean;
}

export function CustomerList() {
  const { t } = useTranslation();
  const [customers, loading, error, fetch] = useApiRequest(
    customersApi.listCustomers,
  );

  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const { savedSorting, setSavedSorting } = useSavedSorting<CustomerListItem>(
    "CustomerList",
    { sortingDesc: false, sortingKey: "name" },
  );
  const [listOptions, setListOptions] = useState<ListOptions>({
    ...savedSorting,
    page: 1,
    pageSize: globalPageSize,
    searchTerm: "",
    ignoreBobutikenFlags: false,
  });

  const fetchCustomers = useCallback(() => {
    fetch(listOptions);
  }, [fetch, listOptions]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  const columns: Column<CustomerListItem>[] = [
    {
      key: "name",
      header: t("general.name"),
      sortable: true,
    },
    {
      key: "license",
      header: t("customer.license"),
      sortable: true,
      cell: ({ row }) => (
        <>
          {row.licenseType === LicenseType.License && (
            <Icons.Check color="primary.500" boxSize={6} />
          )}
        </>
      ),
    },
    {
      key: "demo",
      header: t("customer.demo"),
      sortable: true,
      cell: ({ row }) => (
        <>
          {row.licenseType === LicenseType.Demo && (
            <Icons.Check color="primary.500" boxSize={6} />
          )}
        </>
      ),
    },
    {
      key: "region",
      header: t("region.region"),
      sortable: true,
    },
    {
      key: "seller",
      header: t("customer.seller"),
      sortable: true,
    },
    {
      key: "status",
      width: 200,
      header: t("general.status"),
      sortable: false,
      cell: ({ row }) => <CustomerStatusBadge status={row.status} />,
    },
    {
      key: "actions",
      align: "right",
      cell: ({ row }) => (
        <Button size="sm" as={RouterLink} to={routes.customerDetails(row.id)}>
          {t("general.edit")}
        </Button>
      ),
    },
  ];

  return (
    <Page breadcrumbs={useBreadcrumbs()} data-test-id="customer-list">
      {error && <ErrorDetails error={error} />}
      {customers && (
        <Card titleContent={t("customer.customers")}>
          <Box mb={4}>
            <SearchFilterInput
              value={listOptions.searchTerm}
              onChange={(value) =>
                setListOptions((options) => ({
                  ...options,
                  searchTerm: value,
                  page: 1,
                }))
              }
            />
          </Box>
          <DataTable
            columns={columns}
            totalItems={customers.totalItems ?? 0}
            isLoading={loading}
            onSortingChange={(sorting) => {
              setListOptions((o) => ({ ...o, ...sorting }));
              setSavedSorting(sorting);
            }}
            onPagingChange={(paging) => {
              setListOptions((o) => ({ ...o, ...paging }));
              setGlobalPageSize(paging.pageSize);
            }}
            sorting={listOptions}
            paging={listOptions}
            data={customers.items}
          />
        </Card>
      )}
    </Page>
  );
}

function useBreadcrumbs(): Breadcrumb[] {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("customer.customers"),
      },
    ],
    [t],
  );
}
