import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
  Button,
  ModalCloseButton,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  productPackageApi,
  ProductPackageStyle,
} from "../../api/productPackageApi";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { ServerError } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { ProductPackageStyleAsyncSelect } from "../productPackages/ProductPackageStyleAsyncSelect";

export function AddProductPackageStyleModal({
  productPackageId,
  customerId,
  onClose,
  onSuccess,
}: {
  productPackageId: string;
  customerId: string;
  onClose: () => void;
  onSuccess: (productPackageStyle: ProductPackageStyle) => void;
}) {
  const [error, setError] = useState<ServerError>();
  const { handleSubmit, control } = useForm<AddProductPackageStyle>();
  const [loading, request] = useApiRequestCallback(
    productPackageApi.createProductPackageStyle,
  );

  const { t } = useTranslation();
  function onSubmit(data: AddProductPackageStyle) {
    request({ onSuccess, onError: setError }).send(
      productPackageId,
      data.productPackageStyle.id,
    );
  }
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {error && <ErrorDetails error={error} />}
        {loading && <LoadingIndicator />}
        <ModalHeader>
          {t("general.add")} {t("productPackage.style").toLowerCase()}
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <ModalBody>
            <Box m="5">
              <FormControl id="productPackageStyle">
                <FormLabel>{t("productPackage.style")}</FormLabel>
                <Controller
                  name="productPackageStyle"
                  render={({ field, fieldState }) => (
                    <ProductPackageStyleAsyncSelect
                      customerId={customerId}
                      productPackageId={productPackageId}
                      isDisabled={false}
                      isClearable={true}
                      {...field}
                      {...fieldState}
                    />
                  )}
                  control={control}
                />
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button type="submit">{t("general.add")}</Button>
          </ModalFooter>
        </form>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}

interface AddProductPackageStyle {
  productPackageStyle: ProductPackageStyle;
}
