import { ChakraProps, Spinner, useTimeout } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { useState } from "react";

type Position = "fullscreen" | "overlay" | "block" | "center";

const stylingProps: Record<Position, ChakraProps> = {
  block: { display: "block" },
  overlay: {
    position: "absolute",
    inset: "0",
    zIndex: "overlay",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "whiteAlpha.700",
  },
  center: {
    position: "absolute",
    inset: "0",
    zIndex: "overlay",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fullscreen: {
    position: "fixed",
    width: "full",
    height: "full",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export function LoadingIndicator({
  position = "overlay",
  delay = 100,
}: {
  position?: Position;
  delay?: number;
}) {
  const [visible, setVisible] = useState<boolean>(false);
  useTimeout(() => setVisible(true), delay);
  return (
    <Box
      {...stylingProps[position]}
      visibility={visible ? "visible" : "hidden"}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="primary.500"
        size="xl"
      />
    </Box>
  );
}
