import { Box, Center, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { ServerError } from "../types";
import { ErrorDetails } from "./shared/ErrorDetails";

export function ErrorPage({ error }: { error: ServerError }) {
  return (
    <Center bg="white" height="100vh">
      <Stack
        direction={{ base: "column", md: "row" }}
        alignItems="center"
        spacing={8}
        shouldWrapChildren={true}
      >
        <Box color="red.300">
          <ErrorIcon />
        </Box>
        <Stack spacing={2}>
          <Heading size="md">Anslutningen misslyckades</Heading>
          <Text>Det gick inte att upprätta en anslutning till servern</Text>
          <ErrorDetails error={error} />
        </Stack>
      </Stack>
    </Center>
  );
}

function ErrorIcon() {
  return (
    <svg
      width={180}
      height={180}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M110.933 315.733H102.4c-51.841 0-93.867-42.025-93.867-93.866S50.56 128 102.4 128a87.893 87.893 0 0115.872 1.365 76.8 76.8 0 0166.304-44.032C201.368 33.376 253.473 1.365 307.41 9.87c53.938 8.504 93.666 54.994 93.657 109.598.17 2.984.17 5.975 0 8.96 2.901 0 5.973-.427 8.533-.427 51.841 0 93.867 42.026 93.867 93.867 0 51.84-42.026 93.866-93.867 93.866H110.933z"
          fill="currentColor"
          opacity={0.1}
        />
        <path
          d="M409.6 128c-2.987 0-6.059 0-8.533.427.17-2.985.17-5.976 0-8.96A110.933 110.933 0 00290.133 8.533c-4.248.038-8.492.295-12.714.768 55.894 6.537 98.039 53.89 98.048 110.166.17 2.984.17 5.975 0 8.96 2.901 0 5.973-.427 8.533-.427 48.404.156 88.753 37.092 93.173 85.294 4.42 48.202-28.538 91.862-76.106 100.818v1.621h8.533c51.841 0 93.867-42.025 93.867-93.866S461.44 128 409.6 128z"
          fill="currentColor"
          opacity={0.2}
        />
        <path
          d="M348.416 78.08a8.533 8.533 0 01-6.485-2.987A68.267 68.267 0 00290.133 51.2a8.533 8.533 0 010-17.067 85.333 85.333 0 0164.768 29.782 8.533 8.533 0 01-.938 12.032 8.533 8.533 0 01-5.547 2.133z"
          fill="#FFF"
        />
        <path
          d="M256 503.467h170.667a59.733 59.733 0 0049.92-91.99L305.92 154.54a59.733 59.733 0 00-99.67 0L35.5 411.307a59.733 59.733 0 0049.834 92.16H256z"
          fill="currentColor"
          opacity={0.1}
        />
        <path
          d="M476.33 411.563L305.665 154.624c-13.579-20.71-38.528-30.893-62.72-25.6a59.221 59.221 0 0137.12 25.6l170.667 256.939a59.733 59.733 0 01-49.664 91.904h25.6a59.733 59.733 0 0049.664-91.904z"
          fill="currentColor"
          opacity={0.2}
        />
        <path
          d="M85.333 469.333a25.003 25.003 0 01-22.613-13.397 24.49 24.49 0 011.195-25.6L234.58 173.653a25.6 25.6 0 0142.667 0l170.667 256.939a24.32 24.32 0 011.194 25.6 25.003 25.003 0 01-22.442 13.141H85.333z"
          fill="#FFF"
        />
        <path
          d="M447.83 430.421L277.162 173.483A25.6 25.6 0 00256 162.133a25.6 25.6 0 00-12.715 3.328 25.6 25.6 0 018.534 8.022L422.485 430.42a24.32 24.32 0 011.195 25.6 24.917 24.917 0 01-22.613 13.312h25.6a25.003 25.003 0 0022.613-13.397 24.32 24.32 0 00-1.45-25.515z"
          fill="currentColor"
          opacity={0.1}
        />
        <path
          d="M281.6 256c0 14.165-25.6 93.867-25.6 93.867S230.4 270.165 230.4 256c0-14.138 11.462-25.6 25.6-25.6 14.138 0 25.6 11.462 25.6 25.6z"
          fill="currentColor"
          opacity={0.3}
        />
        <circle
          fill="currentColor"
          opacity={0.3}
          cx={256}
          cy={409.6}
          r={25.6}
        />
        <path
          d="M101.035 311.723c-42.15-12.664-69.841-52.911-66.604-96.802 3.238-43.892 36.535-79.64 80.086-85.982-34.482-4.837-68.809 9.812-89.174 38.055-20.366 28.242-23.425 65.438-7.946 96.629 15.479 31.19 46.951 51.25 81.76 52.11"
          fill="#FFF"
        />
        <path
          d="M25.6 153.6l12.8 11.35a86.101 86.101 0 00-21.333 56.917H0A102.827 102.827 0 0125.6 153.6z"
          fill="#FFF"
        />
        <g fill="currentColor">
          <path d="M341.93 75.093a8.533 8.533 0 006.486 2.987 8.533 8.533 0 005.547-2.133 8.533 8.533 0 00.938-12.032 85.333 85.333 0 00-64.768-29.782 8.533 8.533 0 000 17.067 68.267 68.267 0 0151.798 23.893zM14.763 196.864a8.533 8.533 0 100-17.067 8.533 8.533 0 000 17.067z" />
          <path
            d="M512 221.867c0-56.554-45.846-102.4-102.4-102.4-.118-57.673-41.42-107.028-98.168-117.31C254.683-8.124 198.69 23.603 178.347 77.568a84.736 84.736 0 00-64.683 42.667 99.755 99.755 0 00-11.264-.768A102.4 102.4 0 0025.6 153.6a8.533 8.533 0 001.024 12.288 9.045 9.045 0 0011.776-.939 85.333 85.333 0 0164-28.416 84.224 84.224 0 0147.275 14.336 8.533 8.533 0 004.778 1.451 8.533 8.533 0 007.083-3.755 8.533 8.533 0 00-2.304-12.032 101.035 101.035 0 00-27.819-13.056 68.267 68.267 0 0153.846-29.61h1.877a69.035 69.035 0 0131.147 7.168 8.533 8.533 0 107.594-15.275 86.101 86.101 0 00-29.013-8.533c19.903-43.918 67.746-68 114.877-57.827 47.131 10.175 80.78 51.85 80.792 100.067v1.45a100.779 100.779 0 00-39.765 15.616 8.544 8.544 0 009.557 14.166 84.224 84.224 0 0147.275-14.166c47.128-2.05 86.995 34.493 89.045 81.622 2.05 47.128-34.493 86.995-81.621 89.045L313.003 149.93A68.267 68.267 0 00256 119.468a68.267 68.267 0 00-56.917 30.378L94.805 307.2c-44.14-3.944-77.914-41.018-77.738-85.333a8.533 8.533 0 10-17.067 0c-.197 49.627 35.42 92.166 84.31 100.693l-55.894 83.968A67.84 67.84 0 0085.333 512h341.334a68.267 68.267 0 0057.002-105.301L427.52 322.56c48.977-8.421 84.698-50.999 84.48-100.693zm-40.363 246.186a50.517 50.517 0 01-44.97 26.88H85.333a50.517 50.517 0 01-45.226-26.965A49.835 49.835 0 0142.667 416l170.666-256.683A51.2 51.2 0 01256 136.533a51.2 51.2 0 0142.667 22.87l170.666 256.853a49.664 49.664 0 012.304 51.797z"
            fillRule="nonzero"
          />
          <path
            d="M256 153.6a34.133 34.133 0 00-28.416 15.19L56.917 425.471a33.11 33.11 0 00-1.621 34.133 34.133 34.133 0 0030.037 18.262h341.334a34.133 34.133 0 0030.122-17.92 33.024 33.024 0 00-1.536-34.134L284.587 168.875A34.133 34.133 0 00256 153.6zm185.515 298.667a16.47 16.47 0 01-14.848 8.533H85.333a16.47 16.47 0 01-15.104-8.533 15.872 15.872 0 01.854-17.067l170.666-256.683a17.067 17.067 0 0128.587 0l170.667 256.854a15.701 15.701 0 01.512 16.896z"
            fillRule="nonzero"
          />
          <path
            d="M221.867 256c0 14.763 21.674 82.944 26.026 96.512a8.533 8.533 0 0016.214 0c4.352-13.568 26.026-81.75 26.026-96.512 0-18.851-15.282-34.133-34.133-34.133-18.851 0-34.133 15.282-34.133 34.133zm51.2 0A487.339 487.339 0 01256 321.28 487.339 487.339 0 01238.933 256c0-9.426 7.641-17.067 17.067-17.067s17.067 7.641 17.067 17.067zM256 375.467c-18.851 0-34.133 15.282-34.133 34.133 0 18.851 15.282 34.133 34.133 34.133 18.851 0 34.133-15.282 34.133-34.133 0-18.851-15.282-34.133-34.133-34.133zm0 51.2c-9.426 0-17.067-7.641-17.067-17.067s7.641-17.067 17.067-17.067 17.067 7.641 17.067 17.067-7.641 17.067-17.067 17.067z"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  );
}
