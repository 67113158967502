import { Box, IconButton } from "@chakra-ui/react";
import { Icons } from "../../shared/Icons";
import { useState } from "react";
import React from "react";
import {
  ScreenOrientation,
  useScreenOrientation,
} from "../../../hooks/useScreenOrientation";

export function Sidebar({ children }: { children: React.ReactNode }) {
  const isCollapsible = useScreenOrientation() === ScreenOrientation.Portrait;
  const [shouldCollapse, setShouldCollapse] = useState<boolean>(false);
  const isCollapsed = shouldCollapse;

  return (
    <Box
      position="relative"
      right="0"
      top="0"
      bottom="0"
      display="flex"
      zIndex="100"
    >
      {isCollapsible && (
        <IconButton
          position="absolute"
          right={isCollapsed ? "0px" : { base: "320px", xl: "510px" }}
          aria-label="hide menu"
          borderRightRadius="0"
          size="lg"
          top="30vh"
          onClick={() => setShouldCollapse((s) => !s)}
          colorScheme={isCollapsed ? "primary" : "blackAlpha"}
          icon={
            isCollapsed ? (
              <Icons.ArrowLeft boxSize={8} />
            ) : (
              <Icons.ArrowRight boxSize={8} />
            )
          }
        />
      )}

      {(!isCollapsed && isCollapsible && children) ||
        (!isCollapsible && children)}
    </Box>
  );
}
