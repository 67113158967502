import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ErrorDetails } from "../shared/ErrorDetails";
import { ServerError } from "../../types";
import { useToastNotification } from "../../hooks/useToastNotification";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { regionApi, RegionDetails } from "../../api/regionApi";

export function RegionModal({
  regionId,
  onSuccess,
  onClose,
}: {
  regionId: string | null;
  onSuccess: () => void;
  onClose: () => void;
}) {
  const [region, loading, error, fetch] = useApiRequest(
    regionApi.getRegionDetails,
  );

  useEffect(() => {
    if (regionId) {
      fetch(regionId);
    }
  }, [fetch, regionId]);

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {loading && <LoadingIndicator />}
        {error && <ErrorDetails error={error} />}
        {(!regionId || (regionId && region)) && (
          <ModalFormContent region={region} onSuccess={onSuccess} />
        )}
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}

interface CreateRegionFormData {
  name: string;
  pyramidId: string;
}

function ModalFormContent({
  region,
  onSuccess,
}: {
  region: RegionDetails | null;
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [error, setError] = useState<ServerError>();

  const [createRegionLoading, createRegionRequest] = useApiRequestCallback(
    regionApi.createRegion,
  );
  const [updateRegionLoading, updateRegionRequest] = useApiRequestCallback(
    regionApi.updateRegion,
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreateRegionFormData>({
    defaultValues:
      region != null
        ? {
            name: region.name,
            pyramidId: region.pyramidId,
          }
        : {},
  });

  async function onSubmit(data: CreateRegionFormData) {
    const request = {
      name: data.name,
      pyramidId: data.pyramidId,
    };
    if (region) {
      updateRegionRequest({
        onSuccess: () => {
          onSuccess();
          toast({ title: t("general.saved"), status: "success" });
        },
        onError: setError,
      }).send(region.id, request);
    } else {
      createRegionRequest({
        onSuccess: () => {
          onSuccess();
          toast({ title: t("general.saved"), status: "success" });
        },
        onError: setError,
      }).send(request);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <ModalHeader>
        {region
          ? region.name
          : `${t("general.add")} ${t("region.region").toLowerCase()}`}
      </ModalHeader>
      <ModalBody>
        {(createRegionLoading || updateRegionLoading) && <LoadingIndicator />}
        {error && <ErrorDetails error={error} />}
        <Stack spacing={4} shouldWrapChildren={true}>
          <FormControl
            id="pyramidId"
            isRequired={true}
            isInvalid={!!errors.pyramidId}
          >
            <FormControl
              id="pyramidId"
              isRequired={true}
              isInvalid={!!errors.pyramidId}
            >
              <FormLabel>{t("region.pyramidId")}</FormLabel>
              <Input
                {...register("pyramidId", {
                  required: { value: true, message: t("error.required") },
                })}
              />
              <FormErrorMessage>{errors.pyramidId?.message}</FormErrorMessage>
            </FormControl>
          </FormControl>
          <FormControl id="name" isRequired={true} isInvalid={!!errors.name}>
            <FormLabel>{t("general.name")}</FormLabel>
            <Input
              {...register("name", {
                required: { value: true, message: t("error.required") },
              })}
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button type="submit">{t("general.save")}</Button>
      </ModalFooter>
    </form>
  );
}
