import { ApiRequest } from "../hooks/useApi/types";
import { HttpClient } from "../hooks/useHttpClient/httpClient";
import { PagedItems, PagingOptions, SortingOptions } from "../types";

export const customerProductsApi = {
  listCustomerProductOptions:
    (
      request: ListCustomerProductsRequest,
    ): ApiRequest<PagedItems<CustomerProductOptionListItem>> =>
    (httpClient: HttpClient) =>
      httpClient.get("v1/customer-products/product-options", {
        params: request,
      }),
  listCustomerProducts:
    (
      request: ListCustomerProductsRequest,
    ): ApiRequest<PagedItems<CustomerProductListItem>> =>
    (httpClient: HttpClient) =>
      httpClient.get("v1/customer-products", { params: request }),
  updateCustomerAssortment:
    (request: UpdateCustomerAssortmentRequest): ApiRequest =>
    (httpClient: HttpClient) =>
      httpClient.post("/v1/customer-products", { data: request }),
};

export interface UpdateCustomerAssortmentRequest {
  customerId: string;
  variantsToAdd: string[];
  variantsToRemove: string[];
}
export interface ListCustomerProductsRequest
  extends SortingOptions<CustomerProductListItem>,
    PagingOptions {
  customerId: string;
  searchTerm?: string;
}
export interface CustomerProductListItem {
  id: string;
  name: string;
  productGroupName: string;
  imageThumbnailUrl: string | null;
  variants: CustomerProductVariantListItem[];
}

export interface CustomerProductVariantListItem {
  id: string;
  name: string;
  imageThumbnailUrl: string | null;
}

export interface CustomerProductOptionListItem {
  id: string;
  name: string;
  productGroupName: string;
  imageThumbnailUrl: string | null;
  variants: CustomerProductOptionVariantListItem[];
}

export interface CustomerProductOptionVariantListItem {
  id: string;
  name: string;
  inAssortment: boolean;
  imageThumbnailUrl: string | null;
}
