import { ErrorDetails } from "../shared/ErrorDetails";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Column, DataTable } from "../shared/DataTable/DataTable";
import { PagingOptions, ServerError, SortingOptions } from "../../types";
import { routes } from "../../routes";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useGlobalPageSize } from "../../hooks/usePageSize";
import { useSavedSorting } from "../../hooks/useSavedSorting";
import { useTranslation } from "react-i18next";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { Icons } from "../shared/Icons";
import { rolesApi, RoleListItem } from "../../api/rolesApi";
import { Button } from "@chakra-ui/button";
import { Page } from "../shared/Page";
import { Card } from "../shared/Card";
import { CreateRoleModal } from "./CreateRoleModal";
import { HStack, IconButton } from "@chakra-ui/react";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { useToastNotification } from "../../hooks/useToastNotification";
import { Link as RouterLink } from "react-router-dom";

interface ListOptions extends PagingOptions, SortingOptions<RoleListItem> {}

type ModalState = { open: false } | { open: true; regionId: string | null };

export function RoleListPage() {
  const { t } = useTranslation();
  const [error, setError] = useState<ServerError>();
  const [modalOpenState, setModalOpenState] = useState<ModalState>({
    open: false,
  });
  const { savedSorting, setSavedSorting } = useSavedSorting<RoleListItem>(
    "RoleListPage",
    { sortingDesc: false, sortingKey: "name" },
  );
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const [listOptions, setListOptions] = useState<ListOptions>({
    page: 1,
    pageSize: globalPageSize,
    ...savedSorting,
  });

  const [roles, isLoading, rolesError, fetch] = useApiRequest(
    rolesApi.listRoles,
  );

  const [isDeleting, deleteRequest] = useApiRequestCallback(
    rolesApi.deleteRole,
  );

  const fetchRoles = useCallback(() => {
    fetch({ ...listOptions });
  }, [fetch, listOptions]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const [roleToDelete, setRoleToDelete] = useState<RoleListItem>();
  const toast = useToastNotification();

  function removeRole(roleId: string) {
    setRoleToDelete(undefined);
    deleteRequest({
      onSuccess: () => {
        toast({
          title: t("general.deleted"),
          status: "success",
        });
        fetchRoles();
        setError(undefined);
      },
      onError: (e) => {
        setRoleToDelete(undefined);
        setError(e);
      },
    }).send(roleId);
  }

  const columns = useMemo<Column<RoleListItem>[]>(
    () => [
      {
        key: "name",
        header: t("role.role"),
        sortable: true,
      },
      {
        key: "actions",
        width: 150,
        cell: ({ row }) => (
          <HStack>
            <IconButton
              aria-label="remove"
              variant="outline"
              size="sm"
              icon={<Icons.Trash />}
              isDisabled={isDeleting}
              onClick={() => setRoleToDelete(row)}
            />
            <Button size="sm" as={RouterLink} to={routes.role(row.id)}>
              {t("general.edit")}
            </Button>
          </HStack>
        ),
      },
    ],
    [t, isDeleting],
  );

  function handleRegionSuccess() {
    setModalOpenState({ open: false });
    fetchRoles();
  }

  return (
    <Page breadcrumbs={useBreadcrumbs()}>
      <Card
        titleContent={t("role.rights")}
        extraContent={
          <Button
            size="sm"
            float="right"
            onClick={() => setModalOpenState({ open: true, regionId: null })}
          >
            {t("general.add")}
          </Button>
        }
      >
        {rolesError && <ErrorDetails error={rolesError} />}
        {error && <ErrorDetails error={error} />}

        {modalOpenState.open && (
          <CreateRoleModal
            onSuccess={() => handleRegionSuccess()}
            onClose={() => setModalOpenState({ open: false })}
          />
        )}
        <DataTable
          isLoading={isLoading}
          columns={columns}
          data={roles?.items}
          sorting={listOptions}
          paging={listOptions}
          totalItems={roles?.totalItems ?? 0}
          onSortingChange={(sorting) => {
            setListOptions((o) => ({ ...o, ...sorting }));
            setSavedSorting(sorting);
          }}
          onPagingChange={(paging) => {
            setListOptions((o) => ({ ...o, ...paging }));
            setGlobalPageSize(paging.pageSize);
          }}
        />
      </Card>
      {roleToDelete && (
        <ConfirmationModal
          message={t("general.deleteConfirmation", {
            name: roleToDelete.name,
          })}
          confirmButtonText={t("general.delete")}
          confirmButtonColor="red"
          onConfirm={() => removeRole(roleToDelete.id)}
          onClose={() => setRoleToDelete(undefined)}
        />
      )}
    </Page>
  );
}

function useBreadcrumbs() {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("general.settings"),
        to: routes.settings,
      },
      {
        label: t("role.rights"),
      },
    ],
    [t],
  );
}
