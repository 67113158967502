import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

export function useWindowHeight(delay = 500) {
  const [height, setHeight] = useState(window.visualViewport.height);
  const debouncedHandleResize = useDebouncedCallback(() => {
    const height = window.visualViewport.height;
    setHeight(height);
  }, delay);

  useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [debouncedHandleResize]);

  return height;
}
