import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { clientVersion } from "../constants";
import { useConfiguration } from "../hooks/useConfiguration";
import { initRaygun } from "../raygun";
import { HostEnvironment } from "../types";
import External from "./external/External";
import { Internal } from "./Internal";
import { ResetPassword } from "./ResetPassword";
import { ForgotPassword } from "./ForgotPassword";
import { ActivateUser } from "./ActivateUser";

export function App() {
  const configuration = useConfiguration();

  useEffect(() => {
    if (
      configuration.hostEnvironment === HostEnvironment.Production ||
      configuration.hostEnvironment === HostEnvironment.Staging
    ) {
      initRaygun(configuration.raygunApiKey, clientVersion);
    }
  }, [configuration]);

  return (
    <Routes>
      <Route path="kund/:customerSlug/:projectSlug/*" element={<External />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/activate" element={<ActivateUser />} />
      <Route path="admin/*" element={<Internal />} />
      <Route path="*" element={<Navigate to="/admin" replace={true} />} />
    </Routes>
  );
}
