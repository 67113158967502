import raygun4js, { RaygunStatic } from "raygun4js";

raygun4js("noConflict", true);
raygun4js("attach", false);

export function initRaygun(apiKey: string, clientVersion: string) {
  // Uses v1 API due to v2 can't be initiated after window load
  // Related Issue: https://github.com/MindscapeHQ/raygun4js/issues/383#issuecomment-767137879
  // V1 Docs: https://github.com/MindscapeHQ/raygun4js/blob/master/V1Documentation.md

  const raygun = (window as any).Raygun as RaygunStatic;
  raygun
    .init(apiKey, {
      ignoreAjaxError: true,
      ignoreAjaxAbort: true,
    })
    .attach()
    .setVersion(clientVersion);
}
