import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ProductGroupAsyncSelect } from "../shared/AsyncSelect/ProductGroupAsyncSelect";
import { ErrorDetails } from "../shared/ErrorDetails";
import { ServerError } from "../../types";
import { useToastNotification } from "../../hooks/useToastNotification";
import { WorkStepDetails, workStepsApi } from "../../api/workStepsApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";

export function WorkStepModal({
  workStepId,
  onSuccess,
  onClose,
}: {
  workStepId: string | null;
  onSuccess: (workStep: WorkStepDetails) => void;
  onClose: () => void;
}) {
  const [workStep, loading, error, fetch] = useApiRequest(
    workStepsApi.getWorkStep,
  );

  useEffect(() => {
    if (workStepId) {
      fetch(workStepId);
    }
  }, [fetch, workStepId]);

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {loading && <LoadingIndicator />}
        {error && <ErrorDetails error={error} />}
        {(!workStepId || (workStepId && workStep)) && (
          <ModalFormContent workStep={workStep} onSuccess={onSuccess} />
        )}
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}

interface CreateWorkStepFormData {
  name: string;
  isGeneral: boolean;
  productGroupId: string | null;
}

function ModalFormContent({
  workStep,
  onSuccess,
}: {
  workStep: WorkStepDetails | null;
  onSuccess: (workStep: WorkStepDetails) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [error, setError] = useState<ServerError>();

  const [createWorkStepLoading, createWorkStepRequest] = useApiRequestCallback(
    workStepsApi.createWorkStep,
  );
  const [updateWorkStepLoading, updateWorkStepRequest] = useApiRequestCallback(
    workStepsApi.updateWorkStep,
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
  } = useForm<CreateWorkStepFormData>({
    defaultValues:
      workStep != null
        ? {
            name: workStep.name,
            productGroupId: workStep.productGroupId,
            isGeneral: workStep.productGroupId == null,
          }
        : {},
  });

  const isGeneral = watch("isGeneral");

  function onSubmit(data: CreateWorkStepFormData) {
    const request = {
      name: data.name,
      productGroupId: !data.isGeneral ? data.productGroupId! : undefined,
    };
    if (workStep) {
      updateWorkStepRequest({
        onSuccess: (workStep) => {
          onSuccess(workStep);
          toast({ title: t("general.saved"), status: "success" });
        },
        onError: setError,
      }).send(workStep.id, request);
    } else {
      createWorkStepRequest({
        onSuccess: (workStep) => {
          onSuccess(workStep);
          toast({ title: t("general.saved"), status: "success" });
        },
        onError: setError,
      }).send(request);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <ModalHeader>
        {workStep
          ? workStep.name
          : `${t("general.add")} ${t("workStep.workStep").toLowerCase()}`}
      </ModalHeader>
      <ModalBody>
        {(createWorkStepLoading || updateWorkStepLoading) && (
          <LoadingIndicator />
        )}
        {error && <ErrorDetails error={error} />}
        <Stack spacing={4} shouldWrapChildren={true}>
          <FormControl id="name" isRequired={true} isInvalid={!!errors.name}>
            <FormLabel>{t("general.name")}</FormLabel>
            <Input
              {...register("name", {
                required: { value: true, message: t("error.required") },
              })}
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
          <Checkbox {...register("isGeneral")} isDisabled={!!workStep}>
            {t("workStep.generalWorkStep")}
          </Checkbox>
          {!isGeneral && (
            <FormControl
              id="productGroup"
              isInvalid={!!errors.productGroupId}
              isRequired={true}
            >
              <FormLabel>{t("productGroup.productGroup")}</FormLabel>
              <Controller
                name="productGroupId"
                render={({ field, fieldState }) => (
                  <ProductGroupAsyncSelect {...field} {...fieldState} />
                )}
                control={control}
                rules={{
                  required: { value: true, message: t("error.required") },
                }}
              />
              <FormErrorMessage>
                {errors.productGroupId?.message}
              </FormErrorMessage>
            </FormControl>
          )}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button type="submit">{t("general.save")}</Button>
      </ModalFooter>
    </form>
  );
}
