import {
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  Stack,
  SimpleGrid,
  Button,
  ModalFooter,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import placeholderImg from "../../../assets/external_placeholder.jpg";
import styleInfoImg from "../../../assets/styleInfoImage.jpg";
import { CustomModal } from "../../shared/CustomModal";
import { ImageCarousel } from "../../shared/ImageCarousel";

export function StyleInfoModal({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm: () => void;
}) {
  const { t } = useTranslation();

  return (
    <CustomModal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody overflow="auto" pr="8" pl="8" mt={10}>
          <SimpleGrid spacing="8" columns={{ base: 1, sm: 2 }}>
            <Box>
              <StyleInfoImages imageUrls={[styleInfoImg]} />
            </Box>
            <Stack overflow="auto" spacing={0}>
              <Heading>{t("style.stylesInfoTitle")}</Heading>
              <Text whiteSpace="pre-wrap">{t("style.stylesInfoLong")}</Text>
            </Stack>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onConfirm}>{t("style.stylesInfoConfirm")}</Button>
        </ModalFooter>
        <ModalCloseButton />
      </ModalContent>
    </CustomModal>
  );
}

function StyleInfoImages({ imageUrls }: { imageUrls: string[] }) {
  const images = useMemo(() => {
    const styleImageUrls = !!imageUrls ? imageUrls : [placeholderImg];

    const images = [...styleImageUrls];
    return images.length ? images : [placeholderImg];
  }, [imageUrls]);

  return <ImageCarousel images={images} imageMaxHeight="500px" />;
}
