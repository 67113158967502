import React from "react";
import { useEffect } from "react";
import { useApiRequest } from "../../../hooks/useApi/useApiRequest";
import { SharedSelectProps } from "../Select/types";
import { AsyncSelect } from "./AsyncSelect";
import { RoleListItem, rolesApi } from "../../../api/rolesApi";

interface RoleAsyncSelectProps extends SharedSelectProps {
  value: string | null;
  onChange?: (value: string | null) => void;
  onSelect?: (role: RoleListItem | null) => void;
}

export const RolesAsyncSelect = React.forwardRef(
  (
    { value, onChange, onSelect, ...sharedSelectProps }: RoleAsyncSelectProps,
    ref,
  ) => {
    const [roles, rolesLoading, rolesLoadingError, fetchRoles] = useApiRequest(
      rolesApi.listRoles,
    );
    const [role, roleLoading, roleError, fetchRole, setRole] = useApiRequest(
      rolesApi.getRolesDetails,
    );

    useEffect(() => {
      if (value === role?.id) {
        return;
      }
      if (!value) {
        setRole(null);
      } else {
        fetchRole(value);
      }
    }, [role?.id, value, fetchRole, setRole]);

    function handleChange(item: RoleListItem | null) {
      onChange?.(item?.id ?? null);
      onSelect?.(item);
    }

    return (
      <AsyncSelect
        innerRef={ref}
        value={role}
        valueError={roleError}
        options={roles}
        optionsLoading={rolesLoading}
        valueLoading={roleLoading}
        optionsError={rolesLoadingError}
        getOptionValue={(i) => i.id}
        getOptionLabel={(i) => i.name}
        onChange={handleChange}
        onSearchOptions={(params) =>
          fetchRoles({
            sortingDesc: false,
            sortingKey: "name",
            ...params,
          })
        }
        {...sharedSelectProps}
      />
    );
  },
);
