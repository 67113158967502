import { ApiRequest } from "../hooks/useApi/types";

import { PagedItems, PagingOptions, SortingOptions } from "../types";

export const productPackageApi = {
  listProductPackages:
    (
      request: ListProductPackagesRequest,
    ): ApiRequest<PagedItems<ProductPackageListItem>> =>
    (httpClient) =>
      httpClient.get("/v1/product-packages", { params: request }),

  createProductPackage:
    (request: CreateProductPackageRequest): ApiRequest<ProductPackageDetails> =>
    (httpClient) =>
      httpClient.post("/v1/product-packages", { data: request }),

  getProductPackageDetails:
    (id: string): ApiRequest<ProductPackageDetails> =>
    (httpClient) =>
      httpClient.get(`/v1/product-packages/${id}`),

  updateProductPackageDetails:
    (
      id: string,
      data: UpdateProductPackageRequest,
    ): ApiRequest<ProductPackageDetails> =>
    (httpClient) =>
      httpClient.put(`v1/product-packages/${id}`, { data }),

  uploadProductPackageImage:
    (
      productPackageId: string,
      file: File,
      productPackageImageType: ProductPackageImageType,
    ): ApiRequest<ProductPackageImage> =>
    (httpClient) => {
      const data = new FormData();
      data.append("file", file);
      return httpClient.post(
        `v1/product-packages/${productPackageId}/image/${productPackageImageType}`,
        {
          data,
        },
      );
    },

  deleteProductPackageImage:
    (
      productPackageId: string,
      productPackageImageType: ProductPackageImageType,
    ): ApiRequest<ProductPackageImage> =>
    (httpClient) =>
      httpClient.delete(
        `v1/product-packages/${productPackageId}/image/${productPackageImageType}`,
      ),

  getProductPackageProductOptions:
    (productPackageId: string): ApiRequest<ProductPackageProductOption[]> =>
    (httpClient) =>
      httpClient.get(
        `/v1/product-packages/${productPackageId}/product-options`,
      ),

  updateProductPackageProducts:
    (
      productPackageId: string,
      request: UpdateProductPackageProductsRequest,
    ): ApiRequest =>
    (httpClient) =>
      httpClient.post(`/v1/product-packages/${productPackageId}/products`, {
        data: request,
      }),

  listProductPackageStyles:
    (productPackageId: string): ApiRequest<ProductPackageStyle[]> =>
    (httpClient) =>
      httpClient.get(`v1/product-packages/${productPackageId}/styles`),

  createProductPackageStyle:
    (
      productPackageId: string,
      styleId: string,
    ): ApiRequest<ProductPackageStyle> =>
    (httpClient) =>
      httpClient.post(
        `v1/product-packages/${productPackageId}/styles/${styleId}`,
      ),

  removeProductPackageStyle:
    (productPackageId: string, styleId: string): ApiRequest =>
    (httpClient) =>
      httpClient.delete(
        `v1/product-packages/${productPackageId}/styles/${styleId}`,
      ),

  listProductPackageStyleOptions:
    (
      productPackageId: string,
      request: ListProductPackageStyleOptionsRequest,
    ): ApiRequest<ProductPackageStyle[]> =>
    (httpClient) =>
      httpClient.get(`v1/product-packages/${productPackageId}/styles-options`, {
        params: request,
      }),
};

export interface UpdateProductPackageProductsRequest {
  productVariantsToAddOrUpdate: PackageProductVariantUpdate[];
  productVariantsToRemove: string[];
  productPriceUpdates: PackageProductPriceUpdate[];
  productVariantPriceUpdates: PackageProductVariantPriceUpdate[];
}

export interface ProductPackageDetails {
  id: string;
  name: string;
  image: string | null;
  productPackageStyles: ProductPackageStyle[];
  otherOptionsImage: string | null;
}

export interface ProductPackageImage {
  image: string;
  productPackageImageType: ProductPackageImageType;
}

export enum ProductPackageImageType {
  Default = "Default",
  OtherOptions = "OtherOptions",
}

export interface ProductPackageListItem {
  id: string;
  name: string;
}

export interface ListProductPackagesRequest
  extends SortingOptions<ProductPackageListItem>,
    PagingOptions {
  customerId: string;
}

export interface CreateProductPackageRequest {
  customerId: string;
  name: string;
}

export interface ProductPackageProductOption {
  id: string;
  name: string;
  imageThumbnailUrl: string | null;
  productGroupId: string;
  productGroupName: string;
  variants: ProductPackageProductOptionVariant[];
  packageProductPrice: number | null;
}

export interface ProductPackageProductOptionVariant {
  id: string;
  name: string;
  isIncluded: boolean;
  isInProductPackage: boolean;
  imageThumbnailUrl: string | null;
  packageProductVariantPrice: number | null;
}

export interface UpdateProductPackageRequest {
  name: string;
  description: string;
  price: number | null;
}

export interface PackageProductPriceUpdate {
  productId: string;
  amount: number | null;
}

export interface PackageProductVariantPriceUpdate {
  productVariantId: string;
  amount: number | null;
}

export interface PackageProductVariantUpdate {
  productVariantId: string;
  include: boolean;
}

export interface ProductPackageStyle {
  id: string;
  name: string;
}

export interface ListProductPackageStyleOptionsRequest {
  customerId: string;
}
