import { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LandingPageImageType, projectsApi } from "../../../api/projectsApi";
import { useApiRequestCallback } from "../../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../../hooks/useToastNotification";
import { ServerError } from "../../../types";
import { ImageList } from "../../shared/ImageList";

export function LandingPageImageForm({
  projectId,
  image,
  landingPageImageType,
  onUpdate,
}: {
  projectId: string;
  image: string | null;
  landingPageImageType: LandingPageImageType;
  onUpdate: (image: string | null) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();

  const [uploadError, setUploadError] = useState<ServerError | null>(null);
  const [uploadLoading, uploadRequest] = useApiRequestCallback(
    projectsApi.uploadLandingPageImage,
  );

  const [deleteError, setDeleteError] = useState<ServerError | null>(null);
  const [deleteLoading, deleteRequest] = useApiRequestCallback(
    projectsApi.deleteLandingPageImage,
  );

  const uploadImage = useCallback(
    (image: File) => {
      uploadRequest({
        onSuccess: (response) => {
          setUploadError(null);
          toast({
            title: t("general.added"),
            status: "success",
          });
          onUpdate(response.image);
        },
        onError: setUploadError,
      }).send(projectId, image, landingPageImageType);
    },
    [uploadRequest, projectId, toast, t, onUpdate, landingPageImageType],
  );

  function deleteImage() {
    deleteRequest({
      onSuccess: () => {
        setDeleteError(null);
        toast({
          title: t("general.deleted"),
          status: "success",
        });
        onUpdate(null);
      },
      onError: setDeleteError,
    }).send(projectId, landingPageImageType);
  }

  const imageList = useMemo(
    () =>
      image
        ? [
            {
              id: "image",
              url: image,
            },
          ]
        : [],
    [image],
  );

  return (
    <ImageList
      maxImages={1}
      images={imageList}
      errors={[deleteError, uploadError]}
      isLoading={deleteLoading || uploadLoading}
      onUpload={uploadImage}
      onRemove={deleteImage}
    />
  );
}
