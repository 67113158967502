import { Box } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { useWindowHeight } from "../../hooks/useWindowHeight";

export interface ImageButtonProps {
  isSelected: boolean;
  text: string;
  imgUrl: string;
  onClick: () => void;
}

export function ImageButton({
  isSelected,
  text,
  imgUrl,
  onClick,
}: ImageButtonProps) {
  const windowHeight = useWindowHeight();

  return (
    <Box
      m="2"
      height={windowHeight < 700 ? "30px" : "80px"}
      width={windowHeight < 700 ? "80px" : "80px"}
      cursor="pointer"
      display="flex"
      flexDirection="column"
      alignItems="center"
      borderWidth={3}
      borderColor={isSelected ? "primary.500" : "primary.100"}
      borderStartStyle="solid"
      background={`url(${imgUrl})`}
      backgroundSize="cover"
      backgroundPosition="center"
      justifyContent="end"
      onClick={() => onClick()}
    >
      <Button
        fontSize="xs"
        height={windowHeight < 700 ? "30px" : "20px"}
        width="102%"
        borderRadius="0"
      >
        {text}
      </Button>
    </Box>
  );
}
