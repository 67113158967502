import { ServerError, Session } from "../../types";

export enum SessionStatus {
  Fetching,
  Creating,
  Inactive,
  Active,
  Error,
}

export type SessionState =
  | {
      status:
        | SessionStatus.Fetching
        | SessionStatus.Inactive
        | SessionStatus.Creating;
    }
  | {
      status: SessionStatus.Active;
      session: Session;
    }
  | {
      status: SessionStatus.Error;
      error: ServerError;
    };
