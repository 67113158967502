import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import { Sidebar, SidebarNavItem } from "../Sidebar";
import { ProductList } from "./ProductList";
import { Box, Flex } from "@chakra-ui/react";
import { ProductGroupList } from "./ProductGroupList";
import { ProductDetailsView } from "./ProductDetailsView/ProductDetailsView";
import { routes } from "../../routes";

export function Assortment() {
  const { t } = useTranslation();

  return (
    <Flex flex="1">
      <Sidebar heading={t("assortment.assortment")}>
        <SidebarNavItem to={routes.products} label={t("product.products")} />
        <SidebarNavItem
          to={routes.productGroups}
          label={t("productGroup.productGroups")}
        />
      </Sidebar>
      <Box flexGrow={1}>
        <Routes>
          <Route path="products/:productId" element={<ProductDetailsView />} />
          <Route path="products" element={<ProductList />} />
          <Route path="product-groups" element={<ProductGroupList />} />
          <Route
            path="*"
            element={<Navigate to={routes.products} replace={true} />}
          />
        </Routes>
      </Box>
    </Flex>
  );
}
