import { useLocation, useResolvedPath } from "react-router-dom";

// Hacky solution to check if the to path is active
// https://github.com/ReactTraining/react-router/issues/7552
export function useActivePath(to: string, end: boolean) {
  let location = useLocation();
  let path = useResolvedPath(to);

  return end
    ? location.pathname === path.pathname
    : location.pathname.startsWith(path.pathname);
}
