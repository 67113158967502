import { Radio, IconButton, Button, HStack, Box } from "@chakra-ui/react";
import { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ChangedWorkStep,
  StepPerformStatus,
  workPlansApi,
} from "../../../api/workPlansApi";
import { useApiRequestCallback } from "../../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../../hooks/useToastNotification";
import { ServerError } from "../../../types";
import { Card } from "../../shared/Card";
import { ConfirmationModal } from "../../shared/ConfirmationModal";
import { Column, DataTable } from "../../shared/DataTable/DataTable";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { Icons } from "../../shared/Icons";
import { SearchFilterInput } from "../../shared/SearchFilterInput";
import { EditWorkStepNoteModal } from "../EditWorkStepNoteModal";
import { EditNoteData } from "./types";
import { WorkSteps } from "./useWorkSteps";

interface ListItem {
  id: string;
  workStepId?: string;
  customWorkPlanStepId?: string;
  name: string;
  productGroupName: string;
  performStatus: StepPerformStatus;
  note: string;
}

export function WorkStepList({
  workPlanId,
  workSteps,
}: {
  workPlanId: string;
  workSteps: WorkSteps;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [editNoteData, setEditNoteData] = useState<EditNoteData | null>(null);
  const [customWorkPlanStepChanges, setCustomWorkPlanStepChanges] = useState<
    ChangedWorkStep[]
  >([]);
  const [workPlanStepChanges, setWorkPlanStepChanges] = useState<
    ChangedWorkStep[]
  >([]);

  const {
    workPlanSteps,
    isLoading,
    error,
    fetchWorkPlanSteps,
    setSavedSorting,
    setGlobalPageSize,
    listOptions,
    setListOptions,
  } = workSteps;

  const [saveError, setSaveError] = useState<ServerError>();
  const [isUpdating, updateWorkPlanSteps] = useApiRequestCallback(
    workPlansApi.updateWorkPlanSteps,
  );

  const updateChanges = useCallback(
    (
      stepItem: ListItem,
      change: { note?: string; status?: StepPerformStatus },
    ) => {
      const isWorkStepItem = stepItem.workStepId !== null ? true : false;
      const changeArray = isWorkStepItem
        ? workPlanStepChanges
        : customWorkPlanStepChanges;

      const sourceStep = workPlanSteps?.items.find(
        (it) => it.id === stepItem.id,
      );

      const idx = changeArray.findIndex((change) => change.id === stepItem.id);

      const changed = idx !== -1 ? { ...changeArray[idx] } : { ...stepItem };
      if (change.note !== undefined) {
        changed.note = change.note;
      }
      if (change.status !== undefined) {
        changed.performStatus = change.status;
      }

      let newChanges: ChangedWorkStep[] = [...changeArray];
      if (idx !== -1) {
        newChanges.splice(idx, 1);
        if (
          changed.performStatus !== sourceStep?.performStatus ||
          changed.note !== sourceStep?.note
        ) {
          newChanges = [...newChanges, changed];
        }
      } else {
        newChanges.push(changed);
      }

      isWorkStepItem
        ? setWorkPlanStepChanges(newChanges)
        : setCustomWorkPlanStepChanges(newChanges);
    },
    [
      workPlanStepChanges,
      customWorkPlanStepChanges,
      setWorkPlanStepChanges,
      setCustomWorkPlanStepChanges,
      workPlanSteps?.items,
    ],
  );

  const allWorkPlanSteps = useMemo((): ListItem[] | undefined => {
    return workPlanSteps?.items.map((step) => {
      const isWorkStepItem = step.workStepId !== null ? true : false;
      const changeArray = isWorkStepItem
        ? workPlanStepChanges
        : customWorkPlanStepChanges;
      const change = changeArray.find((it) => it.id === step.id);
      return {
        ...step,
        performStatus: change ? change.performStatus : step.performStatus,
        note: change ? change.note : step.note,
      };
    });
  }, [workPlanSteps?.items, workPlanStepChanges, customWorkPlanStepChanges]);

  function saveWorkPlanSteps() {
    updateWorkPlanSteps({
      onSuccess: () => {
        toast({
          title: t("general.saved"),
          status: "success",
        });
        fetchWorkPlanSteps().then(() => {
          setWorkPlanStepChanges([]);
          setCustomWorkPlanStepChanges([]);
        });
        setIsEditing(false);
        setSaveError(undefined);
      },
      onError: (err) => {
        setSaveError(err);
      },
    }).send(workPlanId, {
      workSteps: workPlanStepChanges,
      customWorkSteps: customWorkPlanStepChanges,
    });
  }

  function editNote(note: string, noteData: EditNoteData) {
    const stepItem = allWorkPlanSteps?.find((it) => it.id === noteData.id);
    if (stepItem) {
      updateChanges(stepItem, { note: note });
    }
    setEditNoteData(null);
  }

  const columns = useMemo<Column<ListItem>[]>(
    () => [
      {
        key: "name",
        header: t("general.name"),
        sortable: true,
      },
      {
        key: "productGroupName",
        header: t("productGroup.productGroup"),
        sortable: true,
      },
      {
        key: "perform",
        header: t("workPlan.perform"),
        cell: ({ row }) => (
          <Radio
            size="lg"
            name={row.id}
            onChange={() =>
              updateChanges(row, { status: StepPerformStatus.Perform })
            }
            isChecked={row.performStatus === StepPerformStatus.Perform}
            isDisabled={!isEditing}
          />
        ),
      },

      {
        key: "optionalAddition",
        header: t("workPlan.optionalAddition"),
        cell: ({ row }) => (
          <Radio
            size="lg"
            name={row.id}
            onChange={() =>
              updateChanges(row, { status: StepPerformStatus.OptionalAddition })
            }
            isChecked={row.performStatus === StepPerformStatus.OptionalAddition}
            isDisabled={!isEditing}
          />
        ),
      },
      {
        key: "whenNecessary",
        header: t("workPlan.whenNecessary"),
        cell: ({ row }) => (
          <Radio
            size="lg"
            name={row.id}
            onChange={() =>
              updateChanges(row, { status: StepPerformStatus.WhenNecessary })
            }
            isChecked={row.performStatus === StepPerformStatus.WhenNecessary}
            isDisabled={!isEditing}
          />
        ),
      },
      {
        key: "omit",
        header: t("workPlan.omit"),
        cell: ({ row }) => (
          <Radio
            size="lg"
            name={row.id}
            onChange={() =>
              updateChanges(row, { status: StepPerformStatus.Omit })
            }
            isChecked={row.performStatus === StepPerformStatus.Omit}
            isDisabled={!isEditing}
          />
        ),
      },
      {
        key: "actions",
        header: t("workPlan.note"),
        width: 150,
        cell: ({ row }) => (
          <IconButton
            variant={row.note ? "solid" : "outline"}
            aria-label="edit"
            icon={<Icons.Edit />}
            size="sm"
            onClick={() => setEditNoteData({ id: row.id, note: row.note })}
          />
        ),
      },
    ],
    [isEditing, t, updateChanges],
  );

  return (
    <>
      <Card
        titleContent={t("workStep.workSteps")}
        extraContent={
          <HStack>
            {isEditing ? (
              <Button
                size="sm"
                onClick={() => {
                  if (
                    workPlanStepChanges.length !== 0 ||
                    customWorkPlanStepChanges.length !== 0
                  ) {
                    setIsCancelling(true);
                  } else {
                    setWorkPlanStepChanges([]);
                    setCustomWorkPlanStepChanges([]);
                    setIsEditing(false);
                  }
                }}
              >
                {t("general.cancel")}
              </Button>
            ) : (
              <Button size="sm" onClick={() => setIsEditing(true)}>
                {t("general.edit")}
              </Button>
            )}

            {isEditing && (
              <Button
                size="sm"
                disabled={
                  (workPlanStepChanges.length === 0 &&
                    customWorkPlanStepChanges.length === 0) ||
                  isUpdating
                }
                onClick={() => {
                  saveWorkPlanSteps();
                }}
              >
                {t("general.save")}
              </Button>
            )}
          </HStack>
        }
      >
        {error && <ErrorDetails error={error} />}
        {saveError && <ErrorDetails error={saveError} />}
        <Box mb={4}>
          <SearchFilterInput
            value={listOptions.searchTerm}
            onChange={(value) =>
              setListOptions((options) => ({
                ...options,
                searchTerm: value,
                page: 1,
              }))
            }
          />
        </Box>
        <DataTable
          isLoading={isLoading || isUpdating}
          columns={columns}
          data={allWorkPlanSteps}
          sorting={listOptions}
          paging={listOptions}
          totalItems={workPlanSteps?.totalItems ?? 0}
          onSortingChange={(sorting) => {
            setListOptions((o) => ({ ...o, ...sorting }));
            setSavedSorting(sorting);
          }}
          onPagingChange={(paging) => {
            setListOptions((o) => ({ ...o, ...paging }));
            setGlobalPageSize(paging.pageSize);
          }}
        />
      </Card>
      {isCancelling && (
        <ConfirmationModal
          onClose={() => setIsCancelling(false)}
          onConfirm={() => {
            setWorkPlanStepChanges([]);
            setCustomWorkPlanStepChanges([]);
            setIsEditing(false);
            setIsCancelling(false);
          }}
          message={t("general.unsavedChangesConfirmation")}
          confirmButtonText={t("general.continue")}
          confirmButtonColor="red"
        />
      )}
      {editNoteData && (
        <EditWorkStepNoteModal
          currentNote={editNoteData.note}
          readonly={!isEditing}
          onClose={() => setEditNoteData(null)}
          onSuccess={(note: string) => editNote(note, editNoteData)}
        />
      )}
    </>
  );
}
