import { useTranslation } from "react-i18next";
import { Card } from "../shared/Card";
import { Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { Checkbox } from "@chakra-ui/react";
import { projectsApi, SceneType } from "../../api/projectsApi";
import { useEffect, useState } from "react";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { ErrorDetails } from "../shared/ErrorDetails";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { ServerError } from "../../types";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { getEnumValues } from "../../helpers";
import { useToastNotification } from "../../hooks/useToastNotification";

export function KitchenScenesCard({
  projectId,
  onChange,
}: {
  projectId: string;
  onChange: () => void;
}) {
  const { t } = useTranslation();

  const [scenes, scenesLoading, scenesError, scenesFetch, setScenes] =
    useApiRequest(projectsApi.getKitchenScenes);

  useEffect(() => {
    scenesFetch(projectId);
  }, [projectId, scenesFetch]);

  return (
    <Card titleContent={t("scenes.arrangements")}>
      {scenesLoading && <LoadingIndicator />}
      {scenesError && <ErrorDetails error={scenesError} />}
      <Table>
        <Tbody>
          {availableScenes.map((availableSceneType) => {
            return (
              <TableRow
                key={availableSceneType}
                projectId={projectId}
                sceneType={availableSceneType}
                hasScene={
                  scenes?.some((s) => s === availableSceneType) ?? false
                }
                onSceneAdded={(addedSceneType) => {
                  setScenes((prevState) =>
                    !prevState ? prevState : [...prevState, addedSceneType],
                  );
                  onChange();
                }}
                onSceneRemoved={(removedSceneType) => {
                  setScenes(
                    (prevState) =>
                      prevState?.filter((s) => s !== removedSceneType) ?? null,
                  );
                  onChange();
                }}
              />
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}

const TableRow = ({
  projectId,
  sceneType,
  hasScene,
  onSceneAdded,
  onSceneRemoved,
}: {
  projectId: string;
  sceneType: SceneType;
  hasScene: boolean;
  onSceneAdded: (scene: SceneType) => void;
  onSceneRemoved: (scene: SceneType) => void;
}) => {
  const { t } = useTranslation();
  const toast = useToastNotification();

  const [error, setError] = useState<ServerError>();
  const [addLoading, addRequest] = useApiRequestCallback(
    projectsApi.addKitchenScene,
  );
  const [removeLoading, removeRequest] = useApiRequestCallback(
    projectsApi.removeKitchenScene,
  );

  const onChecked = (isChecked: boolean) => {
    if (isChecked) {
      addKitchenScene();
    } else {
      removeKitchenScene();
    }
  };

  const addKitchenScene = () => {
    addRequest({
      onSuccess: () => {
        onSceneAdded(sceneType);
        toast({ title: t("general.saved"), status: "success" });
        setError(undefined);
      },
      onError: setError,
    }).send(projectId, sceneType);
  };

  const removeKitchenScene = () => {
    removeRequest({
      onSuccess: () => {
        onSceneRemoved(sceneType);
        toast({ title: t("general.deleted"), status: "success" });
        setError(undefined);
      },
      onError: setError,
    }).send(projectId, sceneType);
  };

  return (
    <Tr>
      <Td width="90%">{t(`scenes.${sceneType}`)}</Td>
      <Td>
        <Checkbox
          isChecked={hasScene}
          isDisabled={addLoading || removeLoading}
          onChange={(e) => onChecked(e.target.checked)}
        />
        {error && <ErrorDetails error={error} />}
      </Td>
    </Tr>
  );
};

const availableScenes = getEnumValues(SceneType);
