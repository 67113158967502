import { SimpleGrid } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  productPackageApi,
  ProductPackageImageType,
} from "../../api/productPackageApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Page } from "../shared/Page";
import { routes } from "../../routes";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useRequiredParams } from "../../hooks/useRequiredParams";
import { CustomerDetails } from "../../api/customersApi";
import { ProductPackageProductList } from "./ProductPackageProductList";
import { ProductPackageImageForm } from "./ProductPackageImageForm";
import { ProductPackageDetailsForm } from "./ProductPackageDetailsForm";
import { ProductPackagesStyleList } from "./ProductPackageStylesList";

export function ProductPackageDetailsView({
  customer,
}: {
  customer: CustomerDetails;
}) {
  const { productPackageId } = useRequiredParams("productPackageId");
  const { t } = useTranslation();
  const [productPackageDetails, isLoading, error, fetch, reset] = useApiRequest(
    productPackageApi.getProductPackageDetails,
  );

  useEffect(() => {
    fetch(productPackageId);
  }, [fetch, productPackageId]);

  return (
    <Page
      breadcrumbs={useBreadcrumbs(
        customer.id,
        customer.name,
        productPackageDetails?.name,
      )}
    >
      {isLoading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      {productPackageDetails && (
        <SimpleGrid spacing={6}>
          <ProductPackageDetailsForm
            productPackageDetails={productPackageDetails}
            onUpdate={reset}
          />
          <SimpleGrid columns={2} spacing={4}>
            <ProductPackageImageForm
              title={t("general.imagePackage")}
              image={productPackageDetails.image}
              productPackageId={productPackageDetails.id}
              productPackageImageType={ProductPackageImageType.Default}
              onUpdate={(image) =>
                reset({
                  ...productPackageDetails,
                  image: image,
                })
              }
            />
            <ProductPackageImageForm
              title={t("general.customizeBackground")}
              image={productPackageDetails.otherOptionsImage}
              productPackageId={productPackageDetails.id}
              productPackageImageType={ProductPackageImageType.OtherOptions}
              onUpdate={(image) =>
                reset({
                  ...productPackageDetails,
                  otherOptionsImage: image,
                })
              }
            />
          </SimpleGrid>
          <ProductPackageProductList
            productPackage={productPackageDetails}
            onUpdate={fetch}
          />
          <ProductPackagesStyleList
            productPackageId={productPackageId}
            productPackageDetails={productPackageDetails}
            onUpdate={fetch}
            customerId={customer.id}
          ></ProductPackagesStyleList>
        </SimpleGrid>
      )}
    </Page>
  );
}

function useBreadcrumbs(
  customerId: string,
  customerName: string,
  productPackageName?: string,
) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("customer.customers"),
        to: routes.customers,
      },
      {
        label: customerName,
        to: routes.customer(customerId),
      },
      {
        label: t("productPackage.productPackages"),
        to: routes.productPackages(customerId),
      },
      { label: productPackageName },
    ],
    [customerName, productPackageName, customerId, t],
  );
}
