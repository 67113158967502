import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authApi } from "../api/authApi";
import { useApiRequestCallback } from "../hooks/useApi/useApiRequestCallback";
import { useBobutikenLogoUrl } from "../hooks/useBobutikenLogoUrl";
import { useToastNotification } from "../hooks/useToastNotification";
import { ServerError } from "../types";
import { ErrorDetails } from "./shared/ErrorDetails";
import { Image } from "./shared/Image";
import { LoadingIndicator } from "./shared/LoadingIndicator";

export function ResetPassword() {
  const [searchParams] = useSearchParams();

  const [params] = useState(() => ({
    userId: searchParams.get("userId"),
    token: searchParams.get("token"),
  }));

  if (!params.token) {
    return <Text>Error: Missing token</Text>;
  }

  if (!params.userId) {
    return <Text>Error: Missing userId</Text>;
  }

  return <ResetPasswordForm userId={params.userId} token={params.token} />;
}

interface FormData {
  password: string;
  confirmPassword: string;
}

function ResetPasswordForm({
  userId,
  token,
}: {
  userId: string;
  token: string;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const navigate = useNavigate();
  const bobutikenLogoUrl = useBobutikenLogoUrl();
  const [resetPasswordError, setResetError] = useState<ServerError>();

  const [isLoading, request] = useApiRequestCallback(authApi.resetPassword);

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = useForm<FormData>({ mode: "onTouched" });

  function handleSaveSuccess() {
    toast({ title: t("general.saved"), status: "success" });
    navigate("/", { replace: true });
  }

  function submit(data: FormData) {
    request({ onSuccess: handleSaveSuccess, onError: setResetError }).send({
      newPassword: data.password,
      userId: userId,
      token: token,
    });
  }

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      bg="primary.50"
      direction="column"
    >
      <Image w="72" src={bobutikenLogoUrl} alt="logo" />
      <Box
        w="full"
        maxW="md"
        bg="white"
        rounded="xl"
        boxShadow="lg"
        p={6}
        my={10}
      >
        {isLoading && <LoadingIndicator />}
        <Heading fontSize="2xl">{t("password.newPassword")}</Heading>
        <form onSubmit={handleSubmit(submit)} autoComplete="off">
          <Stack spacing={4} mt={4}>
            {resetPasswordError && <ErrorDetails error={resetPasswordError} />}
            <FormControl isInvalid={!!errors.password} isRequired={true}>
              <FormLabel htmlFor="password">{t("password.password")}</FormLabel>
              <Input
                id="password"
                type="password"
                {...register("password", {
                  required: {
                    value: true,
                    message: t("error.required"),
                  },
                  minLength: {
                    value: 8,
                    message: t("error.minLength", { value: 8 }),
                  },
                })}
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.confirmPassword} isRequired={true}>
              <FormLabel htmlFor="confirmPassword">
                {t("password.confirmPassword")}
              </FormLabel>
              <Input
                id="confirmPassword"
                type="password"
                {...register("confirmPassword", {
                  validate: (value) =>
                    value === getValues("password") || t("error.matching"),
                })}
              />
              <FormErrorMessage>
                {errors.confirmPassword?.message}
              </FormErrorMessage>
            </FormControl>
            <Button type="submit">{t("general.save")}</Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
}
