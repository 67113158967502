import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UserDetails, usersApi } from "../../api/usersApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { ServerError } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Card } from "../shared/Card";
import { Page } from "../shared/Page";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { routes } from "../../routes";
import { useRequiredParams } from "../../hooks/useRequiredParams";
import { Icons } from "../shared/Icons";
import { useToastNotification } from "../../hooks/useToastNotification";
import { RolesAsyncSelect } from "../shared/AsyncSelect/RolesAsyncSelect";

export function UserDetailsView() {
  const { userId } = useRequiredParams("userId");
  const [user, loading, error, fetch] = useApiRequest(usersApi.getUserDetails);

  useEffect(() => {
    fetch(userId);
  }, [fetch, userId]);

  return (
    <Page breadcrumbs={useBreadcrumbs(user?.email)}>
      {loading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}

      {user && (
        <UserDetailsContent user={user} onUpdateSuccess={() => fetch(userId)} />
      )}
    </Page>
  );
}

interface UserDetailsFormData {
  email: string;
  firstName: string;
  lastName: string;
  roleId: string;
}

function UserDetailsContent({
  user,
  onUpdateSuccess,
}: {
  user: UserDetails;
  onUpdateSuccess: () => void;
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<ServerError>();

  const [updateUserLoading, updateUserRequest] = useApiRequestCallback(
    usersApi.updateUser,
  );

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    reset,
    control,
  } = useForm<UserDetailsFormData>({ defaultValues: user });

  useEffect(() => {
    reset(user);
  }, [user, reset]);

  async function onSubmit(data: UserDetailsFormData) {
    updateUserRequest({
      onSuccess: () => {
        onUpdateSuccess();
        setError(undefined);
      },
      onError: setError,
    }).send(user.id, {
      firstName: data.firstName,
      lastName: data.lastName,
      roleId: data.roleId,
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {updateUserLoading && <LoadingIndicator />}
      <Card>
        {error && <ErrorDetails error={error} />}
        <Stack spacing={4} shouldWrapChildren={true}>
          <FormControl isInvalid={!!errors.email} isRequired={false}>
            <FormLabel htmlFor="email">{t("user.email")}</FormLabel>
            <Input
              id="email"
              type="email"
              disabled={true}
              {...register("email")}
            />
          </FormControl>
          <FormControl
            id="firstName"
            isRequired={true}
            isInvalid={!!errors.firstName}
          >
            <FormLabel>{t("user.firstName")}</FormLabel>
            <Input
              {...register("firstName", {
                required: { value: true, message: t("error.required") },
              })}
            />
            <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
          </FormControl>
          <FormControl
            id="lastName"
            isRequired={true}
            isInvalid={!!errors.lastName}
          >
            <FormLabel>{t("user.lastName")}</FormLabel>
            <Input
              {...register("lastName", {
                required: { value: true, message: t("error.required") },
              })}
            />
            <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
          </FormControl>
          <FormControl
            id="roleId"
            isRequired={true}
            isInvalid={!!errors.roleId}
          >
            <FormLabel>{t("role.role")}</FormLabel>
            <Controller
              name="roleId"
              render={({ field, fieldState }) => (
                <RolesAsyncSelect {...field} {...fieldState} />
              )}
              control={control}
              rules={{
                required: { value: true, message: t("error.required") },
              }}
            />
          </FormControl>
          <FormErrorMessage>{errors.roleId?.message}</FormErrorMessage>
          <FormControl>
            <FormLabel>{t("general.status")}</FormLabel>
            <UserStatus user={user} />
          </FormControl>
          <Flex justifyContent="flex-end">
            <Button type="submit" disabled={!isDirty}>
              {t("general.save")}
            </Button>
          </Flex>
        </Stack>
      </Card>
    </form>
  );
}

function UserStatus({ user }: { user: UserDetails }) {
  const { t } = useTranslation();
  const [error, setError] = useState<ServerError>();
  const [sent, setSent] = useState(false);
  const toast = useToastNotification();

  const [sendActivateUserTokenLoading, sendActivateUserTokenRequest] =
    useApiRequestCallback(usersApi.sendActivateUserToken);

  function sendNewActivationMail() {
    sendActivateUserTokenRequest({
      onSuccess: () => {
        setSent(true);
        toast({ title: t("user.newActivationMailSent"), status: "success" });
      },
      onError: setError,
    }).send(user.id);
  }

  return user.activated ? (
    <HStack>
      <Icons.Check boxSize={5} />
      <Text>{t("user.activated")}</Text>
    </HStack>
  ) : (
    <>
      {error && <ErrorDetails error={error} />}
      <HStack>
        <Icons.MailCheck boxSize={5} />
        <Text>{t("user.activationMailSent")}</Text>
      </HStack>
      <Button
        mt={2}
        size="sm"
        onClick={sendNewActivationMail}
        isLoading={sendActivateUserTokenLoading}
        disabled={sendActivateUserTokenLoading || sent}
      >
        {sent ? (
          <HStack>
            <Icons.Check boxSize={5} />
            <Text>{t("user.newActivationMailSent")}</Text>
          </HStack>
        ) : (
          t("user.sendNewActivationMail")
        )}
      </Button>
    </>
  );
}

function useBreadcrumbs(email?: string) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("general.settings"),
        to: routes.settings,
      },
      {
        label: t("user.users"),
        to: routes.users,
      },
      {
        label: email,
      },
    ],
    [email, t],
  );
}
