import { ApiRequest } from "../hooks/useApi/types";
import { PagedItems, PagingOptions, SortingOptions } from "../types";

export const customersApi = {
  listCustomers:
    (request: ListCustomersRequest): ApiRequest<PagedItems<CustomerListItem>> =>
    (httpClient) =>
      httpClient.get("v1/customers", { params: request }),

  getCustomerDetails:
    (id: string): ApiRequest<CustomerDetails> =>
    (httpClient) =>
      httpClient.get(`v1/customers/${id}`),

  updateCustomerDetails:
    (id: string, data: UpdateCustomerRequest): ApiRequest<CustomerDetails> =>
    (httpClient) =>
      httpClient.put(`v1/customers/${id}`, { data }),

  getCustomerContactDetails:
    (id: string): ApiRequest<CustomerContactDetails> =>
    (httpClient) =>
      httpClient.get(`v1/customers/${id}/contact-details`),
};

export interface ListCustomersRequest
  extends SortingOptions<CustomerListItem>,
    PagingOptions {
  searchTerm?: string;
  ignoreBobutikenFlags: boolean;
}

export interface CustomerListItem {
  id: string;
  name: string;
  customerNumber: string;
  region: string;
  licenseType: LicenseType;
  seller: string;
  status: CustomerStatus;
}

export interface CustomerDetails {
  id: string;
  name: string;
  customerNumber: string;
  licenseType: LicenseType;
  licenseCost: number;
  billingFrequency: FrequencyType;
  otherLicenseInformation: string;
  licenseStartDate: string;
  apartmentInterval: ApartmentIntervalType;
  urlSlug: string;
  region: string;
  seller: string;
  status: CustomerStatus;
  organizationNumber: string;
  visitingAddress: string;
  visitingPostalCode: string;
  invoiceAddress: string;
  invoicePostalCode: string;
  customerReference: string;
  phoneNumber: string;
  email: string;
  completedCustomerDetails: boolean;
  completedAssortment: boolean;
  completedProductPackages: boolean;
  completedWorkPlans: boolean;
  completedProjects: boolean;
}

export interface UpdateCustomerRequest {
  licenseType: LicenseType;
  licenseCost: number;
  billingFrequency: FrequencyType;
  otherLicenseInformation: string;
  licenseStartDate: string;
  apartmentInterval: ApartmentIntervalType;
  urlSlug: string;
}

export interface CustomerContactDetails {
  fitterContactDetails: ContactDetails | null;
  sellerContactDetails: ContactDetails | null;
  contractOwnerContactDetails: ContactDetails | null;
}

export interface ContactDetails {
  name: string;
  email: string;
  phoneNumber: string;
}

export enum LicenseType {
  NoLicense = "NoLicense",
  License = "License",
  Demo = "Demo",
}

export enum CustomerStatus {
  Active = "Active",
  Inactive = "Inactive",
  New = "New",
}

export enum FrequencyType {
  None = "None",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Semiannually = "Semiannually",
  Annually = "Annually",
}

export enum ApartmentIntervalType {
  None = "None",
  ZeroToTwoThousand = "ZeroToTwoThousand",
  TwoThousandToEightThousand = "TwoThousandToEightThousand",
  EightThousandAndMore = "EightThousandAndMore",
}
