import { InputGroup, Input, InputRightElement } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { Icons } from "../shared/Icons";

export interface SearchFilterInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const SearchFilterInput = React.forwardRef<
  HTMLInputElement,
  SearchFilterInputProps
>((props, ref) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const inputValueRef = useRef("");

  function setInputValueWithRef(value: string) {
    inputValueRef.current = value;
    setInputValue(value);
  }

  useEffect(() => {
    if (props.value !== inputValueRef.current) {
      setInputValueWithRef(props.value);
    }
  }, [props.value]);

  const onChangeDebounced = useDebouncedCallback(props.onChange, 350);

  return (
    <InputGroup size="lg">
      <Input
        value={inputValue}
        ref={ref}
        placeholder={t("general.search")}
        borderStyle="solid"
        borderColor="secondary.500"
        onChange={(e) => {
          setInputValueWithRef(e.target.value);
          onChangeDebounced(e.target.value);
        }}
        type="search"
      />
      <InputRightElement>
        <Icons.Search color="secondary.500" boxSize={6} />
      </InputRightElement>
    </InputGroup>
  );
});
