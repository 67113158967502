import {
  Box,
  SystemStyleObject,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { Icons } from "../../shared/Icons";

export interface Step {
  label: string;
  complete: boolean;
  active: boolean;
  disabled: boolean;
  onSelect: () => void;
}

export function Steps({ steps }: { steps: Step[] }) {
  return (
    <Box display="flex">
      {steps.map((step, i) => (
        <StepComponent
          key={i}
          label={step.label}
          position={i + 1}
          complete={step.complete}
          active={step.active}
          first={i === 0}
          disabled={step.disabled}
          onClick={step.onSelect}
        />
      ))}
    </Box>
  );
}

const baseBackground: SystemStyleObject = {
  content: '""',
  position: "absolute",
  left: "0",
  right: "-1px",
  height: "50%",
  borderColor: "secondary.300",
  borderStyle: "solid",
  borderLeftWidth: 2,
  borderRightWidth: 2,
  zIndex: -2,
};

const topBackground: SystemStyleObject = {
  ...baseBackground,
  top: 0,
  transform: "skew(15deg)",
  borderTopWidth: 2,
};

const bottomBackground: SystemStyleObject = {
  ...baseBackground,
  bottom: 0,
  transform: "skew(-15deg)",
  borderBottomWidth: 2,
};

function StepComponent({
  position,
  label,
  complete,
  active,
  first,
  disabled,
  onClick,
}: {
  position: number;
  label: string;
  complete: boolean;
  active: boolean;
  first: boolean;
  disabled: boolean;
  onClick: () => void;
}) {
  const bg = active ? "secondary.200" : "secondary.100";
  const leftOffset = first ? "-20px" : 0;
  const isSmallScreen = useBreakpointValue({ base: true, lg: false });

  function handleClick() {
    if (!disabled) {
      onClick();
    }
  }

  return (
    <Box
      cursor={disabled ? "default" : "pointer"}
      flex={!isSmallScreen || active ? 1 : 0}
      minWidth={10}
      onClick={handleClick}
      color="secondary.700"
      height={16}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="relative"
      fontWeight={active ? "bold" : "normal"}
      _before={{ ...topBackground, left: leftOffset, bg: bg }}
      _after={{ ...bottomBackground, left: leftOffset, bg: bg }}
    >
      <VStack h={6} fontSize="md">
        {complete ? (
          <Icons.CheckboxCircle color="primary.500" h={6} w={6} />
        ) : (
          <Box>{position}</Box>
        )}
      </VStack>
      {(!isSmallScreen || (isSmallScreen && active)) && (
        <Text whiteSpace="nowrap" fontSize="sm">
          {label}
        </Text>
      )}
    </Box>
  );
}
