import { useEffect, useRef, useState } from "react";
import { ServerError, ServerErrorCode } from "../types";

export enum ImageLoaderStatus {
  Loading,
  Error,
  Loaded,
}

type State =
  | {
      status: ImageLoaderStatus.Loading;
      imgSrc?: string;
    }
  | {
      status: ImageLoaderStatus.Error;
      imgSrc?: string;
      error: ServerError;
    }
  | {
      status: ImageLoaderStatus.Loaded;
      imgSrc: string;
    };

export function useImageLoader(imageUrl: string) {
  const latestRequestSymbol = useRef<symbol>();

  const [state, setState] = useState<State>({
    status: ImageLoaderStatus.Loading,
  });

  useEffect(() => {
    if (imageUrl === "") return;
    const currentRequestSymbol = Symbol();
    latestRequestSymbol.current = currentRequestSymbol;

    setState((s) => ({ ...s, status: ImageLoaderStatus.Loading }));
    const image = new Image();
    image.src = imageUrl;
    image
      .decode()
      .then(() => {
        if (currentRequestSymbol !== latestRequestSymbol.current) {
          return;
        }

        setState({ status: ImageLoaderStatus.Loaded, imgSrc: image.src });
      })
      .catch(() => {
        if (currentRequestSymbol !== latestRequestSymbol.current) {
          return;
        }

        setState({
          status: ImageLoaderStatus.Error,
          error: {
            errorCode: ServerErrorCode.Unknown,
            status: 0,
          },
        });
      });
  }, [imageUrl]);

  return state;
}
