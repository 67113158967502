import { Button } from "@chakra-ui/button";
import { Box, HStack, IconButton } from "@chakra-ui/react";
import { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CustomerDetails } from "../../api/customersApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { PagingOptions, ServerError, SortingOptions } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Icons } from "../shared/Icons";
import { Card } from "../shared/Card";
import { Page } from "../shared/Page";
import { Link as RouterLink } from "react-router-dom";
import {
  WorkPlanListItem,
  workPlansApi,
  WorkPlanStatus,
} from "../../api/workPlansApi";
import { Column, DataTable } from "../shared/DataTable/DataTable";
import CreateWorkPlanModal from "./CreateWorkPlanModal";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { routes } from "../../routes";
import { SearchFilterInput } from "../shared/SearchFilterInput";
import { useGlobalPageSize } from "../../hooks/usePageSize";
import { useSavedSorting } from "../../hooks/useSavedSorting";
import { baseApiUrl } from "../../constants";
import { StatusBadge } from "../shared/StatusBadges/StatusBadge";

interface ListOptions extends PagingOptions, SortingOptions<WorkPlanListItem> {
  searchTerm: string;
}

export function WorkPlanList({
  customer,
  onUpdate,
}: {
  customer: CustomerDetails;
  onUpdate: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [workPlanToDelete, setWorkPlanToDelete] = useState<WorkPlanListItem>();
  const [selectedWorkPlan, setSelectedWorkPlan] = useState<WorkPlanListItem>();
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const [deleteError, setDeleteError] = useState<ServerError>();
  const { savedSorting, setSavedSorting } = useSavedSorting<WorkPlanListItem>(
    "WorkPlanList",
    { sortingDesc: false, sortingKey: "name" },
  );
  const [listOptions, setListOptions] = useState<ListOptions>({
    ...savedSorting,
    page: 1,
    pageSize: globalPageSize,
    searchTerm: "",
  });
  const [workPlans, loading, error, fetch] = useApiRequest(
    workPlansApi.listWorkPlans,
  );
  const [isDeleting, deleteRequest] = useApiRequestCallback(
    workPlansApi.deleteWorkPlan,
  );

  const fetchWorkPlans = useCallback(() => {
    fetch({ customerId: customer.id, ...listOptions });
  }, [fetch, customer.id, listOptions]);

  useEffect(() => {
    fetchWorkPlans();
  }, [fetchWorkPlans]);

  function deleteWorkPlan(toDelete: WorkPlanListItem) {
    deleteRequest({
      onSuccess: () => {
        setWorkPlanToDelete(undefined);
        setDeleteError(undefined);
        fetchWorkPlans();
        onUpdate();
        toast({
          title: t("general.deleted"),
          status: "success",
        });
      },
      onError: (e) => {
        setWorkPlanToDelete(undefined);
        setDeleteError(e);
      },
    }).send(toDelete.id);
  }

  const columns = useMemo<Column<WorkPlanListItem>[]>(
    () => [
      {
        key: "name",
        header: t("general.name"),
        sortable: true,
        cell: ({ row }) => `${customer.name} - ${row.name}`,
      },
      {
        key: "version",
        header: t("general.version"),
        sortable: false,
      },
      {
        key: "status",
        header: t("general.status"),
        width: 200,
        sortable: true,
        cell: ({ row }) => <WorkPlanStatusBadge status={row.status} />,
      },
      {
        key: "actions",
        width: 150,
        cell: ({ row }) => (
          <HStack>
            <IconButton
              size="sm"
              variant="outline"
              aria-label="remove"
              icon={<Icons.Trash />}
              isDisabled={isDeleting}
              onClick={() => setWorkPlanToDelete(row)}
            />
            <IconButton
              size="sm"
              variant="outline"
              aria-label="copy"
              icon={<Icons.Copy />}
              isDisabled={isDeleting}
              onClick={() => {
                setSelectedWorkPlan(row);
                setAddModalVisible(true);
              }}
            />
            <IconButton
              as="a"
              href={`${baseApiUrl}/v1/work-plans/${row.id}/pdf`}
              target="_blank"
              size="sm"
              variant="outline"
              aria-label="download"
              icon={<Icons.Download />}
            />
            <Button size="sm" as={RouterLink} to={row.id}>
              {t("general.edit")}
            </Button>
          </HStack>
        ),
      },
    ],
    [customer.name, isDeleting, t],
  );

  return (
    <Page breadcrumbs={useBreadcrumbs(customer.id, customer.name)}>
      {error && <ErrorDetails error={error} />}
      {deleteError && <ErrorDetails error={deleteError} />}
      <Card
        titleContent={t("workPlan.workPlans")}
        extraContent={
          <Button
            size="sm"
            onClick={() => {
              setSelectedWorkPlan(undefined);
              setAddModalVisible(true);
            }}
          >
            {t("workPlan.newWorkPlan")}
          </Button>
        }
      >
        {workPlanToDelete && (
          <ConfirmationModal
            confirmButtonText={t("general.delete")}
            message={t("general.deleteConfirmation", {
              name: workPlanToDelete.name,
            })}
            onClose={() => setWorkPlanToDelete(undefined)}
            onConfirm={() => deleteWorkPlan(workPlanToDelete)}
            confirmButtonColor="red"
          />
        )}
        <Box mb={4}>
          <SearchFilterInput
            value={listOptions.searchTerm}
            onChange={(value) =>
              setListOptions((options) => ({
                ...options,
                searchTerm: value,
                page: 1,
              }))
            }
          />
        </Box>
        <DataTable
          isLoading={loading}
          columns={columns}
          data={workPlans?.items}
          sorting={listOptions}
          paging={listOptions}
          totalItems={workPlans?.totalItems ?? 0}
          onSortingChange={(sorting) => {
            setListOptions((o) => ({ ...o, ...sorting }));
            setSavedSorting(sorting);
          }}
          onPagingChange={(paging) => {
            setListOptions((o) => ({ ...o, ...paging }));
            setGlobalPageSize(paging.pageSize);
          }}
        />
      </Card>
      {addModalVisible && (
        <CreateWorkPlanModal
          customer={customer}
          cloneWorkPlan={selectedWorkPlan != null ? selectedWorkPlan : null}
          onClose={() => setAddModalVisible(false)}
          onSuccess={() => {
            fetchWorkPlans();
            setAddModalVisible(false);
            onUpdate();
          }}
        />
      )}
    </Page>
  );
}

function useBreadcrumbs(customerId: string, customerName: string) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("customer.customers"),
        to: routes.customers,
      },
      {
        label: customerName,
        to: routes.customer(customerId),
      },
      {
        label: t("workPlan.workPlans"),
        to: routes.workPlans(customerId),
      },
    ],
    [customerName, customerId, t],
  );
}

function WorkPlanStatusBadge({ status }: { status: WorkPlanStatus }) {
  const { t } = useTranslation();

  switch (status) {
    case WorkPlanStatus.Active:
      return <StatusBadge status="active" text={t("general.active")} />;
    case WorkPlanStatus.Inactive:
      return <StatusBadge status="inactive" text={t("general.inactive")} />;
  }
}
