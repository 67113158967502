import { Route, Routes } from "react-router";
import { CustomerList } from "./CustomerList";
import { CustomerPage } from "./CustomerPage";

export function Customers() {
  return (
    <Routes>
      <Route path="/" element={<CustomerList />} />
      <Route path=":customerId/*" element={<CustomerPage />} />
    </Routes>
  );
}
