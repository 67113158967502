import { useState } from "react";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormErrorMessage,
  Stack,
} from "@chakra-ui/react";
import { ServerError } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { WorkPlanListItem, workPlansApi } from "../../api/workPlansApi";
import { Controller, useForm } from "react-hook-form";
import { PackageAsyncSelect } from "../shared/AsyncSelect/PackageAsyncSelect";
import { CustomerDetails } from "../../api/customersApi";

export default function CreateWorkPlanModal({
  customer,
  cloneWorkPlan,
  onClose,
  onSuccess,
}: {
  customer: CustomerDetails;
  cloneWorkPlan: WorkPlanListItem | null;
  onClose: () => void;
  onSuccess: () => void;
}) {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalFormContent
          customer={customer}
          cloneWorkPlan={cloneWorkPlan}
          onSuccess={onSuccess}
        />
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}

interface WorkPlanFormData {
  name: string;
  packageId: string | null;
}

function ModalFormContent({
  customer,
  cloneWorkPlan,
  onSuccess,
}: {
  customer: CustomerDetails;
  cloneWorkPlan: WorkPlanListItem | null;
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<ServerError>();
  const [loading, request] = useApiRequestCallback(workPlansApi.createWorkPlan);

  async function onSubmit(data: WorkPlanFormData) {
    request({
      onSuccess: () => {
        setError(undefined);
        onSuccess();
      },
      onError: setError,
    }).send({
      name: data.name,
      customerId: customer.id,
      packageId: data.packageId,
      cloneWorkPlanId: cloneWorkPlan != null ? cloneWorkPlan.id : null,
    });
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<WorkPlanFormData>();

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <ModalHeader>
        {cloneWorkPlan != null
          ? `${t("general.copy")} ${cloneWorkPlan.name}`
          : t("workPlan.newWorkPlan")}
      </ModalHeader>
      <ModalBody>
        {loading && <LoadingIndicator />}
        {error && <ErrorDetails error={error} />}
        <Stack spacing={4} shouldWrapChildren={true}>
          <FormControl id="name" isRequired={true} isInvalid={!!errors.name}>
            <FormLabel>{t("general.name")}</FormLabel>
            <Input
              {...register("name", {
                required: { value: true, message: t("error.required") },
              })}
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>

          {!cloneWorkPlan && (
            <FormControl
              id="packageId"
              isRequired={false}
              isInvalid={!!errors.packageId}
            >
              <FormLabel>{t("productPackage.productPackage")}</FormLabel>
              <Controller
                name="packageId"
                render={({ field, fieldState }) => (
                  <PackageAsyncSelect
                    customer={customer}
                    {...field}
                    {...fieldState}
                  />
                )}
                control={control}
              />
            </FormControl>
          )}
          <FormErrorMessage>{errors.packageId?.message}</FormErrorMessage>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button type="submit">{t("general.save")}</Button>
      </ModalFooter>
    </form>
  );
}
