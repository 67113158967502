import React from "react";
import { useTranslation } from "react-i18next";
import { stylesApi, StyleProductGroup } from "../../api/stylesApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Select } from "../shared/Select/Select";
import { SharedSelectProps } from "../shared/Select/types";

interface StyleProductGroupAsyncSelectProps extends SharedSelectProps {
  value: StyleProductGroup | null;
  styleId: string;
  customerId: string;
  onChange: (styleProductGroup: StyleProductGroup | null) => void;
}

export const StyleProductGroupAsyncSelect = React.forwardRef(
  (
    {
      value,
      customerId,
      styleId,
      onChange,
      isDisabled,
      ...sharedSelectProps
    }: StyleProductGroupAsyncSelectProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const [styleProductGroups, loading, error, fetchStyleProductGroupOptions] =
      useApiRequest(stylesApi.listStyleProductGroupOptions, {
        clearDataOnLoad: true,
      });

    return (
      <Select
        isDisabled={isDisabled}
        isMulti={false}
        innerRef={ref}
        value={value}
        options={styleProductGroups ?? []}
        isLoading={loading}
        getOptionValue={(i) => i.id}
        getOptionLabel={(i) => i.name}
        noOptionsMessage={() =>
          error ? <ErrorDetails error={error} /> : t("general.noItemsFound")
        }
        onChange={(selectedItem) => onChange(selectedItem)}
        onMenuOpen={() => {
          fetchStyleProductGroupOptions({
            customerId: customerId,
            styleId: styleId,
          });
        }}
        {...sharedSelectProps}
      />
    );
  },
);
