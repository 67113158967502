import { ApiRequest } from "../hooks/useApi/types";
import { LandingPageLayout } from "../types";
import { LicenseType } from "./customersApi";
import { CustomOrderFieldType } from "./customOrderFieldsApi";
import { StyleDetails } from "./stylesApi";

export const externalApi = {
  getProjectDetails:
    (
      customerSlug: string,
      projectSlug: string,
    ): ApiRequest<ExternalProjectDetails> =>
    (httpClient) =>
      httpClient.get(`v1/external/projects/${customerSlug}/${projectSlug}`),

  getOrderConfiguration:
    (projectId: string): ApiRequest<OrderConfiguration> =>
    (httpClient) =>
      httpClient.get(`v1/external/configuration/${projectId}`),

  getOrderProductPackage:
    (productPackageId: string): ApiRequest<OrderProductPackage> =>
    (httpClient) =>
      httpClient.get(`v1/external/product-package/${productPackageId}`),

  getProjectPackages:
    (projectId: string): ApiRequest<ProductPackage[]> =>
    (httpClient) =>
      httpClient.get(`v1/external/projects/${projectId}/product-package`),

  createOrder:
    (data: CreateOrderRequest): ApiRequest =>
    (httpClient) =>
      httpClient.post(`v1/external/orders`, { data }),
};

export interface ExternalProjectDetails {
  id: string;
  licenseType: LicenseType;
  logoImage: string;
  buttonImage: string;
  landingPage: ExternalLandingPageDetails;
  orderConfirmationPage: ExternalOrderConfirmationPageDetails;
  linkToTerms: string;
  onlyVisualization: boolean;
  hideLogo: boolean;
}

export interface ExternalLandingPageDetails {
  layout: LandingPageLayout;
  bannerContent: string;
  content1: string;
  content2: string;
  bannerImage: string;
  image1: string;
  image2: string;
}

export interface ExternalOrderConfirmationPageDetails {
  content: string;
  image: string;
}

export interface ProductPackageSummary {
  id: string;
  name: string;
  description: string | null;
  image: string | null;
  price: number | null;
}

export interface OrderConfiguration {
  kitchenScenes: SceneType[];
  productPackages: ProductPackageSummary[];
  customOrderFields: CustomOrderFieldItem[];
}

export interface CustomOrderFieldItem {
  id: string;
  name: string;
  isRequired: boolean;
  fieldType: CustomOrderFieldType;
}

export interface OrderProductPackage {
  id: string;
  name: string;
  price: number | null;
  renderedProductGroups: ProductGroup[];
  nonRenderedProductGroups: ProductGroup[];
  requiredProductGroups: ProductGroup[];
  styles: StyleDetails[];
  otherOptionsImage: string | null;
}

export interface ProductGroup {
  id: string;
  name: string;
  isRendered: boolean;
  products: OrderProduct[];
}

export interface OrderProduct {
  id: string;
  name: string;
  description: string;
  hasVariants: boolean;
  images: OrderProductImageItem[];
  variants: OrderProductVariant[];
}

export interface OrderProductVariant {
  id: string;
  name: string;
  price: number | null;
  description: string;
  images: OrderProductImageItem[];
}

export interface OrderProductImageItem {
  url: string;
  thumbnailUrl: string;
}

export interface ProductPackage {
  id: string;
  name: string;
}

export interface CreateOrderRequest {
  projectId: string;
  productPackageId: string;
  variantPerProductGroup: Record<string, string>;
  formFields: Record<string, string>;
  totalPrice: number | null;
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  address: string;
  apartmentNumber: string;
  postalCode: string;
  city: string;
  sceneType: SceneType;
}

export enum SceneType {
  Lillekokken = "Lillekokken",
  Parallelkokkenet = "Parallelkokkenet",
  Hjornekokken = "Hjornekokken",
}

export enum Angle {
  Overview = "main",
  KitchenDoors = "closeup_01",
  Countertop = "closeup_02",
}
