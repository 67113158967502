import { useEffect } from "react";
import {
  LandingPageImageType,
  ProjectDetails,
  ProjectLandingPageDetails,
  projectsApi,
} from "../../../api/projectsApi";
import { useApiRequest } from "../../../hooks/useApi/useApiRequest";
import { Card } from "../../shared/Card";
import { Stack, SimpleGrid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { LandingPageForm } from "./LandingPageForm";
import { LandingPageImageForm } from "./LandingPageImageForm";

export const ProjectLandingPageView = ({
  project,
  onUpdate,
}: {
  project: ProjectDetails;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();

  const [
    landingPage,
    landingPageLoading,
    landingPageError,
    landingPageFetch,
    landingPageReset,
  ] = useApiRequest(projectsApi.getProjectLandingPage);

  useEffect(() => {
    landingPageFetch(project.id);
  }, [landingPageFetch, project.id]);

  return (
    <>
      {landingPageLoading && <LoadingIndicator />}
      {landingPageError && <ErrorDetails error={landingPageError} />}
      {landingPage && (
        <Stack spacing={4} shouldWrapChildren={true}>
          <Card>
            <LandingPageForm
              landingPage={landingPage}
              projectId={project.id}
              onUpdate={(landingPage: ProjectLandingPageDetails) => {
                landingPageReset(landingPage);
                onUpdate();
              }}
            />
          </Card>
          <SimpleGrid columns={3} spacing={10}>
            <Card titleContent={`${t("general.bannerImage")}`}>
              <LandingPageImageForm
                image={landingPage.bannerImage}
                projectId={project.id}
                onUpdate={(image) =>
                  landingPageReset((prevState) =>
                    prevState === null
                      ? prevState
                      : {
                          ...prevState,
                          bannerImage: image,
                        },
                  )
                }
                landingPageImageType={LandingPageImageType.Banner}
              />
            </Card>
            <Card
              titleContent={t("project.image", {
                number: 1,
              })}
            >
              <LandingPageImageForm
                image={landingPage.image1}
                projectId={project.id}
                onUpdate={(image) =>
                  landingPageReset((prevState) =>
                    prevState === null
                      ? prevState
                      : {
                          ...prevState,
                          image1: image,
                        },
                  )
                }
                landingPageImageType={LandingPageImageType.Image1}
              />
            </Card>
            <Card
              titleContent={t("project.image", {
                number: 2,
              })}
            >
              <LandingPageImageForm
                image={landingPage.image2}
                projectId={project.id}
                onUpdate={(image) =>
                  landingPageReset((prevState) =>
                    prevState === null
                      ? prevState
                      : {
                          ...prevState,
                          image2: image,
                        },
                  )
                }
                landingPageImageType={LandingPageImageType.Image2}
              />
            </Card>
            <Card titleContent={t("project.buttonImage")}>
              <LandingPageImageForm
                image={landingPage.buttonImage}
                projectId={project.id}
                onUpdate={(image) =>
                  landingPageReset((prevState) =>
                    prevState === null
                      ? prevState
                      : {
                          ...prevState,
                          buttonImage: image,
                        },
                  )
                }
                landingPageImageType={LandingPageImageType.ButtonImage}
              />
            </Card>
          </SimpleGrid>
        </Stack>
      )}
    </>
  );
};
