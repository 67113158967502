import { Stack } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CustomerDetails } from "../../api/customersApi";
import { routes } from "../../routes";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { Page } from "../shared/Page";
import { OrdersList } from "./OrdersList";

export function OrdersView({ customer }: { customer: CustomerDetails }) {
  return (
    <Page breadcrumbs={useBreadcrumbs(customer.id, customer.name)}>
      {customer && (
        <Stack>
          <OrdersList customerId={customer.id} customerName={customer.name} />
        </Stack>
      )}
    </Page>
  );
}

function useBreadcrumbs(customerId: string, customerName: string) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("customer.customers"),
        to: routes.customers,
      },
      {
        label: customerName,
        to: routes.customer(customerId),
      },
      {
        label: t("order.orders"),
        to: routes.orders(customerId),
      },
    ],
    [t, customerName, customerId],
  );
}
