import { lazy, Suspense } from "react";
import { LoadingIndicator } from "../LoadingIndicator";

export interface RichTextEditorProps {
  value: string;
  onChange: (html: string) => void;
  showFontFamilyOptions: boolean;
}

const Editor = lazy(() => import("./TinyMCETextEditor"));

export function RichTextEditor(props: RichTextEditorProps) {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Editor {...props} />
    </Suspense>
  );
}
