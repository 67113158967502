import { createContextValue } from "../../../hooks/contextState";
import { SortingOptions } from "../../../types";

interface TableSorting {
  sorting: SortingOptions<any>;
  onToggleSorting: (headerKey: any) => void;
}

export const [TableSortingProvider, useTableSorting] =
  createContextValue<TableSorting>();
