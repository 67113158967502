import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  RadioGroup,
  Radio,
  ButtonGroup,
  SimpleGrid,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ProjectDetails,
  ProjectOrderSheetDetails,
  projectsApi,
} from "../../api/projectsApi";
import {
  Direction,
  CustomOrderSheetFieldListItem,
  customOrderSheetFieldsApi,
  CustomOrderSheetFieldType,
  CustomOrderSheetFieldCategory,
} from "../../api/customOrderSheetFieldsApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Icons } from "../shared/Icons";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Card } from "../shared/Card";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { EmptyListAlert } from "../shared/EmptyListAlert";
import { ServerError } from "../../types";
import { baseApiUrl } from "../../constants";
import { RichTextEditor } from "../shared/RichText/RichTextEditor";
import { PublicLinkButton } from "./PublicLinkButton";
import { routes } from "../../routes";
import { CustomerDetails } from "../../api/customersApi";

export function ProjectOrderSheetView({
  project,
  customer,
  onCustomOrderSheetFieldChange,
}: {
  project: ProjectDetails;
  customer: CustomerDetails;
  onCustomOrderSheetFieldChange: () => void;
}) {
  const { t } = useTranslation();

  const [
    orderSheet,
    orderSheetLoading,
    orderSheetError,
    orderSheetFetch,
    orderSheetReset,
  ] = useApiRequest(projectsApi.getProjectOrderSheet);

  const [fieldToDelete, setFieldToDelete] =
    useState<CustomOrderSheetFieldListItem>();
  const [deleteError, setDeleteError] = useState<ServerError>();

  const [customOrderSheetFields, isLoading, error, fetch] = useApiRequest(
    customOrderSheetFieldsApi.listCustomOrderSheetFields,
  );

  const [isDeleting, deleteRequest] = useApiRequestCallback(
    customOrderSheetFieldsApi.deleteCustomOrderSheetField,
  );

  const [modalState, setModalState] = useState<ModalState>({
    state: "closed",
  });

  const fetchCustomOrderSheetField = useCallback(() => {
    fetch(project.id);
  }, [fetch, project.id]);

  useEffect(() => {
    orderSheetFetch(project.id);
  }, [orderSheetFetch, project.id]);

  useEffect(() => {
    fetchCustomOrderSheetField();
  }, [fetchCustomOrderSheetField]);

  const toast = useToastNotification();

  function deleteCustomOrderSheetField() {
    if (!fieldToDelete || isDeleting) return;

    deleteRequest({
      onSuccess: () => {
        fetchCustomOrderSheetField();
        onCustomOrderSheetFieldChange();
        toast({
          title: t("general.deleted"),
          status: "success",
        });
        setDeleteError(undefined);
        setFieldToDelete(undefined);
      },
      onError: (e) => setDeleteError(e),
    }).send(fieldToDelete.id);
  }

  return (
    <>
      {orderSheetLoading && <LoadingIndicator />}
      {orderSheetError && <ErrorDetails error={orderSheetError} />}
      {deleteError && <ErrorDetails error={deleteError} />}
      <Stack spacing={6} shouldWrapChildren={true}>
        <Card titleContent={t("general.links")} padding="20px">
          <PublicLinkButton
            route={`${routes.externalProject(
              customer.urlSlug,
              project.urlSlug,
            )}/order-sheet`}
          ></PublicLinkButton>
        </Card>
        <Card titleContent={t("orderSheet.downloadOrderSheet")}>
          <PackageTable customer={customer} projectId={project.id} />
        </Card>
        {orderSheet && (
          <OrderSheetForm
            orderSheet={orderSheet}
            projectId={project.id}
            onUpdate={orderSheetReset}
          />
        )}
        {orderSheet && (
          <OrderSheetMetaForm
            orderSheet={orderSheet}
            projectId={project.id}
            onUpdate={orderSheetReset}
          />
        )}
        {error && <ErrorDetails error={error} />}
        <Card
          titleContent={t("customOrderSheetField.categoryTenant")}
          extraContent={
            <Button
              size="sm"
              onClick={() => {
                setModalState({
                  state: "create",
                  category: CustomOrderSheetFieldCategory.Tenant,
                });
              }}
            >
              {t("general.add")}
            </Button>
          }
        >
          {(isDeleting || isLoading) && <LoadingIndicator />}
          {customOrderSheetFields?.tenantItems && (
            <CustomOrderSheetFieldsTable
              fields={customOrderSheetFields?.tenantItems}
              onDeleteField={setFieldToDelete}
              onEditField={(item) =>
                setModalState({
                  state: "edit",
                  item,
                })
              }
              onUpdate={fetchCustomOrderSheetField}
            />
          )}
          {customOrderSheetFields?.tenantItems.length === 0 && (
            <EmptyListAlert />
          )}
        </Card>

        <Card
          titleContent={t("customOrderSheetField.categoryCustomer")}
          extraContent={
            <Button
              size="sm"
              onClick={() => {
                setModalState({
                  state: "create",
                  category: CustomOrderSheetFieldCategory.Customer,
                });
              }}
            >
              {t("general.add")}
            </Button>
          }
        >
          {(isDeleting || isLoading) && <LoadingIndicator />}
          {customOrderSheetFields?.customerItems && (
            <CustomOrderSheetFieldsTable
              fields={customOrderSheetFields?.customerItems}
              onDeleteField={setFieldToDelete}
              onEditField={(item) =>
                setModalState({
                  state: "edit",
                  item,
                })
              }
              onUpdate={fetchCustomOrderSheetField}
            />
          )}
          {customOrderSheetFields?.customerItems.length === 0 && (
            <EmptyListAlert />
          )}
        </Card>
      </Stack>

      {modalState.state !== "closed" && (
        <OrderFieldModal
          projectId={project.id}
          modalState={modalState}
          onClose={() => {
            setModalState({ state: "closed" });
          }}
          onSuccess={() => {
            setModalState({ state: "closed" });
            fetchCustomOrderSheetField();
            onCustomOrderSheetFieldChange();
          }}
        />
      )}
      {fieldToDelete && (
        <ConfirmationModal
          message={t("general.deleteConfirmation", {
            name: fieldToDelete.name,
          })}
          confirmButtonColor="red"
          confirmButtonText={t("general.delete")}
          onClose={() => setFieldToDelete(undefined)}
          onConfirm={deleteCustomOrderSheetField}
        />
      )}
    </>
  );
}

interface OrderSheetFormData {
  content: string;
}

function OrderSheetForm({
  projectId,
  orderSheet,
  onUpdate,
}: {
  projectId: string;
  orderSheet: ProjectOrderSheetDetails;
  onUpdate: (orderSheet: ProjectOrderSheetDetails) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [error, setError] = useState<ServerError>();

  const [updateOrderSheetLoading, updateOrderSheetRequest] =
    useApiRequestCallback(projectsApi.updateProjectOrderSheet);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    control,
  } = useForm<OrderSheetFormData>({
    defaultValues: {
      ...orderSheet,
    },
  });

  function handleUpdateSuccess(details: ProjectOrderSheetDetails) {
    setError(undefined);
    reset({
      ...details,
    });
    onUpdate(details);
    toast({ title: t("general.saved"), status: "success" });
  }

  async function onSubmit(data: OrderSheetFormData) {
    updateOrderSheetRequest({
      onSuccess: handleUpdateSuccess,
      onError: setError,
    }).send(projectId, data);
  }

  return (
    <>
      {updateOrderSheetLoading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      <Card titleContent={t("general.content")}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl id="content" isInvalid={!!errors.content}>
            <Controller
              name="content"
              render={({ field }) => (
                <RichTextEditor
                  value={field.value}
                  onChange={field.onChange}
                  showFontFamilyOptions={false}
                />
              )}
              control={control}
            />
          </FormControl>
          <Flex mt={4} justifyContent="flex-end">
            <Button type="submit" disabled={!isDirty}>
              {t("general.save")}
            </Button>
          </Flex>
        </form>
      </Card>
    </>
  );
}

interface OrderSheetMetaFormData {
  linkSentToEmail: string;
  dateLinkSentToCustomer: Date;
  isCustomerOnlyUsingOrderSheet: boolean;
}

function OrderSheetMetaForm({
  projectId,
  orderSheet,
  onUpdate,
}: {
  projectId: string;
  orderSheet: ProjectOrderSheetDetails;
  onUpdate: (orderSheet: ProjectOrderSheetDetails) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [error, setError] = useState<ServerError>();

  const [updateOrderSheetMetaLoading, updateOrderSheetMetaRequest] =
    useApiRequestCallback(projectsApi.updateProjectOrderSheetMeta);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    control,
  } = useForm<OrderSheetMetaFormData>({
    defaultValues: {
      ...orderSheet,
      dateLinkSentToCustomer: orderSheet.dateLinkSentToCustomer
        ? new Date(orderSheet.dateLinkSentToCustomer)
        : undefined,
    },
  });

  function handleUpdateSuccess(details: ProjectOrderSheetDetails) {
    setError(undefined);
    reset({
      ...details,
      dateLinkSentToCustomer: details.dateLinkSentToCustomer
        ? new Date(details.dateLinkSentToCustomer)
        : undefined,
    });
    onUpdate(details);
    toast({ title: t("general.saved"), status: "success" });
  }

  async function onSubmit(data: OrderSheetMetaFormData) {
    updateOrderSheetMetaRequest({
      onSuccess: handleUpdateSuccess,
      onError: setError,
    }).send(projectId, data);
  }

  return (
    <>
      {updateOrderSheetMetaLoading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      <Card titleContent={t("orderSheet.linkToCustomer")}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <SimpleGrid pt={2} columns={2} spacing={4}>
            <FormControl id="dateLinkSentToCustomer">
              <FormLabel>{t("orderSheet.dateLinkSentToCustomer")}</FormLabel>
              <Controller
                name="dateLinkSentToCustomer"
                control={control}
                render={({ field }) => (
                  <Input
                    type="date"
                    defaultValue={orderSheet.dateLinkSentToCustomer?.slice(
                      0,
                      10,
                    )}
                    onChange={(date) => {
                      field.onChange(new Date(date.target.value), "yyyy-MM-dd");
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl
              id="linkSentToEmail"
              isInvalid={!!errors.linkSentToEmail}
            >
              <FormLabel>{t("orderSheet.linkSentToEmail")}</FormLabel>
              <Input {...register("linkSentToEmail")} />
            </FormControl>
            <Checkbox {...register("isCustomerOnlyUsingOrderSheet")}>
              {t("orderSheet.customerOnlyUsingOrderSheet")}
            </Checkbox>
          </SimpleGrid>
          <Flex mt={4} justifyContent="flex-end">
            <Button type="submit" disabled={!isDirty}>
              {t("general.save")}
            </Button>
          </Flex>
        </form>
      </Card>
    </>
  );
}

function CustomOrderSheetFieldsTable({
  fields,
  onEditField,
  onDeleteField,
  onUpdate,
}: {
  fields: CustomOrderSheetFieldListItem[];
  onEditField: (item: CustomOrderSheetFieldListItem) => void;
  onDeleteField: (item: CustomOrderSheetFieldListItem) => void;
  onUpdate: () => void;
}) {
  const { t } = useTranslation();

  const [isLoading, updateCustomOrderSheetFieldOrderRequest] =
    useApiRequestCallback(
      customOrderSheetFieldsApi.updateCustomOrderSheetFieldOrder,
    );

  const [error, setError] = useState<ServerError | null>();

  const reorderField = (
    item: CustomOrderSheetFieldListItem,
    direction: Direction,
  ) => {
    updateCustomOrderSheetFieldOrderRequest({
      onSuccess: handleUpdateSuccess,
      onError: setError,
    }).send({
      id: item.id,
      direction: direction,
    });
  };

  function handleUpdateSuccess() {
    onUpdate();
    setError(null);
  }

  return (
    <>
      {error && <ErrorDetails error={error} />}
      {isLoading && <LoadingIndicator />}
      <Table>
        <Thead>
          <Tr>
            <Th>{t("general.name")}</Th>
            <Th>{t("customOrderSheetField.required")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {fields.map((f) => (
            <Tr key={f.id}>
              <Td>{f.name}</Td>
              <Td>{f.isRequired && <Icons.Check />}</Td>
              <Td isNumeric={true} width={150}>
                <HStack>
                  <ButtonGroup isAttached={true}>
                    <IconButton
                      variant="outline"
                      aria-label="up"
                      size="sm"
                      icon={<Icons.ArrowUp boxSize={4} />}
                      onClick={() => reorderField(f, Direction.Up)}
                      mr="-px"
                    />
                    <IconButton
                      variant="outline"
                      aria-label="down"
                      size="sm"
                      icon={<Icons.ArrowDown boxSize={4} />}
                      onClick={() => reorderField(f, Direction.Down)}
                    />
                  </ButtonGroup>
                  <IconButton
                    variant="outline"
                    aria-label="remove"
                    size="sm"
                    icon={<Icons.Trash />}
                    onClick={() => onDeleteField(f)}
                  />
                  <Button size="sm" onClick={() => onEditField(f)}>
                    {t("general.edit")}
                  </Button>
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

type ModalState =
  | {
      state: "closed";
    }
  | OpenModalState;

type OpenModalState =
  | {
      state: "edit";
      item: CustomOrderSheetFieldListItem;
    }
  | {
      state: "create";
      category: CustomOrderSheetFieldCategory;
    };

interface OrderFieldFormData {
  name: string;
  isRequired: boolean;
  fieldType: CustomOrderSheetFieldType;
  category: CustomOrderSheetFieldCategory;
}

function OrderFieldModal({
  onSuccess,
  onClose,
  projectId,
  modalState,
}: {
  onSuccess: () => void;
  onClose: () => void;
  projectId: string;
  modalState: OpenModalState;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<OrderFieldFormData>({
    defaultValues:
      modalState.state === "edit"
        ? modalState.item
        : {
            fieldType: CustomOrderSheetFieldType.Text,
            category: modalState.category,
          },
  });
  const { t } = useTranslation();
  const [error, setError] = useState<ServerError>();

  const [isCreating, createRequest] = useApiRequestCallback(
    customOrderSheetFieldsApi.createCustomOrderSheetField,
  );

  const [isUpdating, updateRequest] = useApiRequestCallback(
    customOrderSheetFieldsApi.updateCustomOrderSheetField,
  );

  const isSubmitting = isCreating || isUpdating;

  function onSubmit(data: OrderFieldFormData) {
    if (isSubmitting) return;

    if (modalState.state === "create") {
      createRequest({
        onSuccess,
        onError: (e) => setError(e),
      }).send({ ...data, projectId });
    } else if (modalState.state === "edit") {
      updateRequest({
        onSuccess,
        onError: (e) => setError(e),
      }).send(modalState.item.id, data);
    }
  }
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {modalState.state === "edit"
            ? t("general.edit")
            : `${t("general.add")} ${t(
                "customOrderSheetField.customOrderSheetField",
              ).toLowerCase()}`}
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            {(isCreating || isUpdating) && <LoadingIndicator />}
            {error && <ErrorDetails error={error} />}
            <Stack spacing={4} shouldWrapChildren={true}>
              <FormControl isRequired={true} isInvalid={!!errors?.name}>
                <FormLabel>{t("general.name")}</FormLabel>
                <Input
                  {...register("name", {
                    required: { value: true, message: t("error.required") },
                  })}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="fieldType">
                <FormLabel>{t("customOrderSheetField.fieldType")}</FormLabel>
                <Controller
                  name="fieldType"
                  render={({ field }) => (
                    <Flex alignItems="center" height="38px">
                      <RadioGroup
                        onChange={(status) => {
                          field.onChange(status as CustomOrderSheetFieldType);
                        }}
                        value={field.value}
                      >
                        <HStack>
                          <Radio value={CustomOrderSheetFieldType.Text}>
                            {t("customOrderSheetField.text")}
                          </Radio>
                          <Radio value={CustomOrderSheetFieldType.Checkbox}>
                            {t("customOrderSheetField.checkbox")}
                          </Radio>
                        </HStack>
                      </RadioGroup>
                    </Flex>
                  )}
                  control={control}
                />
              </FormControl>
              <FormControl>
                <Checkbox {...register("isRequired")}>
                  {t("customOrderSheetField.required")}
                </Checkbox>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button disabled={isSubmitting} type="submit">
              {t("general.save")}
            </Button>
          </ModalFooter>
        </form>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}

function PackageTable({
  customer,
  projectId,
}: {
  customer: CustomerDetails;
  projectId: string;
}) {
  const [packages, packagesLoading, packagesError, packagesFetch] =
    useApiRequest(projectsApi.getProjectPackages);

  useEffect(() => {
    packagesFetch(projectId);
  }, [packagesFetch, projectId]);

  const { t } = useTranslation();

  return (
    <>
      {packagesLoading && <LoadingIndicator />}
      {packagesError && <ErrorDetails error={packagesError} />}
      <Table>
        <Thead>
          <Tr>
            <Th width="90%">{t("orderSheet.orderSheet")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {packages?.map((p) => (
            <Tr key={p.id}>
              <Td>
                {customer.name} - {p.name}
              </Td>
              <Td>
                <IconButton
                  as="a"
                  href={`${baseApiUrl}/v1/projects/${projectId}/packages/${p.id}/pdf`}
                  target="_blank"
                  size="sm"
                  variant="outline"
                  aria-label="download"
                  icon={<Icons.Download />}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {packages?.length === 0 && <EmptyListAlert />}
    </>
  );
}
