import {
  Button,
  Flex,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleProductVariant, stylesApi } from "../../api/stylesApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ServerError } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Icons } from "../shared/Icons";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Card } from "../shared/Card";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { EmptyListAlert } from "../shared/EmptyListAlert";
import {
  addEditModalStateType,
  AddEditStyleProductVariantModal,
} from "./AddEditStyleProductVariantModal";

export function StyleProductVariantList({
  styleId,
  customerId,
  hasAnyProductPackages,
}: {
  styleId: string;
  customerId: string;
  hasAnyProductPackages: boolean;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();

  const [addEditProductVariantModalState, setAddEditProductVariantModalState] =
    useState<addEditModalStateType>(addEditModalStateType.Hidden);

  const [productVariant, setProductVariant] =
    useState<StyleProductVariant | null>(null);

  const [
    productVariants,
    productVariantsLoading,
    productVariantsError,
    productVariantsFetch,
  ] = useApiRequest(stylesApi.listStyleProductVariants);

  useEffect(() => {
    productVariantsFetch(styleId);
  }, [productVariantsFetch, styleId]);

  return (
    <Card
      titleContent={t("style.productVariants")}
      extraContent={
        <Button
          size="sm"
          onClick={() =>
            setAddEditProductVariantModalState(addEditModalStateType.Add)
          }
        >
          {t("general.add")}
        </Button>
      }
    >
      {productVariantsLoading && <LoadingIndicator />}
      {productVariantsError && <ErrorDetails error={productVariantsError} />}
      {addEditProductVariantModalState !== addEditModalStateType.Hidden && (
        <AddEditStyleProductVariantModal
          hasAnyProductPackages={hasAnyProductPackages}
          addEditModalState={addEditProductVariantModalState}
          productVariant={productVariant}
          styleId={styleId}
          customerId={customerId}
          onClose={() => {
            setProductVariant(null);
            setAddEditProductVariantModalState(addEditModalStateType.Hidden);
          }}
          onSuccess={() => {
            setProductVariant(null);
            setAddEditProductVariantModalState(addEditModalStateType.Hidden);
            productVariantsFetch(styleId);
            toast({
              title: t("general.created"),
              status: "success",
            });
          }}
        />
      )}
      <ProductVariantTable
        styleId={styleId}
        productVariants={productVariants}
        onRemoveSuccess={() => {
          productVariantsFetch(styleId);
        }}
        onEdit={(p) => {
          setAddEditProductVariantModalState(addEditModalStateType.Edit);
          return setProductVariant(p);
        }}
      />
    </Card>
  );
}

function ProductVariantTable({
  styleId,
  productVariants,
  onRemoveSuccess,
  onEdit,
}: {
  styleId: string;
  productVariants: StyleProductVariant[] | null;
  onRemoveSuccess: () => void;
  onEdit: (productVariant: StyleProductVariant) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [error, setError] = useState<ServerError>();
  const [productVariantToRemove, setProductVariantToRemove] =
    useState<StyleProductVariant | null>(null);
  const [isRemoving, removeProductVariantRequest] = useApiRequestCallback(
    stylesApi.removeStyleProductVariant,
  );

  function removeProductVariant(productVariantId: string) {
    setProductVariantToRemove(null);
    setError(undefined);
    removeProductVariantRequest({
      onSuccess: () => {
        toast({
          title: t("general.deleted"),
          status: "success",
        });
        onRemoveSuccess();
      },
      onError: setError,
    }).send(styleId, productVariantId);
  }

  return (
    <>
      {isRemoving && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      <Table>
        <Thead>
          <Tr>
            <Th width="47%">{t("general.name")}</Th>
            <Th width="47%">{t("productGroup.productGroup")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {productVariants?.map((p) => (
            <Tr key={p.id}>
              <Td>{p.name}</Td>
              <Td>{p.styleProductGroup?.name}</Td>
              <Td>
                <Flex gridGap={4}>
                  <Button size="sm" onClick={() => onEdit(p)}>
                    {t("general.edit")}
                  </Button>
                  <IconButton
                    variant="outline"
                    aria-label={t("general.delete")}
                    icon={<Icons.Trash />}
                    size="sm"
                    onClick={() => setProductVariantToRemove(p)}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {productVariants?.length === 0 && <EmptyListAlert />}
      {productVariantToRemove && (
        <ConfirmationModal
          message={t("general.deleteConfirmation", {
            name: productVariantToRemove.name,
          })}
          confirmButtonText={t("general.delete")}
          confirmButtonColor="red"
          onConfirm={() => removeProductVariant(productVariantToRemove.id)}
          onClose={() => setProductVariantToRemove(null)}
        />
      )}
    </>
  );
}
