import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import { ProductGroupAsyncSelect } from "../shared/AsyncSelect/ProductGroupAsyncSelect";
import { ErrorDetails } from "../shared/ErrorDetails";
import { ServerError } from "../../types";
import { useToastNotification } from "../../hooks/useToastNotification";
import { workPlansApi } from "../../api/workPlansApi";

export function CreateWorkPlanWorkStepModal({
  workPlanId,
  onSuccess,
  onClose,
}: {
  workPlanId: string;
  onSuccess: () => void;
  onClose: () => void;
}) {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalFormContent workPlanId={workPlanId} onSuccess={onSuccess} />
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}

interface CreateWorkStepFormData {
  name: string;
  isGeneral: boolean;
  productGroupId: string | null;
}

function ModalFormContent({
  workPlanId,
  onSuccess,
}: {
  workPlanId: string;
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [error, setError] = useState<ServerError>();

  const [createWorkStepLoading, createWorkStepRequest] = useApiRequestCallback(
    workPlansApi.createWorkPlanStep,
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
  } = useForm<CreateWorkStepFormData>();

  function onSubmit(data: CreateWorkStepFormData) {
    const request = {
      name: data.name,
      productGroupId: !data.isGeneral ? data.productGroupId! : undefined,
    };
    createWorkStepRequest({
      onSuccess: () => {
        setError(undefined);
        toast({ title: t("general.added"), status: "success" });
        onSuccess();
      },
      onError: setError,
    }).send(workPlanId, request);
  }

  const isGeneral = watch("isGeneral");
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <ModalHeader>{t("workStep.newWorkStep")}</ModalHeader>
      <ModalBody>
        {createWorkStepLoading && <LoadingIndicator />}
        {error && <ErrorDetails error={error} />}
        <Stack spacing={4} shouldWrapChildren={true}>
          <FormControl id="name" isRequired={true} isInvalid={!!errors.name}>
            <FormLabel>{t("general.name")}</FormLabel>
            <Input
              {...register("name", {
                required: { value: true, message: t("error.required") },
              })}
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
          <Checkbox {...register("isGeneral")}>
            {t("workStep.generalWorkStep")}
          </Checkbox>
          {!isGeneral && (
            <FormControl
              id="productGroup"
              isRequired={true}
              isInvalid={!!errors.productGroupId}
            >
              <FormLabel>{t("productGroup.productGroup")}</FormLabel>
              <Controller
                name="productGroupId"
                render={({ field, fieldState }) => (
                  <ProductGroupAsyncSelect {...field} {...fieldState} />
                )}
                control={control}
                rules={{
                  required: { value: true, message: t("error.required") },
                }}
              />
              <FormErrorMessage>
                {errors.productGroupId?.message}
              </FormErrorMessage>
            </FormControl>
          )}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button type="submit">{t("general.save")}</Button>
      </ModalFooter>
    </form>
  );
}
