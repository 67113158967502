import { Box } from "@chakra-ui/react";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { EmptyListAlert } from "../EmptyListAlert";

export function DataTableEmptyText({ isLoading }: { isLoading: boolean }) {
  if (isLoading) {
    return (
      <Box position="relative" height="52">
        <LoadingIndicator />
      </Box>
    );
  }

  return <EmptyListAlert />;
}
