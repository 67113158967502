import {
  AlertDialog,
  Box,
  Button,
  IconButton,
  HStack,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Checkbox,
  FormHelperText,
  FormControl,
  ButtonGroup,
} from "@chakra-ui/react";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  ProductGroupListItem,
  productGroupsApi,
} from "../../api/productGroupsApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import {
  PagingOptions,
  Permission,
  ServerError,
  SortingOptions,
} from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Icons } from "../shared/Icons";
import { Card } from "../shared/Card";
import { Column, DataTable } from "../shared/DataTable/DataTable";
import { Page } from "../shared/Page";
import { SearchFilterInput } from "../shared/SearchFilterInput";
import { ProductGroupModal } from "./ProductGroupModal";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { routes } from "../../routes";
import { useGlobalPageSize } from "../../hooks/usePageSize";
import { useSavedSorting } from "../../hooks/useSavedSorting";
import { usePermission } from "../../hooks/usePermission";

type ProductGroupModalState =
  | { open: false }
  | { open: true; productGroupId: string | null };

interface ListOptions
  extends PagingOptions,
    SortingOptions<ProductGroupListItem> {
  searchTerm: string;
}

export function ProductGroupList() {
  const { t } = useTranslation();
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const [deleteError, setDeleteError] = useState<ServerError>();
  const hasPermission = usePermission(Permission.ManageProducts);
  const { savedSorting, setSavedSorting } =
    useSavedSorting<ProductGroupListItem>("ProductGroupList", {
      sortingDesc: false,
      sortingKey: "name",
    });
  const [listOptions, setListOptions] = useState<ListOptions>({
    ...savedSorting,
    page: 1,
    pageSize: globalPageSize,
    searchTerm: "",
  });
  const [productGroupToDelete, setProductGroupToDelete] =
    useState<ProductGroupListItem>();

  const [productGroupModalState, setProductGroupModalState] =
    useState<ProductGroupModalState>({
      open: false,
    });

  const [productGroups, loading, error, fetch] = useApiRequest(
    productGroupsApi.listProductGroups,
  );
  const [isDeleting, deleteRequest] = useApiRequestCallback(
    productGroupsApi.deleteProductGroup,
  );

  const toast = useToastNotification();

  const fetchGroups = useCallback(() => {
    fetch(listOptions);
  }, [fetch, listOptions]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const columns = useMemo<Column<ProductGroupListItem>[]>(
    () => [
      {
        key: "name",
        header: t("general.name"),
        sortable: true,
      },
      {
        key: "renderLayerId",
        header: t("productGroup.renderLayer"),
        sortable: false,
      },
      {
        key: "sortOrder",
        header: t("general.sortOrder"),
        sortable: true,
      },
      {
        key: "actions",
        width: 150,
        cell: ({ row }) => (
          <>
            <HStack>
              <IconButton
                aria-label="remove"
                variant="outline"
                size="sm"
                icon={<Icons.Trash />}
                isDisabled={isDeleting || !hasPermission}
                onClick={() => setProductGroupToDelete(row)}
              />
              <Button
                isDisabled={!hasPermission}
                onClick={() => {
                  setListOptions((o) => ({ ...o, searchTerm: "" }));
                  setProductGroupModalState({
                    open: true,
                    productGroupId: row.id,
                  });
                }}
                size="sm"
              >
                {t("general.edit")}
              </Button>
            </HStack>
          </>
        ),
      },
    ],
    [isDeleting, t, hasPermission],
  );

  return (
    <Page breadcrumbs={useBreadcrumbs()}>
      {error && <ErrorDetails error={error} />}
      <Card
        titleContent={t("productGroup.productGroups")}
        extraContent={
          <Button
            isDisabled={!hasPermission}
            size="sm"
            onClick={() =>
              setProductGroupModalState({ open: true, productGroupId: null })
            }
          >
            {t("general.add")}
          </Button>
        }
      >
        <Box mb={4}>
          <SearchFilterInput
            value={listOptions.searchTerm}
            onChange={(value) =>
              setListOptions((options) => ({
                ...options,
                searchTerm: value,
                page: 1,
              }))
            }
          />
        </Box>
        {productGroupToDelete && (
          <DeleteProductGroupDialog
            productGroup={productGroupToDelete}
            onClose={() => {
              setProductGroupToDelete(undefined);
              setDeleteError(undefined);
            }}
            error={deleteError}
            onSuccess={(force) => {
              deleteRequest({
                onSuccess: () => {
                  setProductGroupToDelete(undefined);
                  setDeleteError(undefined);
                  fetchGroups();
                  toast({
                    title: t("general.deleted"),
                    status: "success",
                  });
                },
                onError: (e) => {
                  setDeleteError(e);
                },
              }).send(productGroupToDelete.id, { force });
            }}
          />
        )}
        <DataTable
          isLoading={loading}
          columns={columns}
          data={productGroups?.items}
          sorting={listOptions}
          paging={listOptions}
          totalItems={productGroups?.totalItems ?? 0}
          onSortingChange={(sorting) => {
            setListOptions((o) => ({ ...o, ...sorting }));
            setSavedSorting(sorting);
          }}
          onPagingChange={(paging) => {
            setListOptions((o) => ({ ...o, ...paging }));
            setGlobalPageSize(paging.pageSize);
          }}
        />
      </Card>
      {productGroupModalState.open && (
        <ProductGroupModal
          productGroupId={productGroupModalState.productGroupId}
          onSuccess={() => {
            setProductGroupModalState({ open: false });
            fetchGroups();
          }}
          onClose={() => setProductGroupModalState({ open: false })}
        />
      )}
    </Page>
  );
}

function DeleteProductGroupDialog({
  productGroup,
  onClose,
  onSuccess,
  error,
}: {
  productGroup: ProductGroupListItem;
  onClose: () => void;
  onSuccess: (force: boolean) => void;
  error: ServerError | undefined;
}) {
  const ref = useRef<null>(null);
  const [force, setForce] = useState<boolean>(false);

  const { t } = useTranslation();
  return (
    <AlertDialog isOpen={true} onClose={onClose} leastDestructiveRef={ref}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>
            {t("productGroup.deleteWarning", {
              productGroup: productGroup.name,
            })}
          </AlertDialogHeader>
          <AlertDialogBody>
            {error && <ErrorDetails error={error} />}
            <FormControl>
              <Checkbox isChecked={force} onChange={() => setForce(!force)}>
                {t("productGroup.forceDelete")}
              </Checkbox>
              <FormHelperText>
                {t("productGroup.forceDeleteDescription")}
              </FormHelperText>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <ButtonGroup>
              <Button variant="outline" ref={ref} onClick={onClose}>
                {t("general.cancel")}
              </Button>
              <Button colorScheme="red" onClick={() => onSuccess(force)}>
                {t("general.delete")}
              </Button>
            </ButtonGroup>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

function useBreadcrumbs() {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("assortment.assortment"),
        to: routes.assortment,
      },
      {
        label: t("productGroup.productGroups"),
        to: routes.productGroups,
      },
    ],
    [t],
  );
}
