import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  useDisclosure,
  Wrap,
  WrapItem,
  Text,
  Image,
} from "@chakra-ui/react";
import { useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { ServerError } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Icons } from "../shared/Icons";
import { LoadingIndicator } from "../shared/LoadingIndicator";

interface ImageItem {
  id: string;
  url: string;
  thumbnailUrl?: string;
}

export function ImageList({
  readOnly,
  images,
  isLoading,
  errors,
  maxImages,
  onUpload,
  onRemove,
}: {
  readOnly?: boolean;
  images: ImageItem[] | null;
  isLoading: boolean;
  errors: (ServerError | null)[];
  maxImages?: number;
  onUpload: (file: File) => void;
  onRemove: (id: string) => void;
}) {
  const hasMaxImages =
    maxImages !== undefined && (images?.length ?? 0) >= maxImages;

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles[0]) {
        onUpload(acceptedFiles[0]);
      }
    },
    [onUpload],
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, open } =
    useDropzone({
      onDropAccepted,
      noClick: true,
      noKeyboard: true,
      accept: "image/jpeg,image/png",
      disabled: images === null || hasMaxImages,
    });

  return (
    <Box {...getRootProps()} position="relative">
      {errors.map((error, index) =>
        error ? <ErrorDetails key={index} error={error} /> : null,
      )}
      {isLoading && <LoadingIndicator />}
      {isDragActive && <DropzoneOverlay isAccepted={isDragAccept} />}
      <input {...getInputProps()} />
      <Wrap spacing="30px" align="center">
        {images?.map((image) => (
          <WrapItem key={image.id}>
            <UploadedImage
              src={image.thumbnailUrl ?? image.url}
              onRemove={() => onRemove(image.id)}
              readOnly={readOnly ?? false}
            />
          </WrapItem>
        ))}
        {!hasMaxImages && (
          <WrapItem>
            <UploadImageButton readOnly={readOnly ?? false} onClick={open} />
          </WrapItem>
        )}
      </Wrap>
    </Box>
  );
}

function DropzoneOverlay({ isAccepted }: { isAccepted: boolean }) {
  return (
    <Box
      position="absolute"
      inset="0"
      zIndex="overlay"
      display="flex"
      alignItems="center"
      justifyContent="center"
      background="rgba(255,255,255,0.5);"
    >
      {isAccepted ? (
        <Icons.Upload boxSize={14} color="primary.500" />
      ) : (
        <Icons.Close boxSize={14} color="red.500" />
      )}
    </Box>
  );
}

function UploadedImage({
  src,
  onRemove,
  readOnly,
}: {
  src: string;
  onRemove: () => void;
  readOnly: boolean;
}) {
  return (
    <Box position="relative">
      <DeleteImageButton readOnly={readOnly} onRemove={onRemove} />
      <Image boxSize={28} src={src} ignoreFallback={true} objectFit="cover" />
    </Box>
  );
}

function DeleteImageButton({
  onRemove,
  readOnly,
}: {
  onRemove: () => void;
  readOnly: boolean;
}) {
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const cancelButtonRef = useRef(null);

  return (
    <Popover
      isLazy={true}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      initialFocusRef={cancelButtonRef}
    >
      <PopoverTrigger>
        <IconButton
          isDisabled={readOnly}
          aria-label="Remove"
          icon={<Icons.Trash />}
          size="xs"
          variant="outline"
          position="absolute"
          top="0"
          right="0"
          bgColor="white"
        />
      </PopoverTrigger>
      <PopoverContent width="64">
        <PopoverArrow />
        <PopoverBody>{t("general.deleteImageConfirmation")}</PopoverBody>
        <PopoverFooter d="flex" justifyContent="flex-end">
          <ButtonGroup size="sm">
            <Button ref={cancelButtonRef} variant="outline" onClick={onClose}>
              {t("general.cancel")}
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                onRemove();
                onClose();
              }}
            >
              {t("general.delete")}
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function UploadImageButton({
  onClick,
  readOnly,
}: {
  onClick: () => void;
  readOnly: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Button
      isDisabled={readOnly}
      boxSize={28}
      variant="outline"
      whiteSpace="normal"
      onClick={onClick}
    >
      <Flex direction="column" justify="center" align="center">
        <Icons.Add boxSize={6} />
        <Text mt={1} whiteSpace="nowrap" fontSize="sm">
          {t("general.add")}
        </Text>
        <Text mt={1} whiteSpace="nowrap" fontSize="xs">
          (jpg/png)
        </Text>
      </Flex>
    </Button>
  );
}
