import { ApiRequest } from "../hooks/useApi/types";
import { PagedItems, PagingOptions, SortingOptions } from "../types";

export const regionApi = {
  listRegions:
    (params: ListRegionsRequest): ApiRequest<PagedItems<RegionListItem>> =>
    (httpClient) =>
      httpClient.get("v1/regions", { params }),
  createRegion:
    (data: CreateRegionRequest): ApiRequest =>
    (httpClient) =>
      httpClient.post("v1/regions", { data }),
  getRegionDetails:
    (id: string): ApiRequest<RegionDetails> =>
    (httpClient) =>
      httpClient.get(`v1/regions/${id}`),
  updateRegion:
    (id: string, data: UpdateRegionRequest): ApiRequest =>
    (httpClient) =>
      httpClient.put(`v1/regions/${id}`, { data }),
};

interface ListRegionsRequest
  extends SortingOptions<RegionListItem>,
    PagingOptions {}

export interface RegionListItem {
  id: string;
  pyramidId: string;
  name: string;
}

export interface CreateRegionRequest {
  pyramidId: string;
  name: string;
}

export interface RegionDetails {
  id: string;
  pyramidId: string;
  name: string;
}

export interface UpdateRegionRequest {
  pyramidId: string;
  name: string;
}
