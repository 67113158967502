import { useCallback, useMemo, useState } from "react";
import { ConfirmationModal } from "../components/shared/ConfirmationModal";

interface Confirmation {
  message: string;
  confirmButtonText: string;
  confirmButtonColor?: "red" | "primary";
  onConfirmation: () => void;
}

export function useConfirmation() {
  const [confirmation, setConfirmation] = useState<Confirmation>();

  const confirm = useCallback((confirmation: Confirmation) => {
    setConfirmation(confirmation);
  }, []);

  const ModalComponent = useMemo(() => {
    if (!confirmation) {
      return null;
    }

    return (
      <ConfirmationModal
        message={confirmation.message}
        confirmButtonText={confirmation.confirmButtonText}
        confirmButtonColor={confirmation.confirmButtonColor}
        onConfirm={() => {
          confirmation.onConfirmation();
          setConfirmation(undefined);
        }}
        onClose={() => setConfirmation(undefined)}
      />
    );
  }, [confirmation]);

  return [confirm, ModalComponent] as const;
}
