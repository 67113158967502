import { Box, CSSObject, Image, ImageProps } from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import "@splidejs/splide/dist/css/splide.min.css";
import { useEffect, useRef } from "react";

const styles: CSSObject = {
  ".splide__pagination__page.is-active": {
    background: "primary.500",
  },
};

export function ImageCarousel({
  images,
  imageMaxHeight,
}: {
  images: string[];
  imageMaxHeight?: ImageProps["maxHeight"];
}) {
  const splideRef = useRef<Splide>(null);

  useEffect(() => {
    splideRef?.current?.go(0);
  }, [images]);

  return (
    <Box sx={styles}>
      <Splide
        ref={splideRef}
        options={{ arrows: images.length > 1, rewind: true }}
      >
        {images.map((url, idx) => (
          <SplideSlide key={idx}>
            <Image
              margin="auto"
              maxHeight={imageMaxHeight}
              src={url}
              objectFit="contain"
            />
          </SplideSlide>
        ))}
      </Splide>
    </Box>
  );
}
