import ReactDOM from "react-dom";
import { Root } from "./components/Root";
import { StrictMode } from "react";
import { HttpClientProvider } from "./hooks/useHttpClient";
import { SessionStoreProvider } from "./hooks/useSessionStore";
import { BrowserRouter } from "react-router-dom";
import "./i18n/config";
import {
  ChakraProvider,
  extendTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import customTheme from "./theme";
import "@fontsource/roboto/latin.css";
import "@fontsource/dosis/latin.css";

const theme = extendTheme(
  customTheme,
  withDefaultColorScheme({ colorScheme: "primary" }),
);

ReactDOM.render(
  <StrictMode>
    <HttpClientProvider>
      <SessionStoreProvider>
        <BrowserRouter>
          <ChakraProvider theme={theme}>
            <Root />
          </ChakraProvider>
        </BrowserRouter>
      </SessionStoreProvider>
    </HttpClientProvider>
  </StrictMode>,
  document.getElementById("root"),
);
