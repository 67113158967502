import { CustomerStatus } from "../../api/customersApi";
import { useTranslation } from "react-i18next";
import { StatusBadge } from "../shared/StatusBadges/StatusBadge";

export function CustomerStatusBadge({ status }: { status: CustomerStatus }) {
  const { t } = useTranslation();

  switch (status) {
    case CustomerStatus.Active:
      return <StatusBadge status="active" text={t("general.active")} />;
    case CustomerStatus.Inactive:
      return <StatusBadge status="inactive" text={t("general.inactive")} />;
    case CustomerStatus.New:
      return <StatusBadge status="new" text={t("general.new")} />;
  }
}
