import { useCallback, useEffect, useState } from "react";
import { Session } from "../../types";
import { createContextHookValue } from "../contextState";
import { useHttpClient } from "../useHttpClient";
import { SessionState, SessionStatus } from "./types";

function useStore() {
  const httpClient = useHttpClient();
  const [sessionState, setSessionState] = useState<SessionState>({
    status: SessionStatus.Fetching,
  });

  useEffect(() => {
    function handleUnAuthorizedResponse() {
      setSessionState({ status: SessionStatus.Inactive });
    }

    return httpClient.onUnauthorized.subscribe(handleUnAuthorizedResponse);
  }, [httpClient]);

  const getSession = useCallback(async () => {
    setSessionState({ status: SessionStatus.Fetching });
    const response = await httpClient.get<Session>("v1/sessions/current");

    if (!response.hasError) {
      setSessionState({
        status: SessionStatus.Active,
        session: response.data,
      });
    } else if (response.status !== 401) {
      setSessionState({
        status: SessionStatus.Error,
        error: response.error,
      });
    }
  }, [httpClient]);

  async function signIn(email: string, password: string, rememberMe: boolean) {
    setSessionState({ status: SessionStatus.Creating });

    const response = await httpClient.post("v1/sessions", {
      data: {
        email,
        password,
        rememberMe,
      },
    });

    if (!response.hasError) {
      await getSession();
    } else {
      setSessionState({
        status: SessionStatus.Error,
        error: response.error,
      });
    }
  }

  async function signOut() {
    const response = await httpClient.delete("v1/sessions/current");
    if (!response.hasError) {
      setSessionState({ status: SessionStatus.Inactive });
    }
  }

  useEffect(() => {
    void getSession();
  }, [getSession]);

  return {
    sessionState,
    signIn,
    signOut,
  };
}

export const [SessionStoreProvider, useSessionStore] =
  createContextHookValue(useStore);
