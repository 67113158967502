import { Button, IconButton, HStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Page } from "../shared/Page";
import { Card } from "../shared/Card";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { UserListItem, usersApi } from "../../api/usersApi";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { ErrorDetails } from "../shared/ErrorDetails";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CreateUserModal } from "./CreateUserModal";
import { Column, DataTable } from "../shared/DataTable/DataTable";
import { PagingOptions, ServerError, SortingOptions } from "../../types";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../../routes";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useGlobalPageSize } from "../../hooks/usePageSize";
import { useSavedSorting } from "../../hooks/useSavedSorting";
import { Icons } from "../shared/Icons";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { useToastNotification } from "../../hooks/useToastNotification";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";

export function UserListPage() {
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<UserListItem>();
  const [deleteUsersError, setDeleteUsersError] = useState<ServerError>();
  const toast = useToastNotification();
  const [isDeleting, deleteRequest] = useApiRequestCallback(
    usersApi.deleteUser,
  );
  const { savedSorting, setSavedSorting } = useSavedSorting<UserListItem>(
    "UserListPage",
    { sortingDesc: false, sortingKey: "email" },
  );
  const [sorting, setSorting] = useState<SortingOptions<UserListItem>>({
    ...savedSorting,
  });
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const [paging, setPaging] = useState<PagingOptions>({
    page: 1,
    pageSize: globalPageSize,
  });

  const { t } = useTranslation();
  const [users, isLoading, usersError, fetch] = useApiRequest(
    usersApi.listUsers,
  );

  const fetchUsers = useCallback(() => {
    fetch({ ...sorting, ...paging });
  }, [fetch, sorting, paging]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const columns = useMemo<Column<UserListItem>[]>(
    () => [
      {
        key: "firstName",
        header: t("user.firstName"),
        sortable: true,
      },
      {
        key: "lastName",
        header: t("user.lastName"),
        sortable: true,
      },
      {
        key: "email",
        header: t("user.email"),
        sortable: true,
      },
      {
        key: "roleName",
        header: t("role.role"),
        sortable: true,
      },
      {
        key: "createdAt",
        header: t("general.created"),
        sortable: true,
      },
      {
        key: "actions",
        width: 150,
        cell: ({ row }) => (
          <HStack>
            <IconButton
              size="sm"
              variant="outline"
              aria-label="remove"
              icon={<Icons.Trash />}
              isDisabled={isDeleting}
              onClick={() => setUserToDelete(row)}
            />
            <Button size="sm" as={RouterLink} to={routes.user(row.id)}>
              {t("general.edit")}
            </Button>
          </HStack>
        ),
      },
    ],
    [t, isDeleting],
  );

  function deleteUser(toDelete: UserListItem) {
    deleteRequest({
      onSuccess: () => {
        setUserToDelete(undefined);
        setDeleteUsersError(undefined);
        fetchUsers();
        toast({
          title: t("general.deleted"),
          status: "success",
        });
      },
      onError: (e) => {
        setUserToDelete(undefined);
        setDeleteUsersError(e);
      },
    }).send(toDelete.id);
  }

  return (
    <Page breadcrumbs={useBreadcrumbs()}>
      <Card
        titleContent={t("user.users")}
        extraContent={
          <Button
            size="sm"
            float="right"
            onClick={() => setCreateModalOpen(true)}
          >
            {t("general.add")}
          </Button>
        }
      >
        {isLoading && <LoadingIndicator />}
        {usersError && <ErrorDetails error={usersError} />}
        {deleteUsersError && <ErrorDetails error={deleteUsersError} />}

        {createModalOpen && (
          <CreateUserModal
            onSuccess={async () => {
              setCreateModalOpen(false);
              setDeleteUsersError(undefined);
              await fetchUsers();
            }}
            onClose={() => setCreateModalOpen(false)}
          />
        )}
        {userToDelete && (
          <ConfirmationModal
            confirmButtonText={t("general.delete")}
            message={t("general.deleteConfirmation", {
              name: userToDelete.email,
            })}
            onClose={() => setUserToDelete(undefined)}
            onConfirm={() => deleteUser(userToDelete)}
            confirmButtonColor="red"
          />
        )}
        <DataTable
          isLoading={isLoading}
          columns={columns}
          data={users?.items}
          sorting={sorting}
          paging={paging}
          totalItems={users?.totalItems ?? 0}
          onSortingChange={(sorting) => {
            setSorting(sorting);
            setSavedSorting(sorting);
          }}
          onPagingChange={(paging) => {
            setPaging((o) => ({ ...o, ...paging }));
            setGlobalPageSize(paging.pageSize);
          }}
        />
      </Card>
    </Page>
  );
}

function useBreadcrumbs() {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("general.settings"),
        to: routes.settings,
      },
      {
        label: t("user.users"),
      },
    ],
    [t],
  );
}
