import { ReactNode } from "react";
import { useActiveSession } from "../hooks/useActiveSession";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
} from "@chakra-ui/react";
import { Icons } from "./shared/Icons";
import { useActivePath } from "../hooks/useActivePath";
import { routes } from "../routes";
import { Permission } from "../types";
import { usePermission } from "../hooks/usePermission";
import { useBobutikenLogoUrl } from "../hooks/useBobutikenLogoUrl";

export function Topbar() {
  const { t } = useTranslation();
  const bobutikenLogoUrl = useBobutikenLogoUrl();
  const { session, signOut } = useActiveSession();

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      flexShrink={0}
      h={20}
      px={8}
    >
      <HStack alignItems="center" spacing="12">
        <Link to="/">
          <Image src={bobutikenLogoUrl} alt="logo" w={48} />
        </Link>
        <HStack spacing="4">
          <NavItem route={routes.customers} label={t("customer.customers")} />
          <NavItem
            route={routes.assortment}
            label={t("assortment.assortments")}
          />
          <NavItem route={routes.workSteps} label={t("workStep.workStep")} />
          {usePermission(Permission.ManageSettings) && (
            <NavItem route={routes.settings} label={t("general.settings")} />
          )}
        </HStack>
      </HStack>

      <Menu>
        <MenuButton
          as={Button}
          variant="outline"
          rounded={20}
          leftIcon={<Icons.User />}
          rightIcon={<Icons.ArrowDown />}
        >
          {session.email}
        </MenuButton>
        <MenuList>
          <MenuItem onClick={signOut}>{t("general.signOut")}</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}

function NavItem({ route, label }: { route: string; label: ReactNode }) {
  const isActive = useActivePath(route, false);

  return (
    <Button
      h="10"
      w="40"
      as={Link}
      to={route}
      size="sm"
      textTransform="uppercase"
      variant={isActive ? "solid" : "outline"}
    >
      {label}
    </Button>
  );
}
