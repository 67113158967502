import { Modal, ModalProps, useBreakpointValue } from "@chakra-ui/react";

export interface CustomModalProps extends ModalProps {}

export function CustomModal({ children, ...props }: CustomModalProps) {
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Modal
      size={isSmallScreen ? "full" : "6xl"}
      isCentered={isSmallScreen ? false : true}
      {...props}
    >
      {children}
    </Modal>
  );
}
