import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { externalApi } from "../../api/externalApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { ErrorPage } from "../ErrorPage";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { OrderWizard } from "./OrderWizard/OrderWizard";
import { LandingPageLayout, ErrorCode } from "../../types";
import { LandingPageView } from "./LandingPageView/LandingPageView";
import { useRequiredParams } from "../../hooks/useRequiredParams";
import { useTranslation } from "react-i18next";
import { OrderSheet } from "./OrderSheet/OrderSheet";
import { useWindowHeight } from "../../hooks/useWindowHeight";
import { LicenseType } from "../../api/customersApi";

export default function External() {
  const { t } = useTranslation();

  const { customerSlug, projectSlug } = useRequiredParams(
    "customerSlug",
    "projectSlug",
  );

  const [project, loading, error, fetchProject] = useApiRequest(
    externalApi.getProjectDetails,
  );

  const windowHeight = useWindowHeight();

  useEffect(() => {
    fetchProject(customerSlug, projectSlug);
  }, [customerSlug, fetchProject, projectSlug]);

  if (error?.errorCode === ErrorCode.ProjectStatusIsInactive) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Text fontSize="4xl" fontWeight="bold">
          {t("external.projectIsClosed")}
        </Text>
      </Flex>
    );
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (loading || !project) {
    return <LoadingIndicator position="fullscreen" />;
  }

  return (
    <Box
      style={{ minHeight: windowHeight }}
      display="flex"
      flexDirection="column"
      bg="white"
    >
      <Routes>
        <Route
          path="order"
          element={
            project.licenseType === LicenseType.NoLicense ? (
              <Navigate to="order-sheet" replace={true} />
            ) : (
              <OrderWizard
                project={project}
                customerSlug={customerSlug}
                projectSlug={projectSlug}
              />
            )
          }
        />
        <Route
          path="order-sheet"
          element={
            <OrderSheet
              project={project}
              customerSlug={customerSlug}
              projectSlug={projectSlug}
            />
          }
        />
        <Route
          path="*"
          element={
            project.licenseType === LicenseType.NoLicense ? (
              <Navigate to="order-sheet" replace={true} />
            ) : project.landingPage.layout === LandingPageLayout.None ? (
              <Navigate to="order" replace={true} />
            ) : (
              <LandingPageView
                project={project}
                customerSlug={customerSlug}
                projectSlug={projectSlug}
              />
            )
          }
        />
      </Routes>
    </Box>
  );
}
