import { Button } from "@chakra-ui/button";
import {
  Box,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState, Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CustomerProductListItem,
  customerProductsApi,
} from "../../api/customerProductsApi";
import { CustomerDetails } from "../../api/customersApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { PagingOptions, SortingOptions } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Icons } from "../shared/Icons";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Card } from "../shared/Card";
import { Page } from "../shared/Page";
import { Pagination } from "../shared/Pagination/Pagination";
import { TableSortingProvider } from "../shared/DataTable/hooks";
import { DataTableHeader } from "../shared/DataTable/DataTableHeader";
import { SearchFilterInput } from "../shared/SearchFilterInput";
import { Link } from "react-router-dom";
import placeholderImg from "../../assets/placeholder.jpg";
import { EmptyListAlert } from "../shared/EmptyListAlert";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { routes } from "../../routes";
import { PageSizePicker } from "../shared/PageSizePicker/PageSizePicker";
import { useGlobalPageSize } from "../../hooks/usePageSize";
import { useSavedSorting } from "../../hooks/useSavedSorting";
import { Image } from "../shared/Image";

interface ListOptions
  extends PagingOptions,
    SortingOptions<CustomerProductListItem> {
  searchTerm: string;
}

export function CustomerProductsView({
  customer,
}: {
  customer: CustomerDetails;
}) {
  const { t } = useTranslation();
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const { savedSorting, setSavedSorting } =
    useSavedSorting<CustomerProductListItem>("CustomerProductsView", {
      sortingKey: "productGroupName",
      sortingDesc: false,
    });
  const [listOptions, setListOptions] = useState<ListOptions>({
    ...savedSorting,
    page: 1,
    pageSize: globalPageSize,
    searchTerm: "",
  });
  const [products, isLoading, error, fetch] = useApiRequest(
    customerProductsApi.listCustomerProducts,
  );
  const [expandedProductIds, setExpandedProductIds] = useState<string[]>([]);

  function isExpanded(productId: string) {
    return expandedProductIds.indexOf(productId) >= 0;
  }

  function onProductExpandClicked(productId: string) {
    const i = expandedProductIds.indexOf(productId);
    if (i >= 0) {
      const newExpandedProductIds = [...expandedProductIds];
      newExpandedProductIds.splice(i, 1);
      setExpandedProductIds(newExpandedProductIds);
    } else {
      setExpandedProductIds([...expandedProductIds, productId]);
    }
  }

  const handleSortedChange = (headerKey: keyof CustomerProductListItem) => {
    setListOptions((options) => ({
      ...options,
      sortingKey: headerKey,
      sortingDesc:
        listOptions.sortingKey === headerKey ? !listOptions.sortingDesc : false,
    }));
    setSavedSorting({
      sortingKey: headerKey,
      sortingDesc:
        listOptions.sortingKey === headerKey ? !listOptions.sortingDesc : false,
    });
  };

  useEffect(() => {
    fetch({ customerId: customer.id, ...listOptions });
  }, [fetch, customer.id, listOptions]);

  return (
    <Page breadcrumbs={useBreadcrumbs(customer.id, customer.name)}>
      <Card
        titleContent={t("product.products")}
        extraContent={
          <Button size="sm" as={Link} to="edit">
            {t("general.edit")}
          </Button>
        }
      >
        {isLoading && <LoadingIndicator />}
        {error && <ErrorDetails error={error} />}

        <Box mb={4}>
          <SearchFilterInput
            value={listOptions.searchTerm}
            onChange={(value) =>
              setListOptions((options) => ({
                ...options,
                searchTerm: value,
                page: 1,
              }))
            }
          />
        </Box>
        {products && (
          <>
            <TableSortingProvider
              value={{
                sorting: listOptions,
                onToggleSorting: handleSortedChange,
              }}
            >
              <Table>
                <Thead>
                  <Tr>
                    <Th width="65px" />
                    <Th />
                    <DataTableHeader
                      columnKey="name"
                      children={t("general.name")}
                      sortable={true}
                    />
                    <DataTableHeader
                      columnKey="productGroupName"
                      children={t("productGroup.productGroup")}
                      sortable={true}
                    />
                    <Th>{t("product.variants")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {products.items.map((p) => {
                    const expanded = isExpanded(p.id);
                    return (
                      <Fragment key={p.id}>
                        <Tr>
                          <Td>
                            {p.variants.length > 0 && (
                              <IconButton
                                variant="ghost"
                                aria-label="expand"
                                color="blackAlpha.800"
                                icon={
                                  expanded ? (
                                    <Icons.ArrowDown boxSize="6" />
                                  ) : (
                                    <Icons.ArrowRight boxSize="6" />
                                  )
                                }
                                size="sm"
                                onClick={() => onProductExpandClicked(p.id)}
                              />
                            )}
                          </Td>
                          <Td>
                            <Image
                              src={p.imageThumbnailUrl ?? placeholderImg}
                              boxSize={12}
                              objectFit="contain"
                            />
                          </Td>
                          <Td>{p.name}</Td>
                          <Td>{p.productGroupName}</Td>
                          <Td>{p.variants.length}</Td>
                        </Tr>
                        {expanded &&
                          p.variants.map((v) => (
                            <Tr key={v.id} backgroundColor="gray.100">
                              <Td />
                              <Td>
                                <Image
                                  src={
                                    v.imageThumbnailUrl ??
                                    p.imageThumbnailUrl ??
                                    placeholderImg
                                  }
                                  boxSize={12}
                                  objectFit="contain"
                                />
                              </Td>
                              <Td colSpan={5}>{v.name}</Td>
                            </Tr>
                          ))}
                      </Fragment>
                    );
                  })}
                </Tbody>
              </Table>
            </TableSortingProvider>
            {products?.items.length === 0 && <EmptyListAlert />}
            <HStack justifyContent="flex-end" mt={4}>
              <PageSizePicker
                pageSizes={[15, 25, 50, 100]}
                onPageSizeChange={(pageSize) => {
                  setListOptions({ ...listOptions, pageSize });
                  setGlobalPageSize(pageSize);
                }}
                pageSize={listOptions.pageSize}
              />
              <Pagination
                totalItems={products?.totalItems}
                pageSize={listOptions.pageSize}
                currentPage={listOptions.page}
                onPageChange={(p) =>
                  setListOptions({ ...listOptions, page: p })
                }
              />
            </HStack>
          </>
        )}
      </Card>
    </Page>
  );
}

function useBreadcrumbs(customerId: string, customerName: string) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("customer.customers"),
        to: routes.customers,
      },
      {
        label: customerName,
        to: routes.customer(customerId),
      },
      {
        label: t("assortment.assortment"),
      },
    ],
    [customerName, customerId, t],
  );
}
