import { Button } from "@chakra-ui/button";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Stack } from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usersApi } from "../../api/usersApi";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ServerError } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { RolesAsyncSelect } from "../shared/AsyncSelect/RolesAsyncSelect";

export function CreateUserModal({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: () => void;
}) {
  const [createUserError, setCreateUserError] = useState<ServerError>();
  const { t } = useTranslation();
  const toast = useToastNotification();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
  } = useForm<CreateUserFormData>();

  const [isLoading, request] = useApiRequestCallback(usersApi.createUser);
  const onSubmit = useCallback(
    (data: CreateUserFormData) => {
      request({
        onSuccess: () => {
          onSuccess();
          toast({ title: t("general.saved"), status: "success" });
        },
        onError: (e) => setCreateUserError(e),
      }).send(data);
    },
    [onSuccess, request, t, toast],
  );

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t("general.add")} {t("user.user").toLowerCase()}
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <ModalBody>
            {isLoading && <LoadingIndicator />}
            <Stack>
              {createUserError && <ErrorDetails error={createUserError} />}
              <FormControl isInvalid={!!errors.email} isRequired={true}>
                <FormLabel htmlFor="email">{t("user.email")}</FormLabel>
                <Input
                  id="email"
                  type="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: t("error.required"),
                    },
                  })}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="firstName"
                isRequired={true}
                isInvalid={!!errors.firstName}
              >
                <FormLabel>{t("user.firstName")}</FormLabel>
                <Input
                  {...register("firstName", {
                    required: { value: true, message: t("error.required") },
                  })}
                />
                <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="lastName"
                isRequired={true}
                isInvalid={!!errors.lastName}
              >
                <FormLabel>{t("user.lastName")}</FormLabel>
                <Input
                  {...register("lastName", {
                    required: { value: true, message: t("error.required") },
                  })}
                />
                <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="roleId"
                isRequired={true}
                isInvalid={!!errors.roleId}
              >
                <FormLabel>{t("role.role")}</FormLabel>
                <Controller
                  name="roleId"
                  render={({ field, fieldState }) => (
                    <RolesAsyncSelect {...field} {...fieldState} />
                  )}
                  control={control}
                  rules={{
                    required: { value: true, message: t("error.required") },
                  }}
                />
                <FormErrorMessage>{errors.roleId?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button type="submit" isLoading={isSubmitting}>
              {t("general.add")}
            </Button>
          </ModalFooter>
        </form>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}
interface CreateUserFormData {
  email: string;
  firstName: string;
  lastName: string;
  roleId: string;
}
