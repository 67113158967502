import { Box } from "@chakra-ui/react";
import { useMemo } from "react";
import { OrderProductPackage } from "../../../api/externalApi";
import { useTranslation } from "react-i18next";
import { SelectedOrderStep, SelectedProduct } from "./types";
import { Step, Steps } from "./Steps";

export function OrderSteps({
  hideProductPackageSelect,
  hideKitchenSceneSelect,
  hideOtherOptions,
  hideStylesSelect,
  hideSummary,
  isAllOtherCategoriesComplete,
  selectedOrderStep,
  selectedProductPackage,
  selectedProductByProductGroupId,
  selectOrderStep,
}: {
  hideProductPackageSelect: boolean;
  hideKitchenSceneSelect: boolean;
  hideOtherOptions: boolean;
  hideStylesSelect: boolean;
  hideSummary: boolean;
  isAllOtherCategoriesComplete: boolean;
  selectedOrderStep: SelectedOrderStep;
  selectedProductPackage: OrderProductPackage;
  selectedProductByProductGroupId: Record<string, SelectedProduct>;
  selectOrderStep: (step: SelectedOrderStep) => void;
  projectSlug: string;
}) {
  const { t } = useTranslation();
  const steps = useMemo<Step[]>(
    () => [
      ...(hideProductPackageSelect
        ? []
        : [
            {
              label: t("productPackage.productPackages"),
              complete: true,
              active: selectedOrderStep.type === "ProductPackages",
              onSelect: () => selectOrderStep({ type: "ProductPackages" }),
              disabled: selectedOrderStep.type === "Confirmation",
            },
          ]),
      ...(hideKitchenSceneSelect
        ? []
        : [
            {
              label: t("scenes.kitchenType"),
              complete: true,
              active: selectedOrderStep.type === "KitchenScene",
              onSelect: () => selectOrderStep({ type: "KitchenScene" }),
              disabled: selectedOrderStep.type === "Confirmation",
            },
          ]),
      ...(hideStylesSelect
        ? []
        : [
            {
              label: t("style.styles"),
              complete: false,
              active: selectedOrderStep.type === "Styles",
              onSelect: () => selectOrderStep({ type: "Styles" }),
              disabled: selectedOrderStep.type === "Confirmation",
            },
          ]),
      ...selectedProductPackage.renderedProductGroups.map((productGroup) => ({
        label: productGroup.name,
        complete:
          selectedProductByProductGroupId[productGroup.id] !== undefined,
        active:
          selectedOrderStep.type === "RenderedProductGroup" &&
          selectedOrderStep.productGroup.id === productGroup.id,
        onSelect: () =>
          selectOrderStep({
            type: "RenderedProductGroup",
            productGroup: productGroup,
          }),
        disabled: selectedOrderStep.type === "Confirmation",
      })),
      ...(hideOtherOptions
        ? []
        : [
            {
              label:
                selectedProductPackage.renderedProductGroups.length === 0
                  ? selectedProductPackage.name
                  : t("external.otherCategories"),
              complete: isAllOtherCategoriesComplete,
              active: selectedOrderStep.type === "NonRenderedProductGroup",
              onSelect: () =>
                selectOrderStep({ type: "NonRenderedProductGroup" }),
              disabled: selectedOrderStep.type === "Confirmation",
            },
          ]),
      ...(hideSummary
        ? []
        : [
            {
              label: t("external.summary"),
              complete: selectedOrderStep.type === "Confirmation",
              active: selectedOrderStep.type === "Summary",
              onSelect: () => selectOrderStep({ type: "Summary" }),
              disabled: selectedOrderStep.type === "Confirmation",
            },
          ]),
    ],
    [
      t,
      selectOrderStep,
      selectedOrderStep,
      selectedProductPackage,
      selectedProductByProductGroupId,
      hideProductPackageSelect,
      hideKitchenSceneSelect,
      hideOtherOptions,
      hideStylesSelect,
      hideSummary,
      isAllOtherCategoriesComplete,
    ],
  );

  return (
    <Box
      position="absolute"
      right={{ base: "2", xl: "500px" }}
      left={0}
      zIndex="50"
    >
      <Steps steps={steps} />
    </Box>
  );
}
