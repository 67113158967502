import { ApiRequest } from "../hooks/useApi/types";
import { PagedItems, PagingOptions, SortingOptions } from "../types";

export const workStepsApi = {
  listWorkSteps:
    (request: ListWorkStepsRequest): ApiRequest<PagedItems<WorkStepListItem>> =>
    (httpClient) =>
      httpClient.get("v1/work-steps", { params: request }),

  getWorkStep:
    (id: string): ApiRequest<WorkStepDetails> =>
    (httpClient) =>
      httpClient.get(`v1/work-steps/${id}`),

  createWorkStep:
    (request: CreateWorkStepRequest): ApiRequest<WorkStepDetails> =>
    (httpClient) =>
      httpClient.post("v1/work-steps", { data: request }),

  updateWorkStep:
    (id: string, request: UpdateWorkStepRequest): ApiRequest<WorkStepDetails> =>
    (httpClient) =>
      httpClient.put(`v1/work-steps/${id}`, { data: request }),

  deleteWorkStep:
    (workStepId: string): ApiRequest =>
    (httpClient) =>
      httpClient.delete(`v1/work-steps/${workStepId}`),
};

interface ListWorkStepsRequest
  extends SortingOptions<WorkStepListItem>,
    PagingOptions {
  searchTerm?: string;
}

export interface WorkStepListItem {
  id: string;
  name: string;
  productGroupName: string;
}

export interface CreateWorkStepRequest {
  name: string;
  productGroupId?: string;
}

export interface UpdateWorkStepRequest {
  name: string;
  productGroupId?: string;
}

export interface WorkStepDetails {
  id: string;
  name: string;
  productGroupId: string;
}
