import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, IconButton } from "@chakra-ui/react";
import { Icons } from "../shared/Icons";
import { useBobutikenBaseUrl } from "../../hooks/usBobutikenBaseUrl";
import { useToastNotification } from "../../hooks/useToastNotification";

export function PublicLinkButton({ route }: { route: string }) {
  const { t } = useTranslation();
  const bobutikenBaseUrl = useBobutikenBaseUrl();
  const toast = useToastNotification();

  return (
    <Box>
      <IconButton
        margin="5px"
        variant="ghost"
        aria-label="expand"
        color="blackAlpha.800"
        icon={<Icons.Copy />}
        size="sm"
        onClick={() => {
          navigator.clipboard.writeText(`${bobutikenBaseUrl}${route}`);
          toast({
            title: t("general.copied"),
            status: "success",
          });
        }}
      />
      <Button
        as={RouterLink}
        to={route}
        target="_blank"
        variant="outline"
        leftIcon={<Icons.ExternalLink boxSize={5} />}
      >
        {t("project.publicLink")}
      </Button>
    </Box>
  );
}
