import { useCallback, useState } from "react";
import { SortingOptions } from "../types";

function getStorageValue<T>(uniqueKey: string): SortingOptions<T> {
  const sortingKey = localStorage.getItem(getStorageKey(uniqueKey));
  return sortingKey !== null ? JSON.parse(sortingKey) : null;
}

export function useSavedSorting<T>(
  uniqueKey: string,
  defaultValue: SortingOptions<T>,
) {
  const [value, setValue] = useState<SortingOptions<T>>(() => {
    return getStorageValue(uniqueKey) ?? defaultValue;
  });

  const updateSorting = useCallback(
    (newValue: SortingOptions<T>) => {
      setValue(newValue);
      localStorage.setItem(getStorageKey(uniqueKey), JSON.stringify(newValue));
    },
    [uniqueKey],
  );

  return { savedSorting: value, setSavedSorting: updateSorting };
}

function getStorageKey(uniqueKey: string) {
  return `sortingKey.${uniqueKey}`;
}
