// A simplified version of the material-ui pagination hook
// Origin: https://github.com/mui-org/material-ui/tree/next/packages/material-ui/src/usePagination

export interface UsePaginationProps {
  // Number of always visible pages at the beginning and end.
  boundaryCount: number;
  // The total number of pages.
  count: number;
  // The current page.
  page: number;
  // Number of always visible pages before and after the current page.
  siblingCount: number;
}

type PaginationItem = "start-ellipsis" | number | "end-ellipsis";

export default function usePagination({
  count,
  page,
  siblingCount,
  boundaryCount,
}: UsePaginationProps) {
  const startPages = range(1, Math.min(boundaryCount, count));
  const endPages = range(
    Math.max(count - boundaryCount + 1, boundaryCount + 1),
    count,
  );

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      page - siblingCount,
      // Lower boundary when page is high
      count - boundaryCount - siblingCount * 2 - 1,
    ),
    // Greater than startPages
    boundaryCount + 2,
  );

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      page + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2,
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : count - 1,
  );

  // Basic list of items to render
  // e.g. [1, 'start-ellipsis', 4, 5, 6, 'end-ellipsis', 10]
  const items: PaginationItem[] = [
    ...startPages,

    // Start ellipsis
    ...(siblingsStart > boundaryCount + 2
      ? ["start-ellipsis" as const]
      : boundaryCount + 1 < count - boundaryCount
      ? [boundaryCount + 1]
      : []),

    // Sibling pages
    ...range(siblingsStart, siblingsEnd),

    // End ellipsis
    ...(siblingsEnd < count - boundaryCount - 1
      ? ["end-ellipsis" as const]
      : count - boundaryCount > boundaryCount
      ? [count - boundaryCount]
      : []),

    ...endPages,
  ];

  return { items, hasPrev: page > 1, hasNext: page < count };
}

// https://dev.to/namirsab/comment/2050
const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};
