import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  productPackageApi,
  ProductPackageDetails,
} from "../../api/productPackageApi";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Card } from "../shared/Card";
import { Controller, useForm } from "react-hook-form";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { ServerError } from "../../types";
import { RichTextEditor } from "../shared/RichText/RichTextEditor";
import { useToastNotification } from "../../hooks/useToastNotification";

interface FormData {
  name: string;
  description: string;
  price: number | null;
}

export function ProductPackageDetailsForm({
  productPackageDetails,
  onUpdate,
}: {
  productPackageDetails: ProductPackageDetails;
  onUpdate: (details: ProductPackageDetails) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();

  const [loading, updateCustomerRequest] = useApiRequestCallback(
    productPackageApi.updateProductPackageDetails,
  );
  const [error, setError] = useState<ServerError | null>();

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    reset,
    control,
  } = useForm<FormData>({ defaultValues: productPackageDetails });

  function handleUpdateSuccess(updatedCustomer: ProductPackageDetails) {
    reset(updatedCustomer);
    toast({ title: t("general.saved"), status: "success" });
    setError(null);
    onUpdate(updatedCustomer);
  }

  async function onSubmit({ name, description, price }: FormData) {
    updateCustomerRequest({
      onSuccess: handleUpdateSuccess,
      onError: setError,
    }).send(productPackageDetails.id, { name, description, price });
  }

  return (
    <Stack spacing={4} shouldWrapChildren={true}>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {error && <ErrorDetails error={error} />}
          {loading && <LoadingIndicator />}
          <SimpleGrid spacing={4}>
            <FormControl id="name" isRequired={true} isInvalid={!!errors.name}>
              <FormLabel>{t("general.name")}</FormLabel>
              <Input
                {...register("name", {
                  required: { value: true, message: t("error.required") },
                })}
              />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id="description" isInvalid={!!errors.description}>
              <FormLabel>{t("general.description")}</FormLabel>
              <Controller
                name="description"
                render={({ field }) => (
                  <RichTextEditor
                    value={field.value}
                    onChange={field.onChange}
                    showFontFamilyOptions={true}
                  />
                )}
                control={control}
              />
              <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id="price" isInvalid={!!errors.price}>
              <FormLabel>{t("general.price")}</FormLabel>
              <Controller
                name="price"
                defaultValue={null}
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    min={0}
                    value={field.value ?? ""}
                    onChange={(_, value) =>
                      field.onChange(isNaN(value) ? null : value)
                    }
                  >
                    <NumberInputField />
                  </NumberInput>
                )}
                control={control}
              />
              <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
            </FormControl>
            <Flex justifyContent="flex-end">
              <Button type="submit" disabled={!isDirty}>
                {t("general.save")}
              </Button>
            </Flex>
          </SimpleGrid>
        </form>
      </Card>
    </Stack>
  );
}
