import { Configuration, ServerError } from "../../types";

export enum ConfigurationStatus {
  Fetching,
  Loaded,
  Error,
}

export type ConfigurationState =
  | {
      status: ConfigurationStatus.Fetching;
    }
  | {
      status: ConfigurationStatus.Loaded;
      configuration: Configuration;
    }
  | {
      status: ConfigurationStatus.Error;
      error: ServerError;
    };
