import React from "react";

const isDev = process.env.NODE_ENV !== "production";

const NO_PROVIDER = {};

function createUseContext<Value>(context: React.Context<Value>) {
  return () => {
    const value = React.useContext<Value>(context);
    if (isDev && value === NO_PROVIDER) {
      // eslint-disable-next-line no-console
      console.warn("Component must be wrapped with Provider.");
    }
    return value;
  };
}

export function createContextHookValue<Props, Value>(
  useValue: (props: Props) => Value,
): [React.FC<Props>, () => Value] {
  const context = React.createContext<Value>(NO_PROVIDER as Value);
  if (isDev) {
    context.displayName = useValue.name;
  }

  const Provider: React.FC<Props> = ({ children, ...props }) => {
    const value = useValue(props as Props);
    return <context.Provider value={value}>{children}</context.Provider>;
  };

  return [Provider, createUseContext<Value>(context)];
}

export function createContextValue<Value>(): [
  React.Provider<Value>,
  () => Value,
] {
  const context = React.createContext<Value>(NO_PROVIDER as Value);
  return [context.Provider, createUseContext<Value>(context)];
}
