import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Button,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { stylesApi, StyleDetails } from "../../api/stylesApi";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { ServerError } from "../../types";
import { CustomModal } from "../shared/CustomModal";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";

export function CreateStyleModal({
  customerId,
  onClose,
  onSuccess,
}: {
  customerId: string;
  onClose: () => void;
  onSuccess: (style: StyleDetails) => void;
}) {
  const [error, setError] = useState<ServerError>();
  const { register, handleSubmit } = useForm<AddStyleFormData>();
  const [loading, request] = useApiRequestCallback(stylesApi.createStyle);

  const { t } = useTranslation();

  function onSubmit(data: AddStyleFormData) {
    request({ onSuccess, onError: setError }).send({
      customerId,
      name: data.name,
    });
  }
  return (
    <CustomModal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {error && <ErrorDetails error={error} />}
        {loading && <LoadingIndicator />}
        <ModalHeader>
          {t("general.add")} {t("style.style").toLowerCase()}
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <ModalBody>
            <FormControl isRequired={true}>
              <FormLabel>{t("general.name")}</FormLabel>
              <Input
                {...register("name", {
                  required: { value: true, message: t("error.required") },
                })}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button type="submit">{t("general.save")}</Button>
          </ModalFooter>
        </form>
        <ModalCloseButton />
      </ModalContent>
    </CustomModal>
  );
}

interface AddStyleFormData {
  name: string;
}
