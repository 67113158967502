import { useCallback, useEffect, useRef, useState } from "react";
import { ServerError } from "../../types";
import { useHttpClient } from "../useHttpClient";
import { ApiRequest } from "./types";

interface ApiResourceOptions {
  clearDataOnLoad?: boolean;
}

export function useApiRequest<A extends any[], R>(
  apiRequestFactory: (...args: A) => ApiRequest<R>,
  options?: ApiResourceOptions,
) {
  const httpClient = useHttpClient();
  const isMountedRef = useRef(true);
  const latestRequestSymbol = useRef<symbol>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<R | null>(null);
  const [error, setError] = useState<ServerError | null>(null);
  const clearDataOnLoad = options?.clearDataOnLoad ?? false;

  const set = useCallback((value: (R | null) | ((v: R | null) => R | null)) => {
    setData(value);
    setError(null);
    setLoading(false);
    latestRequestSymbol.current = undefined;
  }, []);

  const refresh = useCallback(
    async (...args: A) => {
      if (!isMountedRef.current) {
        return;
      }

      setLoading(true);

      if (clearDataOnLoad) {
        setData(null);
      }

      const currentRequestSymbol = Symbol();
      latestRequestSymbol.current = currentRequestSymbol;

      const apiRequest = apiRequestFactory(...args);
      const response = await apiRequest(httpClient);

      if (
        currentRequestSymbol !== latestRequestSymbol.current ||
        !isMountedRef.current
      ) {
        return;
      }

      if (response.hasError) {
        setError(response.error);
        setData(null);
      } else {
        setError(null);
        setData(response.data);
      }

      setLoading(false);
    },
    [apiRequestFactory, clearDataOnLoad, httpClient],
  );

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return [data, loading, error, refresh, set] as const;
}
