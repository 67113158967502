import ReactSelect, {
  GroupBase,
  Props as ReactSelectProps,
  StylesConfig,
  OnChangeValue,
} from "react-select";
import {
  CloseButton,
  Divider,
  Flex,
  Portal,
  useMultiStyleConfig,
  useTheme,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Ref } from "react";
import { SelectSize, SharedSelectProps } from "./types";
import { SelectComponentsConfig } from "react-select/dist/declarations/src/components";

declare module "react-select/dist/declarations/src/Select" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Props<Option, IsMulti, Group> {
    [key: string]: any;
  }
}

export interface SelectProps<
  Option,
  IsMulti extends boolean,
  GroupType extends GroupBase<Option>,
> extends ReactSelectProps<Option, IsMulti, GroupType>,
    SharedSelectProps {
  value: OnChangeValue<Option, IsMulti>;
  innerRef?: Ref<any>;
}

export function Select<
  Option,
  IsMulti extends boolean,
  GroupType extends GroupBase<Option>,
>({
  styles = {},
  components = {},
  size = "md",
  placeholder,
  isDisabled,
  ...props
}: SelectProps<Option, IsMulti, GroupType>) {
  const chakraTheme = useTheme();
  const { t } = useTranslation();

  return (
    <ReactSelect
      isSearchable={!isDisabled}
      menuIsOpen={isDisabled ? false : undefined}
      ref={props.innerRef}
      components={{
        ...selectComponents,
        ...components,
      }}
      styles={{
        ...selectStyles,
        ...styles,
      }}
      theme={(baseTheme) => ({
        ...baseTheme,
        colors: {
          ...baseTheme.colors,
          primary: chakraTheme.colors.primary[400],
          primary75: chakraTheme.colors.primary[300],
          primary50: chakraTheme.colors.primary[200],
          primary25: chakraTheme.colors.primary[100],
          danger: chakraTheme.colors.red[500],
          dangerLight: chakraTheme.colors.red[200],
        },
      })}
      size={size}
      placeholder={
        isDisabled
          ? t("general.noPermissionToSelect")
          : placeholder ?? t("general.select")
      }
      noOptionsMessage={() => t("general.noItemsFound")}
      {...props}
    />
  );
}

const selectStyles: StylesConfig<any, any, any> = {
  valueContainer: (provided) => ({
    ...provided,
    padding: "0.125rem 1rem",
  }),
};

const selectComponents: SelectComponentsConfig<any, any, any> = {
  Control: ({
    children,
    innerRef,
    innerProps,
    selectProps: { size, invalid },
  }) => {
    const inputStyles = useMultiStyleConfig("Input", { size, disabled: true });

    const heights: Record<SelectSize, number> = {
      sm: 8,
      md: 10,
    };

    return (
      <Flex
        ref={innerRef}
        sx={{
          ...inputStyles.field,
          p: 0,
          overflow: "hidden",
          h: "auto",
          minH: heights[size as SelectSize],
        }}
        {...innerProps}
        aria-invalid={invalid}
      >
        {children}
      </Flex>
    );
  },
  IndicatorSeparator: ({ innerProps }) => (
    <Divider {...(innerProps as any)} orientation="vertical" opacity="1" />
  ),
  ClearIndicator: ({ innerProps, selectProps: { size } }) => (
    <CloseButton {...(innerProps as any)} size={size} mx={2} tabIndex={-1} />
  ),
  MenuPortal: ({ children }) => <Portal>{children}</Portal>,
};
