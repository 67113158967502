import { Button } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { CustomerDetails } from "../../api/customersApi";
import {
  productPackageApi,
  ProductPackageDetails,
  ProductPackageListItem,
} from "../../api/productPackageApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { PagingOptions, SortingOptions } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Card } from "../shared/Card";
import { DataTable, Column } from "../shared/DataTable/DataTable";
import { Page } from "../shared/Page";
import { CreateProductPackageModal } from "./CreateProductPackageModal";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { routes } from "../../routes";
import { useGlobalPageSize } from "../../hooks/usePageSize";
import { useSavedSorting } from "../../hooks/useSavedSorting";

export function ProductPackageList({
  customer,
  onUpdate,
}: {
  customer: CustomerDetails;
  onUpdate: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const { savedSorting, setSavedSorting } =
    useSavedSorting<ProductPackageListItem>("ProductPackageList", {
      sortingDesc: false,
      sortingKey: "name",
    });
  const [paging, setPaging] = useState<PagingOptions>({
    page: 1,
    pageSize: globalPageSize,
  });
  const [sorting, setSorting] = useState<
    SortingOptions<ProductPackageListItem>
  >({ ...savedSorting });
  const [packages, isLoading, error, fetch] = useApiRequest(
    productPackageApi.listProductPackages,
  );

  useEffect(() => {
    fetch({
      ...paging,
      ...sorting,
      customerId: customer.id,
    });
  }, [customer.id, fetch, paging, sorting]);

  function onProductPackageCreated(productPackage: ProductPackageDetails) {
    navigate(routes.productPackage(customer.id, productPackage.id));
    onUpdate();
  }

  const columns: Column<ProductPackageListItem>[] = [
    {
      key: "name",
      sortable: true,
      header: t("general.name"),
      cell: ({ row }) => (
        <>
          {customer.name} - {row.name}
        </>
      ),
    },
    {
      key: "actions",
      align: "right",
      cell: ({ row }) => (
        <Button
          as={RouterLink}
          to={routes.productPackage(customer.id, row.id)}
          size="sm"
        >
          {t("general.edit")}
        </Button>
      ),
    },
  ];

  return (
    <Page breadcrumbs={useBreadcrumbs(customer.id, customer.name)}>
      {createModalVisible && (
        <CreateProductPackageModal
          customerId={customer.id}
          onClose={() => setCreateModalVisible(false)}
          onSuccess={onProductPackageCreated}
        />
      )}
      <Card
        titleContent={t("productPackage.productPackages")}
        extraContent={
          <Button
            onClick={() => {
              setCreateModalVisible(true);
            }}
            size="sm"
          >
            {t("general.add")}
          </Button>
        }
      >
        {error && <ErrorDetails error={error} />}
        <DataTable
          columns={columns}
          isLoading={isLoading}
          onSortingChange={(sorting) => {
            setSorting(sorting);
            setSavedSorting(sorting);
          }}
          onPagingChange={(paging) => {
            setPaging((o) => ({ ...o, ...paging }));
            setGlobalPageSize(paging.pageSize);
          }}
          paging={paging}
          sorting={sorting}
          totalItems={packages?.totalItems ?? 0}
          data={packages?.items}
        />
      </Card>
    </Page>
  );
}

function useBreadcrumbs(customerId: string, customerName: string) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("customer.customers"),
        to: routes.customers,
      },
      {
        label: customerName,
        to: routes.customer(customerId),
      },
      {
        label: t("productPackage.productPackages"),
      },
    ],
    [customerName, customerId, t],
  );
}
