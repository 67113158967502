import { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProjectDetailsImageType, projectsApi } from "../../api/projectsApi";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ServerError } from "../../types";
import { ImageList } from "../shared/ImageList";

export function ProjectLogoImageForm({
  image,
  projectId,
  projectDetailsImageType,
  onUpdate,
}: {
  image: string | null;
  projectId: string;
  projectDetailsImageType: ProjectDetailsImageType;
  onUpdate: (image: string | null) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();

  const [uploadError, setUploadError] = useState<ServerError | null>(null);
  const [uploadLoading, uploadRequest] = useApiRequestCallback(
    projectsApi.uploadProjectLogoImage,
  );

  const [deleteError, setDeleteError] = useState<ServerError | null>(null);
  const [deleteLoading, deleteRequest] = useApiRequestCallback(
    projectsApi.deleteProjectLogoImage,
  );

  const uploadImage = useCallback(
    (image: File) => {
      uploadRequest({
        onSuccess: (response) => {
          setUploadError(null);
          toast({
            title: t("general.added"),
            status: "success",
          });
          onUpdate(response.image);
        },
        onError: setUploadError,
      }).send(projectId, image, projectDetailsImageType);
    },
    [uploadRequest, projectId, toast, t, onUpdate, projectDetailsImageType],
  );

  function deleteImage() {
    deleteRequest({
      onSuccess: () => {
        setDeleteError(null);
        toast({
          title: t("general.deleted"),
          status: "success",
        });
        onUpdate(null);
      },
      onError: setDeleteError,
    }).send(projectId, projectDetailsImageType);
  }

  const imageList = useMemo(
    () => (image ? [{ id: "image-logo", url: image }] : []),
    [image],
  );

  return (
    <ImageList
      maxImages={1}
      images={imageList}
      errors={[deleteError, uploadError]}
      isLoading={deleteLoading || uploadLoading}
      onUpload={uploadImage}
      onRemove={deleteImage}
    />
  );
}
