export const routes = {
  assortment: "/admin/assortment",

  customers: "/admin/customers",

  customer: (customerId: string) => `/admin/customers/${customerId}`,

  customerDetails: (customerId: string) =>
    `/admin/customers/${customerId}/details`,

  customerProducts: (customerId: string) =>
    `/admin/customers/${customerId}/products`,

  customerProductsEdit: (customerId: string) =>
    `/admin/customers/${customerId}/products/edit`,

  externalProject: (customerUrlSlug: string, projectUrlSlug: string) =>
    `/kund/${customerUrlSlug}/${projectUrlSlug}`,

  forgotPassword: "/forgot-password",

  productPackages: (customerId: string) =>
    `/admin/customers/${customerId}/packages`,

  productPackage: (customerId: string, packageId: string) =>
    `/admin/customers/${customerId}/packages/${packageId}`,

  projects: (customerId: string) => `/admin/customers/${customerId}/projects`,

  project: (customerId: string, projectId: string) =>
    `/admin/customers/${customerId}/projects/${projectId}`,

  projectLandingPage: (customerId: string, projectId: string) =>
    `/admin/customers/${customerId}/projects/${projectId}/landingpage`,

  projectDetails: (customerId: string, projectId: string) =>
    `/admin/customers/${customerId}/projects/${projectId}/details`,

  projectPackages: (customerId: string, projectId: string) =>
    `/admin/customers/${customerId}/projects/${projectId}/packages`,

  projectOrder: (customerId: string, projectId: string) =>
    `/admin/customers/${customerId}/projects/${projectId}/order`,

  orderConfirmationPage: (customerId: string, projectId: string) =>
    `/admin/customers/${customerId}/projects/${projectId}/orderconfirmationpage`,

  orderSheet: (customerId: string, projectId: string) =>
    `/admin/customers/${customerId}/projects/${projectId}/ordersheet`,

  orders: (customerId: string) => `/admin/customers/${customerId}/orders`,

  product: (productId: string) => `/admin/assortment/products/${productId}`,

  products: "/admin/assortment/products",

  productGroups: "/admin/assortment/product-groups",

  regions: "/admin/settings/regions",

  reports: "/admin/settings/reports",

  roles: "/admin/settings/roles",

  role: (roleId: string) => `/admin/settings/roles/${roleId}`,

  settings: "/admin/settings",

  styles: (customerId: string) => `/admin/customers/${customerId}/styles`,

  style: (customerId: string, styleId: string) =>
    `/admin/customers/${customerId}/styles/${styleId}`,

  users: "/admin/settings/users",

  user: (userId: string) => `/admin/settings/users/${userId}`,

  workPlans: (customerId: string) =>
    `/admin/customers/${customerId}/work-plans`,
  workPlan: (customerId: string, workPlanId: string) =>
    `/admin/customers/${customerId}/work-plans/${workPlanId}`,

  workSteps: "/admin/work-steps",
};
