import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { useForm } from "react-hook-form";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ServerError } from "../../types";
import { ProjectLinks, projectsApi } from "../../api/projectsApi";

export function ProjectLinksForm({
  projectId,
  projectlinks,
  onUpdate,
}: {
  projectlinks: ProjectLinks;
  projectId: string;
  onUpdate: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();

  const [loading, updateProjectLinksRequest] = useApiRequestCallback(
    projectsApi.updateProjectLinks,
  );
  const [error, setError] = useState<ServerError | null>();
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
  } = useForm<FormData>({ defaultValues: projectlinks });

  async function onSubmit({ linkToTerms }: FormData) {
    updateProjectLinksRequest({
      onSuccess: () => {
        toast({ title: t("general.saved"), status: "success" });
        onUpdate();
      },
      onError: setError,
    }).send(projectId, { linkToTerms: linkToTerms });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {error && <ErrorDetails error={error} />}
      {loading && <LoadingIndicator />}
      <SimpleGrid spacing={4} mt="6">
        <FormControl id="linkToTerms" isInvalid={!!errors.linkToTerms}>
          <FormLabel>{t("project.linkToTerms")}</FormLabel>
          <Input
            type="url"
            placeholder="https://www.exempel.se"
            pattern="https://.*"
            {...register("linkToTerms")}
          />
          <FormErrorMessage>{errors.linkToTerms?.message}</FormErrorMessage>
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button type="submit" disabled={!isDirty}>
            {t("general.save")}
          </Button>
        </Flex>
      </SimpleGrid>
    </form>
  );
}

interface FormData {
  linkToTerms: string;
}
