import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Checkbox,
  RadioGroup,
  HStack,
  Radio,
} from "@chakra-ui/react";
import React from "react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ProductGroupDetails,
  ProductGroupPrintLayout,
  productGroupPrintLayouts,
  productGroupsApi,
  RenderLayer,
  UpdateProductGroupRequest,
} from "../../api/productGroupsApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ServerError } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Select } from "../shared/Select/Select";
import { SharedSelectProps } from "../shared/Select/types";

export function ProductGroupModal({
  productGroupId,
  onSuccess,
  onClose,
}: {
  productGroupId: string | null;
  onSuccess: () => void;
  onClose: () => void;
}) {
  const [productGroup, loading, error, fetch] = useApiRequest(
    productGroupsApi.getProductGroup,
  );

  useEffect(() => {
    if (productGroupId) {
      fetch(productGroupId);
    }
  }, [fetch, productGroupId]);

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {loading && <LoadingIndicator />}
        {error && <ErrorDetails error={error} />}
        {(!productGroupId || (productGroupId && productGroup)) && (
          <ModalFormContent productGroup={productGroup} onSuccess={onSuccess} />
        )}
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}

interface ProductGroupFormData {
  name: string;
  is3DVisualized: boolean;
  renderLayer: RenderLayer | null;
  sortOrder: number;
  printLayout: ProductGroupPrintLayout;
}

function ModalFormContent({
  productGroup,
  onSuccess,
}: {
  productGroup: ProductGroupDetails | null;
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [error, setError] = useState<ServerError>();
  const [loadingCreateProductGroup, createProductGroupRequest] =
    useApiRequestCallback(productGroupsApi.createProductGroup);
  const [loadingUpdateProductGroup, updateProductGroupRequest] =
    useApiRequestCallback(productGroupsApi.updateProductGroup);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm<ProductGroupFormData>({
    defaultValues:
      productGroup != null
        ? {
            name: productGroup.name,
            is3DVisualized: productGroup.renderLayer != null,
            sortOrder: productGroup.sortOrder,
            printLayout: productGroup.printLayout,
            renderLayer: productGroup.renderLayer,
          }
        : {},
  });

  const is3DVisualized = watch("is3DVisualized");

  function handleSubmitSuccess() {
    toast({ title: t("general.saved"), status: "success" });
    onSuccess();
  }

  async function onSubmit(data: ProductGroupFormData) {
    const request: UpdateProductGroupRequest = {
      name: data.name,
      renderLayer: data.is3DVisualized ? data.renderLayer : null,
      sortOrder: data.sortOrder,
      printLayout: data.printLayout,
    };
    if (productGroup) {
      updateProductGroupRequest({
        onSuccess: handleSubmitSuccess,
        onError: setError,
      }).send(productGroup.id, request);
    } else {
      createProductGroupRequest({
        onSuccess: handleSubmitSuccess,
        onError: setError,
      }).send(request);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <ModalHeader>
        {productGroup
          ? productGroup.name
          : `${t("general.add")} ${t(
              "productGroup.productGroup",
            ).toLowerCase()}`}
      </ModalHeader>
      <ModalBody>
        {error && <ErrorDetails error={error} />}
        {(loadingCreateProductGroup || loadingUpdateProductGroup) && (
          <LoadingIndicator />
        )}
        <Stack spacing={4} shouldWrapChildren={true}>
          <FormControl id="name" isRequired={true} isInvalid={!!errors.name}>
            <FormLabel>{t("general.name")}</FormLabel>
            <Input
              {...register("name", {
                required: { value: true, message: t("error.required") },
              })}
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl
            id="sortOrder"
            isRequired={true}
            isInvalid={!!errors.sortOrder}
          >
            <FormLabel>{t("general.sortOrder")}</FormLabel>
            <Input
              {...register("sortOrder", {
                required: { value: true, message: t("error.required") },
              })}
              type="number"
              defaultValue={1000}
            />
            <FormErrorMessage>{errors.sortOrder?.message}</FormErrorMessage>
          </FormControl>

          <Checkbox {...register("is3DVisualized")}>
            {t("productGroup.3DVisualized")}
          </Checkbox>

          {is3DVisualized && (
            <FormControl
              id="renderLayer"
              isRequired={true}
              isInvalid={!!errors.renderLayer}
            >
              <FormLabel>{t("productGroup.renderLayer")}</FormLabel>
              <Controller
                name="renderLayer"
                render={({ field, fieldState }) => (
                  <RenderLayerAsyncSelect {...field} {...fieldState} />
                )}
                control={control}
                rules={{
                  required: { value: true, message: t("error.required") },
                }}
              />
            </FormControl>
          )}

          {productGroup && (
            <FormControl id="printLayout">
              <FormLabel>{t("productGroup.printLayout")}</FormLabel>
              <Controller
                name="printLayout"
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <HStack>
                      {productGroupPrintLayouts.map((printLayout) => (
                        <Radio key={printLayout} value={printLayout}>
                          {t(`productGroupPrintLayout.${printLayout}`)}
                        </Radio>
                      ))}
                    </HStack>
                  </RadioGroup>
                )}
                control={control}
              />
            </FormControl>
          )}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button type="submit">{t("general.save")}</Button>
      </ModalFooter>
    </form>
  );
}

interface RenderLayerAsyncSelectProps extends SharedSelectProps {
  value: RenderLayer | null;
  onChange: (renderLayer: RenderLayer | null) => void;
}

export const RenderLayerAsyncSelect = React.forwardRef(
  (
    { value, onChange, ...sharedSelectProps }: RenderLayerAsyncSelectProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const [
      renderLayers,
      renderLayersLoading,
      renderLayersError,
      fetchRenderLayers,
    ] = useApiRequest(productGroupsApi.listRenderLayers, {
      clearDataOnLoad: true,
    });

    return (
      <Select
        isMulti={false}
        innerRef={ref}
        value={value}
        options={renderLayers ?? []}
        isLoading={renderLayersLoading}
        getOptionValue={(i) => i.id}
        getOptionLabel={(i) => i.name}
        noOptionsMessage={() =>
          renderLayersError ? (
            <ErrorDetails error={renderLayersError} />
          ) : (
            t("general.noItemsFound")
          )
        }
        onChange={(selectedItem) => onChange(selectedItem)}
        onMenuOpen={fetchRenderLayers}
        {...sharedSelectProps}
      />
    );
  },
);
