import axios, { AxiosError } from "axios";
import {
  HttpClientResponse,
  Method,
  RequestOptions,
  RequestOptionsWithBody,
} from "./types";
import { SimpleEventDispatcher } from "ste-simple-events";
import { ServerErrorCode, ServerErrorDetails } from "../../types";

export function createHttpClient(baseUrl?: string) {
  const onUnauthorizedEvent = new SimpleEventDispatcher<null>();
  const axiosClient = axios.create({ timeout: 60000 });
  axiosClient.interceptors.response.use(undefined, errorResponseHandler);

  function errorResponseHandler(error: AxiosError<unknown>) {
    if (error.response && error.response.status === 401) {
      onUnauthorizedEvent.dispatch(null);
    }

    return Promise.reject(error);
  }

  async function request<T>(
    method: Method,
    url: string,
    options?: RequestOptionsWithBody,
  ): Promise<HttpClientResponse<T>> {
    try {
      const response = await axiosClient.request({
        url,
        method,
        baseURL: baseUrl,
        ...options,
      });
      return {
        hasError: false,
        status: response.status,
        data: response.data as unknown as T,
        headers: response.headers,
      };
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }

      if (!error.response) {
        return {
          hasError: true,
          status: 0,
          error: {
            errorCode: ServerErrorCode.Unknown,
            status: 0,
          },
        };
      }

      const { data, status } = error.response;

      if (!data.errorCode) {
        return {
          hasError: true,
          status: status,
          error: {
            errorCode: ServerErrorCode.Unknown,
            status,
          },
        };
      }

      return {
        hasError: true,
        status,
        error: data as ServerErrorDetails,
      };
    }
  }

  return {
    get: <T = unknown>(url: string, options?: RequestOptions) =>
      request<T>("GET", url, options),
    put: <T = unknown>(url: string, options?: RequestOptionsWithBody) =>
      request<T>("PUT", url, options),
    post: <T = unknown>(url: string, options?: RequestOptionsWithBody) =>
      request<T>("POST", url, options),
    delete: <T = unknown>(url: string, options?: RequestOptions) =>
      request<T>("DELETE", url, options),
    onUnauthorized: {
      subscribe: (fn: () => void) => onUnauthorizedEvent.subscribe(fn),
      unsubscribe: (fn: () => void) => onUnauthorizedEvent.unsubscribe(fn),
    },
  };
}

export type HttpClient = ReturnType<typeof createHttpClient>;
