import { ExternalProjectDetails } from "../../../api/externalApi";
import { LandingPageLayoutDesign } from "./LandingPageLayoutDesign";
import { Header } from "../Header";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { useWindowHeight } from "../../../hooks/useWindowHeight";

export function LandingPageView({
  project,
  customerSlug,
  projectSlug,
}: {
  project: ExternalProjectDetails;
  customerSlug: string;
  projectSlug: string;
}) {
  const windowHeight = useWindowHeight();
  const isSmallScreen = useBreakpointValue({ base: true, xl: false });

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          style={isSmallScreen ? {} : { maxWidth: (windowHeight + 150) * 1.4 }}
        >
          <Header
            customerSlug={customerSlug}
            projectSlug={projectSlug}
            logoImage={project.logoImage}
            hideLogo={project.hideLogo}
          />
        </Box>
      </Box>
      <LandingPageLayoutDesign
        landingPage={project.landingPage}
        buttonImage={project.buttonImage}
      />
    </>
  );
}
