import { Button, Stack } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Icons } from "../../shared/Icons";
import usePagination from "./hooks";

interface PaginationProps {
  totalItems: number;
  pageSize: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export function Pagination({
  totalItems,
  pageSize,
  currentPage,
  onPageChange,
}: PaginationProps) {
  const { items, hasPrev, hasNext } = usePagination({
    page: currentPage,
    count: Math.ceil(totalItems / pageSize),
    siblingCount: 1,
    boundaryCount: 1,
  });

  return (
    <Stack direction="row" spacing={1}>
      <PageButton
        disabled={!hasPrev}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <Icons.ArrowLeft />
      </PageButton>
      {items.map((item) =>
        item === "start-ellipsis" || item === "end-ellipsis" ? (
          <Ellipsis key={item} />
        ) : (
          <PageButton
            active={item === currentPage}
            key={item}
            onClick={() => onPageChange(item)}
          >
            {item}
          </PageButton>
        ),
      )}
      <PageButton
        disabled={!hasNext}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <Icons.ArrowRight />
      </PageButton>
    </Stack>
  );
}

const PageButton = ({
  active,
  disabled,
  children,
  onClick,
}: {
  active?: boolean;
  disabled?: boolean;
  children: ReactNode;
  onClick: () => void;
}) => {
  return (
    <Button
      size="sm"
      variant="outline"
      onClick={onClick}
      disabled={disabled}
      isActive={active}
    >
      {children}
    </Button>
  );
};

const Ellipsis = () => {
  return (
    <Button
      size="sm"
      aria-hidden="true"
      variant="ghost"
      disabled={true}
      _disabled={{ cursor: "default" }}
    >
      ...
    </Button>
  );
};
