import { ApiRequest } from "../hooks/useApi/types";
import { PagedItems, PagingOptions, SortingOptions } from "../types";

export const workPlansApi = {
  listWorkPlans:
    (request: ListWorkPlansRequest): ApiRequest<PagedItems<WorkPlanListItem>> =>
    (httpClient) =>
      httpClient.get("v1/work-plans", { params: request }),

  createWorkPlan:
    (data: CreateWorkPlanRequest): ApiRequest<WorkPlanDetails> =>
    (httpClient) =>
      httpClient.post("v1/work-plans", { data }),

  getWorkPlan:
    (workPlanId: string): ApiRequest<WorkPlanDetails> =>
    (httpClient) =>
      httpClient.get(`v1/work-plans/${workPlanId}`),

  updateWorkPlan:
    (
      workPlanId: string,
      data: UpdateWorkPlanRequest,
    ): ApiRequest<WorkPlanDetails> =>
    (httpClient) =>
      httpClient.put(`v1/work-plans/${workPlanId}`, { data }),

  deleteWorkPlan:
    (workPlanId: string): ApiRequest =>
    (httpClient) =>
      httpClient.delete(`v1/work-plans/${workPlanId}`),

  getWorkPlanSteps:
    (
      workPlanId: string,
      request: GetWorkPlanStepsRequest,
    ): ApiRequest<PagedItems<WorkPlanStepListItem>> =>
    (httpClient) =>
      httpClient.get(`v1/work-plans/${workPlanId}/steps`, { params: request }),

  updateWorkPlanSteps:
    (workPlanId: string, data: UpdateWorkPlanStepsRequestData): ApiRequest =>
    (httpClient) =>
      httpClient.put(`v1/work-plans/${workPlanId}/steps`, { data }),

  createWorkPlanStep:
    (workPlanId: string, data: CreateWorkPlanStepRequest): ApiRequest =>
    (httpClient) =>
      httpClient.post(`v1/work-plans/${workPlanId}/steps`, { data }),

  listGeneralWorkPlanSteps:
    (
      workPlanId: string,
      request: ListGeneralWorkPlanStepsRequest,
    ): ApiRequest<PagedItems<GeneralWorkPlanStepListItem>> =>
    (httpClient) =>
      httpClient.get(`/v1/work-plans/${workPlanId}/general-steps`, {
        params: request,
      }),

  updateGeneralWorkPlanSteps:
    (workPlanId: string, data: UpdateGeneralWorkPlanStepsRequest): ApiRequest =>
    (httpClient) =>
      httpClient.put(`/v1/work-plans/${workPlanId}/general-steps`, { data }),
};

export interface WorkPlanStepChange {
  note?: string;
}
export interface UpdateGeneralWorkPlanStepsRequest {
  custom?: Record<string, WorkPlanStepChange>;
  global?: Record<string, WorkPlanStepChange>;
}

export enum WorkPlanStepType {
  Global = "Global",
  Custom = "Custom",
}
export interface GeneralWorkPlanStepListItem {
  id: string;
  name: string;
  note: string;
  type: WorkPlanStepType;
}

export interface ListGeneralWorkPlanStepsRequest
  extends SortingOptions<GeneralWorkPlanStepListItem>,
    PagingOptions {}

interface ListWorkPlansRequest
  extends SortingOptions<WorkPlanListItem>,
    PagingOptions {
  searchTerm?: string;
  customerId: string;
}

export interface WorkPlanListItem {
  id: string;
  name: string;
  status: WorkPlanStatus;
  version: number;
}

interface GetWorkPlanStepsRequest
  extends SortingOptions<WorkPlanStepListItem>,
    PagingOptions {
  searchTerm?: string;
}

export interface WorkPlanStepListItem {
  id: string;
  workStepId?: string;
  customWorkPlanStepId?: string;
  name: string;
  productGroupName: string;
  performStatus: StepPerformStatus;
  note: string;
}

export interface CreateWorkPlanRequest {
  name: string;
  customerId: string;
  packageId: string | null;
  cloneWorkPlanId: string | null;
}

export interface WorkPlanDetails {
  id: string;
  name: string;
  status: WorkPlanStatus;
  contactDetailsCustomerId: string | null;
  packageId: string | null;
  startDate: string;
}

export interface UpdateWorkPlanRequest {
  name: string;
  status: WorkPlanStatus;
  contactDetailsCustomerId: string | null;
  packageId: string | null;
  startDate: Date;
}

export interface UpdateWorkPlanStepsRequestData {
  workSteps: ChangedWorkStep[];
  customWorkSteps: ChangedWorkStep[];
}

export interface ChangedWorkStep {
  id: string;
  performStatus: StepPerformStatus;
  note: string;
}

export interface CreateWorkPlanStepRequest {
  name: string;
  productGroupId?: string;
}

export enum WorkPlanStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum StepPerformStatus {
  Perform = "Perform",
  OptionalAddition = "OptionalAddition",
  WhenNecessary = "WhenNecessary",
  Omit = "Omit",
}
