import { ApiRequest } from "../hooks/useApi/types";
import {
  LandingPageLayout,
  PagedItems,
  PagingOptions,
  ServerError,
  SortingOptions,
} from "../types";
import { LicenseType } from "./customersApi";
import { ProductGroupListItem } from "./productGroupsApi";

export const projectsApi = {
  listProjects:
    (params: ListProjectsRequest): ApiRequest<PagedItems<ProjectListItem>> =>
    (httpClient) =>
      httpClient.get(`v1/projects`, { params }),

  createProject:
    (data: CreateProjectRequest): ApiRequest<ProjectDetails> =>
    (httpClient) =>
      httpClient.post("v1/projects", { data }),

  getProjectDetails:
    (id: string): ApiRequest<ProjectDetails> =>
    (httpClient) =>
      httpClient.get(`v1/projects/${id}`),

  updateProjectDetails:
    (
      id: string,
      data: UpdateProjectDetailsRequest,
    ): ApiRequest<ProjectDetails> =>
    (httpClient) =>
      httpClient.put(`v1/projects/${id}`, { data }),

  updateProjectLinks:
    (id: string, data: UpdateProjectLinksRequest): ApiRequest<ProjectLinks> =>
    (httpClient) =>
      httpClient.put(`v1/projects/${id}/links`, { data }),

  getProjectPackages:
    (id: string): ApiRequest<ProjectPackageListItem[]> =>
    (httpClient) =>
      httpClient.get(`v1/projects/${id}/packages`),

  addProjectPackage:
    (id: string, data: AddProjectPackageRequest): ApiRequest =>
    (httpClient) =>
      httpClient.post(`v1/projects/${id}/packages`, { data }),

  removeProjectPackage:
    (projectId: string, packageId: string): ApiRequest =>
    (httpClient) =>
      httpClient.delete(`v1/projects/${projectId}/packages/${packageId}`),

  getProjectLandingPage:
    (projectId: string): ApiRequest<ProjectLandingPageDetails> =>
    (httpClient) =>
      httpClient.get(`v1/projects/${projectId}/landing-page`),

  updateProjectLandingPage:
    (
      projectId: string,
      data: UpdateProjectLandingPageRequest,
    ): ApiRequest<ProjectLandingPageDetails> =>
    (httpClient) =>
      httpClient.put(`v1/projects/${projectId}/landing-page`, {
        data,
      }),

  uploadLandingPageImage:
    (
      projectId: string,
      file: File,
      landingPageImageType: LandingPageImageType,
    ): ApiRequest<LandingPageImage> =>
    (httpClient) => {
      const data = new FormData();
      data.append("file", file);
      return httpClient.post(
        `v1/projects/${projectId}/landing-page/image/${landingPageImageType}`,
        {
          data,
        },
      );
    },

  deleteLandingPageImage:
    (
      projectId: string,
      landingPageImageType: LandingPageImageType,
    ): ApiRequest<LandingPageImage> =>
    (httpClient) =>
      httpClient.delete(
        `v1/projects/${projectId}/landing-page/image/${landingPageImageType}`,
      ),

  getProjectOrderConfirmationPage:
    (projectId: string): ApiRequest<ProjectOrderConfirmationPageDetails> =>
    (httpClient) =>
      httpClient.get(`v1/projects/${projectId}/order-confirmation-page`),

  updateProjectOrderConfirmationPage:
    (
      projectId: string,
      data: UpdateProjectOrderConfirmationPageRequest,
    ): ApiRequest<ProjectOrderConfirmationPageDetails> =>
    (httpClient) =>
      httpClient.put(`v1/projects/${projectId}/order-confirmation-page`, {
        data,
      }),

  uploadOrderConfirmationPageImage:
    (projectId: string, file: File): ApiRequest<OrderConfirmationPageImage> =>
    (httpClient) => {
      const data = new FormData();
      data.append("file", file);
      return httpClient.post(
        `v1/projects/${projectId}/order-confirmation-page/image`,
        {
          data,
        },
      );
    },

  deleteOrderConfirmationPageImage:
    (projectId: string): ApiRequest<OrderConfirmationPageImage> =>
    (httpClient) =>
      httpClient.delete(
        `v1/projects/${projectId}/order-confirmation-page/image`,
      ),

  deleteProjectLogoImage:
    (
      projectId: string,
      projectDetailsImageType: ProjectDetailsImageType,
    ): ApiRequest<ProjectLogoImage> =>
    (httpClient) =>
      httpClient.delete(
        `v1/projects/${projectId}/logo/image/${projectDetailsImageType}`,
      ),

  uploadProjectLogoImage:
    (
      projectId: string,
      file: File,
      projectDetailsImageType: ProjectDetailsImageType,
    ): ApiRequest<ProjectLogoImage> =>
    (httpClient) => {
      const data = new FormData();
      data.append("file", file);
      return httpClient.post(
        `v1/projects/${projectId}/logo/image/${projectDetailsImageType}`,
        {
          data,
        },
      );
    },

  getProjectOrderSheet:
    (projectId: string): ApiRequest<ProjectOrderSheetDetails> =>
    (httpClient) =>
      httpClient.get(`v1/projects/${projectId}/order-sheet`),

  updateProjectOrderSheet:
    (
      projectId: string,
      data: UpdateProjectOrderSheetRequest,
    ): ApiRequest<ProjectOrderSheetDetails> =>
    (httpClient) =>
      httpClient.put(`v1/projects/${projectId}/order-sheet`, {
        data,
      }),

  updateProjectOrderSheetMeta:
    (
      projectId: string,
      data: UpdateProjectOrderSheetMetaRequest,
    ): ApiRequest<ProjectOrderSheetDetails> =>
    (httpClient) =>
      httpClient.put(`v1/projects/${projectId}/order-sheet-meta`, {
        data,
      }),

  getKitchenScenes:
    (projectId: string): ApiRequest<SceneType[]> =>
    (httpClient) =>
      httpClient.get(`v1/projects/${projectId}/kitchen-scenes`),
  addKitchenScene:
    (projectId: string, sceneType: SceneType): ApiRequest =>
    (httpClient) =>
      httpClient.post(`v1/projects/${projectId}/kitchen-scenes/${sceneType}`),
  removeKitchenScene:
    (projectId: string, sceneType: SceneType): ApiRequest =>
    (httpClient) =>
      httpClient.delete(`v1/projects/${projectId}/kitchen-scenes/${sceneType}`),
};

export interface ListGeneralProjectsRequest
  extends SortingOptions<ProductGroupListItem>,
    PagingOptions {}

interface ListProjectsRequest
  extends SortingOptions<ProjectListItem>,
    PagingOptions {
  searchTerm?: string;
  customerId: string;
}

export interface ProjectListItem {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  status: ProjectStatus;
  orderSum: number;
  packageNames: string[];
}

export interface CreateProjectRequest {
  name: string;
  urlSlug: string;
  customerId: string;
}

export interface ProjectLinks {
  linkToTerms: string;
}

export interface ProjectDetails {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  urlSlug: string;
  state: ProjectState;
  calculatedStatus: CalculatedStatus;
  email: string | null;
  logoImage: string | null;
  projectLinks: ProjectLinks;
  onlyVisualization: boolean;
  hideLogo: boolean;
  customerLicenseType: LicenseType;
}

export interface CalculatedStatus {
  activationErrors: ServerError[];
  status: ProjectStatus;
}

export interface UpdateProjectDetailsRequest {
  name: string;
  startDate?: Date;
  endDate?: Date;
  urlSlug: string;
  state: ProjectState;
  email: string | null;
  onlyVisualization: boolean;
  hideLogo: boolean;
}

export interface ProjectPackageListItem {
  id: string;
  name: string;
}

export interface AddProjectPackageRequest {
  productPackageId: string;
}

export interface UpdateProjectLandingPageRequest {
  layout: LandingPageLayout;
  bannerContent: string;
  content1: string;
  content2: string;
}

export interface ProjectLandingPageDetails {
  layout: LandingPageLayout;
  bannerContent: string;
  content: string;
  content2: string;
  bannerImage: string | null;
  image1: string | null;
  image2: string | null;
  buttonImage: string | null;
}

export interface LandingPageImage {
  image: string;
  landingPageImageType: LandingPageImageType;
}

export interface ProjectLogoImage {
  image: string;
  projectDetailsImageType: ProjectDetailsImageType;
}

export interface ProjectOrderSheetDetails {
  content: string;
  linkSentToEmail: string;
  dateLinkSentToCustomer: string;
  isCustomerOnlyUsingOrderSheet: boolean;
}

export interface UpdateProjectOrderSheetRequest {
  content: string;
}
export interface UpdateProjectOrderSheetMetaRequest {
  linkSentToEmail: string;
  dateLinkSentToCustomer: Date;
  isCustomerOnlyUsingOrderSheet: boolean;
}

export interface UpdateProjectOrderConfirmationPageRequest {
  content: string;
}

export interface ProjectOrderConfirmationPageDetails {
  content: string;
  image: string | null;
}

export interface OrderConfirmationPageImage {
  image: string;
}

export enum ProjectStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum ProjectDetailsImageType {
  Logo = "Logo",
  ButtonImage = "ButtonImage",
}

export enum LandingPageImageType {
  Banner = "Banner",
  Image1 = "Image1",
  Image2 = "Image2",
  ButtonImage = "ButtonImage",
}

export interface KitchenScenesDetails {
  kitchenScenes: KitchenScene[];
}

export interface KitchenScene {
  sceneType: SceneType;
}

export enum ProjectState {
  Open = "Open",
  Closed = "Closed",
}

export enum SceneType {
  Lillekokken = "Lillekokken",
  Parallelkokkenet = "Parallelkokkenet",
  Hjornekokken = "Hjornekokken",
}

export interface UpdateProjectLinksRequest {
  linkToTerms: string;
}
