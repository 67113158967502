import {
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Box,
  Button,
  Stack,
  SimpleGrid,
  Image,
  Divider,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ProductGroup,
  OrderProduct,
  OrderProductVariant,
} from "../../../api/externalApi";
import placeholderImg from "../../../assets/external_placeholder.jpg";
import { CustomModal } from "../../shared/CustomModal";
import { Icons } from "../../shared/Icons";
import { ImageCarousel } from "../../shared/ImageCarousel";
import { Select } from "../../shared/Select/Select";
import { ProductSelection, SelectedProduct } from "./types";

export function ProductDetailsModal({
  productGroup,
  product,
  onClose,
  onSelectProduct,
  selectedProduct,
  isReadOnly = false,
}: {
  productGroup: ProductGroup;
  product: OrderProduct;
  onClose: () => void;
  onSelectProduct: (product: ProductSelection) => void;
  selectedProduct: SelectedProduct | undefined;
  isReadOnly?: boolean;
}) {
  const { t } = useTranslation();

  const [previewVariant, setPreviewVariant] = useState<OrderProductVariant>(
    () => {
      // If the product is already selected, use the selected variant
      if (selectedProduct?.product.id === product.id) {
        return selectedProduct.variant;
      }

      return product.variants[0];
    },
  );

  const previewVariantIsSelected =
    selectedProduct?.variant.id === previewVariant.id;

  return (
    <CustomModal isOpen={true} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody overflow="auto" pr="8" pl="8" mt={10} mb={10}>
          <SimpleGrid spacing={8} columns={{ base: 1, sm: 2 }}>
            <Box>
              <ProductImages
                product={product}
                previewVariant={previewVariant}
              />
            </Box>
            <Stack spacing={4}>
              <Heading>{product.name}</Heading>
              {product.variants.length > 1 && (
                <Select
                  isDisabled={isReadOnly}
                  isMulti={false}
                  isSearchable={false}
                  value={previewVariant}
                  options={product.variants}
                  isLoading={false}
                  getOptionValue={(i) => i.id}
                  getOptionLabel={(i) => i.name}
                  formatOptionLabel={(v) => (
                    <Stack direction="row" alignItems="center">
                      <Image
                        boxSize={6}
                        src={v.images[0]?.thumbnailUrl ?? placeholderImg}
                      />
                      <Text>{v.name}</Text>
                      {v.price !== null && (
                        <>
                          <Divider w={2} />
                          <Text fontSize="smaller">
                            {t("general.krPerMonth", {
                              price: v.price,
                            })}
                          </Text>
                        </>
                      )}
                    </Stack>
                  )}
                  onChange={(selectedItem) => {
                    if (selectedItem) setPreviewVariant(selectedItem);
                  }}
                />
              )}
              <Text whiteSpace="pre-wrap">
                {previewVariant.description ?? product.description}
              </Text>
              {previewVariant.price !== null && (
                <Text>
                  {t("general.krPerMonth", { price: previewVariant.price })}
                </Text>
              )}
              {!isReadOnly && (
                <Button
                  onClick={() =>
                    onSelectProduct({
                      productGroup,
                      product,
                      variant: previewVariant,
                    })
                  }
                  isFullWidth={true}
                  leftIcon={
                    previewVariantIsSelected ? <Icons.Check /> : undefined
                  }
                  disabled={previewVariantIsSelected}
                >
                  {previewVariantIsSelected
                    ? t("general.selected")
                    : t("general.select")}
                </Button>
              )}
            </Stack>
          </SimpleGrid>
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </CustomModal>
  );
}

function ProductImages({
  product,
  previewVariant,
}: {
  product: OrderProduct;
  previewVariant: OrderProductVariant;
}) {
  const images = useMemo(() => {
    const productImageUrls = product.images.map((i) => i.url);
    const variantImageUrls = previewVariant.images.map((i) => i.url);

    const images = [...variantImageUrls, ...productImageUrls];

    return images.length ? images : [placeholderImg];
  }, [product, previewVariant]);

  return <ImageCarousel images={images} imageMaxHeight="500px" />;
}
