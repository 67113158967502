import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  RadioGroup,
  Radio,
  ButtonGroup,
  Checkbox,
  Textarea,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ProjectDetails } from "../../api/projectsApi";
import {
  Direction,
  CustomOrderFieldListItem,
  customOrderFieldsApi,
  CustomOrderFieldType,
} from "../../api/customOrderFieldsApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Icons } from "../shared/Icons";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Card } from "../shared/Card";
import { ConfirmationModal } from "../shared/ConfirmationModal";
import { EmptyListAlert } from "../shared/EmptyListAlert";
import { ServerError } from "../../types";
import { PublicLinkButton } from "./PublicLinkButton";
import { routes } from "../../routes";
import { CustomerDetails } from "../../api/customersApi";
import { ProjectLinksForm } from "./ProjectLinksForm";

export function ProjectOrderView({
  project,
  customer,
  onUpdate,
}: {
  project: ProjectDetails;
  customer: CustomerDetails;
  onUpdate: () => void;
}) {
  const { t } = useTranslation();

  const [fieldToDelete, setFieldToDelete] =
    useState<CustomOrderFieldListItem>();
  const [deleteError, setDeleteError] = useState<ServerError>();

  const [customOrderFields, isLoading, error, fetch] = useApiRequest(
    customOrderFieldsApi.listCustomOrderFields,
  );

  const [isDeleting, deleteRequest] = useApiRequestCallback(
    customOrderFieldsApi.deleteCustomOrderField,
  );

  const [modalState, setModalState] = useState<ModalState>({
    state: "closed",
  });

  const fetchCustomOrderFields = useCallback(() => {
    fetch(project.id);
  }, [fetch, project.id]);

  useEffect(() => {
    fetchCustomOrderFields();
  }, [fetchCustomOrderFields]);

  const toast = useToastNotification();

  function deleteCustomOrderField() {
    if (!fieldToDelete || isDeleting) return;

    deleteRequest({
      onSuccess: () => {
        fetchCustomOrderFields();
        toast({
          title: t("general.deleted"),
          status: "success",
        });
        setDeleteError(undefined);
        setFieldToDelete(undefined);
      },
      onError: (e) => setDeleteError(e),
    }).send(fieldToDelete.id);
  }

  return (
    <>
      {deleteError && <ErrorDetails error={deleteError} />}
      <Stack spacing={6} shouldWrapChildren={true}>
        {error && <ErrorDetails error={error} />}
        <Card titleContent={t("general.links")} padding="20px">
          <PublicLinkButton
            route={routes.externalProject(customer.urlSlug, project.urlSlug)}
          ></PublicLinkButton>
          <ProjectLinksForm
            projectId={project.id}
            projectlinks={project.projectLinks}
            onUpdate={onUpdate}
          ></ProjectLinksForm>
        </Card>

        <Card
          titleContent={t("customOrderField.customOrderFields")}
          extraContent={
            <Button
              size="sm"
              onClick={() => {
                setModalState({
                  state: "create",
                });
              }}
            >
              {t("general.add")}
            </Button>
          }
        >
          {(isDeleting || isLoading) && <LoadingIndicator />}
          {customOrderFields && (
            <CustomOrderFieldsTable
              fields={customOrderFields}
              onDeleteField={setFieldToDelete}
              onEditField={(item) =>
                setModalState({
                  state: "edit",
                  item,
                })
              }
              onUpdate={fetchCustomOrderFields}
            />
          )}
          {customOrderFields?.length === 0 && <EmptyListAlert />}
        </Card>
      </Stack>

      {modalState.state !== "closed" && (
        <OrderFieldModal
          projectId={project.id}
          modalState={modalState}
          onClose={() => {
            setModalState({ state: "closed" });
          }}
          onSuccess={() => {
            setModalState({ state: "closed" });
            fetchCustomOrderFields();
          }}
        />
      )}
      {fieldToDelete && (
        <ConfirmationModal
          message={t("general.deleteConfirmation", {
            name: fieldToDelete.name,
          })}
          confirmButtonColor="red"
          confirmButtonText={t("general.delete")}
          onClose={() => setFieldToDelete(undefined)}
          onConfirm={deleteCustomOrderField}
        />
      )}
    </>
  );
}

function CustomOrderFieldsTable({
  fields,
  onEditField,
  onDeleteField,
  onUpdate,
}: {
  fields: CustomOrderFieldListItem[];
  onEditField: (item: CustomOrderFieldListItem) => void;
  onDeleteField: (item: CustomOrderFieldListItem) => void;
  onUpdate: () => void;
}) {
  const { t } = useTranslation();

  const [isLoading, updateCustomOrderFieldSortOrderRequest] =
    useApiRequestCallback(customOrderFieldsApi.updateCustomOrderFieldSortOrder);

  const [error, setError] = useState<ServerError | null>();

  const reorderField = (
    item: CustomOrderFieldListItem,
    direction: Direction,
  ) => {
    updateCustomOrderFieldSortOrderRequest({
      onSuccess: handleUpdateSuccess,
      onError: setError,
    }).send({
      id: item.id,
      direction: direction,
    });
  };

  function handleUpdateSuccess() {
    onUpdate();
    setError(null);
  }

  return (
    <>
      {error && <ErrorDetails error={error} />}
      {isLoading && <LoadingIndicator />}
      <Table>
        <Thead>
          <Tr>
            <Th>{t("general.name")}</Th>
            <Th>{t("general.required")}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {fields.map((f) => (
            <Tr key={f.id}>
              <Td>{f.name}</Td>
              <Td>{f.isRequired && <Icons.Check />}</Td>
              <Td isNumeric={true} width={150}>
                <HStack>
                  <ButtonGroup isAttached={true}>
                    <IconButton
                      variant="outline"
                      aria-label="up"
                      size="sm"
                      icon={<Icons.ArrowUp boxSize={4} />}
                      onClick={() => reorderField(f, Direction.Up)}
                      mr="-px"
                    />
                    <IconButton
                      variant="outline"
                      aria-label="down"
                      size="sm"
                      icon={<Icons.ArrowDown boxSize={4} />}
                      onClick={() => reorderField(f, Direction.Down)}
                    />
                  </ButtonGroup>
                  <IconButton
                    variant="outline"
                    aria-label="remove"
                    size="sm"
                    icon={<Icons.Trash />}
                    onClick={() => onDeleteField(f)}
                  />
                  <Button size="sm" onClick={() => onEditField(f)}>
                    {t("general.edit")}
                  </Button>
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

type ModalState =
  | {
      state: "closed";
    }
  | OpenModalState;

type OpenModalState =
  | {
      state: "edit";
      item: CustomOrderFieldListItem;
    }
  | {
      state: "create";
    };

interface OrderFieldFormData {
  name: string;
  isRequired: boolean;
  fieldType: CustomOrderFieldType;
}

function OrderFieldModal({
  onSuccess,
  onClose,
  projectId,
  modalState,
}: {
  onSuccess: () => void;
  onClose: () => void;
  projectId: string;
  modalState: OpenModalState;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<OrderFieldFormData>({
    defaultValues:
      modalState.state === "edit"
        ? modalState.item
        : {
            fieldType: CustomOrderFieldType.Text,
          },
  });
  const { t } = useTranslation();
  const [error, setError] = useState<ServerError>();

  const [isCreating, createRequest] = useApiRequestCallback(
    customOrderFieldsApi.createCustomOrderField,
  );

  const [isUpdating, updateRequest] = useApiRequestCallback(
    customOrderFieldsApi.updateCustomOrderField,
  );

  const isSubmitting = isCreating || isUpdating;

  function onSubmit(data: OrderFieldFormData) {
    if (isSubmitting) return;

    if (modalState.state === "create") {
      createRequest({
        onSuccess,
        onError: (e) => setError(e),
      }).send({ ...data, projectId });
    } else if (modalState.state === "edit") {
      updateRequest({
        onSuccess,
        onError: (e) => setError(e),
      }).send(modalState.item.id, data);
    }
  }
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {modalState.state === "edit"
            ? t("general.edit")
            : `${t("general.add")} ${t(
                "customOrderField.customOrderField",
              ).toLowerCase()}`}
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            {(isCreating || isUpdating) && <LoadingIndicator />}
            {error && <ErrorDetails error={error} />}
            <Stack spacing={4} shouldWrapChildren={true}>
              <FormControl isRequired={true} isInvalid={!!errors?.name}>
                <FormLabel>{t("general.name")}</FormLabel>
                <Textarea
                  {...register("name", {
                    required: { value: true, message: t("error.required") },
                  })}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="fieldType">
                <FormLabel>{t("customOrderField.fieldType")}</FormLabel>
                <Controller
                  name="fieldType"
                  render={({ field }) => (
                    <Flex alignItems="center" height="38px">
                      <RadioGroup
                        onChange={(status) => {
                          field.onChange(status as CustomOrderFieldType);
                        }}
                        value={field.value}
                      >
                        <HStack>
                          <Radio value={CustomOrderFieldType.Text}>
                            {t("customOrderField.text")}
                          </Radio>
                          <Radio value={CustomOrderFieldType.Checkbox}>
                            {t("customOrderField.checkbox")}
                          </Radio>
                        </HStack>
                      </RadioGroup>
                    </Flex>
                  )}
                  control={control}
                />
              </FormControl>
              <FormControl>
                <Checkbox {...register("isRequired")}>
                  {t("customOrderSheetField.required")}
                </Checkbox>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button disabled={isSubmitting} type="submit">
              {t("general.save")}
            </Button>
          </ModalFooter>
        </form>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}
