import { Alert, AlertDescription } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export function EmptyListAlert() {
  const { t } = useTranslation();
  return (
    <Alert
      colorScheme="whiteAlpha"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="100px"
    >
      <AlertDescription maxWidth="sm">
        {t("general.noItemsFound")}
      </AlertDescription>
    </Alert>
  );
}
