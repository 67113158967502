import {
  Stack,
  Flex,
  Button,
  IconButton,
  Checkbox,
  Heading,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ProductPackageDetails,
  productPackageApi,
  ProductPackageProductOption,
  ProductPackageProductOptionVariant,
  PackageProductVariantPriceUpdate,
  PackageProductPriceUpdate,
  PackageProductVariantUpdate,
} from "../../api/productPackageApi";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ServerError } from "../../types";
import { Card } from "../shared/Card";
import { EmptyListAlert } from "../shared/EmptyListAlert";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Icons } from "../shared/Icons";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import placeholderImg from "../../assets/external_placeholder.jpg";
import { Image } from "../shared/Image";
import { ConfirmationModal } from "../shared/ConfirmationModal";

export function ProductPackageProductList({
  productPackage,
  onUpdate,
}: {
  productPackage: ProductPackageDetails;
  onUpdate: (productPackageId: string) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [updateError, setUpdateError] = useState<ServerError>();

  const [products, isLoading, error, fetch] = useApiRequest(
    productPackageApi.getProductPackageProductOptions,
  );

  const [isUpdating, updateRequest] = useApiRequestCallback(
    productPackageApi.updateProductPackageProducts,
  );

  const [showChangesWarning, setShowChangesWarning] = useState<boolean>(false);

  useEffect(() => {
    fetch(productPackage.id);
  }, [fetch, productPackage.id]);

  const [changes, setChanges] = useState<Changes>(defaultChanges);

  const productGroups = useMemo(() => {
    if (!products) {
      return null;
    }

    return groupProducts(products, changes);
  }, [products, changes]);

  function onGroupCheckboxChange(group: ProductGroupEditItem) {
    const variants = group.products.flatMap((p) => p.variants);
    if (group.selectState === "selected") {
      setChanges((c) => variants.reduce(removeFromProductPackage, c));
    } else {
      setChanges((c) =>
        variants.filter((v) => !v.isSelected).reduce(addToProductPackage, c),
      );
    }
  }

  function onProductCheckboxChange(product: ProductEditItem) {
    if (product.selectState === "selected") {
      setChanges((c) => product.variants.reduce(removeFromProductPackage, c));
    } else {
      setChanges((c) =>
        product.variants
          .filter((v) => !v.isSelected)
          .reduce(addToProductPackage, c),
      );
    }
  }

  function onVariantCheckboxChange(variant: ProductVariantEditItem) {
    if (!variant.isSelected) {
      setChanges((c) => addToProductPackage(c, variant));
    } else {
      setChanges((c) => removeFromProductPackage(c, variant));
    }
  }

  function onGroupIncludeChange(group: ProductGroupEditItem) {
    if (group.selectState === "unselected") return;
    setChanges((c) =>
      setGroupInclusion(c, group, group.includeState !== "selected"),
    );
  }

  function onProductIncludeChange(product: ProductEditItem) {
    if (product.selectState === "unselected") return;
    setChanges((c) =>
      setProductInclusion(c, product, product.includeState !== "selected"),
    );
  }

  function onVariantIncludeChange(variant: ProductVariantEditItem) {
    if (variant.isSelected === false) return;
    setChanges((c) => setVariantInclusion(c, variant, !variant.included));
  }

  function onProductPriceChange(productId: string, amount: number | null) {
    setChanges((c) => setProductPrice(c, productId, amount));
  }

  function onProductVariantPriceChange(
    productVariantId: string,
    amount: number | null,
  ) {
    setChanges((c) => setProductVariantPrice(c, productVariantId, amount));
  }

  function saveChanges() {
    updateRequest({
      onSuccess: () => {
        setUpdateError(undefined);
        fetch(productPackage.id).then(() => {
          setChanges(defaultChanges);
        });
        onUpdate(productPackage.id);
        toast({ title: t("general.saved"), status: "success" });
      },
      onError: setUpdateError,
    }).send(productPackage.id, {
      productVariantsToAddOrUpdate: changes.variantsToAdd,
      productVariantsToRemove: changes.variantsToRemove,
      productPriceUpdates: changes.productPrices,
      productVariantPriceUpdates: changes.productVariantPrices,
    });
  }

  return (
    <>
      <Card titleContent={t("product.products")}>
        {(isLoading || isUpdating) && <LoadingIndicator />}
        {error && <ErrorDetails error={error} />}
        {updateError && <ErrorDetails error={updateError} />}
        <Stack shouldWrapChildren={true} spacing={2}>
          <Table variant="noRowHighlight">
            <Thead>
              <Tr>
                <Th />
                <Th />
                <Th />
                <Th></Th>
                <Th></Th>
                <Th>{t("product.otherIncluded")}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {productGroups?.map((group) => (
                <ProductList
                  key={group.id}
                  productGroup={group}
                  onGroupCheckboxChange={onGroupCheckboxChange}
                  onProductCheckboxChange={onProductCheckboxChange}
                  onVariantCheckboxChange={onVariantCheckboxChange}
                  onGroupIncludeChange={onGroupIncludeChange}
                  onProductIncludeChange={onProductIncludeChange}
                  onVariantIncludeChange={onVariantIncludeChange}
                  onProductPriceChange={onProductPriceChange}
                  onProductVariantPriceChange={onProductVariantPriceChange}
                />
              ))}
            </Tbody>
          </Table>
          {productGroups?.length === 0 && <EmptyListAlert />}
          <Flex justifyContent="flex-end">
            <Button
              isDisabled={
                changes.variantsToAdd.length === 0 &&
                changes.variantsToRemove.length === 0 &&
                changes.productPrices.length === 0 &&
                changes.productVariantPrices.length === 0
              }
              onClick={() => {
                if (changes.variantsToRemove.length > 0)
                  return setShowChangesWarning(true);

                saveChanges();
              }}
            >
              {t("general.save")}
            </Button>
          </Flex>
        </Stack>
      </Card>
      {showChangesWarning && (
        <ConfirmationModal
          message={t("productPackage.warningDeleteStylesFromProductPackages")}
          confirmButtonText={t("general.continue")}
          confirmButtonColor="red"
          onConfirm={() => {
            saveChanges();
            return setShowChangesWarning(false);
          }}
          onClose={() => setShowChangesWarning(false)}
        />
      )}
    </>
  );
}

function ProductList({
  onGroupCheckboxChange,
  onProductCheckboxChange,
  onVariantCheckboxChange,
  onGroupIncludeChange,
  onProductIncludeChange,
  onVariantIncludeChange,
  onProductPriceChange,
  onProductVariantPriceChange,
  productGroup,
}: {
  productGroup: ProductGroupEditItem;
  onGroupCheckboxChange: (group: ProductGroupEditItem) => void;
  onProductCheckboxChange: (product: ProductEditItem) => void;
  onVariantCheckboxChange: (variant: ProductVariantEditItem) => void;
  onGroupIncludeChange: (product: ProductGroupEditItem) => void;
  onProductIncludeChange: (product: ProductEditItem) => void;
  onVariantIncludeChange: (variant: ProductVariantEditItem) => void;
  onProductPriceChange: (productId: string, amount: number | null) => void;
  onProductVariantPriceChange: (
    productVariantId: string,
    amount: number | null,
  ) => void;
}) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <Tr
        alignItems="center"
        paddingX={3}
        paddingY={2}
        background="secondary.50"
      >
        <Td width="65px">
          <IconButton
            variant="ghost"
            aria-label="expand"
            color="blackAlpha.800"
            icon={
              isExpanded ? (
                <Icons.ArrowDown boxSize="6" />
              ) : (
                <Icons.ArrowRight boxSize="6" />
              )
            }
            size="sm"
            onClick={() => setIsExpanded((expanded) => !expanded)}
          />
        </Td>
        <Td width="65px">
          <Checkbox
            size="lg"
            isIndeterminate={productGroup.selectState === "partial"}
            isChecked={productGroup.selectState === "selected"}
            onChange={() => onGroupCheckboxChange(productGroup)}
          />
        </Td>
        <Td>
          <Heading
            mx={1}
            size="sm"
            fontWeight={
              productGroup.selectState === "unselected" ? "normal" : "bold"
            }
          >
            {productGroup.name}
          </Heading>
        </Td>
        <Td />
        <Td />
        <Td width="250px">
          <Checkbox
            size="lg"
            isIndeterminate={
              productGroup.includeState === "partial" &&
              productGroup.selectState !== "unselected"
            }
            isChecked={
              productGroup.includeState === "selected" &&
              productGroup.selectState !== "unselected"
            }
            disabled={productGroup.selectState === "unselected"}
            onChange={() => onGroupIncludeChange(productGroup)}
          />
        </Td>
        <Td width="250px" />
      </Tr>
      {isExpanded && (
        <Tr>
          <Td px={0} colSpan={7}>
            <Table mb={8}>
              <Thead>
                <Tr>
                  <Th />
                  <Th />
                  <Th />
                  <Th>{t("general.name")}</Th>
                  <Th>{t("product.variants")}</Th>
                  <Th>{t("product.otherIncluded")}</Th>
                  <Th>{t("general.price")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {productGroup.products.map((p) => (
                  <ProductRow
                    key={p.id}
                    product={p}
                    onProductCheckboxChange={onProductCheckboxChange}
                    onVariantCheckboxChange={onVariantCheckboxChange}
                    onProductIncludeChange={onProductIncludeChange}
                    onVariantIncludeChange={onVariantIncludeChange}
                    onProductPriceChange={onProductPriceChange}
                    onProductVariantPriceChange={onProductVariantPriceChange}
                  />
                ))}
              </Tbody>
            </Table>
          </Td>
        </Tr>
      )}
    </>
  );
}

function ProductRow({
  product,
  onProductCheckboxChange,
  onVariantCheckboxChange,
  onProductIncludeChange,
  onVariantIncludeChange,
  onProductPriceChange,
  onProductVariantPriceChange,
}: {
  product: ProductEditItem;
  onProductCheckboxChange: (product: ProductEditItem) => void;
  onVariantCheckboxChange: (variant: ProductVariantEditItem) => void;
  onProductIncludeChange: (product: ProductEditItem) => void;
  onVariantIncludeChange: (variant: ProductVariantEditItem) => void;
  onProductPriceChange: (productId: string, amount: number | null) => void;
  onProductVariantPriceChange: (
    productVariantId: string,
    amount: number | null,
  ) => void;
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <Tr>
        <Td width="65px">
          {product.variants.length > 1 && (
            <IconButton
              variant="ghost"
              aria-label="expand"
              color="blackAlpha.800"
              icon={
                isExpanded ? (
                  <Icons.ArrowDown boxSize="6" />
                ) : (
                  <Icons.ArrowRight boxSize="6" />
                )
              }
              size="sm"
              onClick={() => setIsExpanded((expanded) => !expanded)}
            />
          )}
        </Td>
        <Td width="65px">
          <Checkbox
            size="lg"
            isIndeterminate={product.selectState === "partial"}
            isChecked={product.selectState === "selected"}
            onChange={() => onProductCheckboxChange(product)}
          />
        </Td>
        <Td width="100px">
          <Image
            src={product.imageThumbnailUrl ?? placeholderImg}
            boxSize={10}
            objectFit="contain"
          />
        </Td>
        <Td>{product.name}</Td>
        <Td>{product.variants.length}</Td>
        <Td width="250px">
          <Checkbox
            size="lg"
            isIndeterminate={
              product.includeState === "partial" &&
              product.selectState !== "unselected"
            }
            isChecked={
              product.includeState === "selected" &&
              product.selectState !== "unselected"
            }
            disabled={product.selectState === "unselected"}
            onChange={() => onProductIncludeChange(product)}
          />
        </Td>
        <Td width="250px">
          <NumberInput
            backgroundColor="white"
            min={0}
            value={product.price ?? ""}
            onChange={(_, value) =>
              onProductPriceChange(product.id, isNaN(value) ? null : value)
            }
          >
            <NumberInputField />
          </NumberInput>
        </Td>
      </Tr>
      {isExpanded &&
        product.variants.map((v) => (
          <Tr key={v.id} backgroundColor="gray.50">
            <Td />
            <Td>
              <Checkbox
                size="lg"
                isChecked={v.isSelected}
                onChange={() => onVariantCheckboxChange(v)}
              />
            </Td>
            <Td>
              <Image
                src={
                  v.imageThumbnailUrl ??
                  product.imageThumbnailUrl ??
                  placeholderImg
                }
                boxSize={10}
                objectFit="contain"
              />
            </Td>
            <Td colSpan={2}>{v.name}</Td>
            <Td>
              <Checkbox
                size="lg"
                isChecked={v.included && v.isSelected}
                disabled={!v.isSelected}
                onChange={() => onVariantIncludeChange(v)}
              />
            </Td>
            <Td>
              <NumberInput
                backgroundColor="white"
                min={0}
                value={v.price ?? ""}
                onChange={(_, value) =>
                  onProductVariantPriceChange(v.id, isNaN(value) ? null : value)
                }
              >
                <NumberInputField
                  placeholder={product.price?.toString() ?? ""}
                />
              </NumberInput>
            </Td>
          </Tr>
        ))}
    </>
  );
}

function groupProducts(
  products: ProductPackageProductOption[],
  changes: Changes,
): ProductGroupEditItem[] {
  interface Group {
    id: string;
    name: string;
    products: ProductEditItem[];
  }
  const groupByGroupId: Record<string, Group> = {};

  for (const product of products) {
    let group = groupByGroupId[product.productGroupId];

    if (!group) {
      group = groupByGroupId[product.productGroupId] = {
        id: product.productGroupId,
        name: product.productGroupName,
        products: [],
      };
    }

    const variants = product.variants.map((v) => ({
      ...v,
      isSelected: isVariantSelected(changes, v),
      included: isVariantIncluded(changes, v),
      price: getProductVariantPrice(changes, v),
      isInProductPackage: v.isInProductPackage,
    }));

    const p = {
      ...product,
      selectState: getProductSelectedState(variants),
      includeState: getProductIncludedState(variants),
      price: getProductPrice(changes, product),
      variants,
    };

    group.products.push(p);
  }

  return Object.values(groupByGroupId).map((group) => ({
    ...group,
    selectState: getProductGroupSelectedState(group.products),
    includeState: getProductGroupIncludedState(group.products),
  }));
}

interface Changes {
  variantsToAdd: PackageProductVariantUpdate[];
  variantsToRemove: string[];
  productPrices: PackageProductPriceUpdate[];
  productVariantPrices: PackageProductVariantPriceUpdate[];
}

const defaultChanges: Changes = {
  variantsToAdd: [],
  variantsToRemove: [],
  productPrices: [],
  productVariantPrices: [],
};

interface ProductGroupEditItem {
  id: string;
  name: string;
  selectState: SelectState;
  includeState: SelectState;
  products: ProductEditItem[];
}

type SelectState = "selected" | "partial" | "unselected";

interface ProductEditItem {
  id: string;
  name: string;
  imageThumbnailUrl: string | null;
  selectState: SelectState;
  price: number | null;
  includeState: SelectState;
  variants: ProductVariantEditItem[];
}

interface ProductVariantEditItem {
  id: string;
  name: string;
  isInProductPackage: boolean;
  isSelected: boolean;
  included: boolean;
  price: number | null;
  imageThumbnailUrl: string | null;
}

function getProductSelectedState(
  variants: ProductVariantEditItem[],
): SelectState {
  if (variants.every((v) => v.isSelected)) {
    return "selected";
  }

  if (variants.some((v) => v.isSelected)) {
    return "partial";
  }

  return "unselected";
}

function getProductGroupSelectedState(
  products: ProductEditItem[],
): SelectState {
  if (products.every((p) => p.selectState === "selected")) {
    return "selected";
  }

  if (products.some((p) => p.selectState !== "unselected")) {
    return "partial";
  }

  return "unselected";
}

function getProductIncludedState(
  variants: ProductVariantEditItem[],
): SelectState {
  if (variants.filter((v) => v.isSelected).every((v) => v.included)) {
    return "selected";
  }

  if (variants.filter((v) => v.isSelected).some((v) => v.included)) {
    return "partial";
  }

  return "unselected";
}

function getProductGroupIncludedState(
  products: ProductEditItem[],
): SelectState {
  if (products.every((p) => p.includeState === "selected")) {
    return "selected";
  }

  if (products.some((p) => p.includeState !== "unselected")) {
    return "partial";
  }

  return "unselected";
}

function isVariantSelected(
  changes: Changes,
  variant: ProductPackageProductOptionVariant,
) {
  return variant.isInProductPackage
    ? changes.variantsToRemove.indexOf(variant.id) < 0
    : changes.variantsToAdd
        .map((x) => x.productVariantId)
        .indexOf(variant.id) >= 0;
}

function isVariantIncluded(
  changes: Changes,
  variant: ProductPackageProductOptionVariant,
) {
  const change = changes.variantsToAdd.find(
    (v) => v.productVariantId === variant.id,
  );
  return change ? change.include : variant.isIncluded;
}

function addToProductPackage(
  changes: Changes,
  variant: ProductVariantEditItem,
): Changes {
  return {
    ...changes,
    variantsToAdd: variant.isInProductPackage
      ? [...changes.variantsToAdd]
      : [
          ...changes.variantsToAdd,
          { productVariantId: variant.id, include: false },
        ],

    variantsToRemove: [
      ...changes.variantsToRemove.filter((id) => id !== variant.id),
    ],
  };
}

function removeFromProductPackage(
  changes: Changes,
  variant: ProductVariantEditItem,
): Changes {
  return {
    ...changes,
    variantsToAdd: [
      ...changes.variantsToAdd.filter((v) => v.productVariantId !== variant.id),
    ],

    variantsToRemove: variant.isInProductPackage
      ? [...changes.variantsToRemove, variant.id]
      : [...changes.variantsToRemove],
  };
}

function setVariantInclusion(
  changes: Changes,
  variant: ProductVariantEditItem,
  include: boolean,
): Changes {
  return {
    ...changes,
    variantsToAdd: [
      ...changes.variantsToAdd.filter((v) => v.productVariantId !== variant.id),
      {
        productVariantId: variant.id,
        include,
      },
    ],
  };
}

function setGroupInclusion(
  changes: Changes,
  group: ProductGroupEditItem,
  include: boolean,
): Changes {
  return {
    ...changes,
    variantsToAdd: [
      ...changes.variantsToAdd.filter((v) =>
        group.products.every(
          (p) => !p.variants.some((x) => x.id === v.productVariantId),
        ),
      ),
      ...group.products.flatMap((p) =>
        p.variants
          .filter((v) => v.isSelected)
          .map((v) => ({ productVariantId: v.id, include })),
      ),
    ],
  };
}

function setProductInclusion(
  changes: Changes,
  product: ProductEditItem,
  include: boolean,
): Changes {
  return {
    ...changes,
    variantsToAdd: [
      ...changes.variantsToAdd.filter(
        (v) => !product.variants.some((x) => x.id === v.productVariantId),
      ),
      ...product.variants
        .filter((v) => v.isSelected)
        .map((v) => ({ productVariantId: v.id, include })),
    ],
  };
}

function getProductPrice(
  changes: Changes,
  product: ProductPackageProductOption,
): number | null {
  const changePrice = changes.productPrices.find(
    (p) => p.productId === product.id,
  );

  if (changePrice) {
    return changePrice.amount;
  }

  return product.packageProductPrice;
}

function getProductVariantPrice(
  changes: Changes,
  variant: ProductPackageProductOptionVariant,
): number | null {
  const changePrice = changes.productVariantPrices.find(
    (p) => p.productVariantId === variant.id,
  );

  if (changePrice) {
    return changePrice.amount;
  }

  return variant.packageProductVariantPrice;
}

function setProductPrice(
  changes: Changes,
  productId: string,
  amount: number | null,
): Changes {
  return {
    ...changes,
    productPrices: [
      ...changes.productPrices.filter((p) => p.productId !== productId),
      { productId, amount },
    ],
  };
}

function setProductVariantPrice(
  changes: Changes,
  productVariantId: string,
  amount: number | null,
): Changes {
  return {
    ...changes,
    productVariantPrices: [
      ...changes.productVariantPrices.filter(
        (p) => p.productVariantId !== productVariantId,
      ),
      { productVariantId, amount },
    ],
  };
}
