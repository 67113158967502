import { useState } from "react";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { projectsApi } from "../../api/projectsApi";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormErrorMessage,
  Stack,
} from "@chakra-ui/react";
import { ServerError } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Controller, useForm } from "react-hook-form";
import { slugify } from "../../helpers";
import { urlSafeRegExp } from "../../constants";

export default function CreateProjectModal({
  customerId,
  onClose,
  onSuccess,
}: {
  customerId: string;
  onClose: () => void;
  onSuccess: () => void;
}) {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalFormContent customerId={customerId} onSuccess={onSuccess} />
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}

interface ProjectFormData {
  name: string;
  urlSlug: string;
}

function ModalFormContent({
  customerId,
  onSuccess,
}: {
  customerId: string;
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<ServerError>();
  const [loading, request] = useApiRequestCallback(projectsApi.createProject);

  async function onSubmit(data: ProjectFormData) {
    request({ onSuccess, onError: setError }).send({
      name: data.name,
      urlSlug: data.urlSlug,
      customerId,
    });
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<ProjectFormData>();

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <ModalHeader>
        {t("general.add")} {t("general.project").toLowerCase()}
      </ModalHeader>
      <ModalBody>
        {loading && <LoadingIndicator />}
        {error && <ErrorDetails error={error} />}
        <Stack spacing={4} shouldWrapChildren={true}>
          <FormControl id="name" isRequired={true} isInvalid={!!errors.name}>
            <FormLabel>{t("general.name")}</FormLabel>
            <Controller
              name="name"
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("urlSlug", slugify(e.target.value), {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                />
              )}
              control={control}
              rules={{
                required: { value: true, message: t("error.required") },
              }}
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
          <FormControl
            id="urlSlug"
            isRequired={true}
            isInvalid={!!errors.urlSlug}
          >
            <FormLabel>{t("project.slug")}</FormLabel>
            <Input
              {...register("urlSlug", {
                required: { value: true, message: t("error.required") },
                pattern: {
                  value: urlSafeRegExp,
                  message: t("error.slugPattern"),
                },
              })}
            />
            <FormErrorMessage>{errors.urlSlug?.message}</FormErrorMessage>
          </FormControl>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Button type="submit">{t("general.save")}</Button>
      </ModalFooter>
    </form>
  );
}
