import { ProductSelection } from "./types";
import { RadioGroup } from "@headlessui/react";
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  Divider,
  SimpleGrid,
} from "@chakra-ui/react";
import { Icons } from "../../shared/Icons";
import { useTranslation } from "react-i18next";
import {
  ProductGroup,
  OrderProduct,
  OrderProductVariant,
} from "../../../api/externalApi";
import placeholderImage from "../../../assets/external_placeholder.jpg";
import { TextHighlight } from "../../shared/TextHighlight";
import { useCompactSideMenu } from "../../../hooks/useCompactSideMenu";

export function ProductVariants({
  productGroup,
  selectedVariant,
  selectedProduct,
  isVisible,
  onClose,
  onSelectProduct,
}: {
  productGroup: ProductGroup;
  selectedVariant?: OrderProductVariant;
  selectedProduct: OrderProduct;
  isVisible: boolean;
  onClose: () => void;
  onSelectProduct: (product: ProductSelection) => void;
}) {
  const { t } = useTranslation();
  const isUsingCompactSideMenu = useCompactSideMenu();

  function productVariantImage(
    product: OrderProduct,
    variant: OrderProductVariant,
  ) {
    return variant.images.length > 0
      ? variant.images[0].thumbnailUrl
      : product.images.length > 0
      ? product.images[0].thumbnailUrl
      : placeholderImage;
  }

  if (!isVisible) return <></>;

  return (
    <Box
      borderRight={!isUsingCompactSideMenu ? "1px solid" : ""}
      bg="white"
      w={!isUsingCompactSideMenu ? "xs" : "xs"}
      pb={{ base: 1, xl: 6 }}
    >
      <Box p="5">
        {isUsingCompactSideMenu && <Divider mb={3} />}
        <Flex alignItems="center">
          <Heading size="md" my={3}>
            <TextHighlight>{t("product.variants")}</TextHighlight>
          </Heading>
          {!isUsingCompactSideMenu && (
            <Box onClick={() => onClose()} textAlign="right" flex={1}>
              <Icons.Close boxSize={5} />
            </Box>
          )}
        </Flex>
        <RadioGroup
          value={selectedVariant!}
          onChange={(v: OrderProductVariant) =>
            onSelectProduct({
              productGroup: productGroup,
              product: selectedProduct,
              variant: v,
            })
          }
        >
          <SimpleGrid
            columns={!isUsingCompactSideMenu ? 1 : 4}
            spacing={{ base: 0, xl: 4 }}
          >
            {selectedProduct.variants.map((v) => (
              <RadioGroup.Option value={v} key={v.id}>
                {({ checked }) => (
                  <Flex
                    direction={!isUsingCompactSideMenu ? "row" : "column"}
                    gridGap={!isUsingCompactSideMenu ? 4 : 0}
                    textAlign="center"
                    cursor="pointer"
                    alignItems="center"
                  >
                    <Image
                      w={12}
                      h={12}
                      objectFit="contain"
                      rounded={2}
                      src={productVariantImage(selectedProduct, v)}
                      alt="placeholder"
                      borderStyle="solid"
                      borderWidth="2px"
                      borderColor={checked ? "primary.400" : "transparent"}
                    />
                    <Text
                      fontSize="sm"
                      fontWeight={checked ? "bold" : "normal"}
                    >
                      {v.name}
                    </Text>
                    {v.price !== null && (
                      <Text fontSize="xs">
                        {t("general.krPerMonth", { price: v.price })}
                      </Text>
                    )}
                  </Flex>
                )}
              </RadioGroup.Option>
            ))}
          </SimpleGrid>
        </RadioGroup>
      </Box>
    </Box>
  );
}
