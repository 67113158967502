import { AspectRatio, Box, Flex, Grid } from "@chakra-ui/react";
import {
  Angle,
  ExternalOrderConfirmationPageDetails,
  SceneType,
} from "../../../api/externalApi";
import { Image } from "../../shared/Image";
import { RichTextContent } from "../../shared/RichText/RichTextContent";
import { Preview } from "./Preview";
import { SelectedProduct } from "./types";

export function ConfirmationPage({
  orderConfirmationPage,
  selectedProductByProductGroupId,
  sceneType,
}: {
  orderConfirmationPage: ExternalOrderConfirmationPageDetails;
  selectedProductByProductGroupId: Record<string, SelectedProduct>;
  sceneType: SceneType;
}) {
  return (
    <Flex justifyContent="center">
      <Grid
        textAlign="center"
        gridGap="20px"
        gridTemplateAreas={{ base: `'preview' 'text'`, sm: `'preview text'` }}
        gridTemplateColumns={{ base: "1fr", sm: "1fr 1fr", xl: "500px 1fr" }}
        width="100%"
        mb="30px"
        ml="30px"
        mr="30px"
        mt="100px"
      >
        <AspectRatio
          gridArea="preview"
          display="flex"
          position="sticky"
          top={5}
          rounded="xl"
          overflow="hidden"
        >
          <Preview
            selectedProductByProductGroupId={selectedProductByProductGroupId}
            sceneType={sceneType}
            angle={Angle.Overview}
            isImageNavigationVisible={false}
          />
        </AspectRatio>
        <Box gridArea="text">
          <Box
            textAlign="left"
            mr={{ base: "0px", xl: "300px", "2xl": "500px" }}
          >
            {orderConfirmationPage.content.length > 0 && (
              <RichTextContent content={orderConfirmationPage.content} />
            )}
            {orderConfirmationPage.image && (
              <Image
                objectFit="cover"
                src={orderConfirmationPage.image}
                rounded={4}
                maxW="500px"
                maxH="200px"
                maxWidth="20vw"
                alignSelf="center"
              />
            )}
          </Box>
        </Box>
      </Grid>
    </Flex>
  );
}
