import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CustomerDetailsView } from "./CustomerDetailsView";
import { EditCustomerProductsView } from "./EditCustomerProductsView";
import { CustomerProductsView } from "./CustomerProductsView";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { customersApi } from "../../api/customersApi";
import { useEffect } from "react";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { ErrorDetails } from "../shared/ErrorDetails";
import { Sidebar, SidebarNavItem } from "../Sidebar";
import { ProjectList } from "../projects/ProjectList";
import { ProjectPage } from "../projects/ProjectPage";
import { ProductPackageList } from "../productPackages/ProductPackageList";
import { useRequiredParams } from "../../hooks/useRequiredParams";
import { WorkPlanList } from "../workPlans/WorkPlanList";
import { Icons } from "../shared/Icons";
import { EditWorkPlanView } from "../workPlans/EditWorkPlanView/EditWorkPlanView";
import { routes } from "../../routes";
import { ProductPackageDetailsView } from "../productPackages/ProductPackageDetailsView";
import { OrdersView } from "../orders/OrdersView";
import { StyleList } from "../styles/StyleList";
import { StyleDetailsView } from "../styles/StyleDetailsView";
import { CustomerStatusBadge } from "./CustomerStatusBadge";

export function CustomerPage() {
  const { t } = useTranslation();

  const { customerId } = useRequiredParams("customerId");
  const [customer, loading, error, fetch] = useApiRequest(
    customersApi.getCustomerDetails,
  );

  useEffect(() => {
    fetch(customerId);
  }, [fetch, customerId]);

  function getCustomerDetails() {
    fetch(customerId);
  }
  return (
    <Flex flex="1">
      {loading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      <Sidebar
        topContent={
          customer?.status && (
            <Box m="2" mb="4" mr="4">
              <CustomerStatusBadge
                status={customer?.status}
              ></CustomerStatusBadge>
            </Box>
          )
        }
        heading={customer?.name}
      >
        <Heading mt="8" size="sm">
          {t("general.backOffice")}
        </Heading>
        <SidebarNavItem
          to={routes.customerDetails(customerId)}
          label={t("customer.customerDetails")}
          extra={
            <CompletedIcon completed={customer?.completedCustomerDetails} />
          }
        />
        <SidebarNavItem
          to={routes.customerProducts(customerId)}
          label={t("customer.assortment")}
          extra={<CompletedIcon completed={customer?.completedAssortment} />}
        />
        <SidebarNavItem
          to={routes.styles(customerId)}
          label={t("style.styles")}
        />
        <SidebarNavItem
          to={routes.productPackages(customerId)}
          label={t("productPackage.productPackages")}
          extra={
            <CompletedIcon completed={customer?.completedProductPackages} />
          }
        />
        <SidebarNavItem
          to={routes.workPlans(customerId)}
          label={t("workPlan.workPlans")}
          extra={<CompletedIcon completed={customer?.completedWorkPlans} />}
        />
        <Heading mt="8" size="sm">
          {t("general.theProject")} -{" "}
          {t("general.kitchen").toString().toLowerCase()}
        </Heading>
        <SidebarNavItem
          to={routes.projects(customerId)}
          label={t("general.projects")}
          extra={<CompletedIcon completed={customer?.completedProjects} />}
        />
        <SidebarNavItem
          to={routes.orders(customerId)}
          label={t("order.orders")}
        />
      </Sidebar>
      <Box flexGrow={1}>
        {customer && (
          <Routes>
            <Route
              path="projects"
              element={
                <ProjectList
                  customer={customer}
                  onUpdate={getCustomerDetails}
                />
              }
            />
            <Route
              path="details"
              element={
                <CustomerDetailsView
                  customer={customer}
                  onUpdate={getCustomerDetails}
                />
              }
            />
            <Route
              path="products"
              element={<CustomerProductsView customer={customer} />}
            />
            <Route
              path="products/edit"
              element={
                <EditCustomerProductsView
                  customer={customer}
                  onUpdate={getCustomerDetails}
                />
              }
            />
            <Route
              path="packages"
              element={
                <ProductPackageList
                  customer={customer}
                  onUpdate={getCustomerDetails}
                />
              }
            />
            <Route
              path="packages/:productPackageId"
              element={<ProductPackageDetailsView customer={customer} />}
            />
            <Route
              path="styles"
              element={
                <StyleList customer={customer} onUpdate={getCustomerDetails} />
              }
            />
            <Route
              path="styles/:styleId"
              element={<StyleDetailsView customer={customer} />}
            />
            <Route
              path="work-plans"
              element={
                <WorkPlanList
                  customer={customer}
                  onUpdate={getCustomerDetails}
                />
              }
            />
            <Route
              path="work-plans"
              element={
                <WorkPlanList
                  customer={customer}
                  onUpdate={getCustomerDetails}
                />
              }
            />
            <Route
              path="work-plans"
              element={
                <WorkPlanList
                  customer={customer}
                  onUpdate={getCustomerDetails}
                />
              }
            />
            <Route
              path="work-plans/:workPlanId"
              element={<EditWorkPlanView customer={customer} />}
            />
            <Route
              path="projects/:projectId/*"
              element={<ProjectPage customer={customer} />}
            />
            <Route path="orders" element={<OrdersView customer={customer} />} />
            <Route
              path="*"
              element={
                <Navigate to={routes.projects(customerId)} replace={true} />
              }
            />
          </Routes>
        )}
      </Box>
    </Flex>
  );
}

function CompletedIcon({ completed }: { completed?: boolean }) {
  return completed ? (
    <Icons.CheckboxCircle color="primary.500" boxSize={4} />
  ) : (
    <Icons.CheckboxBlankCircle color="primary.500" boxSize={4} />
  );
}
