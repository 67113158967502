import { ApiRequest } from "../hooks/useApi/types";
import { PagedItems, SortingOptions, PagingOptions } from "../types";

export const ordersApi = {
  getOrdersList:
    (params: ListOrderRequest): ApiRequest<PagedItems<OrderListItem>> =>
    (httpClient) =>
      httpClient.get(`v1/orders`, { params }),

  deleteOrder:
    (orderId: string): ApiRequest =>
    (httpClient) =>
      httpClient.delete(`v1/orders/${orderId}`),
};

interface ListOrderRequest
  extends SortingOptions<OrderListItem>,
    PagingOptions {
  customerId: string;
}

export interface OrderListItem {
  id: string;
  projectName: string;
  orderDate: string;
  tenantAddress: string;
  tenantPostalCode: string;
  tenantCity: string;
  tenantApartmentNumber: string;
}
