import { ApiRequest } from "../hooks/useApi/types";

export const customOrderSheetFieldsApi = {
  createCustomOrderSheetField:
    (request: CreateCustomOrderSheetFieldRequest): ApiRequest =>
    (httpClient) =>
      httpClient.post("v1/custom-order-sheet-fields", { data: request }),

  listCustomOrderSheetFields:
    (projectId: string): ApiRequest<CustomOrderSheetFieldList> =>
    (httpClient) =>
      httpClient.get("v1/custom-order-sheet-fields", { params: { projectId } }),

  updateCustomOrderSheetField:
    (id: string, request: UpdateCustomOrderSheetFieldRequest): ApiRequest =>
    (httpClient) =>
      httpClient.put(`v1/custom-order-sheet-fields/${id}`, { data: request }),

  updateCustomOrderSheetFieldOrder:
    (request: UpdateCustomOrderSheetFieldSortOrderRequest): ApiRequest =>
    (httpClient) =>
      httpClient.put(`v1/custom-order-sheet-fields/sort-order`, {
        data: request,
      }),

  deleteCustomOrderSheetField:
    (id: string): ApiRequest =>
    (httpClient) =>
      httpClient.delete(`v1/custom-order-sheet-fields/${id}`),
};

export interface UpdateCustomOrderSheetFieldRequest {
  name: string;
  isRequired: boolean;
  fieldType: CustomOrderSheetFieldType;
}

export interface CreateCustomOrderSheetFieldRequest {
  projectId: string;
  name: string;
  isRequired: boolean;
  fieldType: CustomOrderSheetFieldType;
  category: CustomOrderSheetFieldCategory;
}

export interface CustomOrderSheetFieldList {
  customerItems: CustomOrderSheetFieldListItem[];
  tenantItems: CustomOrderSheetFieldListItem[];
}

export interface CustomOrderSheetFieldListItem {
  id: string;
  name: string;
  isRequired: boolean;
  sortOrder: number;
  fieldType: CustomOrderSheetFieldType;
}

export interface UpdateCustomOrderSheetFieldSortOrderRequest {
  id: string;
  direction: Direction;
}

export enum Direction {
  Up = "Up",
  Down = "Down",
}

export enum CustomOrderSheetFieldType {
  Text = "Text",
  Checkbox = "Checkbox",
}

export enum CustomOrderSheetFieldCategory {
  Customer = "Customer",
  Tenant = "Tenant",
}
