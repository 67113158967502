// Source: https://gist.github.com/codeguy/6684588
export function slugify(value: string) {
  return value
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .replace(/[\\/_,:;!@|#$^&*()\-=+ ]/g, " ")
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces
    .replace(/\s+/g, "-"); // separator
}

export function getEnumValues<T>(enumObj: T): Array<T[keyof T]> {
  return Object.keys(enumObj)
    .filter((key) => isNaN(parseInt(key, 10)))
    .map((key) => (enumObj as any)[key]);
}

export function nullSafeAddUpList(values: (number | null)[]): number | null {
  return Object.values(values)
    .filter((v) => v !== null)
    .reduce((prev, curr) => prev! + curr!, null);
}

export function nullSafeSubtract(
  a: number | null,
  b: number | null,
): number | null {
  if (a === null && b === null) return null;
  return a! - b!;
}
