import { Button, Flex, useCallbackRef } from "@chakra-ui/react";
import { Ref, useEffect, useState } from "react";
import { InputActionMeta, components } from "react-select";
import { SelectComponentsConfig } from "react-select/dist/declarations/src/components";
import { useDebouncedCallback } from "use-debounce";
import { PagedItems, ServerError, PagingOptions } from "../../../types";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { Icons } from "../../shared/Icons";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { Select } from "../Select/Select";
import { SharedSelectProps } from "../Select/types";

interface AsyncSelectProps<T> extends SharedSelectProps {
  options: PagedItems<T> | null;
  optionsError: ServerError | null;
  optionsLoading: boolean;
  value: T | null;
  valueError: ServerError | null;
  valueLoading: boolean;
  onChange: (item: T | null) => void;
  getOptionValue: (item: T) => string;
  getOptionLabel: (item: T) => string;
  onSearchOptions: (params: {
    searchTerm: string;
    page: number;
    pageSize: number;
  }) => void;
  innerRef?: Ref<any>;
}

export function AsyncSelect<T>({
  options,
  optionsError,
  optionsLoading,
  value,
  valueError,
  valueLoading,
  onChange,
  getOptionValue,
  getOptionLabel,
  onSearchOptions,
  ...selectProps
}: AsyncSelectProps<T>) {
  const [searchTerm, setSearchTerm] = useState<string>();
  const [paging, setPaging] = useState<PagingOptions>({
    page: 1,
    pageSize: 30,
  });

  const stableSearchCallback = useCallbackRef(onSearchOptions);

  useEffect(() => {
    if (searchTerm === undefined) {
      return;
    }

    stableSearchCallback({ searchTerm, ...paging });
  }, [searchTerm, paging, stableSearchCallback]);

  const onSearchItemsDebounced = useDebouncedCallback(setSearchTerm, 350);

  function handleSelectInputChange(
    inputValue: string,
    actionMeta: InputActionMeta,
  ) {
    if (actionMeta.action === "input-change") {
      onSearchItemsDebounced(inputValue);
    }
  }

  function handleSelectMenuOpen() {
    setSearchTerm("");
  }

  const internalSelectProps: InternalSelectProps = {
    totalOptions: options?.totalItems ?? 0,
    paging,
    onPageChange: (page: number) => setPaging((s) => ({ ...s, page })),
    optionsLoading,
    optionsError,
  };

  return (
    <>
      <Select
        value={value}
        onChange={(selectedItem) => onChange(selectedItem as T)}
        options={options?.items as any}
        isLoading={valueLoading || optionsLoading}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onInputChange={handleSelectInputChange}
        onMenuOpen={handleSelectMenuOpen}
        filterOption={null}
        components={{ Menu }}
        {...selectProps}
        {...internalSelectProps}
      />
      {valueError && <ErrorDetails error={valueError} />}
    </>
  );
}

interface InternalSelectProps {
  paging: PagingOptions;
  onPageChange: (page: number) => void;
  totalOptions: number;
  optionsError: ServerError | null;
  optionsLoading: boolean;
}

const Menu: SelectComponentsConfig<any, any, any>["Menu"] = (props) => {
  const { totalOptions, paging, onPageChange, optionsError, optionsLoading } =
    props.selectProps as unknown as InternalSelectProps;
  const pageCount = Math.ceil(totalOptions / paging.pageSize);
  const currentPage = paging.page;
  const hasPrev = currentPage > 1;
  const hasNext = currentPage < pageCount;

  return (
    <>
      <components.Menu {...props}>
        <>
          {optionsLoading && <LoadingIndicator position="overlay" />}
          {optionsError && <ErrorDetails error={optionsError} />}
          {props.children}
          {pageCount > 1 && (
            <Flex>
              <Button
                variant="outline"
                borderRadius={0}
                isFullWidth={true}
                disabled={!hasPrev}
                onClick={() => onPageChange(currentPage - 1)}
              >
                <Icons.ArrowLeft boxSize={6} />
              </Button>
              <Button
                variant="outline"
                borderRadius={0}
                isFullWidth={true}
                disabled={!hasNext}
                onClick={() => onPageChange(currentPage + 1)}
              >
                <Icons.ArrowRight boxSize={6} />
              </Button>
            </Flex>
          )}
        </>
      </components.Menu>
    </>
  );
};
