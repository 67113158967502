import { useCallback, useEffect, useMemo, useState } from "react";
import {
  workPlansApi,
  GeneralWorkPlanStepListItem,
} from "../../../api/workPlansApi";
import { useApiRequest } from "../../../hooks/useApi/useApiRequest";
import { useGlobalPageSize } from "../../../hooks/usePageSize";
import { useSavedSorting } from "../../../hooks/useSavedSorting";
import { PagingOptions } from "../../../types";

export function useGeneralWorkSteps(workPlanId: string) {
  const [workSteps, isLoading, error, fetchItems] = useApiRequest(
    workPlansApi.listGeneralWorkPlanSteps,
  );
  const { savedSorting, setSavedSorting } =
    useSavedSorting<GeneralWorkPlanStepListItem>(
      "EditWorkPlanView.GeneralWorkStepList",
      { sortingDesc: false, sortingKey: "name" },
    );
  const { globalPageSize, setGlobalPageSize } = useGlobalPageSize();
  const [paging, setPaging] = useState<PagingOptions>({
    page: 1,
    pageSize: globalPageSize,
  });

  useEffect(() => {
    fetchItems(workPlanId, { ...paging, ...savedSorting });
  }, [fetchItems, paging, savedSorting, workPlanId]);

  const fetchGeneralWorkSteps = useCallback(
    () => fetchItems(workPlanId, { ...paging, ...savedSorting }),
    [fetchItems, paging, savedSorting, workPlanId],
  );

  return useMemo(
    () => ({
      workSteps,
      isLoading,
      error,
      fetchGeneralWorkSteps,
      savedSorting,
      setSavedSorting,
      globalPageSize,
      setGlobalPageSize,
      paging,
      setPaging,
    }),
    [
      workSteps,
      isLoading,
      error,
      fetchGeneralWorkSteps,
      savedSorting,
      setSavedSorting,
      globalPageSize,
      setGlobalPageSize,
      paging,
      setPaging,
    ],
  );
}

export type GeneralWorkSteps = ReturnType<typeof useGeneralWorkSteps>;
