import { Center, Container, Heading } from "@chakra-ui/layout";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  OrderProductPackage,
  ProductPackageSummary,
  SceneType,
} from "../../../api/externalApi";
import { Icons } from "../../shared/Icons";
import { RichTextContent } from "../../shared/RichText/RichTextContent";
import { TextHighlight } from "../../shared/TextHighlight";
import { getScenePreviewImage } from "../Helpers/KitchenSceneHelper";

export default function ProductPackageSelector({
  productPackages,
  selectedProductPackage,
  selectProductPackage,
  selectedKitchenScene,
}: {
  productPackages: ProductPackageSummary[];
  selectedProductPackage: OrderProductPackage | null;
  selectProductPackage: (productPackage: ProductPackageSummary) => void;
  selectedKitchenScene: SceneType | null;
}) {
  const { t } = useTranslation();

  return (
    <Box
      position="relative"
      top={selectedKitchenScene && selectedProductPackage ? "80px" : "0"}
    >
      <Container maxWidth="7xl">
        <Flex flexDirection="column" alignItems="center" py={10}>
          <Heading fontSize="5xl" mb={4}>
            <TextHighlight>{t("external.selectPackage")}</TextHighlight>
          </Heading>
          <Text textAlign="center" fontSize="sm" fontWeight="bold">
            {t("external.selectPackageDescription")}
          </Text>
          <Box w="180px" mt="6" mb="2" h="1" background="primary.500" />
          <Flex
            w={{ base: "580px", lg: "800px" }}
            maxW="80vw"
            textAlign="left"
            m="4"
          >
            <Button
              variant="link"
              fontSize="xs"
              display="flex"
              alignItems="center"
              onClick={() => window.history.back()}
            >
              <Icons.ArrowLeft boxSize={5} />
              {t("general.back")}
            </Button>
          </Flex>
          <Flex overflowY="auto" maxWidth="90vw">
            {productPackages.map((productPackage) => (
              <ProductPackage
                key={productPackage.id}
                isSelected={selectedProductPackage?.id === productPackage.id}
                productPackage={productPackage}
                selectProductPackage={selectProductPackage}
                selectedKitchenScene={selectedKitchenScene}
              />
            ))}
          </Flex>
          <Center w={{ base: "100%", md: "auto" }}>
            <Flex direction={{ base: "column", md: "row" }} wrap="wrap"></Flex>
          </Center>
        </Flex>
      </Container>
    </Box>
  );
}

function ProductPackage({
  isSelected,
  productPackage,
  selectProductPackage,
  selectedKitchenScene,
}: {
  isSelected: boolean;
  productPackage: ProductPackageSummary;
  selectProductPackage: (productPackage: ProductPackageSummary) => void;
  selectedKitchenScene: SceneType | null;
}) {
  const { t } = useTranslation();

  function handleClick() {
    selectProductPackage(productPackage);
  }

  return (
    <Box
      borderWidth={isSelected ? "3px" : 0}
      borderColor={isSelected ? "primary.500" : "primary.100"}
      m="4"
      background="blackAlpha.100"
      maxW={isSelected ? "306px" : "300px"}
    >
      <Flex flexDirection="column">
        <Box
          height={{ base: "150px", lg: "300px" }}
          w={{ base: "250px", lg: "300px" }}
          cursor={isSelected ? "default" : "pointer"}
          key={productPackage.id}
          display="flex"
          flexDirection="column"
          alignItems="center"
          borderStartStyle="solid"
          background={`url(${
            productPackage.image
              ? productPackage.image
              : selectedKitchenScene
              ? getScenePreviewImage(selectedKitchenScene)
              : getScenePreviewImage(SceneType.Hjornekokken)
          })`}
          backgroundSize="cover"
          backgroundPosition="center"
          py="6"
        ></Box>
        <Flex
          alignItems="center"
          flexDirection="column"
          p="4"
          gridGap={2}
          flexGrow={1}
        >
          <Heading
            justifySelf="top"
            textAlign="center"
            fontSize="xl"
            color="blackAlpha.800"
            whiteSpace="pre-wrap"
            maxW="220px"
          >
            {productPackage.name}
          </Heading>
          <Flex flexGrow={1} w="100%" justifyContent="center">
            <Button
              w="60%"
              leftIcon={isSelected ? <Icons.Check /> : undefined}
              opacity={isSelected ? 0.4 : 1}
              marginTop="auto"
              onClick={handleClick}
            >
              {isSelected ? t("general.selected") : t("general.select")}
            </Button>
          </Flex>
          {productPackage.price && (
            <Text fontSize="sm" fontWeight="medium" color="primary.500">
              {t("general.krPerMonth", { price: productPackage.price })}
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex w={{ base: "250px", lg: "300px" }} justifyContent="center">
        {productPackage.description && (
          <Box p="4">
            <RichTextContent content={productPackage.description} />
          </Box>
        )}
      </Flex>
    </Box>
  );
}
