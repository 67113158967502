import { Heading, Stack, StackDivider, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ProductVariantListItem } from "../../../api/productVariantsApi";

export function ProductVariantList({
  selectedVariantId,
  onSelectVariantId,
  productVariants,
}: {
  selectedVariantId: string;
  onSelectVariantId: (variantId: string) => void;
  productVariants: ProductVariantListItem[];
}) {
  const { t } = useTranslation();

  return (
    <>
      <Heading size="sm" mb={2}>
        {t("product.variants")}
      </Heading>
      <Stack
        direction="column"
        divider={<StackDivider borderColor="gray.50" />}
        spacing={0}
      >
        {productVariants.map((productVariant) => {
          const isSelected = productVariant.id === selectedVariantId;
          return (
            <Box
              cursor="pointer"
              bg={isSelected ? "primary.500" : "white"}
              color={isSelected ? "white" : "gray.900"}
              px={3}
              py={2}
              key={productVariant.id}
              onClick={() => onSelectVariantId(productVariant.id)}
            >
              {productVariant.name}
            </Box>
          );
        })}
      </Stack>
    </>
  );
}
