import { Route, Routes } from "react-router";
import { Link as RouterLink, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { projectsApi } from "../../api/projectsApi";
import { ReactNode, useEffect, useMemo } from "react";
import { Page } from "../shared/Page";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { ErrorDetails } from "../shared/ErrorDetails";
import { ProjectDetailsView } from "./ProjectDetailsView";
import { useActivePath } from "../../hooks/useActivePath";
import { ProjectLandingPageView } from "./projectLandingPage/ProjectLandingPageView";
import { CustomerDetails } from "../../api/customersApi";
import { ProjectOrderConfirmationPageView } from "./ProjectOrderConfirmationPageView";
import { ProjectOrderSheetView } from "./ProjectOrderSheetView";
import { ProjectOrderView } from "./ProjectOrderView";
import { routes } from "../../routes";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useRequiredParams } from "../../hooks/useRequiredParams";
import { KitchenDesignerView } from "./KitchenDesignerView";

export function ProjectPage({ customer }: { customer: CustomerDetails }) {
  const { t } = useTranslation();
  const { projectId } = useRequiredParams("projectId");
  const [project, loading, error, fetch, reset] = useApiRequest(
    projectsApi.getProjectDetails,
  );

  useEffect(() => {
    fetch(projectId);
  }, [fetch, projectId]);

  return (
    <Page
      breadcrumbs={useBreadcrumbs(customer.id, customer.name, project?.name)}
    >
      {loading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      {project && (
        <>
          <Flex alignItems="center">
            <Heading size="lg" mr={8} isTruncated={true} maxW="96">
              {project?.name}
            </Heading>
            <NavTab to={routes.projectDetails(customer.id, project.id)}>
              {t("general.settings")}
            </NavTab>
            <NavTab to={routes.projectPackages(customer.id, project.id)}>
              {t("general.kitchenDesigner")}
            </NavTab>
            <NavTab to={routes.projectLandingPage(customer.id, project.id)}>
              {t("project.landingPage")}
            </NavTab>
            <NavTab to={routes.orderConfirmationPage(customer.id, project.id)}>
              {t("project.orderConfirmationPage")}
            </NavTab>
            <NavTab to={routes.orderSheet(customer.id, project.id)}>
              {t("orderSheet.orderSheet")}
            </NavTab>
            <NavTab
              to={routes.projectOrder(customer.id, project.id)}
              end={true}
            >
              {t("project.digitalOrder")}
            </NavTab>
          </Flex>
          <Box mt={6}>
            <Routes>
              <Route
                path="details"
                element={
                  <ProjectDetailsView project={project} onUpdate={reset} />
                }
              />
              <Route
                path="packages"
                element={
                  <KitchenDesignerView
                    project={project}
                    customer={customer}
                    onChange={() => fetch(projectId)}
                  />
                }
              />
              <Route
                path="landingpage"
                element={
                  <ProjectLandingPageView
                    project={project}
                    onUpdate={() => fetch(projectId)}
                  />
                }
              />
              <Route
                path="orderconfirmationpage"
                element={
                  <ProjectOrderConfirmationPageView
                    project={project}
                    onUpdate={() => fetch(projectId)}
                  />
                }
              />
              <Route
                path="ordersheet"
                element={
                  <ProjectOrderSheetView
                    project={project}
                    customer={customer}
                    onCustomOrderSheetFieldChange={() => fetch(projectId)}
                  />
                }
              />
              <Route
                path="order"
                element={
                  <ProjectOrderView
                    project={project}
                    onUpdate={() => fetch(projectId)}
                    customer={customer}
                  />
                }
              />
              <Route
                path="*"
                element={
                  <Navigate
                    to={routes.projectDetails(customer.id, projectId)}
                    replace={true}
                  />
                }
              />
            </Routes>
          </Box>
        </>
      )}
    </Page>
  );
}

const activeNavTab = {
  borderBottom: "3px solid",
  marginBottom: "-3px",
};

const inactiveNavTab = {
  color: "gray.600",
  _hover: { bg: "none" },
};

function NavTab({
  to,
  end = false,
  children,
}: {
  to: string;
  end?: boolean;
  children: ReactNode;
}) {
  const isActive = useActivePath(to, end);

  return (
    <Button
      as={RouterLink}
      to={to}
      variant="ghost"
      borderRadius="none"
      zIndex="100"
      {...(isActive ? activeNavTab : inactiveNavTab)}
      borderBottomColor="primary.500"
    >
      {children}
    </Button>
  );
}

function useBreadcrumbs(
  customerId: string,
  customerName: string,
  projectName?: string,
) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("customer.customers"),
        to: routes.customers,
      },
      {
        label: customerName,
        to: routes.customer(customerId),
      },
      {
        label: t("general.projects"),
        to: routes.projects(customerId),
      },
      { label: projectName },
    ],
    [customerName, customerId, projectName, t],
  );
}
