import { createIcon } from "@chakra-ui/react";

/*
  Exposes svg icons wrapped as chakra-ui icons.

  Browse the full set of Remix Icons at http://remixicon.com
*/

import { ReactComponent as SvgUser } from "@svg-icons/remix-fill/user.svg";
import { ReactComponent as SvgArrowUpS } from "@svg-icons/remix-line/arrow-up-s.svg";
import { ReactComponent as SvgArrowDownS } from "@svg-icons/remix-line/arrow-down-s.svg";
import { ReactComponent as SvgArrowLeftS } from "@svg-icons/remix-line/arrow-left-s.svg";
import { ReactComponent as SvgArrowRightS } from "@svg-icons/remix-line/arrow-right-s.svg";
import { ReactComponent as SvgArrowUpFillS } from "@svg-icons/remix-fill/arrow-up-s.svg";
import { ReactComponent as SvgArrowDownFillS } from "@svg-icons/remix-fill/arrow-down-s.svg";
import { ReactComponent as SvgArrowLeftFillS } from "@svg-icons/remix-fill/arrow-left-s.svg";
import { ReactComponent as SvgArrowGoBack } from "@svg-icons/remix-fill/arrow-go-back.svg";
import { ReactComponent as SvgArrowRightFillS } from "@svg-icons/remix-fill/arrow-right-s.svg";
import { ReactComponent as SvgCheckboxCircle } from "@svg-icons/remix-fill/checkbox-circle.svg";
import { ReactComponent as SvgCheckboxBlankCircle } from "@svg-icons/remix-line/checkbox-blank-circle.svg";
import { ReactComponent as SvgAdd } from "@svg-icons/remix-line/add.svg";
import { ReactComponent as SvgClose } from "@svg-icons/remix-line/close.svg";
import { ReactComponent as SvgUpload } from "@svg-icons/remix-line/upload.svg";
import { ReactComponent as SvgSearch } from "@svg-icons/remix-line/search.svg";
import { ReactComponent as SvgFocus3 } from "@svg-icons/remix-fill/focus-3.svg";
import { ReactComponent as SvgTrash } from "@svg-icons/remix-line/delete-bin.svg";
import { ReactComponent as SvgCheck } from "@svg-icons/remix-line/check.svg";
import { ReactComponent as SvgExternalLink } from "@svg-icons/remix-line/external-link.svg";
import { ReactComponent as SvgEdit } from "@svg-icons/remix-line/edit.svg";
import { ReactComponent as SvgCopy } from "@svg-icons/remix-line/file-copy.svg";
import { ReactComponent as SvgMailCheck } from "@svg-icons/remix-line/mail-check.svg";
import { ReactComponent as SvgDownload } from "@svg-icons/remix-line/download.svg";
import { ReactComponent as SvgFullScreen } from "@svg-icons/remix-line/fullscreen.svg";

export const Icons = {
  Add: createIcon({ path: <SvgAdd /> }),
  ArrowUp: createIcon({ path: <SvgArrowUpS /> }),
  ArrowDown: createIcon({ path: <SvgArrowDownS /> }),
  ArrowLeft: createIcon({ path: <SvgArrowLeftS /> }),
  ArrowRight: createIcon({ path: <SvgArrowRightS /> }),
  ArrowUpFill: createIcon({ path: <SvgArrowUpFillS /> }),
  ArrowDownFill: createIcon({ path: <SvgArrowDownFillS /> }),
  ArrowLeftFill: createIcon({ path: <SvgArrowLeftFillS /> }),
  ArrowRightFill: createIcon({ path: <SvgArrowRightFillS /> }),
  ArrowGoBack: createIcon({ path: <SvgArrowGoBack /> }),
  CheckboxCircle: createIcon({ path: <SvgCheckboxCircle /> }),
  CheckboxBlankCircle: createIcon({ path: <SvgCheckboxBlankCircle /> }),
  Close: createIcon({ path: <SvgClose /> }),
  Focus3: createIcon({ path: <SvgFocus3 /> }),
  FullScreen: createIcon({ path: <SvgFullScreen /> }),
  Upload: createIcon({ path: <SvgUpload /> }),
  Search: createIcon({ path: <SvgSearch /> }),
  Trash: createIcon({ path: <SvgTrash /> }),
  Check: createIcon({ path: <SvgCheck /> }),
  ExternalLink: createIcon({ path: <SvgExternalLink /> }),
  Edit: createIcon({ path: <SvgEdit /> }),
  Copy: createIcon({ path: <SvgCopy /> }),
  MailCheck: createIcon({ path: <SvgMailCheck /> }),
  Download: createIcon({ path: <SvgDownload /> }),
  User: createIcon({ path: <SvgUser /> }),
};
