import { Flex, Text, Box, useBreakpointValue } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  externalApi,
  ExternalProjectDetails,
  OrderConfiguration,
} from "../../../api/externalApi";
import { useApiRequest } from "../../../hooks/useApi/useApiRequest";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { Header } from "../Header";
import { ConfirmationPage } from "./ConfirmationPage";
import { OrderSteps } from "./OrderSteps";
import { OtherOptionsProductsSelector } from "./OtherOptionsProductsSelector";
import ProductPackageSelector from "./PackageSelector";
import { ProductSelector } from "./ProductSelector";
import { Summary } from "./Summary";
import { useOrderState } from "./useOrderState";
import { KitchenSceneSelector } from "./KitchenSceneSelector";
import { useWindowHeight } from "../../../hooks/useWindowHeight";
import { TextHighlight } from "../../shared/TextHighlight";
import { StylesSelector } from "./StylesSelector";

export function OrderWizard({
  project,
  customerSlug,
  projectSlug,
}: {
  project: ExternalProjectDetails;
  customerSlug: string;
  projectSlug: string;
}) {
  const [orderConfiguration, isLoading, error, fetch] = useApiRequest(
    externalApi.getOrderConfiguration,
  );

  useEffect(() => {
    fetch(project.id);
  }, [project.id, fetch]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <ErrorDetails error={error} />;
  }

  return (
    orderConfiguration && (
      <Content
        project={project}
        orderConfiguration={orderConfiguration}
        customerSlug={customerSlug}
        projectSlug={projectSlug}
      />
    )
  );
}

function Content({
  project,
  orderConfiguration,
  customerSlug,
  projectSlug,
}: {
  project: ExternalProjectDetails;
  orderConfiguration: OrderConfiguration;
  customerSlug: string;
  projectSlug: string;
}) {
  const {
    selectedOrderStep,
    productPackages,
    kitchenScenes,
    selectedProductByProductGroupId,
    selectedProductPackage,
    selectedKitchenScene,
    selectedStyle,
    selectProduct,
    selectStyle,
    selectProductPackage,
    selectKitchenScene,
    goToNextStep,
    goToPreviousStep,
    selectOrderStep,
    setIsStyleInfoAtInitVisible,
    totalPrice,
    totalVariantsPrice,
    totalOtherVariantsPrice,
    totalIncludedVariantsPrice,
    uncompletedSteps,
    hasAnyOtherCategories,
    isAllOtherCategoriesComplete,
    isStyleInfoAtInitVisible,
    isLastRenderedProductGroupStep,
  } = useOrderState(orderConfiguration);
  const { t } = useTranslation();

  const hideProductPackageSelect = productPackages.length === 1;
  const hideKitchenSceneSelect =
    kitchenScenes.length === 1 ||
    selectedProductPackage?.renderedProductGroups.length === 0;
  const hideStylesSelect = selectedProductPackage?.styles.length === 0;
  const windowHeight = useWindowHeight();
  const isSmallScreen = useBreakpointValue({ base: true, xl: false });

  useEffect(() => {
    if (
      selectedOrderStep.type === "ProductPackages" &&
      hideProductPackageSelect
    ) {
      selectProductPackage(productPackages[0]);
    }
  }, [
    productPackages,
    selectProductPackage,
    selectedOrderStep.type,
    hideProductPackageSelect,
  ]);

  useEffect(() => {
    if (selectedOrderStep.type === "KitchenScene" && hideKitchenSceneSelect) {
      selectKitchenScene(kitchenScenes[0]);
    }
  }, [
    kitchenScenes,
    selectKitchenScene,
    selectedOrderStep.type,
    hideKitchenSceneSelect,
  ]);

  useEffect(() => {
    if (selectedOrderStep.type === "Styles" && hideStylesSelect) {
      goToNextStep();
    }
  }, [selectedOrderStep, hideStylesSelect, goToNextStep]);

  return (
    <Box display="flex" flex="1" justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        flex="1"
        style={
          selectedOrderStep.type === "Summary" && isSmallScreen
            ? {}
            : { maxWidth: (windowHeight + 150) * 1.4 }
        }
      >
        <Header
          customerSlug={customerSlug}
          projectSlug={projectSlug}
          logoImage={project.logoImage}
          hideLogo={project.hideLogo}
          showCleanAndStartOver={true}
          extraContent={
            totalPrice !== null &&
            totalPrice >= 0 &&
            selectedOrderStep.type !== "Confirmation" &&
            selectedOrderStep.type !== "Summary" && (
              <TextHighlight>
                <Text fontSize={{ base: "xs", xl: "xl" }}>
                  {`${t("external.total")}: `}
                  <strong>
                    {t("external.krPerMonth", { price: totalPrice })}
                  </strong>
                </Text>
              </TextHighlight>
            )
          }
        />
        <Flex direction="column" flex="1" position="relative">
          {selectedKitchenScene && selectedProductPackage && (
            <OrderSteps
              projectSlug={projectSlug}
              hideProductPackageSelect={hideProductPackageSelect}
              hideKitchenSceneSelect={hideKitchenSceneSelect}
              hideOtherOptions={!hasAnyOtherCategories}
              hideStylesSelect={hideStylesSelect}
              hideSummary={project.onlyVisualization}
              isAllOtherCategoriesComplete={isAllOtherCategoriesComplete}
              selectedOrderStep={selectedOrderStep}
              selectedProductPackage={selectedProductPackage}
              selectedProductByProductGroupId={selectedProductByProductGroupId}
              selectOrderStep={selectOrderStep}
            />
          )}
          {!hideProductPackageSelect &&
            selectedOrderStep.type === "ProductPackages" && (
              <ProductPackageSelector
                productPackages={productPackages}
                selectedProductPackage={selectedProductPackage}
                selectedKitchenScene={selectedKitchenScene}
                selectProductPackage={selectProductPackage}
              />
            )}
          {!hideKitchenSceneSelect &&
            selectedOrderStep.type === "KitchenScene" && (
              <KitchenSceneSelector
                scenes={kitchenScenes}
                selectedProductPackage={selectedProductPackage}
                selectedKitchenScene={selectedKitchenScene}
                selectKitchenScene={selectKitchenScene}
                goBack={goToPreviousStep}
              />
            )}
          {!hideStylesSelect &&
            selectedOrderStep.type === "Styles" &&
            selectedProductPackage &&
            selectedKitchenScene && (
              <StylesSelector
                selectedProductByProductGroupId={
                  selectedProductByProductGroupId
                }
                selectedStyle={selectedStyle}
                selectedKitchenScene={selectedKitchenScene}
                styles={selectedProductPackage.styles}
                isStyleInfoAtInitVisible={isStyleInfoAtInitVisible}
                onSelectStyle={selectStyle}
                goToNextStep={goToNextStep}
                setIsStyleInfoAtInitVisible={setIsStyleInfoAtInitVisible}
              ></StylesSelector>
            )}
          {selectedOrderStep.type === "RenderedProductGroup" &&
            selectedProductPackage &&
            selectedKitchenScene && (
              <ProductSelector
                selectedProductByProductGroupId={
                  selectedProductByProductGroupId
                }
                hideNextStep={
                  selectedProductPackage.nonRenderedProductGroups.length ===
                    0 &&
                  project.onlyVisualization &&
                  isLastRenderedProductGroupStep
                }
                selectedKitchenScene={selectedKitchenScene}
                selectProduct={selectProduct}
                goToNextStep={goToNextStep}
                productGroup={selectedOrderStep.productGroup}
              />
            )}
          {selectedOrderStep.type === "NonRenderedProductGroup" &&
            selectedKitchenScene &&
            selectedProductPackage && (
              <OtherOptionsProductsSelector
                selectedProductByProductGroupId={
                  selectedProductByProductGroupId
                }
                selectedKitchenScene={selectedKitchenScene}
                selectProduct={selectProduct}
                goToNextStep={goToNextStep}
                hideNextStep={project.onlyVisualization}
                categories={selectedProductPackage.nonRenderedProductGroups}
                otherOptionsImage={selectedProductPackage.otherOptionsImage}
              />
            )}
          {selectedOrderStep.type === "Summary" &&
            selectedProductPackage &&
            selectedKitchenScene &&
            !project.onlyVisualization && (
              <Summary
                project={project}
                customOrderFields={orderConfiguration.customOrderFields}
                selectedProductPackage={selectedProductPackage}
                selectedProductByProductGroupId={
                  selectedProductByProductGroupId
                }
                totalPrice={totalPrice}
                totalVariantsPrice={totalVariantsPrice}
                totalOtherVariantsPrice={totalOtherVariantsPrice}
                totalOtherIncludedPrice={totalIncludedVariantsPrice}
                selectedKitchenScene={selectedKitchenScene}
                uncompletedSteps={uncompletedSteps}
                onConfirmation={() => selectOrderStep({ type: "Confirmation" })}
              />
            )}
          {selectedOrderStep.type === "Confirmation" &&
            selectedKitchenScene && (
              <ConfirmationPage
                sceneType={selectedKitchenScene}
                orderConfirmationPage={project.orderConfirmationPage}
                selectedProductByProductGroupId={
                  selectedProductByProductGroupId
                }
              />
            )}
        </Flex>
      </Box>
    </Box>
  );
}
