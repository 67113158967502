import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { Permission, ServerError } from "../../types";
import { ImageList } from "../shared/ImageList";
import { usePermission } from "../../hooks/usePermission";
import { stylesApi, StyleDetails } from "../../api/stylesApi";
import { Card } from "../shared/Card";

export function StyleImageForm({
  style,
  onUpdate,
}: {
  style: StyleDetails;
  onUpdate: (style: StyleDetails) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const hasPermission = usePermission(Permission.ManageProducts);

  const [uploadError, setUploadError] = useState<ServerError | null>(null);
  const [uploadLoading, uploadRequest] = useApiRequestCallback(
    stylesApi.uploadStyleImage,
  );

  const [deleteError, setDeleteError] = useState<ServerError | null>(null);
  const [deleteLoading, deleteRequest] = useApiRequestCallback(
    stylesApi.deleteStyleImage,
  );

  const uploadImage = useCallback(
    (image: File) => {
      uploadRequest({
        onSuccess: (response) => {
          toast({
            title: t("general.added"),
            status: "success",
          });
          setUploadError(null);
          onUpdate({ ...style, image: response.image });
        },
        onError: setUploadError,
      }).send(style.id, image);
    },
    [uploadRequest, toast, t, onUpdate, style],
  );

  function deleteImage() {
    deleteRequest({
      onSuccess: () => {
        toast({
          title: t("general.deleted"),
          status: "success",
        });
        setDeleteError(null);
        onUpdate({ ...style, image: null });
      },
      onError: setDeleteError,
    }).send(style.id);
  }

  const imageList = useMemo(
    () => (style.image ? [{ id: "style-image", url: style.image }] : []),
    [style],
  );

  return (
    <Card titleContent={t("general.image")}>
      <ImageList
        readOnly={!hasPermission}
        maxImages={1}
        images={imageList}
        errors={[deleteError, uploadError]}
        isLoading={deleteLoading || uploadLoading}
        onUpload={uploadImage}
        onRemove={deleteImage}
      />
    </Card>
  );
}
