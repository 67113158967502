import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  SimpleGrid,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { productsApi, ProductDetails } from "../../../api/productsApi";
import { useApiRequest } from "../../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../../hooks/useApi/useApiRequestCallback";
import { Permission, ServerError } from "../../../types";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { ProductGroupAsyncSelect } from "../../shared/AsyncSelect/ProductGroupAsyncSelect";
import { Card } from "../../shared/Card";
import { Page } from "../../shared/Page";
import { ProductVariants } from "./ProductVariants";
import { useToastNotification } from "../../../hooks/useToastNotification";
import { ImageList } from "../../shared/ImageList";
import { routes } from "../../../routes";
import { Breadcrumb } from "../../shared/Breadcrumbs";
import { useRequiredParams } from "../../../hooks/useRequiredParams";
import { usePermission } from "../../../hooks/usePermission";

export function ProductDetailsView() {
  const { productId } = useRequiredParams("productId");
  const [product, loading, error, fetchProduct, setProduct] = useApiRequest(
    productsApi.getProductDetails,
  );

  useEffect(() => {
    fetchProduct(productId);
  }, [fetchProduct, productId]);

  return (
    <Page breadcrumbs={useBreadcrumbs(product?.name)}>
      {loading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}

      {product && (
        <ProductDetailsContent
          product={product}
          onUpdate={(product) => setProduct(product)}
        />
      )}
    </Page>
  );
}

interface ProductDetailsFormData {
  name: string;
  description: string;
  productGroupId: string;
}

function ProductDetailsContent({
  product,
  onUpdate,
}: {
  product: ProductDetails;
  onUpdate: (product: ProductDetails) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [error, setError] = useState<ServerError>();
  const hasPermission = usePermission(Permission.ManageProducts);

  const [updateProductLoading, updateProductRequest] = useApiRequestCallback(
    productsApi.updateProduct,
  );

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    reset,
    control,
  } = useForm<ProductDetailsFormData>({ defaultValues: product });

  function handleUpdateSuccess(updatedProduct: ProductDetails) {
    setError(undefined);
    reset(updatedProduct);
    onUpdate(updatedProduct);
    toast({ title: t("general.saved"), status: "success" });
  }

  async function onSubmit(data: ProductDetailsFormData) {
    updateProductRequest({
      onSuccess: handleUpdateSuccess,
      onError: setError,
    }).send(product.id, {
      name: data.name,
      description: data.description,
      productGroupId: data.productGroupId,
    });
  }

  return (
    <>
      {updateProductLoading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}
      <SimpleGrid spacing={6}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Card>
            <Stack spacing={4} shouldWrapChildren={true}>
              <FormControl
                id="name"
                isRequired={true}
                isInvalid={!!errors.name}
                isReadOnly={!hasPermission}
              >
                <FormLabel>{t("general.name")}</FormLabel>
                <Input
                  {...register("name", {
                    required: { value: true, message: t("error.required") },
                  })}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="productGroup"
                isRequired={!product.isUsedInCustomerAssortment}
                isInvalid={!!errors.productGroupId}
                isReadOnly={
                  !hasPermission || product.isUsedInCustomerAssortment
                }
              >
                <FormLabel>{t("productGroup.productGroup")}</FormLabel>
                <Controller
                  name="productGroupId"
                  render={({ field, fieldState }) => (
                    <ProductGroupAsyncSelect
                      isDisabled={
                        !hasPermission || product.isUsedInCustomerAssortment
                      }
                      {...field}
                      {...fieldState}
                    />
                  )}
                  control={control}
                  rules={{
                    required: { value: true, message: t("error.required") },
                  }}
                />
                {product.isUsedInCustomerAssortment && (
                  <FormHelperText fontSize="xs" color="orange">
                    {t("productGroup.isUsedInCustomerAssortment")}
                  </FormHelperText>
                )}
                <FormErrorMessage>
                  {errors.productGroupId?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="description"
                isInvalid={!!errors.description}
                isReadOnly={!hasPermission}
              >
                <FormLabel>{t("general.description")}</FormLabel>
                <Textarea {...register("description")} />
                <FormErrorMessage>
                  {errors.description?.message}
                </FormErrorMessage>
              </FormControl>
              <Flex justifyContent="flex-end">
                <Button type="submit" disabled={!isDirty}>
                  {t("general.save")}
                </Button>
              </Flex>
            </Stack>
          </Card>
        </form>
        <Card titleContent={t("product.productImages")}>
          <ProductImages product={product} />
        </Card>
        <ProductVariants product={product} />
      </SimpleGrid>
    </>
  );
}

function ProductImages({ product }: { product: ProductDetails }) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const hasPermission = usePermission(Permission.ManageProducts);

  const [uploadError, setUploadError] = useState<ServerError | null>(null);
  const [uploadLoading, uploadRequest] = useApiRequestCallback(
    productsApi.uploadProductImage,
  );

  const [deleteError, setDeleteError] = useState<ServerError | null>(null);
  const [deleteLoading, deleteRequest] = useApiRequestCallback(
    productsApi.deleteProductImage,
  );

  const [
    productImages,
    productImagesLoading,
    productImagesError,
    fetchProductImages,
  ] = useApiRequest(productsApi.listProductImages);

  useEffect(() => {
    fetchProductImages(product.id);
  }, [fetchProductImages, product.id]);

  const uploadImage = useCallback(
    (image: File) => {
      uploadRequest({
        onSuccess: () => {
          toast({
            title: t("general.added"),
            status: "success",
          });
          setUploadError(null);
          fetchProductImages(product.id);
        },
        onError: setUploadError,
      }).send(product.id, image);
    },
    [fetchProductImages, product.id, uploadRequest, toast, t],
  );

  function deleteImage(imageId: string) {
    deleteRequest({
      onSuccess: () => {
        toast({
          title: t("general.deleted"),
          status: "success",
        });
        setDeleteError(null);
        fetchProductImages(product.id);
      },
      onError: setDeleteError,
    }).send(product.id, imageId);
  }

  return (
    <ImageList
      readOnly={!hasPermission}
      images={productImages}
      errors={[productImagesError, deleteError, uploadError]}
      isLoading={productImagesLoading || deleteLoading || uploadLoading}
      onUpload={uploadImage}
      onRemove={deleteImage}
    />
  );
}

function useBreadcrumbs(productName?: string) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("assortment.assortment"),
        to: routes.assortment,
      },
      {
        label: t("product.products"),
        to: routes.products,
      },
      {
        label: productName,
      },
    ],
    [productName, t],
  );
}
