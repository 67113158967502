import { Button } from "@chakra-ui/button";
import { Stack } from "@chakra-ui/react";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CustomerDetails } from "../../../api/customersApi";
import { useApiRequest } from "../../../hooks/useApi/useApiRequest";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { Card } from "../../shared/Card";
import { Page } from "../../shared/Page";
import { workPlansApi } from "../../../api/workPlansApi";
import { routes } from "../../../routes";
import { Breadcrumb } from "../../shared/Breadcrumbs";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { CreateWorkPlanWorkStepModal } from "../CreateWorkPlanWorkStepModal";
import { useRequiredParams } from "../../../hooks/useRequiredParams";
import { useGeneralWorkSteps } from "./useGeneralWorkSteps";
import { GeneralWorkStepList } from "./GeneralWorkStepList";
import { WorkStepList } from "./WorkStepList";
import { useWorkSteps } from "./useWorkSteps";
import { WorkPlanDetailsForm } from "./WorkPlanDetailsForm";

export function EditWorkPlanView({ customer }: { customer: CustomerDetails }) {
  const { t } = useTranslation();
  const { workPlanId } = useRequiredParams("workPlanId");
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [workPlan, loadingWorkPlan, workPlanError, fetchWorkPlan, setWorkPlan] =
    useApiRequest(workPlansApi.getWorkPlan);

  const generalWorkSteps = useGeneralWorkSteps(workPlanId);
  const workSteps = useWorkSteps(workPlanId);

  useEffect(() => {
    fetchWorkPlan(workPlanId);
  }, [fetchWorkPlan, workPlanId]);

  return (
    <Page
      breadcrumbs={useBreadcrumbs(customer.id, customer.name, workPlan?.name)}
    >
      {workPlanError && <ErrorDetails error={workPlanError} />}
      {loadingWorkPlan && <LoadingIndicator />}
      {workPlan && (
        <Stack>
          <Card
            titleContent={t("workPlan.workPlan")}
            extraContent={
              <Button size="sm" onClick={() => setAddModalVisible(true)}>
                {t("workStep.newWorkStep")}
              </Button>
            }
          >
            <WorkPlanDetailsForm
              workPlan={workPlan}
              onUpdate={setWorkPlan}
              customer={customer}
              onWorkPlanDetailsUpdated={() => workSteps.fetchWorkPlanSteps()}
            />
          </Card>
          <GeneralWorkStepList
            workPlanId={workPlanId}
            generalWorkSteps={generalWorkSteps}
          />
          <WorkStepList workPlanId={workPlanId} workSteps={workSteps} />
        </Stack>
      )}
      {addModalVisible && (
        <CreateWorkPlanWorkStepModal
          workPlanId={workPlanId}
          onClose={() => setAddModalVisible(false)}
          onSuccess={() => {
            generalWorkSteps.fetchGeneralWorkSteps();
            workSteps.fetchWorkPlanSteps();
            setAddModalVisible(false);
          }}
        />
      )}
    </Page>
  );
}

function useBreadcrumbs(
  customerId: string,
  customerName: string,
  workPlanName?: string,
) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("customer.customers"),
        to: routes.customers,
      },
      {
        label: customerName,
        to: routes.customer(customerId),
      },
      {
        label: t("workPlan.workPlans"),
        to: routes.workPlans(customerId),
      },
      {
        label: workPlanName,
      },
    ],
    [t, customerName, customerId, workPlanName],
  );
}
