import { ApiRequest } from "../hooks/useApi/types";
import { PagedItems, PagingOptions, SortingOptions } from "../types";

export const usersApi = {
  listUsers:
    (request: ListUserRequest): ApiRequest<PagedItems<UserListItem>> =>
    (httpClient) =>
      httpClient.get("v1/users", { params: request }),

  createUser:
    (data: CreateUserRequest): ApiRequest =>
    (httpClient) =>
      httpClient.post("v1/users", { data }),

  getUserDetails:
    (id: string): ApiRequest<UserDetails> =>
    (httpClient) =>
      httpClient.get(`v1/users/${id}`),

  updateUser:
    (id: string, request: UpdateUserRequest): ApiRequest<UserDetails> =>
    (httpClient) =>
      httpClient.put(`v1/users/${id}`, { data: request }),

  deleteUser:
    (id: string): ApiRequest<UserDetails> =>
    (httpClient) =>
      httpClient.delete(`v1/users/${id}`),

  sendActivateUserToken:
    (id: string): ApiRequest<UserDetails> =>
    (httpClient) =>
      httpClient.post(`v1/users/${id}/send-activate-user-token`),
};

interface ListUserRequest extends SortingOptions<UserListItem>, PagingOptions {}

export interface UserListItem {
  id: string;
  email: string;
  roleName?: string;
}

export interface CreateUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  roleId: string;
}

export interface UserDetails {
  id: string;
  roleId: string;
  firstName: string;
  lastName: string;
  email: string;
  roleName?: string;
  activated: boolean;
}

export interface UpdateUserRequest {
  firstName: string;
  lastName: string;
  roleId: string;
}
