import {
  Alert,
  AlertDescription,
  AlertTitle,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  ServerError,
  ServerErrorCode,
  ServerErrorDetails,
  UnknownServerError,
} from "../../types";

export function ErrorDetails({ error }: { error: ServerError }) {
  switch (error.errorCode) {
    case ServerErrorCode.Unknown:
      return <UnknownErrorDetails error={error} />;
    default:
      return <DefaultErrorDetails error={error} />;
  }
}

function DefaultErrorDetails({ error }: { error: ServerErrorDetails }) {
  const { t } = useTranslation();
  const message = t([
    `serverError.${error.errorCode}` as any,
    "serverError.Unknown",
  ]);
  return (
    <Alert
      status="error"
      data-test-id="error-details"
      flexDirection="column"
      alignItems="start"
    >
      <AlertTitle>{message}</AlertTitle>
      <AlertDescription>
        {!error.details && (
          <Text opacity={0.5} fontSize="xs" mt={2}>
            {error.errorCode} {error.traceId}
          </Text>
        )}
        {!!error.details && (
          <UnorderedList paddingTop={2}>
            {Object.keys(error.details).map((key) => {
              return (
                <ListItem
                  key={key}
                  textAlign="left"
                  fontSize="medium"
                  marginLeft={2}
                >
                  {error.details[key]}
                </ListItem>
              );
            })}
          </UnorderedList>
        )}
      </AlertDescription>
    </Alert>
  );
}

function UnknownErrorDetails({ error }: { error: UnknownServerError }) {
  const { t } = useTranslation();
  return (
    <Alert status="error" data-test-id="error-details">
      <AlertTitle>
        {t("serverError.Unknown")} [{error.status}]
      </AlertTitle>
    </Alert>
  );
}
