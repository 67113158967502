import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Angle, SceneType } from "../../../api/externalApi";
import { getScenePreviewImage } from "../Helpers/KitchenSceneHelper";
import kitchenDoorsImg from "../../../assets/closeup_01.jpg";
import countertopImg from "../../../assets/closeup_02.jpg";
import { ImageButton } from "../../shared/ImageButton";
import { getEnumValues } from "../../../helpers";
import { TextHighlight } from "../../shared/TextHighlight";

export function AngleSelector({
  selectedKitchenScene,
  selectedAngle,
  onSetAngle,
}: {
  selectedKitchenScene: SceneType;
  selectedAngle: Angle;
  onSetAngle: (angle: Angle) => void;
}) {
  const { t } = useTranslation();

  return (
    <Box
      position="absolute"
      top={{ base: "-5px", xl: "65px" }}
      left="5"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box mb="1" textAlign="center" position="relative" top="2">
        <Box
          display="inline-block"
          fontSize="s"
          textAlign="center"
          fontWeight="bold"
          pl="3"
          pr="3"
        >
          <TextHighlight>{t("angles.views")}</TextHighlight>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row">
        {angles.map((angle) => (
          <ImageButton
            key={angle}
            isSelected={angle === selectedAngle}
            text={t(`angles.${angle}`)}
            imgUrl={getAngleImage(angle, selectedKitchenScene)}
            onClick={() => onSetAngle(angle)}
          ></ImageButton>
        ))}
      </Box>
    </Box>
  );
}

function getAngleImage(angle: Angle, scene: SceneType) {
  switch (angle) {
    case Angle.Overview:
      return getScenePreviewImage(scene);
    case Angle.KitchenDoors:
      return kitchenDoorsImg;
    case Angle.Countertop:
      return countertopImg;
  }
}

const angles = getEnumValues(Angle);
