import {
  Heading,
  Flex,
  Stack,
  StackDivider,
  Divider,
  Link,
  Box,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { useActivePath } from "../hooks/useActivePath";
import { Link as RouterLink } from "react-router-dom";

export function Sidebar({
  heading,
  children,
  topContent,
}: {
  heading?: React.ReactNode;
  children: React.ReactNode;
  topContent?: React.ReactNode;
}) {
  return (
    <Flex
      flexDirection="column"
      w={64}
      pl={10}
      bg="white"
      shadow="xl"
      borderRight={1}
      borderRightColor="gray.200"
      overflowY="auto"
    >
      <Flex h={12} alignItems="center">
        <Heading size="md" isTruncated={true}>
          {heading}
        </Heading>
      </Flex>
      <Divider />
      {topContent}
      <Stack
        py={2}
        direction="column"
        divider={<StackDivider borderColor="gray.200" />}
      >
        {children}
      </Stack>
    </Flex>
  );
}

export function SidebarNavItem({
  to,
  label,
  extra,
}: {
  to: string;
  label: ReactNode;
  extra?: ReactNode;
}) {
  const isActive = useActivePath(to, false);

  return (
    <Flex mr={2} align="center">
      <Link
        to={to}
        as={RouterLink}
        color={isActive ? "primary.500" : "blackAlpha.800"}
        fontWeight={isActive ? "bold" : "normal"}
        flexGrow={1}
        mr={2}
      >
        {label}
      </Link>
      {extra && <Box mr={2}>{extra}</Box>}
    </Flex>
  );
}
