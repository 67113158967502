import { ApiRequest } from "../hooks/useApi/types";

export const authApi = {
  sendResetPasswordToken:
    (data: SendResetPasswordTokenRequest): ApiRequest =>
    (httpClient) =>
      httpClient.post("v1/auth/send-reset-password-token", { data }),

  resetPassword:
    (data: ResetPasswordRequest): ApiRequest =>
    (httpClient) =>
      httpClient.post("v1/auth/reset-password", { data }),

  activateUser:
    (data: ActivateUserRequest): ApiRequest =>
    (httpClient) =>
      httpClient.post("v1/auth/activate-user", { data }),
};

export interface SendResetPasswordTokenRequest {
  userEmail: string;
}

export interface ResetPasswordRequest {
  userId: string;
  newPassword: string;
  token: string;
}

export interface ActivateUserRequest {
  userId: string;
  password: string;
  token: string;
}
