import React from "react";
import { Navigate, Outlet } from "react-router";
import { usePermission } from "../hooks/usePermission";
import { Permission } from "../types";

export default function ProtectedRoute({
  permission,
}: {
  permission: Permission;
}) {
  if (usePermission(permission)) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
}
