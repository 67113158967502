import { ApiRequest } from "../hooks/useApi/types";
import { HttpClient } from "../hooks/useHttpClient/httpClient";

import { PagedItems, PagingOptions, SortingOptions } from "../types";
import { ProductGroup } from "./externalApi";

export const stylesApi = {
  listStyles:
    (request: ListStylesRequest): ApiRequest<PagedItems<StyleListItem>> =>
    (httpClient) =>
      httpClient.get("/v1/styles", { params: request }),

  createStyle:
    (request: CreateStyleRequest): ApiRequest<StyleDetails> =>
    (httpClient) =>
      httpClient.post("/v1/styles", { data: request }),

  getStyleDetails:
    (id: string): ApiRequest<StyleDetails> =>
    (httpClient) =>
      httpClient.get(`/v1/styles/${id}`),

  updateStyleDetails:
    (id: string, data: UpdateStyleRequest): ApiRequest<StyleDetails> =>
    (httpClient) =>
      httpClient.put(`v1/styles/${id}`, { data }),

  uploadStyleImage:
    (styleId: string, file: File): ApiRequest<StyleImage> =>
    (httpClient) => {
      const data = new FormData();
      data.append("file", file);
      return httpClient.post(`v1/styles/${styleId}/image`, {
        data,
      });
    },

  deleteStyleImage:
    (styleId: string): ApiRequest<StyleImage> =>
    (httpClient) =>
      httpClient.delete(`v1/styles/${styleId}/image`),

  listStyleProductVariantOptions:
    (
      request: ListStyleProductVariantOptionsRequest,
    ): ApiRequest<StyleProductVariant[]> =>
    (httpClient: HttpClient) =>
      httpClient.get("v1/styles/product-variant-options", {
        params: request,
      }),

  listStyleProductGroupOptions:
    (
      request: ListStyleProductGroupOptionsRequest,
    ): ApiRequest<StyleProductGroup[]> =>
    (httpClient: HttpClient) =>
      httpClient.get("v1/styles/product-group-options", {
        params: request,
      }),

  listStyleProductVariants:
    (styleId: string): ApiRequest<StyleProductVariant[]> =>
    (httpClient) =>
      httpClient.get(`v1/styles/${styleId}/product-variants`),

  createStyleProductVariant:
    (
      styleId: string,
      productVariantId: string,
    ): ApiRequest<StyleProductVariant> =>
    (httpClient) =>
      httpClient.post(
        `v1/styles/${styleId}/product-variants/${productVariantId}`,
      ),

  updateStyleProductVariant:
    (
      styleId: string,
      productVariantId: string,
    ): ApiRequest<StyleProductVariant> =>
    (httpClient) =>
      httpClient.put(
        `v1/styles/${styleId}/product-variants/${productVariantId}`,
      ),

  removeStyleProductVariant:
    (styleId: string, productVariantId: string): ApiRequest =>
    (httpClient) =>
      httpClient.delete(
        `v1/styles/${styleId}/product-variants/${productVariantId}`,
      ),
};

export interface StyleDetails {
  id: string;
  name: string;
  description: string;
  image: string | null;
  hasAnyProductPackages: boolean;
  productGroups: ProductGroup[];
}

export interface StyleProductVariant {
  id: string;
  name: string;
  styleProductGroup?: StyleProductGroup;
}

export interface StyleProductGroup {
  id: string;
  name: string;
}

export interface StyleImage {
  image: string;
}

export interface StyleListItem {
  id: string;
  name: string;
}

export interface ListStylesRequest
  extends SortingOptions<StyleListItem>,
    PagingOptions {
  customerId: string;
}

export interface CreateStyleRequest {
  customerId: string;
  name: string;
}

export interface UpdateStyleRequest {
  name: string;
  description: string;
}

export interface ListStyleProductVariantOptionsRequest {
  customerId: string;
  productGroupId: string;
}

export interface ListStyleProductGroupOptionsRequest {
  customerId: string;
  styleId: string;
}
export interface StyleProductGroup {
  id: string;
  name: string;
}

export interface CreateStyleProductVariantRequest {
  productVariantId: string;
}
