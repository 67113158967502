import { ApiRequest } from "../hooks/useApi/types";

export const productVariantsApi = {
  listProductVariants:
    (productId: string): ApiRequest<ProductVariantListItem[]> =>
    (httpClient) =>
      httpClient.get(`v1/product-variants?productId=${productId}`),

  createProductVariant:
    (data: CreateProductVariantRequest): ApiRequest<ProductVariantDetails> =>
    (httpClient) =>
      httpClient.post("v1/product-variants", { data }),

  updateProductVariant:
    (
      id: string,
      request: UpdateProductVariantRequest,
    ): ApiRequest<ProductVariantDetails> =>
    (httpClient) =>
      httpClient.put(`v1/product-variants/${id}`, { data: request }),

  getProductVariantDetails:
    (id: string): ApiRequest<ProductVariantDetails> =>
    (httpClient) =>
      httpClient.get(`v1/product-variants/${id}`),

  listProductVariantImages:
    (id: string): ApiRequest<ProductVariantImageItem[]> =>
    (httpClient) =>
      httpClient.get(`v1/product-variants/${id}/images`),

  uploadProductVariantImage:
    (id: string, file: File): ApiRequest<ProductVariantImageItem> =>
    (httpClient) => {
      const data = new FormData();
      data.append("file", file);
      return httpClient.post(`v1/product-variants/${id}/images`, {
        data,
      });
    },

  deleteProductVariantImage:
    (id: string, imageId: string): ApiRequest =>
    (httpClient) =>
      httpClient.delete(`v1/product-variants/${id}/images/${imageId}`),

  listRenderMaterials:
    (renderLayerId: string): ApiRequest<RenderMaterial[]> =>
    (httpClient) =>
      httpClient.get(
        `v1/product-variants/render-materials?renderLayerId=${renderLayerId}`,
      ),
};

export interface ProductVariantListItem {
  id: string;
  name: string;
}

export interface ProductVariantDetails {
  id: string;
  name: string;
  description: string;
  productId: string;
  productGroupRenderLayerId: string;
}

export interface CreateProductVariantRequest {
  productId: string;
  name: string;
}

export interface UpdateProductVariantRequest {
  name: string;
  description: string;
  renderMaterial: RenderMaterial | null;
}

export interface ProductVariantImageItem {
  id: string;
  url: string;
  thumbnailUrl: string;
}

export interface RenderMaterial {
  id: string;
  name: string;
}
