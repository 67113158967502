import { SystemStyleObject, Box } from "@chakra-ui/react";

const background: SystemStyleObject = {
  content: '""',
  position: "absolute",
  top: 0,
  bottom: 0,
  left: "-20px",
  right: "-20px",
  background: "secondary.100",
  zIndex: -1,
  clipPath: "polygon(12% 100%, 100% 75%, 88% 5%, 0% 35%)",
};

export function TextHighlight({ children }: { children: React.ReactNode }) {
  return (
    <Box
      whiteSpace="nowrap"
      as="span"
      position="relative"
      marginRight="10px"
      zIndex={1}
      _after={background}
    >
      {children}
    </Box>
  );
}
