import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useApiRequest } from "../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { Permission, ServerError } from "../../types";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Card } from "../shared/Card";
import { Page } from "../shared/Page";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { routes } from "../../routes";
import { useRequiredParams } from "../../hooks/useRequiredParams";
import { RoleDetails, rolesApi } from "../../api/rolesApi";
import { permissions } from "../../constants";

export function RoleDetailsView() {
  const { roleId } = useRequiredParams("roleId");

  const [role, loading, error, fetch, setRole] = useApiRequest(
    rolesApi.getRolesDetails,
  );

  useEffect(() => {
    fetch(roleId);
  }, [fetch, roleId]);

  return (
    <Page breadcrumbs={useBreadcrumbs(role?.name)}>
      {loading && <LoadingIndicator />}
      {error && <ErrorDetails error={error} />}

      {role && <RoleDetailsContent role={role} onUpdateSuccess={setRole} />}
    </Page>
  );
}

interface RoleDetailsFormData {
  name: string;
  permissions: Permission[];
}

function RoleDetailsContent({
  role,
  onUpdateSuccess,
}: {
  role: RoleDetails;
  onUpdateSuccess: (updatedRole: RoleDetails) => void;
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<ServerError>();

  const [updateRoleLoading, updateRoleRequest] = useApiRequestCallback(
    rolesApi.updateRole,
  );

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    reset,
    control,
  } = useForm<RoleDetailsFormData>({ defaultValues: role });

  useEffect(() => {
    reset(role);
  }, [role, reset]);

  function onSubmit(data: RoleDetailsFormData) {
    updateRoleRequest({
      onSuccess: (roleDetailsResponse) => {
        onUpdateSuccess(roleDetailsResponse);
        setError(undefined);
      },
      onError: setError,
    }).send(role.id, {
      name: data.name,
      permissions: data.permissions,
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {updateRoleLoading && <LoadingIndicator />}
      <Card>
        {error && <ErrorDetails error={error} />}
        <Stack spacing={4} shouldWrapChildren={true}>
          <FormControl
            id="firstName"
            isRequired={true}
            isInvalid={!!errors.name}
          >
            <FormLabel>{t("general.name")}</FormLabel>
            <Input
              {...register("name", {
                required: { value: true, message: t("error.required") },
              })}
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>{t("role.rights")}</FormLabel>
            <Controller
              name="permissions"
              control={control}
              render={({ field }) => (
                <PermissionsInput
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FormControl>

          <Flex justifyContent="flex-end">
            <Button type="submit" disabled={!isDirty}>
              {t("general.save")}
            </Button>
          </Flex>
        </Stack>
      </Card>
    </form>
  );
}

function PermissionsInput({
  value,
  onChange,
}: {
  value: Permission[];
  onChange: (value: Permission[]) => void;
}) {
  const { t } = useTranslation();

  function togglePermission(permission: Permission) {
    const newPermissions = value.includes(permission)
      ? value.filter((i) => i !== permission)
      : [...value, permission];

    onChange(newPermissions);
  }

  return (
    <Stack>
      {permissions.map((p) => (
        <Checkbox
          key={p}
          isChecked={value.includes(p)}
          onChange={() => togglePermission(p)}
        >
          {t(`permission.${p}`)}
        </Checkbox>
      ))}
    </Stack>
  );
}

function useBreadcrumbs(roleName?: string) {
  const { t } = useTranslation();

  return useMemo<Breadcrumb[]>(
    () => [
      {
        label: t("general.settings"),
        to: routes.settings,
      },
      {
        label: t("role.rights"),
        to: routes.roles,
      },
      {
        label: roleName,
      },
    ],
    [roleName, t],
  );
}
