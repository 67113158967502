import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorDetails } from "../shared/ErrorDetails";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { Controller, useForm } from "react-hook-form";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { ServerError } from "../../types";
import { stylesApi, StyleDetails } from "../../api/stylesApi";
import { Card } from "../shared/Card";

export function StyleDetailsForm({
  styleDetails,
  onUpdate,
}: {
  styleDetails: StyleDetails;
  onUpdate: (styleDetails: StyleDetails) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();

  const [loading, updateCustomerRequest] = useApiRequestCallback(
    stylesApi.updateStyleDetails,
  );
  const [error, setError] = useState<ServerError | null>();
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    reset,
    control,
  } = useForm<FormData>({ defaultValues: styleDetails });

  function handleUpdateSuccess(updatedCustomer: StyleDetails) {
    reset(updatedCustomer);
    setError(null);
    onUpdate(updatedCustomer);
  }

  async function onSubmit({ name, description }: FormData) {
    updateCustomerRequest({
      onSuccess: (customer) => {
        toast({ title: t("general.saved"), status: "success" });
        handleUpdateSuccess(customer);
      },
      onError: setError,
    }).send(styleDetails.id, { name, description });
  }
  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {error && <ErrorDetails error={error} />}
        {loading && <LoadingIndicator />}
        <SimpleGrid spacing={4}>
          <FormControl id="name" isRequired={true} isInvalid={!!errors.name}>
            <FormLabel>{t("general.name")}</FormLabel>
            <Input
              {...register("name", {
                required: { value: true, message: t("error.required") },
              })}
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
          <FormControl id="description" isInvalid={!!errors.description}>
            <FormLabel>{t("general.description")}</FormLabel>
            <Controller
              name="description"
              render={({ field }) => (
                <Textarea value={field.value ?? ""} onChange={field.onChange} />
              )}
              control={control}
            />
            <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
          </FormControl>
          <Flex justifyContent="flex-end">
            <Button type="submit" disabled={!isDirty}>
              {t("general.save")}
            </Button>
          </Flex>
        </SimpleGrid>
      </form>
    </Card>
  );
}

interface FormData {
  name: string;
  description: string;
}
