import {
  OrderConfiguration,
  OrderProductPackage,
  ProductPackageSummary,
  SceneType,
} from "../../../api/externalApi";
import { StyleDetails } from "../../../api/stylesApi";
import {
  SelectedOrderStep,
  SelectedProduct,
  ProductSelection,
  StyleSelection,
} from "./types";

export interface OrderWizardState {
  productPackages: ProductPackageSummary[];
  isStyleInfoAtInitVisible: boolean;
  selectedProductPackage: OrderProductPackage | null;
  kitchenScenes: SceneType[];
  selectedKitchenScene: SceneType | null;
  selectedOrderStep: SelectedOrderStep;
  selectedStyle: StyleDetails | null;
  selectedProductByProductGroupId: Record<string, SelectedProduct>;
}

export type OrderWizardAction =
  | { type: "selectedKitchenScene"; scene: SceneType }
  | { type: "selectedProductPackage"; productPackage: OrderProductPackage }
  | { type: "selectProduct"; selection: ProductSelection }
  | { type: "unSelectProduct"; unSelection: ProductSelection }
  | { type: "selectStyle"; selection: StyleSelection }
  | { type: "selectOrderStep"; step: SelectedOrderStep }
  | {
      type: "setIsStyleInfoAtInitVisible";
      isStyleInfoAtInitVisible: boolean;
    };

export function getInitialState(
  configuration: OrderConfiguration,
): OrderWizardState {
  return {
    productPackages: configuration.productPackages,
    kitchenScenes: configuration.kitchenScenes,
    selectedProductByProductGroupId: {},
    selectedOrderStep: { type: "ProductPackages" },
    selectedProductPackage: null,
    selectedStyle: null,
    selectedKitchenScene: null,
    isStyleInfoAtInitVisible: true,
  };
}

export const orderWizardReducer = (
  state: OrderWizardState,
  action: OrderWizardAction,
): OrderWizardState => {
  switch (action.type) {
    case "selectedProductPackage": {
      return {
        ...state,
        selectedProductPackage: action.productPackage,
        selectedProductByProductGroupId: {},
        selectedOrderStep: { type: "KitchenScene" },
      };
    }
    case "selectedKitchenScene": {
      return {
        ...state,
        selectedKitchenScene: action.scene,
        selectedOrderStep: { type: "Styles" },
        selectedStyle: null,
      };
    }
    case "selectStyle": {
      return {
        ...state,
        selectedStyle: action.selection.styleDetails,
      };
    }

    case "selectProduct": {
      return {
        ...state,
        selectedProductByProductGroupId: {
          ...state.selectedProductByProductGroupId,
          [action.selection.productGroup.id]: {
            productGroup: action.selection.productGroup,
            variant:
              action.selection.variant ?? action.selection.product.variants[0],
            product: action.selection.product,
          },
        },
        selectedStyle: null,
      };
    }

    case "unSelectProduct": {
      const result: Record<string, SelectedProduct> = {
        ...state.selectedProductByProductGroupId,
      };
      delete result[action.unSelection.productGroup.id];
      return {
        ...state,
        selectedProductByProductGroupId: result,
        selectedStyle: null,
      };
    }

    case "selectOrderStep": {
      return {
        ...state,
        selectedOrderStep: action.step,
      };
    }

    case "setIsStyleInfoAtInitVisible": {
      return {
        ...state,
        isStyleInfoAtInitVisible: action.isStyleInfoAtInitVisible,
      };
    }
  }
};
