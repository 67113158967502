import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  productPackageApi,
  ProductPackageImageType,
} from "../../api/productPackageApi";
import { Card } from "../shared/Card";
import { useApiRequestCallback } from "../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../hooks/useToastNotification";
import { Permission, ServerError } from "../../types";
import { ImageList } from "../shared/ImageList";
import { usePermission } from "../../hooks/usePermission";

export function ProductPackageImageForm({
  productPackageId,
  onUpdate,
  title,
  productPackageImageType,
  image,
}: {
  productPackageId: string;
  onUpdate: (image: string | null) => void;
  title: string;
  productPackageImageType: ProductPackageImageType;
  image: string | null;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const hasPermission = usePermission(Permission.ManageProducts);

  const [uploadError, setUploadError] = useState<ServerError | null>(null);
  const [uploadLoading, uploadRequest] = useApiRequestCallback(
    productPackageApi.uploadProductPackageImage,
  );

  const [deleteError, setDeleteError] = useState<ServerError | null>(null);
  const [deleteLoading, deleteRequest] = useApiRequestCallback(
    productPackageApi.deleteProductPackageImage,
  );

  const uploadImage = useCallback(
    (image: File) => {
      uploadRequest({
        onSuccess: (response) => {
          setUploadError(null);
          toast({
            title: t("general.added"),
            status: "success",
          });
          onUpdate(response.image);
        },
        onError: setUploadError,
      }).send(productPackageId, image, productPackageImageType);
    },
    [
      uploadRequest,
      toast,
      t,
      onUpdate,
      productPackageId,
      productPackageImageType,
    ],
  );

  function deleteImage() {
    deleteRequest({
      onSuccess: () => {
        toast({
          title: t("general.deleted"),
          status: "success",
        });
        setDeleteError(null);
        onUpdate(null);
      },
      onError: setDeleteError,
    }).send(productPackageId, productPackageImageType);
  }

  const imageList = useMemo(
    () => (image ? [{ id: "product-package-image", url: image }] : []),
    [image],
  );

  return (
    <Card titleContent={title}>
      <ImageList
        readOnly={!hasPermission}
        maxImages={1}
        images={imageList}
        errors={[deleteError, uploadError]}
        isLoading={deleteLoading || uploadLoading}
        onUpload={uploadImage}
        onRemove={deleteImage}
      />
    </Card>
  );
}
