import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Input,
  Heading,
  Box,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  customersApi,
  ContactDetails,
  CustomerDetails,
} from "../../../api/customersApi";
import {
  WorkPlanDetails,
  workPlansApi,
  WorkPlanStatus,
} from "../../../api/workPlansApi";
import { useApiRequest } from "../../../hooks/useApi/useApiRequest";
import { useApiRequestCallback } from "../../../hooks/useApi/useApiRequestCallback";
import { useConfirmation } from "../../../hooks/useConfirmation";
import { useToastNotification } from "../../../hooks/useToastNotification";
import { ServerError } from "../../../types";
import { CustomerAsyncSelect } from "../../shared/AsyncSelect/CustomerAsyncSelect";
import { PackageAsyncSelect } from "../../shared/AsyncSelect/PackageAsyncSelect";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { LoadingIndicator } from "../../shared/LoadingIndicator";

interface FormData {
  name: string;
  contactDetailsCustomerId: string | null;
  packageId: string | null;
  status: WorkPlanStatus;
  startDate: Date;
}

export function WorkPlanDetailsForm({
  workPlan,
  onUpdate,
  customer,
  onWorkPlanDetailsUpdated,
}: {
  workPlan: WorkPlanDetails;
  onUpdate: (details: WorkPlanDetails) => void;
  customer: CustomerDetails;
  onWorkPlanDetailsUpdated: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [confirm, ConfirmationModal] = useConfirmation();
  const [error, setError] = useState<ServerError | null>();
  const [isUpdatingWorkPlan, updateWorkPlan] = useApiRequestCallback(
    workPlansApi.updateWorkPlan,
  );

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    reset,
    control,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      ...workPlan,
      startDate: workPlan.startDate ? new Date(workPlan.startDate) : undefined,
    },
  });

  const currentCustomerId = watch("contactDetailsCustomerId");

  function handleUpdateSuccess(updatedWorkPlan: WorkPlanDetails) {
    reset({
      ...updatedWorkPlan,
      startDate: updatedWorkPlan.startDate
        ? new Date(updatedWorkPlan.startDate)
        : undefined,
    });
    setError(null);
    onWorkPlanDetailsUpdated();
    onUpdate(updatedWorkPlan);
    toast({
      title: t("general.saved"),
      status: "success",
    });
  }

  function onSubmit(data: FormData) {
    if (workPlan.packageId !== data.packageId) {
      confirm({
        message: t("workPlan.editPackageWarning"),
        confirmButtonText: t("general.continue"),
        confirmButtonColor: "red",
        onConfirmation: () => {
          submitUpdateWorkPlan(data);
        },
      });
    } else {
      submitUpdateWorkPlan(data);
    }
  }

  const submitUpdateWorkPlan = ({
    name,
    contactDetailsCustomerId,
    status,
    packageId,
    startDate,
  }: FormData) => {
    updateWorkPlan({
      onSuccess: handleUpdateSuccess,
      onError: setError,
    }).send(workPlan.id, {
      name,
      status,
      contactDetailsCustomerId,
      packageId,
      startDate,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        {error && <ErrorDetails error={error} />}
        {isUpdatingWorkPlan && <LoadingIndicator />}
        <SimpleGrid columns={3} spacing={4}>
          <FormControl id="name" isRequired={true} isInvalid={!!errors.name}>
            <FormLabel>{t("general.name")}</FormLabel>
            <Input
              {...register("name", {
                required: { value: true, message: t("error.required") },
              })}
            />
          </FormControl>
          <FormControl
            id="contactDetailsCustomerId"
            isRequired={false}
            isInvalid={!!errors.contactDetailsCustomerId}
          >
            <FormLabel>{t("customer.customer")}</FormLabel>
            <Controller
              name="contactDetailsCustomerId"
              render={({ field, fieldState }) => (
                <CustomerAsyncSelect
                  {...field}
                  {...fieldState}
                  isClearable={true}
                />
              )}
              control={control}
            />
          </FormControl>
          <FormControl id="packageId" isInvalid={!!errors.packageId}>
            <FormLabel>{t("productPackage.productPackage")}</FormLabel>
            <Controller
              name="packageId"
              render={({ field, fieldState }) => (
                <PackageAsyncSelect
                  customer={customer}
                  {...field}
                  {...fieldState}
                />
              )}
              control={control}
            />
          </FormControl>
          <FormControl id="status">
            <FormLabel>{t("general.active")}</FormLabel>
            <Controller
              name="status"
              render={({ field }) => (
                <Flex alignItems="center" height="38px">
                  <RadioGroup
                    onChange={(status) => {
                      field.onChange(status as WorkPlanStatus);
                    }}
                    value={field.value}
                  >
                    <Stack direction="row">
                      <Radio value={WorkPlanStatus.Active}>
                        {t("general.yes")}
                      </Radio>
                      <Radio value={WorkPlanStatus.Inactive}>
                        {t("general.no")}
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Flex>
              )}
              control={control}
            />
          </FormControl>
          <FormControl id="startDate">
            <FormLabel>{t("workPlan.startDate")}</FormLabel>
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <Input
                  type="date"
                  defaultValue={
                    workPlan.startDate
                      ? workPlan.startDate.slice(0, 10)
                      : undefined
                  }
                  onChange={(date) => {
                    field.onChange(new Date(date.target.value), "yyyy-MM-dd");
                  }}
                />
              )}
            />
          </FormControl>
        </SimpleGrid>

        <ContactDetailsRows customerId={currentCustomerId} />

        <Flex justifyContent="flex-end" mt={4}>
          <Button type="submit" disabled={!isDirty}>
            {t("general.save")}
          </Button>
        </Flex>
      </form>
      {ConfirmationModal}
    </>
  );
}

function ContactDetailsRows({ customerId }: { customerId: string | null }) {
  const { t } = useTranslation();
  const [
    contactDetails,
    loadingContactDetails,
    contactDetailsError,
    fetchContactDetails,
    setContactDetails,
  ] = useApiRequest(customersApi.getCustomerContactDetails);

  useEffect(() => {
    if (customerId) {
      fetchContactDetails(customerId);
    } else {
      setContactDetails(null);
    }
  }, [customerId, fetchContactDetails, setContactDetails]);

  return (
    <Box position="relative">
      {loadingContactDetails && <LoadingIndicator />}
      {contactDetailsError && <ErrorDetails error={contactDetailsError} />}
      <ContactDetailsRow
        title={t("workPlan.fitter")}
        contactDetails={contactDetails?.fitterContactDetails ?? null}
      />
      <ContactDetailsRow
        title={t("workPlan.seller")}
        contactDetails={contactDetails?.sellerContactDetails ?? null}
      />
      <ContactDetailsRow
        title={t("workPlan.contractOwner")}
        contactDetails={contactDetails?.contractOwnerContactDetails ?? null}
      />
    </Box>
  );
}

function ContactDetailsRow({
  title,
  contactDetails,
}: {
  title: string;
  contactDetails: ContactDetails | null;
}) {
  const { t } = useTranslation();

  return (
    <Box mt={6}>
      <Heading size="sm">{title}</Heading>
      <SimpleGrid columns={3} spacing={4} mt={2}>
        <FormControl>
          <FormLabel>{t("general.name")}</FormLabel>
          <Input
            value={contactDetails?.name ?? ""}
            readOnly={true}
            variant="filled"
          />
        </FormControl>
        <FormControl>
          <FormLabel>{t("general.email")}</FormLabel>
          <Input
            value={contactDetails?.email ?? ""}
            readOnly={true}
            variant="filled"
          />
        </FormControl>
        <FormControl>
          <FormLabel>{t("general.phoneNumber")}</FormLabel>
          <Input
            value={contactDetails?.phoneNumber ?? ""}
            readOnly={true}
            variant="filled"
          />
        </FormControl>
      </SimpleGrid>
    </Box>
  );
}
