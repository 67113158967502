import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ProductVariantDetails,
  productVariantsApi,
} from "../../../api/productVariantsApi";
import { useApiRequestCallback } from "../../../hooks/useApi/useApiRequestCallback";
import { useToastNotification } from "../../../hooks/useToastNotification";
import { ServerError } from "../../../types";
import { ErrorDetails } from "../../shared/ErrorDetails";
import { LoadingIndicator } from "../../shared/LoadingIndicator";

export default function CreateProductVariantModal({
  productId,
  onClose,
  onSuccess,
}: {
  productId: string;
  onClose: () => void;
  onSuccess: (productVariant: ProductVariantDetails) => void;
}) {
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalFormContent productId={productId} onSuccess={onSuccess} />
      </ModalContent>
    </Modal>
  );
}

interface ProductVariantFormData {
  name: string;
}

function ModalFormContent({
  productId,
  onSuccess,
}: {
  productId: string;
  onSuccess: (productVariant: ProductVariantDetails) => void;
}) {
  const { t } = useTranslation();
  const toast = useToastNotification();
  const [error, setError] = useState<ServerError>();

  const [loading, request] = useApiRequestCallback(
    productVariantsApi.createProductVariant,
  );

  async function onSubmit(data: ProductVariantFormData) {
    request({
      onSuccess: (variant) => {
        onSuccess(variant);
        toast({ title: t("general.saved"), status: "success" });
      },
      onError: setError,
    }).send({
      productId: productId,
      name: data.name,
    });
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<ProductVariantFormData>();

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <ModalHeader>
        {t("general.add")} {t("productVariant.productVariant").toLowerCase()}
      </ModalHeader>
      <ModalBody>
        {loading && <LoadingIndicator />}
        {error && <ErrorDetails error={error} />}
        <FormControl id="name" isRequired={true} isInvalid={!!errors.name}>
          <FormLabel>{t("general.name")}</FormLabel>
          <Input
            {...register("name", {
              required: { value: true, message: t("error.required") },
            })}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button type="submit">{t("general.save")}</Button>
      </ModalFooter>
    </form>
  );
}
