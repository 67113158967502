import { useBreakpointValue } from "@chakra-ui/react";
import {
  ScreenOrientation,
  useScreenOrientation,
} from "./useScreenOrientation";

export function useCompactSideMenu() {
  const isSmallScreen = useBreakpointValue({ base: true, lg: false });
  const isScreenPortrait =
    useScreenOrientation() === ScreenOrientation.Portrait;

  if (!isSmallScreen) {
    return true;
  }
  return isScreenPortrait;
}
